import React, { FC, useState } from "react";
import styles from "./index.module.scss";
import dayjs, { Dayjs } from "dayjs";
import {
  Box,
  Button,
  Typography,
  Menu,
  MenuItem,
  FormControl,
  Popover,
} from "@mui/material";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import KeyboardArrowLeftRoundedIcon from "@mui/icons-material/KeyboardArrowLeftRounded";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import { FormattedDate, FormattedMessage } from "react-intl";
import { CustomDay } from "./CustomDay";

interface Props {
  selectedDate: Dayjs;
  setSelectedDate: React.Dispatch<React.SetStateAction<Dayjs>>;
  week: Dayjs;
  setWeek: React.Dispatch<React.SetStateAction<Dayjs>>;
  weekRange: any;
  selectedMenuItem: string;
  setSelectedMenuItem: React.Dispatch<React.SetStateAction<string>>;
}

const DateComponent: FC<Props> = ({
  selectedDate,
  setSelectedDate,
  week,
  setWeek,
  weekRange,
  selectedMenuItem,
  setSelectedMenuItem,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [popoverAnchorEl, setPopoverAnchorEl] = useState<null | HTMLElement>(
    null
  );

  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const handleClickShowMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setPopoverAnchorEl(null);
  };

  const handleMenuItemClick = (menuItemText: string) => {
    setSelectedMenuItem(menuItemText);
    handleClose();
  };

  const handleDateChange = (date: Dayjs | null) => {
    if (date !== null) {
      setSelectedDate(date);
    }
    setIsCalendarOpen(false);
  };

  const handleTypographyClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setPopoverAnchorEl(event.currentTarget);
    setIsCalendarOpen(true);
  };

  const handleDecrementDate = () => {
    if (selectedMenuItem === "Day") {
      const newDate = selectedDate.subtract(1, "day");
      setSelectedDate(newDate);
    } else if (selectedMenuItem === "Month") {
      const newDate = selectedDate.subtract(1, "month");
      setSelectedDate(newDate);
    } else if (selectedMenuItem === "Week") {
      const newWeek = week.subtract(1, "week");
      setWeek(newWeek);
    }
  };

  const handleIncrementDate = () => {
    if (selectedMenuItem === "Day") {
      const maxDate = dayjs().add(1, "day");
      const newDate = dayjs(selectedDate).add(1, "day");
      if (newDate.isBefore(maxDate, "day")) {
        setSelectedDate(newDate);
      }
    } else if (selectedMenuItem === "Month") {
      const maxDate = dayjs().add(1, "month");
      const newDate = selectedDate.add(1, "month");
      if (newDate.isBefore(maxDate, "day")) {
        setSelectedDate(newDate);
      }
    } else if (selectedMenuItem === "Week") {
      const newWeek = week.add(1, "week");

      if (newWeek.isBefore(dayjs(), "day") || newWeek.isSame(dayjs(), "day")) {
        setWeek(newWeek);
      }
    }
  };

  return (
    <Box
      className={styles.container}
      display="flex"
      sx={{
        maxHeight: "30px",
        alignItems: "center",
        flexWrap: "wrap",
        gap: "10px 20px",
      }}
    >
      <Menu
        className={styles.menu}
        id="basic-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={() => handleMenuItemClick("Day")}>
          <FormattedMessage id="menu.day" defaultMessage="Day" />
        </MenuItem>
        <MenuItem onClick={() => handleMenuItemClick("Month")}>
          <FormattedMessage id="menu.month" defaultMessage="Month" />
        </MenuItem>
        <MenuItem onClick={() => handleMenuItemClick("Week")}>
          <FormattedMessage id="menu.week" defaultMessage="Week" />
        </MenuItem>
      </Menu>

      <FormControl className={styles.formControl}>
        <Box
          className={styles.formBox}
          sx={{ display: "flex", maxHeight: "30px" }}
        >
          <Button
            sx={{ p: "5px 9px", maxWidth: "48px" }}
            onClick={handleDecrementDate}
          >
            <KeyboardArrowLeftRoundedIcon />
          </Button>

          <Typography
            onClick={handleTypographyClick}
            className={styles.formText}
            component="div"
            sx={{
              minWidth: "107px",
              p: "8px 17px 7px 17px",
              whiteSpace: "nowrap",
            }}
          >
            {selectedMenuItem === "Day" && (
              <>
                <FormattedDate
                  value={selectedDate.toDate()}
                  day="numeric"
                  month="long"
                />{" "}
                {selectedDate.year()}
              </>
            )}
            {selectedMenuItem === "Month" && (
              <>
                <FormattedDate value={selectedDate.toDate()} month="long" />{" "}
                {selectedDate.year()}
              </>
            )}
            {selectedMenuItem === "Week" && (
              <>
                <FormattedDate
                  value={weekRange.start.toDate()}
                  month="short"
                  day="2-digit"
                />
                {" - "}
                <FormattedDate
                  value={weekRange.end.toDate()}
                  month="short"
                  day="2-digit"
                />{" "}
                {weekRange.end.year()}
              </>
            )}
          </Typography>
          {popoverAnchorEl && (
            <Popover
              open={isCalendarOpen}
              onClose={handleClose}
              anchorEl={popoverAnchorEl}
              className={styles.formText}
            >
              {selectedMenuItem === "Week" ? (
                <CustomDay week={week} setWeek={setWeek} />
              ) : (
                <DateCalendar
                  value={selectedDate}
                  onChange={handleDateChange}
                  openTo={selectedMenuItem === "Month" ? "month" : "day"}
                  views={["day", "month"]}
                  showDaysOutsideCurrentMonth
                  fixedWeekNumber={6}
                  maxDate={dayjs()}
                />
              )}
            </Popover>
          )}
          <Button
            className={styles.formIncBtn}
            sx={{
              p: "5px 9px",
              maxWidth: "48px",
            }}
            onClick={handleIncrementDate}
          >
            <KeyboardArrowRightRoundedIcon />
          </Button>
        </Box>
      </FormControl>

      <Button
        className={styles.menuBtn}
        id="basic-button"
        aria-haspopup="true"
        onClick={handleClickShowMenu}
      >
        {selectedMenuItem === "Day" && (
          <FormattedMessage id="menu.day" defaultMessage="Day" />
        )}
        {selectedMenuItem === "Month" && (
          <FormattedMessage id="menu.month" defaultMessage="Month" />
        )}
        {selectedMenuItem === "Week" && (
          <FormattedMessage id="menu.week" defaultMessage="Week" />
        )}
        <KeyboardArrowDownRoundedIcon sx={{ ml: "8px" }} />
      </Button>
    </Box>
  );
};

export default DateComponent;
