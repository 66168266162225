import { FC } from "react";
import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material";
import { ReactComponent as ExclamationCircle } from "../../assets/ExclamationCircle.svg";
import { FormattedMessage } from "react-intl";
import styles from "./index.module.scss";

interface Props {}

export const DeleteAccountButton: FC<Props> = () => {
  return (
    <>
      <ExclamationCircle />
      <Typography className={styles.title}>
        <FormattedMessage
          id="actionDeleteAccountForm"
          defaultMessage="Please read this carefully. This is important information."
        />
      </Typography>
      <Typography
        sx={{
          pl: 1,
          pr: 1,
          textAlign: "center",
          fontWeight: 400,
          fontSize: "16px",
        }}
        className={styles.secondaryText}
      >
        <FormattedMessage
          id="actionDeleteAccountFormText"
          defaultMessage="If you attempt to delete your Kizwork account, your data will be lost. If you have outstanding financial transactions, you will need to pay for them."
        />
      </Typography>

      <Box alignItems="center" sx={{ p: 8 }}>
        <FormControlLabel
          sx={{
            "& .MuiFormControlLabel-asterisk": {
              display: "none",
            },
          }}
          control={<Checkbox required size="small" />}
          label={
            <Typography
              className={styles.secondaryText}
              sx={{ fontWeight: 400, fontSize: "14px" }}
            >
              <FormattedMessage
                id="actionDeleteAccountFormCheckOne"
                defaultMessage="Yes, I undertake to pay for all outstanding financial transactions and understand that under certain circumstances I may not receive my profits."
              />
            </Typography>
          }
        />
        <FormControlLabel
          sx={{
            "& .MuiFormControlLabel-asterisk": {
              display: "none",
            },
          }}
          control={<Checkbox required size="small" />}
          label={
            <Typography
              className={styles.secondaryText}
              sx={{ fontWeight: 400, fontSize: "14px" }}
            >
              <FormattedMessage
                id="actionDeleteAccountFormCheckTwo"
                defaultMessage="Yes, I want to permanently delete this Kizwork account and all its data."
              />
            </Typography>
          }
        />
      </Box>
    </>
  );
};
