import React, { ChangeEvent, useRef, useState } from "react";
import styles from "./index.module.scss";
import { ReactComponent as UploadSvg } from "../../assets/uploadSvg.svg";
import {
  Button,
  Modal,
  Container,
  Box,
  Typography,
  IconButton,
  Divider,
  FormControl,
  OutlinedInput,
  FormHelperText,
} from "@mui/material";
import { loader } from "graphql.macro";
import { Mutation } from "@apollo/client/react/components";
import Error from "../../components/Error";
import { Formik, FormikErrors } from "formik";
import { faEdit } from "@fortawesome/free-regular-svg-icons/faEdit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormattedMessage, useIntl } from "react-intl";
import Thumb from "../Thumb";
import { ReactComponent as CloseSvg } from "../../assets/CloseSvg.svg";

const UPDATE_ORGANIZATION = loader(
  "../../schema/mutation/UpdateOrganization.graphql"
);

interface Props {
  organizationId: string;
  name: string | null;
  logoId: string | null;
  premiumType: string;
}

interface Data {
  updateOrganizationOrganization: Organization;
}

interface FormValues {
  name: string;
  logo: File | null;
}

const UpdateOrganizationModal: React.FC<Props> = ({
  organizationId,
  name,
  logoId,
  premiumType,
}) => {
  const intl = useIntl();
  const [uploadedLogoId, setUploadedLogoId] = useState<string | null>(
    logoId || null
  );
  const imageField = useRef(null);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const initialValues: FormValues = {
    name: (name !== "Personal" ? name : "") || "",
    logo: null,
  };

  return (
    <>
      <Button
        sx={{ mr: "20px" }}
        onClick={handleShow}
        variant="contained"
        color="secondary"
        startIcon={<FontAwesomeIcon icon={faEdit} />}
      >
        <FormattedMessage
          id="manage-organization.edit-info"
          defaultMessage="Edit info"
        />
      </Button>

      <Modal open={show} onClose={handleClose}>
        <Container
          disableGutters
          maxWidth={false}
          className={styles.container}
          sx={{
            bgcolor: "background.paper",
          }}
        >
          <Mutation<Data, FormValues>
            mutation={UPDATE_ORGANIZATION}
            onCompleted={handleClose}
          >
            {(updateOrganization, { error }) => (
              <Formik
                initialValues={initialValues}
                validate={(values: FormValues) => {
                  const errors: FormikErrors<FormValues> = {};
                  if (!values.name) {
                    errors.name = intl.formatMessage({
                      id: "errors.required",
                      defaultMessage: "Required",
                    });
                  }
                  return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                  let data = {
                    ...values,
                    organizationId: organizationId,
                  };
                  // @ts-ignore-line
                  delete data.logo;

                  if (uploadedLogoId === null && values.logo) {
                    const formData = new FormData();
                    // @ts-ignore-line
                    formData.append("owned_file", values.logo);
                    fetch("/files", {
                      method: "POST",
                      body: formData,
                    })
                      .then((response) => response.json())
                      .then((result) => {
                        // @ts-ignore-line
                        data.logoId = result.data.id;
                        setUploadedLogoId(result.data.id);
                        updateOrganization({
                          variables: data,
                        })
                          .then(() => {
                            // @ts-ignore-line
                            if (typeof _paq !== "undefined")
                              // @ts-ignore-line
                              _paq.push([
                                "trackEvent",
                                "Accounts",
                                "Update organization",
                              ]);
                          })
                          .catch(() => {
                            setSubmitting(false);
                          });
                      })
                      .catch((error) => {
                        console.error("Error:", error);
                      });
                  } else {
                    // @ts-ignore-line
                    data.logoId = uploadedLogoId;

                    updateOrganization({
                      variables: data,
                    })
                      .then(() => {
                        // @ts-ignore-line
                        if (typeof _paq !== "undefined")
                          // @ts-ignore-line
                          _paq.push([
                            "trackEvent",
                            "Accounts",
                            "Update organization",
                          ]);
                      })
                      .catch(() => {
                        setSubmitting(false);
                      });
                  }
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                }) => (
                  <>
                    <Typography className={styles.title}>
                      <FormattedMessage
                        id="manage-organization.modification-organization"
                        defaultMessage="Modification of the organization"
                      />
                      <IconButton onClick={handleClose}>
                        <CloseSvg />
                      </IconButton>
                    </Typography>

                    <Box
                      component="form"
                      id="upDateOrg"
                      onSubmit={handleSubmit}
                    >
                      <Box sx={{ p: 3 }}>
                        <Error error={error} />
                        <Box className={styles.inlineField}>
                          <Typography
                            component="label"
                            className={styles.label}
                          >
                            <FormattedMessage
                              id="manage-organization.name-field"
                              defaultMessage="Organization's name"
                            />
                          </Typography>
                          <FormControl fullWidth sx={{ maxWidth: "414px" }}>
                            <OutlinedInput
                              name="name"
                              id="name"
                              type="text"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.name}
                              error={!!errors.name}
                              required
                            />
                            {errors.name && touched.name && (
                              <FormHelperText error>
                                {errors.name}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Box>
                        {premiumType === "NONE" ? (
                          <Box display="flex" flexDirection="column">
                            <Box className={styles.inlineField}>
                              <Typography
                                component="label"
                                className={styles.label}
                              >
                                <FormattedMessage
                                  id="organization.organization-logo"
                                  defaultMessage="Organization's logo"
                                />
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: "14px",
                                  fontWeight: 400,
                                  maxWidth: "415px",
                                }}
                                className={styles.text}
                              >
                                <FormattedMessage
                                  id="manage-organizations.logo-usage"
                                  defaultMessage="The logo will be used on the wall in the 3d rooms."
                                />
                              </Typography>
                            </Box>

                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <Typography className={styles.subscriptionLink}>
                                <FormattedMessage
                                  id="manage.upgrade-to-business-offer-for-logo"
                                  defaultMessage="<a>Subscribe to the Business plan</a> to unlock this feature."
                                  values={{
                                    a: (...chunks) => (
                                      <a href="/pricing">{chunks}</a>
                                    ),
                                  }}
                                />
                              </Typography>
                            </Box>
                          </Box>
                        ) : (
                          <>
                            <Box
                              display="flex"
                              justifyContent="space-between"
                              flexWrap="wrap"
                            >
                              <Typography
                                component="label"
                                className={styles.label}
                              >
                                <FormattedMessage
                                  id="organization.organization-logo"
                                  defaultMessage="Organization's logo"
                                />
                              </Typography>
                              <FormControl sx={{ maxWidth: "409px" }}>
                                <Box>
                                  <Typography
                                    sx={{ fontSize: "14px", fontWeight: 400 }}
                                    className={styles.text}
                                  >
                                    <FormattedMessage
                                      id="manage-organizations.logo-usage"
                                      defaultMessage="The logo will be used on the wall in the 3d rooms."
                                    />
                                  </Typography>
                                  <Typography
                                    sx={{ fontSize: "14px", fontWeight: 400 }}
                                    mb="20px"
                                    className={styles.text}
                                  >
                                    <FormattedMessage
                                      id="manage-organizations.logo-format"
                                      defaultMessage="The image have to be a transparent png of size 512x512."
                                    />
                                  </Typography>
                                </Box>
                              </FormControl>
                            </Box>

                            <FormControl fullWidth>
                              {values.logo || uploadedLogoId ? (
                                <Box
                                  maxWidth="116px"
                                  className={styles.logoContainer}
                                >
                                  {(values.logo || uploadedLogoId) && (
                                    <Box
                                      display="flex"
                                      alignItems="center"
                                      justifyContent="space-between"
                                    >
                                      <Typography
                                        className={styles.text}
                                        sx={{
                                          fontSize: "12px",
                                          fontWeight: 500,
                                          pb: "5px",
                                        }}
                                      >
                                        logo.png
                                      </Typography>
                                      <IconButton
                                        sx={{
                                          p: 0,
                                          maxHeight: "16px",
                                          maxWidth: "16px",
                                        }}
                                        onClick={() => {
                                          setUploadedLogoId(null);
                                          setFieldValue("logo", null);
                                        }}
                                      >
                                        <CloseSvg />
                                      </IconButton>
                                    </Box>
                                  )}
                                  {values.logo && <Thumb file={values.logo} />}

                                  {!values.logo && uploadedLogoId && (
                                    <div style={{ width: "116px" }}>
                                      <a
                                        href={`/files/${uploadedLogoId}`}
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        <img
                                          src={`/files/${uploadedLogoId}`}
                                          alt={intl.formatMessage({
                                            id: "organization.organization-logo",
                                            defaultMessage:
                                              "Organization's logo",
                                          })}
                                          className="img-thumbnail"
                                        />
                                      </a>
                                    </div>
                                  )}
                                </Box>
                              ) : (
                                <Box
                                  className={styles.uploadBox}
                                  component="label"
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    position: "relative",
                                    "&.hover": {
                                      borderColor: "primary.main",
                                    },
                                  }}
                                  htmlFor="logo"
                                  ref={imageField}
                                >
                                  <UploadSvg />
                                  <Typography color="primary">
                                    <FormattedMessage
                                      id="avatar.form-upload-file"
                                      defaultMessage="Upload a file"
                                    />
                                    <Box component="span" sx={{ pl: "4px" }}>
                                      <FormattedMessage
                                        id="avatar.form-drop-text"
                                        defaultMessage="or drag and drop"
                                      />
                                    </Box>
                                  </Typography>
                                  <Typography className={styles.helpedText}>
                                    <FormattedMessage
                                      id="upDateOrga.formHelpedText"
                                      defaultMessage="(png of size 512x512)"
                                    />
                                  </Typography>
                                  <input
                                    accept="image/png"
                                    name="logo"
                                    id="logo"
                                    style={{
                                      position: "absolute",
                                      inset: 0,
                                      opacity: 0,
                                      cursor: "pointer",
                                    }}
                                    type="file"
                                    onChange={(
                                      event: ChangeEvent<HTMLInputElement>
                                    ) => {
                                      setUploadedLogoId(null);
                                      setFieldValue(
                                        "logo",
                                        event.currentTarget.files
                                          ? event.currentTarget.files[0]
                                          : null
                                      );
                                    }}
                                    onBlur={handleBlur}
                                    onDragEnter={() => {
                                      if (imageField.current !== null) {
                                        // @ts-ignore-line
                                        imageField.current.classList.add(
                                          "hover"
                                        );
                                      }
                                    }}
                                    onDrop={() => {
                                      if (imageField.current !== null) {
                                        // @ts-ignore-line
                                        imageField.current.classList.remove(
                                          "hover"
                                        );
                                      }
                                    }}
                                  />
                                </Box>
                              )}
                            </FormControl>
                          </>
                        )}
                      </Box>
                    </Box>
                    <Divider />
                    <Box className={styles.modalFooter}>
                      <Button
                        onClick={handleClose}
                        variant="outlined"
                        color="secondary"
                      >
                        <FormattedMessage
                          id="actions.cancel"
                          defaultMessage="Cancel"
                        />
                      </Button>

                      <Button
                        type="submit"
                        variant="contained"
                        form="upDateOrg"
                        disabled={isSubmitting}
                      >
                        <FormattedMessage
                          id="actions.update"
                          defaultMessage="Update"
                        />
                      </Button>
                    </Box>
                  </>
                )}
              </Formik>
            )}
          </Mutation>
        </Container>
      </Modal>
    </>
  );
};

export default UpdateOrganizationModal;
