import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import * as Yup from "yup";
import styles from "./index.module.scss";
import { FormattedMessage } from "react-intl";
import { useFormik } from "formik";
import GetLocationsAutoComplete from "./GetLocationsAutoComplete";
import { useState } from "react";
import {
  transport,
  getDistance,
  calculateCo2ForDays,
} from "../../../lib/helpers";

interface FormValues {
  homeAddress: string;
  officeAddress: string;
  officeDays: number;
  remoteDays: number;
  selectedMode: string;
}

const validationSchema = Yup.object().shape({
  officeDays: Yup.number()
    .required("Office days is required")
    .min(0, "Office days cannot be negative")
    .max(7, "Office days cannot exceed 7"),
  remoteDays: Yup.number()
    .required("Remote days is required")
    .min(0, "Remote days cannot be negative")
    .max(7, "Remote days cannot exceed 7"),
});

export const Credits = () => {
  const [result, setResult] = useState(false);
  const [calculatedEmissions, setCalculatedEmissions] = useState(0);
  const [calculatedAvoided, setCalculatedAvoided] = useState(0);

  const initialValues: FormValues = {
    homeAddress: "",
    officeAddress: "",
    officeDays: 0,
    remoteDays: 0,
    selectedMode: "",
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      handleCalculate();
    },
  });
  const { officeDays, remoteDays, selectedMode } = formik.values;

  const handleCalculate = async () => {
    try {
      const dist = (await getDistance(
        formik.values.homeAddress,
        formik.values.officeAddress
      )) as string;

      const co2Emissions = calculateCo2ForDays(
        dist,
        officeDays,
        selectedMode,
        transport
      );
      const co2Avoided = calculateCo2ForDays(
        dist,
        remoteDays,
        selectedMode,
        transport
      );
      setResult(true);
      setCalculatedEmissions(co2Emissions);
      setCalculatedAvoided(co2Avoided);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Typography
        sx={{ display: "flex", justifyContent: "flex-start", mb: 5 }}
        className={styles.title}
      >
        <FormattedMessage
          id="settings-carbonCredits"
          defaultMessage="Carbon Credits"
        />
      </Typography>

      <Box
        onSubmit={formik.handleSubmit}
        component="form"
        id="calculate"
        mt="24px"
        display="flex"
        flexDirection="column"
      >
        <Box
          display="flex"
          justifyContent="space-between"
          flexWrap="wrap"
          sx={{ width: "100%", mb: "10px" }}
        >
          <FormControl
            sx={{ mr: "24px", maxWidth: "47%" }}
            className={styles.inputWithLabel}
          >
            <Typography>
              <FormattedMessage
                id="carbonCreditsFormHome"
                defaultMessage="Home address"
              />
            </Typography>

            <GetLocationsAutoComplete
              getAddress={(description: string) => {
                formik.setFieldValue("homeAddress", description);
              }}
            />
          </FormControl>
          <FormControl
            sx={{ maxWidth: "47%" }}
            className={styles.inputWithLabel}
          >
            <Typography>
              <FormattedMessage
                id="carbonCreditsFormOffice"
                defaultMessage="Office address"
              />
            </Typography>
            <GetLocationsAutoComplete
              getAddress={(description: string) => {
                formik.setFieldValue("officeAddress", description);
              }}
            />
          </FormControl>

          <Box display="flex" flexWrap="wrap">
            <FormControl
              sx={{ maxWidth: "45%", mr: "18px" }}
              className={styles.inputWithLabel}
            >
              <Typography>
                <FormattedMessage
                  id="carbonCreditsFormOfficeDays"
                  defaultMessage="Office (days per week)"
                />
              </Typography>
              <TextField
                type="number"
                required
                sx={{ maxHeight: "42px", minHeight: "32px" }}
                fullWidth
                id="officeDays"
                name="officeDays"
                value={formik.values.officeDays}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                helperText={
                  <span style={{ color: "red" }}>
                    {formik.errors.officeDays}
                  </span>
                }
              />
            </FormControl>
            <FormControl
              sx={{ maxWidth: "49%" }}
              className={styles.inputWithLabel}
            >
              <Typography sx={{ whiteSpace: "nowrap" }}>
                <FormattedMessage
                  id="carbonCreditsFormRemoteDays"
                  defaultMessage="Remote (days per week)"
                />
              </Typography>
              <TextField
                type="number"
                required
                sx={{ maxHeight: "42px", minHeight: "32px" }}
                fullWidth
                id="remoteDays"
                name="remoteDays"
                value={formik.values.remoteDays}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                helperText={
                  <span style={{ color: "red" }}>
                    {formik.errors.remoteDays}
                  </span>
                }
              />
            </FormControl>
          </Box>

          <FormControl
            sx={{ maxWidth: "47%" }}
            className={styles.inputWithLabel}
          >
            <Typography>
              <FormattedMessage
                id="carbonCreditsFormTransportationMode"
                defaultMessage="Transportation mode"
              />
            </Typography>

            <Select
              required
              sx={{ maxHeight: "42px", minHeight: "32px" }}
              fullWidth
              id="selectedMode"
              name="selectedMode"
              value={formik.values.selectedMode}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              inputProps={{ "aria-label": "Without label" }}
              // renderValue={(selected) => {
              //   if (Array.isArray(selected)) {
              //     if (selected.length === 0) {
              //       return (
              //         <FormattedMessage
              //           id="calcTrasportSelect"
              //           defaultMessage="Choose the transport you are using"
              //         />
              //       );
              //     }
              //     return selected.join(", ");
              //   } else {
              //     return selected;
              //   }
              // }}
            >
              {/* <MenuItem disabled value="">
                <FormattedMessage
                  id="calcTrasportSelect"
                  defaultMessage="Choose the transport you are using"
                />
              </MenuItem> */}
              {transport.map((item, index) => (
                <MenuItem key={index} value={item.label}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Box>
      <Box display="flex" mt={3} flexWrap="wrap">
        <Box className={styles.cardBox} sx={{ mr: "auto" }}>
          {result && (
            <>
              <Box
                className={styles.issuedBox}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  p: "16px 45px",
                }}
              >
                <Typography sx={{ whiteSpace: "nowrap" }}>
                  {calculatedEmissions.toFixed(3)} kgCO2
                </Typography>
                <Typography
                  component="span"
                  sx={{ fontSize: "12px", fontWeight: 400 }}
                >
                  <FormattedMessage
                    id="calc-issued"
                    defaultMessage="issued over {days} days"
                    values={{
                      days: officeDays,
                    }}
                  />
                </Typography>
              </Box>

              <Box
                className={styles.avoidedBox}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  p: "16px 45px",
                }}
              >
                <Typography sx={{ whiteSpace: "nowrap" }}>
                  {calculatedAvoided.toFixed(3)} kgCO2
                </Typography>
                <Typography
                  component="span"
                  sx={{ fontSize: "12px", fontWeight: 400 }}
                >
                  <FormattedMessage
                    id="calc-avoided"
                    defaultMessage="avoided over {days} days"
                    values={{
                      days: remoteDays,
                    }}
                  />
                </Typography>
              </Box>
            </>
          )}
        </Box>

        <Box className={styles.modalFooter}>
          <Button
            form="calculate"
            variant="contained"
            color="primary"
            type="submit"
          >
            <FormattedMessage
              id="carbonCreditsFormCalculate"
              defaultMessage="Calculate"
            />
          </Button>
        </Box>
      </Box>
    </>
  );
};
