import styles from "../../assets/stylesheets/modal.module.scss";
import {
  Box,
  Button,
  Container,
  Divider,
  IconButton,
  Modal,
  OutlinedInput,
  Tooltip,
  Typography,
} from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { ReactComponent as Pencil } from "../../assets/editSvg.svg";
import { useState } from "react";
import { FormikValues, useFormik } from "formik";
import { ReactComponent as CloseSvg } from "../../assets/CloseSvg.svg";
import type { ApiKey } from "./APIKeys";

interface Props {
  apikey: ApiKey;
  onCompleted: (apikey: ApiKey) => void;
}

export const UpdateKeyButton: React.FC<Props> = ({ apikey, onCompleted }) => {
  const intl = useIntl();

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const initialValues: FormikValues = {
    name: apikey.name,
  };

  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      fetch(`/rapi/apikeys/${apikey.id}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ api_key: values }),
      })
        .then((response) => response.json())
        .then((result) => {
          const apikey = result.data;
          onCompleted(apikey);
          handleClose();
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    },
  });

  return (
    <>
      <Tooltip
        title={intl.formatMessage({
          id: "apikeys.edit-key",
          defaultMessage: "Edit key",
        })}
      >
        <IconButton onClick={handleShow} size="small">
          <Pencil />
        </IconButton>
      </Tooltip>
      <Modal open={show} onClose={handleClose}>
        <Container
          disableGutters
          maxWidth={false}
          className={styles.container}
          sx={{
            bgcolor: "background.paper",
          }}
        >
          <Typography className={styles.title}>
            <FormattedMessage
              id="header.edit-secret-key"
              defaultMessage="Edit secret key"
            />
            <IconButton onClick={handleClose}>
              <CloseSvg />
            </IconButton>
          </Typography>
          <form onSubmit={formik.handleSubmit}>
            <Box sx={{ p: 3 }}>
              <Box className={styles.inlineField} sx={{ mb: 0 }}>
                <Typography
                  component="label"
                  htmlFor="name"
                  className={styles.label}
                >
                  <FormattedMessage
                    id="apikeys.name-optional"
                    defaultMessage="Name (Optional)"
                  />
                </Typography>
                <OutlinedInput
                  sx={{ maxWidth: "448px" }}
                  fullWidth
                  type="text"
                  name="name"
                  id="name"
                  autoFocus
                  placeholder={intl.formatMessage({
                    id: "my-test-key",
                    defaultMessage: "My Test Key",
                  })}
                  value={formik.values.name || ""}
                  onChange={formik.handleChange}
                />
              </Box>
            </Box>
            <Divider />
            <Box className={styles.modalFooter}>
              <Button
                type="button"
                onClick={handleClose}
                variant="outlined"
                color="secondary"
              >
                <FormattedMessage id="actions.cancel" defaultMessage="Cancel" />
              </Button>

              <Button variant="contained" color="primary" type="submit">
                <FormattedMessage id="actions.save" defaultMessage="Save" />
              </Button>
            </Box>
          </form>
        </Container>
      </Modal>
    </>
  );
};
