import { FC, useState } from "react";
import styles from "../../assets/stylesheets/modal.module.scss";
import dayjs, { Dayjs } from "dayjs";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import { ReactComponent as CloseSvg } from "../../assets/CloseSvg.svg";
import { loader } from "graphql.macro";
import { useMutation } from "@apollo/client";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { TimezoneSelect, clientTz } from "timezone-select-js";
import { FormattedMessage, useIntl } from "react-intl";
import { AutocompleteChangeDetails } from "@mui/material/Autocomplete";
import Error from "../Error";
import { formatToNaiveDateTime } from "../../lib/helpers";
// import { getEntryModeIcon } from "../MyRooms/components/MeetingCard/index";
import {
  Box,
  Button,
  Container,
  Divider,
  FormControl,
  IconButton,
  Modal,
  OutlinedInput,
  Typography,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import { useFormik } from "formik";
import { colors } from "../../lib/constants";
import ParticipantsInput from "../CreateMeetingForm/components/ParticipantsInput";

const { backgroundCustomPink, greyBlue, purple } = colors;

const UPDATE_ROOM_MUTATION = loader("../../schema/mutation/UpdateRoom.graphql");

type ChangeHandler = (
  event: React.SyntheticEvent,
  value: (string | Attendee)[],
  reason: "createOption" | "selectOption" | "removeOption" | "blur" | "clear",
  details?: AutocompleteChangeDetails<Attendee> | undefined
) => void;

interface Data {
  updateRoom: Room;
}

interface Variables {
  name: string;
  description: string;
  slug: string;
  entryMode: string;
  startDate: string | null;
  endDate: string | null;
  timeZone: string | null;
  attendees: Attendee[];
}

interface Props {
  room: Room;
  onCompleted: () => void;
  open: boolean;
}

const UpdateRoomForm: FC<Props> = ({ room, onCompleted, open }) => {
  const intl = useIntl();
  const initialValues = {
    name: room.name || "",
    description: room.description || "",
    startDate: room.startDate ? dayjs(room.startDate) : null,
    endDate: room.endDate ? dayjs(room.endDate) : null,
    timeZone: room.timeZone || clientTz(),
    entryMode: room.entryMode,
    // remove __typename in each attendee
    attendees: room.attendees.map((attendee) => ({
      email: attendee.email,
      fullname: attendee.fullname,
    })),
  };

  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      updateRoom({
        variables: {
          name: values.name || NAME_PLACEHOLDER,
          description: values.description,
          slug: room.slug,
          startDate: formatToNaiveDateTime(values.startDate),
          endDate: formatToNaiveDateTime(values.endDate),
          timeZone: room.persistent ? null : values.timeZone,
          entryMode: values.entryMode,
          attendees: values.attendees,
        },
      })
        .then(() => {
          onCompleted();
        })
        .catch(() => {});
    },
  });

  const handleStartDateChange = (startDate: Dayjs | null) => {
    let endDate = formik.values.endDate;
    if (startDate) {
      endDate = startDate.add(1, "hour");
    }

    formik.setFieldValue("startDate", startDate);
    formik.setFieldValue("endDate", endDate);
  };

  const handleEndDateChange = (endDate: Dayjs | null) => {
    formik.setFieldValue("endDate", endDate);
  };

  const hasPickedDate = () => {
    return room.persistent || formik.values.startDate;
  };

  const NAME_PLACEHOLDER = room.persistent
    ? "Room name for the project"
    : "Meeting";

  const handleTimeZoneChange = (option: any) => {
    formik.setFieldValue("timeZone", option ? option.value : null);
  };

  //   const buttonLabel = room.persistent ? "Update room" : "Update meeting";

  const [updateRoom, { loading, error }] = useMutation<Data, Variables>(
    UPDATE_ROOM_MUTATION
  );

  const handleSelectedUsers: ChangeHandler = (
    event,
    value,
    reason,
    details
  ) => {
    if (reason === "selectOption") {
      formik.setFieldValue("attendees", value as Attendee[]);
    }
    if (reason === "clear") {
      formik.setFieldValue("attendees", []);
    }
    if (reason === "removeOption") {
      formik.setFieldValue("attendees", value as Attendee[]);
    }
    if (reason === "createOption") {
      const createdOption = details?.option as string | undefined;
      if (createdOption && createdOption?.indexOf("@") > -1) {
        // timeout to avoid instant validation of the dialog's form.
        setTimeout(() => {
          setOpenCreateDialog(true);
          setDialogValue({
            email: createdOption,
            fullname: "",
          });
        });
      }
    }
  };

  const [openCreateDialog, setOpenCreateDialog] = useState(false);

  const handleClose = () => {
    setDialogValue({
      fullname: "",
      email: "",
    });
    setOpenCreateDialog(false);
  };
  const [dialogValue, setDialogValue] = useState({
    fullname: "",
    email: "",
  });

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const newUser: Attendee = {
      fullname: dialogValue.fullname,
      email: dialogValue.email,
    };

    formik.setFieldValue("attendees", [...formik.values.attendees, newUser]);
    handleClose();
  };

  return (
    <Modal open={open} onClose={onCompleted}>
      <>
        <Dialog open={openCreateDialog} onClose={handleClose}>
          <form onSubmit={handleSubmit}>
            <DialogTitle>
              <FormattedMessage
                id="meetingForm.add-a-participant"
                defaultMessage="Add a participant"
              />
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                <FormattedMessage
                  id="meetingForm.please-enter-the-name-of-the-participant"
                  defaultMessage="Please enter the name of the participant"
                />
              </DialogContentText>

              <TextField
                autoFocus
                margin="dense"
                id="fullname"
                value={dialogValue.fullname}
                onChange={(event) =>
                  setDialogValue({
                    ...dialogValue,
                    fullname: event.target.value,
                  })
                }
                label={intl.formatMessage({
                  id: "fullname",
                  defaultMessage: "Fullname",
                })}
                type="text"
                variant="standard"
              />
              <TextField
                margin="dense"
                id="email"
                value={dialogValue.email}
                onChange={(event) =>
                  setDialogValue({
                    ...dialogValue,
                    email: event.target.value,
                  })
                }
                label={intl.formatMessage({
                  id: "email",
                  defaultMessage: "Email",
                })}
                type="text"
                variant="standard"
              />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleClose}
                variant="outlined"
                color="secondary"
              >
                <FormattedMessage id="actions.cancel" defaultMessage="Cancel" />
              </Button>
              <Button type="submit" variant="contained" color="primary">
                <FormattedMessage id="actions.add" defaultMessage="Add" />
              </Button>
            </DialogActions>
          </form>
        </Dialog>
        <Container
          disableGutters
          maxWidth={false}
          className={styles.container}
          sx={{
            bgcolor: "background.paper",
          }}
        >
          <Typography className={styles.title}>
            <FormattedMessage
              id="meetingForm.modification-meeting"
              defaultMessage="Modification of the meeting"
            />
            <IconButton onClick={onCompleted}>
              <CloseSvg />
            </IconButton>
          </Typography>

          <Box component="form" id="editMeeting" onSubmit={formik.handleSubmit}>
            <Box sx={{ p: 3 }}>
              <Error error={error} />
              <Box className={styles.inlineField}>
                <Typography className={styles.label} component="label">
                  <FormattedMessage
                    id="meetingForm.title"
                    defaultMessage="Title"
                  />
                </Typography>
                <OutlinedInput
                  sx={{ maxWidth: "448px" }}
                  fullWidth
                  type="text"
                  name="name"
                  id="name"
                  autoFocus
                  placeholder={NAME_PLACEHOLDER}
                  value={formik.values.name}
                  onChange={formik.handleChange}
                />
              </Box>

              <Box className={styles.inlineField}>
                <Typography className={styles.label} component="label">
                  <FormattedMessage
                    id="meetingForm.description"
                    defaultMessage="Description"
                  />
                </Typography>
                <OutlinedInput
                  sx={{ maxWidth: "448px", maxHeight: "38px" }}
                  fullWidth
                  type="text"
                  name="description"
                  id="description"
                  placeholder={intl.formatMessage({
                    id: "meetingForm.descriptionPlaceholder",
                    defaultMessage: "What the meeting is about",
                  })}
                  value={formik.values.description}
                  onChange={formik.handleChange}
                />
              </Box>

              <Box className={styles.inlineField}>
                <Typography
                  component="label"
                  htmlFor="attendees"
                  className={styles.label}
                >
                  <FormattedMessage
                    id="meetingForm.participants"
                    defaultMessage="Participants"
                  />
                </Typography>
                <ParticipantsInput
                  id="attendees"
                  attendees={formik.values.attendees}
                  handleSelectedUsers={handleSelectedUsers}
                  placeholder={intl.formatMessage({
                    id: "meetingForm.participantsPlaceholder",
                    defaultMessage: "Choose participants",
                  })}
                />
              </Box>

              {!room.persistent ? (
                <>
                  <Box className={styles.inlineField}>
                    <Typography
                      component="label"
                      htmlFor="timeZone"
                      className={styles.label}
                    >
                      <FormattedMessage
                        id="meetingForm.timezone"
                        defaultMessage="Timezone"
                      />
                    </Typography>
                    <FormControl fullWidth sx={{ maxWidth: "448px" }}>
                      <TimezoneSelect
                        name="timeZone"
                        id="timeZone"
                        value={formik.values.timeZone || ""}
                        onChange={handleTimeZoneChange}
                        // @ts-ignore
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary25: backgroundCustomPink,
                            primary50: backgroundCustomPink,
                            primary: purple,
                          },
                        })}
                        styles={{
                          // @ts-ignore
                          control: (baseStyles, state) => {
                            return {
                              ...baseStyles,
                              "&:hover": {
                                borderColor: state.isFocused
                                  ? purple
                                  : greyBlue,
                              },
                            };
                          },
                        }}
                      />
                    </FormControl>
                  </Box>

                  <Box className={styles.inlineField}>
                    <Typography className={styles.label} component="label">
                      <FormattedMessage
                        id="meetingForm.startDate"
                        defaultMessage="Start Date"
                      />
                    </Typography>
                    <FormControl>
                      <DateTimePicker
                        minDate={dayjs()}
                        value={formik.values.startDate}
                        onChange={handleStartDateChange}
                        viewRenderers={{
                          hours: renderTimeViewClock,
                          minutes: renderTimeViewClock,
                        }}
                        sx={{
                          "& .MuiInputBase-root": {
                            maxHeight: "40px",
                            border: !hasPickedDate() ? "1px solid red" : null,
                          },
                        }}
                      />
                      {!hasPickedDate() && (
                        <Typography
                          variant="caption"
                          sx={{
                            color: "red",
                          }}
                        >
                          <FormattedMessage
                            id="meetingForm.startDateError"
                            defaultMessage="Enter the correct date. (Must be in the future)"
                          />
                        </Typography>
                      )}
                    </FormControl>
                  </Box>
                  <Box className={styles.inlineField} sx={{ mb: 0 }}>
                    <Typography className={styles.label} component="label">
                      <FormattedMessage
                        id="meetingForm.endDate"
                        defaultMessage="End Date"
                      />
                    </Typography>
                    <FormControl>
                      <DateTimePicker
                        minDate={dayjs()}
                        value={formik.values.endDate}
                        onChange={handleEndDateChange}
                        viewRenderers={{
                          hours: renderTimeViewClock,
                          minutes: renderTimeViewClock,
                        }}
                      />
                    </FormControl>
                  </Box>
                </>
              ) : null}
              {/* <FormControl>
              <Typography className={styles.text} sx={{ pb: 1 }}>
                <FormattedMessage
                  id="room.entry-mode"
                  defaultMessage="Entry mode"
                />
                <FontAwesomeIcon
                  icon={getEntryModeIcon(formik.values.entryMode)}
                  fixedWidth
                  className="primary-color"
                />
              </Typography>
              <FormControl>
                <Select
                  name="entryMode"
                  id="entryMode"
                  value={formik.values.entryMode}
                  onChange={formik.handleChange}
                  aria-describedby="entryModeHelpBlock"
                >
                  <MenuItem value="ALLOW">
                    {intl.formatMessage({
                      id: "entry-mode.allow",
                      defaultMessage: "Allow everyone knowing the URL",
                    })}
                  </MenuItem>
                  <MenuItem value="INTERNAL_EXTERNAL">
                    {intl.formatMessage({
                      id: "entry-mode.internal-external",
                      defaultMessage:
                        "Internal and external members of the organization",
                    })}
                  </MenuItem>
                  <MenuItem value="INTERNAL_ONLY">
                    {intl.formatMessage({
                      id: "entry-mode.internal-only",
                      defaultMessage:
                        "Internal members of the organization only",
                    })}
                  </MenuItem>
                </Select>
              </FormControl>

              {formik.values.entryMode === "ALLOW" && (
                <Form.Text id="entryModeHelpBlock">
                  <FormattedMessage
                    id="room.entry-mode-explanation"
                    defaultMessage="Anyone on the internet with the link can access the room."
                  />
                </Form.Text>
              )}
            </FormControl> */}
            </Box>

            <Divider />
            <Box className={styles.modalFooter}>
              <Button
                onClick={onCompleted}
                form=""
                variant="outlined"
                color="secondary"
              >
                <FormattedMessage id="actions.cancel" defaultMessage="Cancel" />
              </Button>

              <Button
                type="submit"
                variant="contained"
                form="editMeeting"
                disabled={loading || !hasPickedDate()}
              >
                <FormattedMessage id="actions.update" defaultMessage="Update" />
              </Button>
            </Box>
          </Box>
        </Container>
      </>
    </Modal>
  );
};

export default UpdateRoomForm;
