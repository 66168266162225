import { Box, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { useEffect, useState } from "react";
import { CreateKeyButton } from "./CreateKeyButton";
import { KeysTable } from "./KeysTable";

interface Props {
  organizationId: string;
}

export interface ApiKey {
  id: string;
  name: string;
  key: string | null;
  key_suffix: string;
  inserted_at: string;
  updated_at: string;
  last_used: string | null;
}

export const APIKeys: React.FC<Props> = (props) => {
  const [apiKeys, setApiKeys] = useState<ApiKey[]>([]);

  useEffect(() => {
    fetch("/rapi/apikeys", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((result) => {
        setApiKeys(result.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  const removeKey = (id: string) => {
    setApiKeys((keys) => keys.filter((key) => key.id !== id));
  };

  const addKey = (apikey: ApiKey) => {
    setApiKeys((v) => [...v, { ...apikey, key: null }]);
  };

  const updateKey = (apikey: ApiKey) => {
    const newApiKeys: ApiKey[] = [];
    apiKeys.forEach((key) => {
      if (key.id === apikey.id) {
        newApiKeys.push(apikey);
      } else {
        newApiKeys.push(key);
      }
    });

    setApiKeys(newApiKeys);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        maxWidth: "800px",
      }}
    >
      <Typography>
        <FormattedMessage
          id="apikeys.sentence1"
          defaultMessage="Your secret API keys are listed below. Please note that we do not display your secret API keys again after you generate them."
        />
      </Typography>
      <Typography>
        <FormattedMessage
          id="apikeys.sentence2"
          defaultMessage="Do not share your API key with others, or expose it in the browser or other client-side code. In order to protect the security of your account, Kizwork may also automatically disable any API key that we've found has leaked publicly."
        />
      </Typography>
      <Box>
        <KeysTable
          apikeys={apiKeys}
          removeKey={removeKey}
          updateKey={updateKey}
        />
        <CreateKeyButton onCompleted={addKey} />
      </Box>
    </Box>
  );
};
