import React, { FC, useEffect, useState } from "react";
import Chart from "chart.js/auto";
import { useMediaQuery } from "@mui/material";

interface Props {
  selectedMenuItem: string;
}
type DoughnutChartType = Chart<"doughnut", number[], string>;

const DoughnutChart: FC<Props> = ({ selectedMenuItem }) => {
  const chartRef = React.useRef<HTMLCanvasElement | null>(null);
  const [chart, setChart] = useState<DoughnutChartType | null>(null);

  const isNarrowScreen = useMediaQuery("(max-width:499px)");

  const data = {
    labels: ["United States", "France", "Mexico", "Other"],
    datasets: [
      {
        data: [30, 50, 75, 100],
        backgroundColor: ["#9900CC", "#FFCDFA", "#BEDCFF", "#CBFFB0"],
        borderWidth: 4,
        borderColor: "#F7F9FB",
        borderRadius: {
          innerEnd: -50,
          innerStart: 50,
          outerEnd: 50,
          outerStar: -50,
        },
      },
    ],
  };

  useEffect(() => {
    const newData = {
      labels: data.labels,
      datasets: data.datasets,
    };
    if (chart) {
      chart.destroy();
    }

    if (chartRef.current) {
      const ctx = chartRef.current.getContext("2d") as CanvasRenderingContext2D;

      chartRef.current.style.backgroundColor = "#F7F9FB";
      chartRef.current.style.borderStyle = "";
      const newChart = new Chart(ctx, {
        type: "doughnut",
        data: newData,
        options: {
          responsive: true,
          maintainAspectRatio: false,
          cutout: isNarrowScreen ? 30 : 55,

          plugins: {
            legend: {
              display: false,
              position: isNarrowScreen ? "bottom" : "right",
              labels: {
                boxWidth: 15,
                boxHeight: 5,
                borderRadius: 12,
                font: {
                  size: 12,
                },
                pointStyle: "circle",
                usePointStyle: true,
                textAlign: "left",
                color: "rgba(28, 28, 28, 1)",
                useBorderRadius: true,
                padding: 10,
              },
            },
          },
        },
      });

      setChart(newChart);
    }
    // eslint-disable-next-line
  }, [chartRef, selectedMenuItem]);

  return <canvas width="100%" ref={chartRef} />;
};

export default DoughnutChart;
