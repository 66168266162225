import { Box, FormControl, Typography } from "@mui/material";
import styles from "./index.module.scss";
import { FormattedMessage, FormattedNumber } from "react-intl";
import { ReactComponent as Brand } from "../../../assets/Brand.svg";
import { ReactComponent as BrandGlobe } from "../../../assets/BrandGlobe.svg";

export const Focus = () => {
  return (
    <>
      <Typography
        sx={{ display: "flex", justifyContent: "flex-start", mt: 2 }}
        className={styles.title}
      >
        <FormattedMessage
          id="esg-focus"
          defaultMessage="ESG Focus (1h call/2 persons consumption)"
        />
      </Typography>

      <Box
        className={styles.container}
        display="flex"
        justifyContent="space-between"
        flexWrap="wrap"
      >
        <FormControl
          className={styles.formControl}
          sx={{
            width: "45%",
            backgroundColor: "#FDFBFD",
            borderRadius: "6px",
            p: "32px 24px 0px 24px",
          }}
        >
          <Brand />
          <Typography
            className={styles.cardTitle}
            sx={{ mb: "20px", textAlign: "center", mt: "32px" }}
          >
            <FormattedMessage
              id="esg-focusCardTitle"
              defaultMessage="Common video conferencing services"
            />
          </Typography>
          <Typography
            className={styles.cardText}
            sx={{ mb: "20px", textAlign: "center" }}
          >
            <FormattedMessage
              id="infoCard.savedKg"
              defaultMessage="{number} kg of CO2"
              values={{
                // eslint-disable-next-line
                number: <FormattedNumber value={2.8} style="decimal" />,
              }}
            />
          </Typography>

          <Typography className={styles.cardText} sx={{ mb: "20px" }}>
            <FormattedMessage
              id="esg-focusCardText"
              defaultMessage="Often referred to as “full HD,” 1080 (1920 x 1080 pixels) has become the industry standard for a crisp HD digital video that doesn’t break your storage space."
            />
          </Typography>
          <Typography className={styles.cardText} sx={{ mb: "20px" }}>
            <FormattedMessage
              id="esg-focusCardSecondaryText"
              defaultMessage="Common screen resolution for smartphones and Laptop camera."
            />
          </Typography>
        </FormControl>

        <FormControl
          className={styles.formControl}
          sx={{
            width: "45%",
            backgroundColor: "#FDFBFD",
            borderRadius: "6px",
            p: "32px 24px 0px 24px",
          }}
        >
          <BrandGlobe />
          <Typography
            className={styles.cardTitle}
            sx={{ mb: "20px", textAlign: "center", mt: "32px" }}
          >
            Kizwork
          </Typography>

          <Typography
            className={styles.cardText}
            sx={{ mb: "10px", textAlign: "center" }}
          >
            <FormattedMessage
              id="infoCard.savedKg"
              defaultMessage="{number} kg of CO2"
              values={{
                // eslint-disable-next-line
                number: <FormattedNumber value={0.08} style="decimal" />,
              }}
            />
          </Typography>
          <Typography className={styles.cardText} sx={{ mb: "20px" }}>
            <FormattedMessage
              id="esg-focusKizworkCardText"
              defaultMessage="Our technology uses from 10 to 35 times less energy than any conventional video solution. The secret ? Our 3D environment and avatars don't use more than conventional audio streaming."
            />
          </Typography>
          <Typography className={styles.cardText} sx={{ mb: "20px" }}>
            <FormattedMessage
              id="esg-focusKizworkCardSecondary"
              defaultMessage="For 1h call/2people, Kizwork consumes {x35lessofco2}, energy, bandwidth and network resources!"
              values={{
                x35lessofco2: (
                  <FormattedMessage
                    tagName={"span"}
                    id="esg-focusKizworkCardLess"
                    defaultMessage="35x Less of CO2"
                  />
                ),
              }}
            />
          </Typography>
        </FormControl>
      </Box>
    </>
  );
};
