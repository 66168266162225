import React, { useState } from "react";

import { useIntl } from "react-intl";
import UpdateRoomForm from "../UpdateRoomForm/UpdateRoomForm";
import { ReactComponent as Pencil } from "../../assets/editSvg.svg";
import { IconButton, Tooltip } from "@mui/material";

interface Props {
  room: Room;
}

const UpdateRoomModal: React.FC<Props> = ({ room }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const intl = useIntl();
  return (
    <>
      <Tooltip
        title={intl.formatMessage({
          id: "actions.edit",
          defaultMessage: "Edit",
        })}
      >
        <IconButton onClick={handleShow}>
          <Pencil />
        </IconButton>
      </Tooltip>

      {/* Because the UpdateRoomForm component create an initialFormValues state outside Modal,
      add a show condition here to properly unmount and clean the form state. */}
      {show && (
        <UpdateRoomForm room={room} onCompleted={handleClose} open={show} />
      )}
    </>
  );
};

export default UpdateRoomModal;
