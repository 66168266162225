import { useContext } from "react";
import { LangContext } from "../I18nProvider";

export const UserSettings = () => {
  const { language } = useContext(LangContext);
  return (
    <iframe
      src={`/users/settings?locale=${language}`}
      title="User settings"
      style={{
        border: 0,
        top: "-16px",
        position: "absolute",
        width: "100%",
        height: "100%",
        paddingBottom: "100px",
      }}
    />
  );
};
