// style from react-google-login
import React, { useState } from "react";
import GoogleIcon from "./GoogleIcon";

interface Props {
  theme?: "light" | "dark";
  onClick: () => void;
  children: React.ReactNode;
}

const GoogleButton: React.FC<Props> = ({
  theme = "light",
  onClick,
  children,
}) => {
  const [hovered, setHovered] = useState(false);
  const [active, setActive] = useState(false);
  const initialStyle = {
    backgroundColor: theme === "dark" ? "rgb(66, 133, 244)" : "#fff",
    display: "inline-flex",
    alignItems: "center",
    color: theme === "dark" ? "#fff" : "#3c4043",
    padding: 0,
    borderRadius: "4px",
    border: "1px solid #dadce0",
    fontSize: 14,
    fontWeight: 600,
    fontFamily: "Roboto-Regular,arial,sans-serif",
  };

  const hoveredStyle = {
    cursor: "pointer",
    backgroundColor: "#f8faff",
    border: "1px solid #d2e3fc",
  };

  const activeStyle = {
    cursor: "pointer",
    backgroundColor: theme === "dark" ? "#3367D6" : "#f8faff",
  };

  const defaultStyle = (() => {
    if (active) {
      if (theme === "dark") {
        return { ...initialStyle, ...activeStyle };
      }

      return { ...initialStyle, ...activeStyle };
    }

    if (hovered) {
      return { ...initialStyle, ...hoveredStyle };
    }

    return initialStyle;
  })();
  return (
    <button
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => {
        setHovered(false);
        setActive(false);
      }}
      onMouseDown={() => setActive(true)}
      onMouseUp={() => setActive(false)}
      onClick={onClick}
      style={defaultStyle}
      type="button"
    >
      <GoogleIcon />
      <span
        style={{
          paddingRight: 10,
          paddingLeft: 0,
          paddingTop: 10,
          paddingBottom: 10,
        }}
      >
        {children}
      </span>
    </button>
  );
};

export default GoogleButton;
