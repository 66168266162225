import { FormattedDate, FormattedMessage } from "react-intl";
import type { ApiKey } from "./APIKeys";
import { DeleteKeyButton } from "./DeleteKeyButton";
import { UpdateKeyButton } from "./UpdateKeyButton";

interface Props {
  apikeys: ApiKey[];
  updateKey: (apikey: ApiKey) => void;
  removeKey: (id: string) => void;
}

export const KeysTable: React.FC<Props> = ({
  apikeys,
  updateKey,
  removeKey,
}) => {
  return (
    <table className="table table-bordered">
      <thead>
        <tr>
          <th>
            <FormattedMessage id="apikeys.col-name" defaultMessage="Name" />
          </th>
          <th>
            <FormattedMessage id="apikeys.col-key" defaultMessage="Key" />
          </th>
          <th>
            <FormattedMessage
              id="apikeys.col-created"
              defaultMessage="Created"
            />
          </th>
          <th>
            <FormattedMessage
              id="apikeys.col-last-used"
              defaultMessage="Last used"
            />
          </th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {apikeys.map((apikey) => {
          return (
            <tr key={apikey.id}>
              <td>{apikey.name || "Secret key"}</td>
              <td>{"************" + apikey.key_suffix}</td>
              <td>
                <FormattedDate
                  value={new Date(apikey.inserted_at)}
                  year="numeric"
                  month="long"
                  day="2-digit"
                />
              </td>
              <td>
                {apikey.last_used ? (
                  <FormattedDate
                    value={new Date(apikey.last_used)}
                    year="numeric"
                    month="long"
                    day="2-digit"
                  />
                ) : (
                  ""
                )}
              </td>
              <td>
                <UpdateKeyButton
                  apikey={apikey}
                  onCompleted={(apikey) => {
                    updateKey(apikey);
                  }}
                />
                <DeleteKeyButton
                  apikey={apikey}
                  onCompleted={() => {
                    removeKey(apikey.id);
                  }}
                />
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
