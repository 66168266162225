import styles from "../../assets/stylesheets/modal.module.scss";
import {
  Box,
  Button,
  Container,
  Divider,
  IconButton,
  Modal,
  OutlinedInput,
  Typography,
} from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { useClipboard } from "use-clipboard-copy";
import { ReactComponent as Plus } from "../../assets/PlusCircle.svg";
import { useState } from "react";
import { FormikValues, useFormik } from "formik";
import { ReactComponent as CloseSvg } from "../../assets/CloseSvg.svg";
import type { ApiKey } from "./APIKeys";

interface Props {
  onCompleted: (apikey: ApiKey) => void;
}

export const CreateKeyButton: React.FC<Props> = ({ onCompleted }) => {
  const intl = useIntl();
  const clipboard = useClipboard({
    copiedTimeout: 600, // timeout duration in milliseconds
  });

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [createdApiKey, setCreatedApiKey] = useState<ApiKey | null>(null);

  const initialValues: FormikValues = {
    name: "",
  };

  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      fetch("/rapi/apikeys", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ api_key: values }),
      })
        .then((response) => response.json())
        .then((result) => {
          const apikey = result.data;
          setCreatedApiKey(apikey);
          onCompleted(apikey);
          formik.resetForm();
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    },
  });

  return (
    <>
      <Box>
        <Button onClick={handleShow} startIcon={<Plus />} variant="contained">
          <FormattedMessage
            id="apikeys.create-new-secret-key"
            defaultMessage="Create new secret key"
          />
        </Button>
      </Box>
      <Modal open={show} onClose={handleClose}>
        <Container
          disableGutters
          maxWidth={false}
          className={styles.container}
          sx={{
            bgcolor: "background.paper",
          }}
        >
          <Typography className={styles.title}>
            <FormattedMessage
              id="apikeys.create-new-secret-key"
              defaultMessage="Create new secret key"
            />
            <IconButton onClick={handleClose}>
              <CloseSvg />
            </IconButton>
          </Typography>
          {createdApiKey ? (
            <>
              <Box
                sx={{ p: 3, display: "flex", flexDirection: "column", gap: 2 }}
              >
                <Typography>
                  <FormattedMessage
                    id="apikeys.please-save"
                    defaultMessage="Please save this secret key somewhere safe and accessible. For security reasons, you won't be able to view it again through your account. If you lose this secret key, you'll need to generate a new one."
                  />
                </Typography>
                <Box
                  sx={{
                    mb: 0,
                    display: "flex",
                    justifyContent: "start",
                    gap: 1,
                  }}
                >
                  <OutlinedInput
                    inputRef={clipboard.target}
                    sx={{ flexGrow: 1 }}
                    type="text"
                    readOnly
                    value={createdApiKey.key}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    type="button"
                    onClick={(e) => {
                      e.stopPropagation();
                      clipboard.copy();
                    }}
                  >
                    {clipboard.copied
                      ? intl.formatMessage({
                          id: "apikeys.copied",
                          defaultMessage: "Copied!",
                        })
                      : intl.formatMessage({
                          id: "apikeys.copy",
                          defaultMessage: "Copy",
                        })}
                  </Button>
                </Box>
              </Box>
              <Divider />
              <Box className={styles.modalFooter}>
                <Button
                  type="button"
                  onClick={() => {
                    setCreatedApiKey(null);
                    handleClose();
                  }}
                  variant="contained"
                  color="primary"
                >
                  <FormattedMessage id="actions.done" defaultMessage="Done" />
                </Button>
              </Box>
            </>
          ) : (
            <form onSubmit={formik.handleSubmit}>
              <Box sx={{ p: 3 }}>
                <Box className={styles.inlineField} sx={{ mb: 0 }}>
                  <Typography
                    component="label"
                    htmlFor="name"
                    className={styles.label}
                  >
                    <FormattedMessage
                      id="apikeys.name-optional"
                      defaultMessage="Name (Optional)"
                    />
                  </Typography>
                  <OutlinedInput
                    sx={{ maxWidth: "448px" }}
                    fullWidth
                    type="text"
                    name="name"
                    id="name"
                    autoFocus
                    placeholder={intl.formatMessage({
                      id: "my-test-key",
                      defaultMessage: "My Test Key",
                    })}
                    value={formik.values.name}
                    onChange={formik.handleChange}
                  />
                </Box>
              </Box>
              <Divider />
              <Box className={styles.modalFooter}>
                <Button
                  type="button"
                  onClick={handleClose}
                  variant="outlined"
                  color="secondary"
                >
                  <FormattedMessage
                    id="actions.cancel"
                    defaultMessage="Cancel"
                  />
                </Button>

                <Button variant="contained" color="primary" type="submit">
                  <FormattedMessage
                    id="actions.create"
                    defaultMessage="Create"
                  />
                </Button>
              </Box>
            </form>
          )}
        </Container>
      </Modal>
    </>
  );
};
