import React, { FC, useState } from "react";
import { Box, Fade, Tab, Tabs, Typography, useMediaQuery } from "@mui/material";
import styles from "./index.module.scss";
import { FormattedMessage, useIntl } from "react-intl";
import { PaymentMethods } from "./PaymentMethods";
import { PlanDetails } from "./PlanDetails";
import { BillingDetails } from "./BillingDetails";
import { ChangePlan } from "./ChangePlan";
import { NavLink } from "react-router-dom";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const CustomTabPanel: FC<TabPanelProps> = ({
  children,
  value,
  index,
  ...other
}) => {
  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Fade in={true} timeout={800}>
          <Box
            sx={{
              p: 0,
              pt: 3,
              display: "flex",
              flexDirection: "column",
            }}
          >
            {children}
          </Box>
        </Fade>
      )}
    </Box>
  );
};

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const plan = "Starter";

export default function Payment() {
  const [value, setValue] = React.useState(0);
  const [showAllPlans, setShowAllPlans] = useState(false);
  const isNarrowScreen = useMediaQuery("(max-width:360px)");
  const handleShowAllPlans = () => {
    setShowAllPlans(true);
  };
  const handleCloseAllPlans = () => {
    setShowAllPlans(false);
  };
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const intl = useIntl();

  return (
    <Box sx={{ minHeight: "100%" }}>
      <Tabs
        orientation={isNarrowScreen ? "vertical" : "horizontal"}
        className={styles.TabBox}
        value={value}
        onChange={handleChange}
        variant="fullWidth"
        sx={{
          border: 1,
          borderColor: "divider",
          borderRadius: "6px",
        }}
      >
        <Tab
          sx={{
            borderRight: 1,
          }}
          label={intl.formatMessage({
            id: "payment-methods",
            defaultMessage: "Payment methods",
          })}
          {...a11yProps(0)}
        />
        <Tab
          sx={{ borderRight: 1 }}
          label={intl.formatMessage({
            id: "payment-plan",
            defaultMessage: "Plan details",
          })}
          {...a11yProps(1)}
        />
        <Tab
          sx={{ borderRight: 1 }}
          label={intl.formatMessage({
            id: "payment-security",
            defaultMessage: "Security & privacy",
          })}
          {...a11yProps(2)}
        />
        <Tab
          label={intl.formatMessage({
            id: "payment-details",
            defaultMessage: "Billing details",
          })}
          {...a11yProps(3)}
        />
      </Tabs>

      <CustomTabPanel value={value} index={0}>
        <PaymentMethods />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        {!showAllPlans ? (
          <PlanDetails handleShowAllPlans={handleShowAllPlans} plan={plan} />
        ) : (
          <ChangePlan handleCloseAllPlans={handleCloseAllPlans} plan={plan} />
        )}
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <Typography
          sx={{ display: "flex", justifyContent: "flex-start", mb: "30px" }}
          className={styles.title}
        >
          <FormattedMessage
            id="payment-security"
            defaultMessage="Security & privacy"
          />
        </Typography>

        <Box>
          <Typography
            sx={{ display: "flex", justifyContent: "flex-start", mb: "20px" }}
            className={styles.text}
          >
            <FormattedMessage
              id="access-securityText"
              defaultMessage="Control access to this account, view the most recently active devices and more."
            />
          </Typography>

          <Box
            sx={{ display: "flex", justifyContent: "flex-start", mb: "10px" }}
            className={styles.text}
          >
            <Typography className={styles.text} sx={{ mr: "5px" }}>
              <FormattedMessage id="access-i-want" defaultMessage="I want" />
            </Typography>

            <NavLink to="/">
              <Typography component="span">
                <FormattedMessage
                  id="access-manage-access-and-devices"
                  defaultMessage="Manage access and devices"
                />
              </Typography>
            </NavLink>
          </Box>

          <Box
            sx={{ display: "flex", justifyContent: "flex-start", mb: 2 }}
            className={styles.text}
          >
            <Typography sx={{ mr: "5px" }} className={styles.text}>
              <FormattedMessage id="access-i-want" defaultMessage="I want" />
            </Typography>
            <NavLink to="/">
              <Typography component="span">
                <FormattedMessage
                  id="access-securityLinkToSign"
                  defaultMessage="Sign out of all devices"
                />
              </Typography>
            </NavLink>
          </Box>
        </Box>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        <BillingDetails />
      </CustomTabPanel>
    </Box>
  );
}
