import React, { FC, useEffect } from "react";
import { TransitionGroup } from "react-transition-group";
import { loader } from "graphql.macro";
import { useQuery } from "@apollo/client";
import Error from "../../../Error";
import CurrentUser from "../../../CurrentUser";
import PleaseLogIn from "../../../PleaseLogIn";
import AvatarCard from "../AvatarCard/AvatarCard";
import { MAX_AVATARS } from "../../../../lib/constants";
import { DefaultAvatarCard } from "../DefaultAvatarCard";
import DefaultAvatarSelect, {
  defaultAvatars,
} from "../DefaultAvatarSelect/DefaultAvatarSelect";
import { Fade, Grid } from "@mui/material";
import { getAvatarObj } from "../../../AvatarSelect";

const GET_AVATARS_QUERY = loader("../../../../schema/query/GetAvatars.graphql");

interface Data {
  avatars: Array<Avatar>;
  me: {
    selectedAvatar: string | null;
  };
}
interface Props {
  setHasCreatedAnAvatar?: any;
}
const AvatarList: FC<Props> = ({ setHasCreatedAnAvatar }) => {
  const { data, loading, error } = useQuery<Data>(GET_AVATARS_QUERY);
  let hasCreatedAnAvatar: boolean = (data && data.avatars.length > 0) || false;

  useEffect(() => {
    if (setHasCreatedAnAvatar && hasCreatedAnAvatar) {
      setHasCreatedAnAvatar(true);
    }
  }, [setHasCreatedAnAvatar, hasCreatedAnAvatar]);

  return (
    <CurrentUser>
      {(currentUser: User | null) => {
        if (!currentUser) {
          return <PleaseLogIn />;
        }
        if (loading) return null;
        if (error || !data) return <Error error={error} />;
        if (!data.avatars) {
          return null;
        }
        const selectedAvatar = data.me.selectedAvatar;

        let activeDefaultAvatar =
          (selectedAvatar && defaultAvatars.indexOf(selectedAvatar) > -1) ||
          data.avatars.length === 0;

        if (!getAvatarObj(selectedAvatar, data.avatars)) {
          // avatar was removed
          activeDefaultAvatar = true;
        }

        return (
          <>
            <DefaultAvatarCard
              name={`Me ${data.avatars.length + 1}`}
              disabled={data.avatars.length >= MAX_AVATARS}
            />

            <DefaultAvatarSelect
              activeDefaultAvatar={activeDefaultAvatar}
              localStorageSelectedAvatar={localStorage.getItem("avatarValue")}
            />

            <TransitionGroup component={null}>
              {data.avatars.map((avatar) => (
                <Fade key={avatar.id} in={true} timeout={500}>
                  <Grid item>
                    <AvatarCard
                      avatar={avatar}
                      currentlyChosen={
                        selectedAvatar &&
                        avatar.modelId &&
                        selectedAvatar.endsWith(avatar.modelId)
                          ? true
                          : false
                      }
                    />
                  </Grid>
                </Fade>
              ))}
            </TransitionGroup>
          </>
        );
      }}
    </CurrentUser>
  );
};

export default AvatarList;
