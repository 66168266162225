import { FC } from "react";
import { Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";

const LogInButton: FC = () => {
  const searchParams = new URLSearchParams();
  const currentUrlWithoutDomain =
    window.location.pathname + window.location.search + window.location.hash;
  searchParams.set("return_to", currentUrlWithoutDomain);
  let url = "/users/log_in";
  if (currentUrlWithoutDomain !== "/") {
    url += `?${searchParams.toString()}`;
  }

  return (
    <Typography component="a" href={url} sx={{ textWrap: "nowrap" }}>
      <FormattedMessage id="nav.login" defaultMessage="Log In" />
    </Typography>
  );
};

export default LogInButton;
