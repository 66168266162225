import React, { useEffect, useRef, useState } from "react";
import { Box, Button, Dialog, DialogContent, DialogTitle } from "@mui/material";
import { faCamera } from "@fortawesome/free-solid-svg-icons/faCamera";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormattedMessage, useIntl } from "react-intl";
import helpTakeSelfie1 from "../assets/body_shape/help_take_selfie_1.gif";
import helpTakeSelfie2 from "../assets/body_shape/help_take_selfie_2.gif";

import Webcam from "../lib/webcam-easy";

interface Props {
  setFile: (blob: Blob) => void;
}

const TakePicture: React.FC<Props> = ({ setFile }) => {
  const intl = useIntl();
  const [show, setShow] = useState(false);
  const [webcam, setWebcam] = useState<Webcam | null>(null);
  const [step, setStep] = useState<number>(1);
  const [webcamRefused, setWebcamRefused] = useState<boolean>(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  let webcamRef = useRef<HTMLVideoElement | null>(null);
  let canvasRef = useRef<HTMLCanvasElement | null>(null);

  useEffect(() => {
    if (
      webcamRef.current &&
      canvasRef.current &&
      show &&
      step === 3 &&
      !webcam
    ) {
      const webcam = new Webcam(
        webcamRef.current,
        "user",
        // @ts-ignore-line
        canvasRef.current
        // snapSoundElement
      );
      setWebcam(webcam);
      webcam
        .start()
        .then(() => {
          if (webcamRef.current?.srcObject) {
            const stream = webcamRef.current.srcObject;
            // @ts-ignore-line
            const videoTracks = stream.getVideoTracks();
            const videoSettings =
              videoTracks.length > 0 ? videoTracks[0].getSettings() : {};

            if (videoSettings.height && videoSettings.width) {
              webcamRef.current.width = 640;
              webcamRef.current.height =
                (640 * videoSettings.height) / videoSettings.width;
            }
          } else {
            // srcObject null?
            setWebcamRefused(true);
          }
          console.log("webcam started");
        })
        .catch((err: string) => {
          console.log(err);
          setWebcamRefused(true);
        });
    }
    return () => {
      if (webcam) {
        webcam.stop();
        setWebcam(null);
      }
    };
  }, [show, webcam, step]);

  const takePicture = async () => {
    if (webcam && webcamRef.current?.srcObject) {
      let picture = webcam.snap();
      const resp = await fetch(picture);
      const blob = await resp.blob();
      setFile(blob);
    }
    closeApp();
  };

  const closeApp = () => {
    handleClose();
  };

  return (
    <>
      <Button
        onClick={handleShow}
        startIcon={<FontAwesomeIcon icon={faCamera} />}
        variant="contained"
      >
        <FormattedMessage
          id="manage-avatars.take-a-selfie"
          defaultMessage="Take a selfie"
        />
      </Button>

      <Dialog open={show} onClose={handleClose}>
        <Box>
          <DialogTitle>
            <FormattedMessage
              id="manage-avatars.take-a-selfie"
              defaultMessage="Take a selfie"
            />
          </DialogTitle>

          <DialogContent
            sx={{
              position: "relative",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              minHeight: "520px",
            }}
          >
            {step === 1 && (
              <>
                <p>
                  {intl.formatMessage({
                    id: "manage-avatars.dont-smile",
                    defaultMessage: "Don't smile.",
                  })}
                </p>
                <img
                  style={{
                    width: "100%",
                    maxWidth: "500px",
                  }}
                  src={helpTakeSelfie1}
                  alt={intl.formatMessage({
                    id: "manage-avatars.dont-smile",
                    defaultMessage: "Don't smile.",
                  })}
                />
                <Button
                  type="button"
                  onClick={() => setStep(2)}
                  style={{ position: "absolute", top: "430px" }}
                  variant="contained"
                >
                  <FormattedMessage id="actions.next" defaultMessage="Next" />
                </Button>
              </>
            )}
            {step === 2 && (
              <>
                <p>
                  {intl.formatMessage({
                    id: "manage-avatars.whole-face-lit",
                    defaultMessage: "Make sure the whole face is lit.",
                  })}
                </p>
                <img
                  style={{
                    width: "100%",
                    maxWidth: "500px",
                  }}
                  src={helpTakeSelfie2}
                  alt={intl.formatMessage({
                    id: "manage-avatars.whole-face-lit",
                    defaultMessage: "Make sure the whole face is lit.",
                  })}
                />
                <Button
                  type="button"
                  onClick={() => setStep(3)}
                  style={{ position: "absolute", top: "430px" }}
                  variant="contained"
                >
                  <FormattedMessage
                    id="manage-avatars.got-it"
                    defaultMessage="Got it"
                  />
                </Button>
              </>
            )}
            {step === 3 && (
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    maxHeight: "480px",
                    overflow: "hidden",
                  }}
                >
                  <video
                    ref={webcamRef}
                    id="webcam"
                    autoPlay
                    playsInline
                    width="640"
                    height="480"
                  ></video>
                </div>
                <canvas
                  ref={canvasRef}
                  id="canvas"
                  style={{ display: "none" }}
                ></canvas>
                {/* <audio id="snapSound" src="audio/snap.wav" preload = "auto"></audio> */}
                {webcamRefused ? (
                  <p style={{ position: "absolute", top: "0px" }}>
                    <FormattedMessage
                      id="manage-avatars.please-accept-webcam"
                      defaultMessage="Please make sure you have a camera connected and you allowed the camera permission from the url bar to be able to take a picture."
                    />
                  </p>
                ) : (
                  <Button
                    type="button"
                    onClick={takePicture}
                    style={{ position: "absolute", top: "430px" }}
                    startIcon={<FontAwesomeIcon icon={faCamera} />}
                    variant="contained"
                  >
                    <FormattedMessage
                      id="manage-avatars.take-picture"
                      defaultMessage="Take picture"
                    />
                  </Button>
                )}
              </>
            )}
          </DialogContent>
        </Box>
      </Dialog>
    </>
  );
};

export default TakePicture;
