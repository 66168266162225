import { useState, FC } from "react";
import { Box, Button, FormControl, Typography } from "@mui/material";
import { ReactComponent as ExclamationCircle } from "../../assets/CurrencyDollar.svg";
import { FormattedMessage, useIntl } from "react-intl";
import { ReactComponent as Visa } from "../../assets/visa.svg";
import { ReactComponent as MasterCard } from "../../assets/Mastercard.svg";
import styles from "./index.module.scss";
import { paymentCards } from "../Payment/PaymentMethods";
import { NavLink } from "react-router-dom";
import { AddCardForm } from "../Payment/AddCardForm";
interface Props {}

export const ManagePaymentInfo: FC<Props> = () => {
  const intl = useIntl();
  const [showAddCardForm, setShowAddCardForm] = useState(false);
  const handleClose = () => setShowAddCardForm(false);
  const handleShow = () => setShowAddCardForm(true);
  return (
    <>
      {!showAddCardForm ? (
        <>
          <Box display="flex" justifyContent="center">
            <ExclamationCircle />
          </Box>

          <Typography className={styles.title}>
            <FormattedMessage
              id="manage-paymentInfo"
              defaultMessage="Manage payment info"
            />
          </Typography>
          <Typography
            sx={{
              pl: 1,
              pr: 1,
              textAlign: "center",
              mb: "16px",
            }}
            className={styles.subtitle}
          >
            <FormattedMessage
              id="manage-paymentInfoText"
              defaultMessage="Edit your payment details, add a backup, or switch your preferred payment method."
            />
          </Typography>

          {paymentCards.map((card, index) => (
            <Box
              key={index}
              p={2}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box>
                <Box display="flex">
                  <Box className={styles.iconWrapper}>
                    {card.type === "MASTERCARD" ? (
                      <MasterCard />
                    ) : (
                      <Visa width={38} height={38} />
                    )}
                  </Box>

                  <Typography className={styles.numberCard} sx={{ ml: 3 }}>
                    {card.cardNumber}
                  </Typography>
                </Box>

                <Typography className={styles.text}>
                  <FormattedMessage
                    id="manage-infoPaymentHelpText"
                    defaultMessage="To remove, add another payment method first."
                  />
                </Typography>
              </Box>

              <FormControl fullWidth sx={{ maxWidth: "225px" }}>
                <Button variant="outlined" color="secondary">
                  <FormattedMessage id="actions.edit" defaultMessage="Edit" />
                </Button>
              </FormControl>
            </Box>
          ))}

          <Box flexWrap="wrap" gap="20px" className={styles.modalFooter}>
            <NavLink to="/account-settings">
              <FormControl fullWidth>
                <Button variant="contained" color="secondary">
                  <FormattedMessage
                    id="deleteModalBackToAccount"
                    defaultMessage="Back to Account"
                  />
                </Button>
              </FormControl>
            </NavLink>

            <Button onClick={handleShow} variant="contained" color="primary">
              <FormattedMessage
                id="action-addPayment"
                defaultMessage="Add payment method"
              />
            </Button>
          </Box>
        </>
      ) : (
        <AddCardForm
          formTitle={intl.formatMessage({
            id: "manage-UpdatePaymentInfo",
            defaultMessage: "Add your credit or debit card.",
          })}
          handleClose={handleClose}
        />
      )}
    </>
  );
};
