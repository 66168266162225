import React from "react";
import styles from "./index.module.scss";
import {
  Card,
  Box,
  Typography,
  Switch,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { loader } from "graphql.macro";
import { Mutation } from "@apollo/client/react/components";

import DeleteAvatarButton from "./DeleteAvatarButton";
import AvatarOutfitSelect from "./AvatarOutfitSelect";

interface Props {
  avatar: Avatar;
  currentlyChosen?: boolean;
}

interface UpdateChoiceData {
  saveAvatarChoice: Avatar;
}

const SAVE_AVATAR_CHOICE = loader(
  "../../../../schema/mutation/SaveAvatarChoice.graphql"
);

const AvatarCard: React.FC<Props> = ({ avatar, currentlyChosen = false }) => {
  const intl = useIntl();
  return (
    <Card
      className={`${
        avatar.status === "ERROR" ? styles.avatarError : styles.card
      }`}
      sx={{
        boxShadow: "none",
        borderRadius: "12px",
        minHeight: "100%",
        maxWidth: "300px",
        width: "35vw",
        minWidth: "250px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {avatar.status === "COMPLETED" && (
        <Box className={styles.header}>
          <Typography
            component="h1"
            display="flex"
            justifyContent="space-between"
            className={styles.title}
          >
            {avatar.name}
          </Typography>

          <Mutation<UpdateChoiceData> mutation={SAVE_AVATAR_CHOICE}>
            {(saveAvatarChoice) => (
              <Tooltip
                title={intl.formatMessage({
                  id: "SwitchTooltip",
                  defaultMessage: "Switch Avatar",
                })}
              >
                <Switch
                  size="small"
                  checked={currentlyChosen}
                  onClick={() => {
                    const variables = {
                      selectedAvatar: avatar.modelId,
                    };
                    saveAvatarChoice({ variables });
                  }}
                />
              </Tooltip>
            )}
          </Mutation>
        </Box>
      )}

      {avatar.status === "PENDING" && <CircularProgress />}

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {avatar.status === "PENDING" && (
          <Box className={styles.header}>
            <Typography>
              <FormattedMessage
                id="manage-avatars.avatar-generating"
                defaultMessage="Avatar generating, please wait a minute"
              />
            </Typography>
            <DeleteAvatarButton id={avatar.id} />
          </Box>
        )}
        {avatar.status === "ERROR" && (
          <Box className={styles.header}>
            <Typography>
              <FormattedMessage
                id="manage-avatars.avatar-error"
                defaultMessage="An error occurred while generating the avatar"
              />
            </Typography>
            <DeleteAvatarButton id={avatar.id} />
          </Box>
        )}
        {avatar.status === "COMPLETED" && (
          <AvatarOutfitSelect avatar={avatar} />
        )}

        {avatar.status !== "COMPLETED" && (
          <Box sx={{ height: "200px", overflow: "hidden" }}>
            {avatar.photoId === null ? (
              <FormattedMessage
                id="manage-avatars.no-photo"
                defaultMessage="No photo"
              />
            ) : (
              <img
                src={`/files/${avatar.photoId}`}
                alt=""
                className="img-thumbnail"
                height={200}
                width={213}
              />
            )}
          </Box>
        )}
      </Box>
    </Card>
  );
};

export default AvatarCard;
