import React, {
  FC,
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { IntlProvider } from "react-intl";
import messagesInFrench from "./lang/fr.json";

import dayjs from "dayjs";
import "dayjs/locale/fr";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import isBetweenPlugin from "dayjs/plugin/isBetween";
dayjs.extend(isBetweenPlugin);

export const supportedLanguagesWithLabel = new Map([
  ["en", "English"],
  ["fr", "Français"],
]);

export const supportedLanguages = Array.from(
  supportedLanguagesWithLabel.keys()
);

interface Props {
  children: React.ReactNode;
}

export const LangContext = createContext({
  language: "en",
  setLanguage: (locale: string) => {},
});

export const I18nProvider: FC<Props> = (props) => {
  const [locale, setLocale] = useState("en");
  const [messages, setMessages] = useState({});

  const setLanguage = useCallback((locale: string) => {
    setLocale(locale);
    setMessages(locale === "fr" ? messagesInFrench : {});
    // If there is a /users/ iframe in the page, set the locale to reload, otherwise call set_locale to set the locale cookie
    // @ts-ignore
    const iframe: HTMLIFrameElement | null = document.querySelector("iframe");
    if (iframe && iframe.src.indexOf("/users/") > -1) {
      const src = iframe.src.split("?locale")[0];
      iframe.src = `${src}?locale=${locale}`;
    } else {
      fetch(`/users/set_locale?locale=${locale}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({}),
      })
        .then((response) => {})
        .catch((error) => {});
    }
  }, []);

  useEffect(() => {
    let lang = "en";
    let savedLang = localStorage.getItem("lang");
    if (savedLang !== null && supportedLanguages.indexOf(savedLang) === -1) {
      savedLang = null;
    }
    if (savedLang === null) {
      const locale = navigator.language;
      if (locale.startsWith("fr")) {
        lang = "fr";
      }
    } else {
      lang = savedLang;
    }
    setLocale(lang);
    setMessages(lang === "fr" ? messagesInFrench : {});
  }, []);

  const contextValue = useMemo(
    () => ({
      language: locale,
      setLanguage,
    }),
    [locale, setLanguage]
  );

  useEffect(() => {
    localStorage.setItem("lang", locale);
    document.querySelector("html")?.setAttribute("lang", locale);
  }, [locale]);

  return (
    <IntlProvider messages={messages} locale={locale} defaultLocale="en">
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        adapterLocale={locale === "fr" ? "fr" : "en"}
      >
        <LangContext.Provider value={contextValue}>
          {props.children}
        </LangContext.Provider>
      </LocalizationProvider>
    </IntlProvider>
  );
};
