import React from "react";
import styles from "./index.module.scss";
import { ReactComponent as CloseSvg } from "../../../assets/CloseSvg.svg";

import {
  Modal,
  Container,
  Box,
  Typography,
  IconButton,
  Divider,
} from "@mui/material";
import BarChart from "../BarChart";
import { FormattedMessage } from "react-intl";

interface Props {
  show: boolean;
  handleClose: any;
  id: string;
  barChartData?: any;
  selectedMenuItem: string;
}

const ChartModal: React.FC<Props> = ({
  show,
  handleClose,
  id,
  barChartData,
  selectedMenuItem,
}) => {
  const { data, options } = barChartData;
  return (
    <Modal open={show} onClose={handleClose}>
      <Container
        disableGutters
        maxWidth={false}
        className={styles.container}
        sx={{
          maxWidth: "640px",
          bgcolor: "background.paper",
          borderRadius: "20px",
        }}
      >
        <Box>
          <Typography
            className={styles.title}
            display="flex"
            justifyContent="space-between"
          >
            {id === "nbOfMeeting" && (
              <FormattedMessage
                id="card-nbOfMeeting"
                defaultMessage="Nb of meetings"
              />
            )}

            {id === "nbOfMeetingCreated" && (
              <FormattedMessage
                id="card-nbOfMeetingCreated"
                defaultMessage="Nb of meetings created"
              />
            )}

            {id === "nbOfInternalUser" && (
              <FormattedMessage
                id="card-nbOfInternalUser"
                defaultMessage="Nb of internal users"
              />
            )}

            {id === "nbOfMeetingInvited" && (
              <FormattedMessage
                id="card-nbOfMeetingInvited"
                defaultMessage="Nb of meetings invited"
              />
            )}

            {id === "nbOfExternalUser" && (
              <FormattedMessage
                id="card-nbOfExternalUser"
                defaultMessage="Nb of external users"
              />
            )}
            {id === "nbOfInternalUserInvited" && (
              <FormattedMessage
                id="card-nbOfInternalUserInvited"
                defaultMessage="Nb of internal users invited"
              />
            )}

            {id === "nbOfTotalUsers" && (
              <FormattedMessage
                id="card-nbOfTotalUsers"
                defaultMessage="Nb of total users"
              />
            )}
            {id === "nbOfExternalUserInvited" && (
              <FormattedMessage
                id="card-nbOfExternalUserInvited"
                defaultMessage="Nb of external users invited"
              />
            )}

            {id === "hoursInOfficeAndRemote" && (
              <FormattedMessage
                id="card-hoursInOfficeAndRemote"
                defaultMessage="Nb of office h and non office h"
              />
            )}

            {id === "nbOfRoomsUsed" && (
              <FormattedMessage
                id="card-nbOfRoomsUsed"
                defaultMessage="Nb of rooms used"
              />
            )}
            {id === "totalCarbonCredits" && (
              <FormattedMessage
                id="card-totalCarbonCredits"
                defaultMessage="Total Carbon credits (kg of CO2)"
              />
            )}
            {id === "averageSpentTime" && (
              <FormattedMessage
                id="card-averageSpentTime"
                defaultMessage="Average time spent by meeting"
              />
            )}

            <IconButton onClick={handleClose} sx={{ p: 0 }}>
              <CloseSvg />
            </IconButton>
          </Typography>
          <Divider />

          <Box sx={{ height: "50vh", p: 5 }}>
            <BarChart
              selectedMenuItem={selectedMenuItem}
              isModal={show}
              data={data}
              options={options}
            />
          </Box>
        </Box>
      </Container>
    </Modal>
  );
};

export default ChartModal;
