import { Box, Button, Container, Fade, FormControl } from "@mui/material";
import { ConfirmPassword } from "../components/ConfirmPassword";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { DeleteAccountButton } from "../components/DeleteAccountButton";

export const DeleteAccount = () => {
  const [password, setPassword] = useState("");

  const [confirmedPassword, setConfirmedPassword] = useState(false);

  const handleCheck = () => {
    if (password) {
      setConfirmedPassword(true);
    }
  };
  return (
    <Fade in={true} timeout={800}>
      <Container
        sx={{
          minHeight: "75vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          p: 4,
        }}
      >
        {confirmedPassword ? (
          <Fade in={true} timeout={800}>
            <Box
              component="form"
              id="deleteAccount"
              sx={{
                width: "60%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                flex: "wrap",
              }}
            >
              <DeleteAccountButton />

              <FormControl
                fullWidth
                sx={{
                  maxHeight: "48px",
                  minHeight: "32px",
                  maxWidth: "400px",
                  mt: "46px",
                }}
              >
                <Button type="submit" form="deleteAccount">
                  <FormattedMessage
                    id="actions.continue"
                    defaultMessage="Continue"
                  />
                </Button>
              </FormControl>
            </Box>
          </Fade>
        ) : (
          <Fade in={true} timeout={800}>
            <Box
              component="form"
              sx={{
                width: "60%",

                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ConfirmPassword setPassword={setPassword} />
              <FormControl
                fullWidth
                sx={{
                  maxHeight: "48px",
                  minHeight: "32px",
                  maxWidth: "400px",
                  mt: "100px",
                }}
              >
                <Button type="submit" onClick={handleCheck}>
                  <FormattedMessage
                    id="actions.continue"
                    defaultMessage="Continue"
                  />
                </Button>
              </FormControl>
            </Box>
          </Fade>
        )}
      </Container>
    </Fade>
  );
};
