import React, { useState } from "react";
import { FormattedMessage, useIntl, IntlShape } from "react-intl";
import { loader } from "graphql.macro";
import { Mutation } from "@apollo/client/react/components";
import { Formik } from "formik";
import AvatarModelViewer from "../../../AvatarModelViewer";
import DeleteAvatarButton from "./DeleteAvatarButton";
import UpdateAvatarButton from "./UpdateAvatarButton";
import OutfitCard from "../../../OutfitCard";
import {
  Box,
  Button,
  Container,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";
import { ReactComponent as Scissors } from "../../../../assets/Scissors.svg";
import { ReactComponent as CloseSvg } from "../../../../assets/CloseSvg.svg";
import styles from "../../../../assets/stylesheets/modal.module.scss";

const UPDATE_AVATAR = loader(
  "../../../../schema/mutation/UpdateAvatar.graphql"
);

interface Props {
  avatar: Avatar;
  expanded?: boolean;
  showUpdateDeleteButtons?: boolean;
}

interface Data {
  updateAvatar: Avatar;
}

interface FormValues {
  name: string;
  selectedOutfit: string;
  tempSelectedOutfit: string;
}

const getDefaultOutfit = (isWoman: boolean): string => {
  return isWoman ? "outfit_2_lowpoly" : "outfit_4_lowpoly";
};

const getByGender = (
  gender: string | null,
  female: any,
  male: any,
  nonBinary: any
): any => {
  if (gender === null) {
    return male;
  }
  if (gender === "FEMALE") {
    return female;
  }
  if (gender === "MALE") {
    return male;
  }
  if (gender === "NON_BINARY") {
    return nonBinary;
  }
};

const getFemaleOutfits = (intl: IntlShape) => [
  {
    value: "outfit_3_lowpoly",
    label:
      intl.formatMessage({
        id: "outfit.casual",
        defaultMessage: "Casual",
      }) + " 1",
  },
  ...[1, 2, 3, 4].map((n) => ({
    value: `outfit_3_lowpoly|${n}`,
    label:
      intl.formatMessage({
        id: "outfit.casual",
        defaultMessage: "Casual",
      }) + ` ${n + 1}`,
  })),
  {
    value: "outfit_2_lowpoly",
    label:
      intl.formatMessage({
        id: "outfit.business-casual",
        defaultMessage: "Business casual",
      }) + " 1",
  },
  {
    value: "outfit_0_lowpoly",
    label:
      intl.formatMessage({
        id: "outfit.business",
        defaultMessage: "Business",
      }) + " 1",
  },
  ...[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((n) => ({
    value: `outfit_0_lowpoly|${n}`,
    label:
      intl.formatMessage({
        id: "outfit.business",
        defaultMessage: "Business",
      }) + ` ${n + 1}`,
  })),
];

const getMaleOutfits = (intl: IntlShape) => [
  {
    value: "outfit_1_lowpoly",
    label:
      intl.formatMessage({
        id: "outfit.casual",
        defaultMessage: "Casual",
      }) + " 1",
  },
  ...[1, 2, 3, 4, 5, 6].map((n) => ({
    value: `outfit_1_lowpoly|${n}`,
    label:
      intl.formatMessage({
        id: "outfit.casual",
        defaultMessage: "Casual",
      }) + ` ${n + 1}`,
  })),
  {
    value: "outfit_4_lowpoly",
    label:
      intl.formatMessage({
        id: "outfit.business-casual",
        defaultMessage: "Business casual",
      }) + " 1",
  },
  ...[1, 2, 3, 4, 5].map((n) => ({
    value: `outfit_4_lowpoly|${n}`,
    label:
      intl.formatMessage({
        id: "outfit.business-casual",
        defaultMessage: "Business casual",
      }) + ` ${n + 1}`,
  })),
  {
    value: "outfit_5_lowpoly",
    label:
      intl.formatMessage({
        id: "outfit.business",
        defaultMessage: "Business",
      }) + " 1",
  },
  ...[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((n) => ({
    value: `outfit_5_lowpoly|${n}`,
    label:
      intl.formatMessage({
        id: "outfit.business",
        defaultMessage: "Business",
      }) + ` ${n + 1}`,
  })),
];

const AvatarOutfitSelect: React.FC<Props> = ({
  avatar: { id, name, selectedOutfit, gender, height: avatarHeight, modelId },
  expanded = false,
  showUpdateDeleteButtons = true,
}) => {
  const intl = useIntl();
  const [show, setShow] = useState(expanded);
  const handleClose = () => setShow(false);

  const isWoman = gender === "FEMALE";
  const defaultOutfit = getDefaultOutfit(isWoman);
  const initialValues: FormValues = {
    name: name,
    selectedOutfit: selectedOutfit || defaultOutfit,
    tempSelectedOutfit: selectedOutfit || defaultOutfit,
  };

  return (
    <Mutation<Data, FormValues> mutation={UPDATE_AVATAR}>
      {(updateAvatar) => (
        <Formik
          initialValues={initialValues}
          onSubmit={(values, { setSubmitting }) => {
            const data = { ...values, id: id };

            updateAvatar({
              variables: data,
            })
              .then(() => {
                // @ts-ignore-line
                if (typeof _paq !== "undefined")
                  // @ts-ignore-line
                  _paq.push([
                    "trackEvent",
                    "Interactions",
                    "Change outfit",
                    values.selectedOutfit,
                  ]);
              })
              .catch(() => {
                setSubmitting(false);
              });
          }}
        >
          {({
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
          }) => {
            const avatarSrc = `/files/${modelId}`;
            const onOutfitClick = (value: string) => {
              setFieldValue("tempSelectedOutfit", value);
            };
            const handleShow = () => {
              setFieldValue("tempSelectedOutfit", values.selectedOutfit);
              setShow(true);
            };

            return (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  flexGrow: 1,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    flexGrow: 1,
                  }}
                >
                  <AvatarModelViewer
                    name={name}
                    avatarSrc={avatarSrc}
                    avatarHeight={avatarHeight}
                    outfit={values.selectedOutfit}
                  />

                  {showUpdateDeleteButtons && (
                    <Box
                      sx={{
                        pb: 1,
                        px: 2,
                        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                      }}
                      display="flex"
                      justifyContent="space-between"
                    >
                      <UpdateAvatarButton id={id} name={name} />
                      <DeleteAvatarButton id={id} />
                    </Box>
                  )}
                </Box>
                <Box
                  sx={{
                    height: showUpdateDeleteButtons ? "100px" : "50px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    px: "20px",
                  }}
                >
                  <Button
                    sx={{
                      maxHeight: "34px",
                      width: "100%",
                      maxWidth: "16rem",
                    }}
                    type="button"
                    onClick={handleShow}
                    startIcon={<Scissors />}
                    variant="contained"
                    color="secondary"
                  >
                    <FormattedMessage
                      id="actions.change-outfit"
                      defaultMessage="Choose outfit"
                    />
                  </Button>
                </Box>
                <Modal open={show} onClose={handleClose}>
                  <Container
                    disableGutters
                    maxWidth={false}
                    className={styles.container}
                    sx={{
                      bgcolor: "background.paper",
                      minWidth: "95%",
                    }}
                  >
                    <Typography className={styles.title}>
                      <FormattedMessage
                        id="actions.select-outfit"
                        defaultMessage="Select outfit"
                      />
                      <IconButton onClick={handleClose}>
                        <CloseSvg />
                      </IconButton>
                    </Typography>

                    <Box
                      sx={{
                        display: "flex",
                        gap: 1,
                        pl: 1,
                        flexDirection: "row",
                        "@media (max-width:575px)": {
                          flexDirection: "column",
                        },
                      }}
                    >
                      <Box sx={{ alignSelf: "center" }}>
                        <Box
                          className="outfit-avatar-preview"
                          sx={{
                            display: "flex",
                            justifyContent: "space-evenly",
                            alignItems: "center",
                            flexDirection: "column",
                          }}
                        >
                          <AvatarModelViewer
                            name={name}
                            avatarSrc={avatarSrc}
                            avatarHeight={avatarHeight}
                            outfit={values.tempSelectedOutfit}
                          />
                          <Button
                            type="button"
                            style={{ margin: "10px 0", minWidth: "120px" }}
                            onClick={() => {
                              setFieldValue(
                                "selectedOutfit",
                                values.tempSelectedOutfit
                              );
                              handleSubmit();
                              handleClose();
                            }}
                            variant="contained"
                          >
                            {intl.formatMessage({
                              id: "actions.validate",
                              defaultMessage: "Validate",
                            })}
                          </Button>
                        </Box>
                      </Box>
                      <Box className="outfits-grid">
                        {getByGender(
                          gender, // female
                          <>
                            {getFemaleOutfits(intl).map(
                              ({ value, label }, n) => (
                                <OutfitCard
                                  key={n}
                                  selected={value === values.selectedOutfit}
                                  tempSelected={
                                    value === values.tempSelectedOutfit
                                  }
                                  value={value}
                                  label={label}
                                  onClick={onOutfitClick}
                                />
                              )
                            )}
                          </>, // male
                          <>
                            {getMaleOutfits(intl).map(({ value, label }, n) => (
                              <OutfitCard
                                key={n}
                                selected={value === values.selectedOutfit}
                                tempSelected={
                                  value === values.tempSelectedOutfit
                                }
                                value={value}
                                label={label}
                                onClick={onOutfitClick}
                              />
                            ))}
                          </>, // non_binary
                          <>
                            {getFemaleOutfits(intl).map(
                              ({ value, label }, n) => (
                                <OutfitCard
                                  key={n}
                                  selected={value === values.selectedOutfit}
                                  tempSelected={
                                    value === values.tempSelectedOutfit
                                  }
                                  value={value}
                                  label={`${label}f`}
                                  onClick={onOutfitClick}
                                />
                              )
                            )}
                            {getMaleOutfits(intl).map(({ value, label }, n) => (
                              <OutfitCard
                                key={n}
                                selected={value === values.selectedOutfit}
                                tempSelected={
                                  value === values.tempSelectedOutfit
                                }
                                value={value}
                                label={`${label}m`}
                                onClick={onOutfitClick}
                              />
                            ))}
                          </>
                        )}
                      </Box>
                    </Box>
                  </Container>
                </Modal>
              </Box>
            );
          }}
        </Formik>
      )}
    </Mutation>
  );
};

export default AvatarOutfitSelect;
export { getDefaultOutfit };
