import React, { ChangeEvent, useState, useRef } from "react";
import styles from "../../assets/stylesheets/modal.module.scss";
import { loader } from "graphql.macro";
import { Mutation } from "@apollo/client/react/components";
import Error from "../Error";
import { Formik, FormikErrors } from "formik";
import { FormattedMessage, useIntl } from "react-intl";
import { ReactComponent as UploadSvg } from "../../assets/uploadSvg.svg";
import { ReactComponent as GenerationSvg } from "../../assets/generatingSvg.svg";
import { ReactComponent as CloseSvg } from "../../assets/CloseSvg.svg";

import Thumb from "../Thumb";
import ProgressBarTimer from "../ProgressBarTimer";
import TakePicture from "../TakePicture";

import maleNormal from "../../assets/body_shape/male_normal.jpeg";
import maleOverweight from "../../assets/body_shape/male_overweight.jpeg";
import maleObese from "../../assets/body_shape/male_obese.jpeg";
import malePhoto from "../../assets/body_shape/male.jpeg";

import nonBinaryNormal from "../../assets/body_shape/non_binary_normal.jpeg";
import nonBinaryOverweight from "../../assets/body_shape/non_binary_overweight.jpeg";
import nonBinaryObese from "../../assets/body_shape/non_binary_obese.jpeg";
import nonBinaryPhoto from "../../assets/body_shape/non_binary.jpeg";

import femaleNormal from "../../assets/body_shape/female_normal.jpeg";
import femaleOverweight from "../../assets/body_shape/female_overweight.jpeg";
import femaleObese from "../../assets/body_shape/female_obese.jpeg";
import femalePhoto from "../../assets/body_shape/female.jpeg";
import {
  Box,
  Button,
  FormGroup,
  OutlinedInput,
  Typography,
  FormControl,
  FormHelperText,
  InputAdornment,
  Divider,
  RadioGroup,
  FormControlLabel,
  Radio,
  Modal,
  Container,
  IconButton,
} from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  heigh: "100%",
  bgcolor: "background.paper",
  borderRadius: "8px",
  p: "24px",
};

const GET_AVATARS_QUERY = loader("../../schema/query/GetAvatars.graphql");
const CREATE_AVATAR = loader("../../schema/mutation/CreateAvatar.graphql");

const getByGender = (
  gender: string | null,
  female: any,
  male: any,
  nonBinary: any
): any => {
  if (gender === null) {
    return male;
  }
  if (gender === "FEMALE") {
    return female;
  }
  if (gender === "MALE") {
    return male;
  }
  if (gender === "NON_BINARY") {
    return nonBinary;
  }
};

interface Props {
  name: string;
  onCompleted?: (data: Data) => void;
  open: boolean;
  onClose?: () => void;
}

interface Data {
  createAvatar: Avatar;
}

interface FormValues {
  removeGlasses: boolean;
  height: string;
  weight: string;
  name: string;
  photo: File | null;
  gender: string | null;
}

const AddAvatarForm: React.FC<Props> = ({
  name,
  onCompleted = undefined,
  open,
  onClose,
}) => {
  const intl = useIntl();
  const [uploadedPhotoId, setUploadedPhotoId] = useState<string | null>(null);

  const initialValues: FormValues = {
    removeGlasses: false,
    height: "MEDIUM",
    weight: "NORMAL",
    name: name,
    photo: null,
    gender: "FEMALE",
  };

  const imageField = useRef<HTMLInputElement | null>(null);

  // Don't use onClose={onClose} on the Modal here, we don't want the user to cancel generating the avatar when clicking on backdrop.
  // Also Formik needs to be inside Modal so the form state (isSubmitting) is properly cleared when the avatar is created.
  return (
    <Modal open={open}>
      <Container
        disableGutters
        maxWidth={false}
        className={styles.container}
        sx={{
          bgcolor: "background.paper",
        }}
      >
        <Mutation<Data, FormValues>
          mutation={CREATE_AVATAR}
          onCompleted={onCompleted}
          refetchQueries={[
            { query: GET_AVATARS_QUERY, variables: { status: "COMPLETED" } },
            { query: GET_AVATARS_QUERY, variables: {} },
          ]}
        >
          {(createAvatar, { error }) => (
            <Formik
              initialValues={initialValues}
              validate={(values: FormValues) => {
                const errors: FormikErrors<FormValues> = {};
                if (!values.name) {
                  errors.name = intl.formatMessage({
                    id: "errors.required",
                    defaultMessage: "Required",
                  });
                }
                if (!values.photo) {
                  errors.photo = intl.formatMessage({
                    id: "errors.required",
                    defaultMessage: "Required",
                  });
                }
                if (!values.gender) {
                  errors.gender = intl.formatMessage({
                    id: "errors.required",
                    defaultMessage: "Required",
                  });
                }
                return errors;
              }}
              onSubmit={(values, { setSubmitting }) => {
                const data = { ...values };
                // @ts-ignore-line
                delete data.photo;
                if (uploadedPhotoId === null) {
                  const formData = new FormData();
                  // @ts-ignore-line
                  formData.append("owned_file", values.photo);
                  fetch("/files", {
                    method: "POST",
                    body: formData,
                  })
                    .then((response) => response.json())
                    .then((result) => {
                      // console.log("Success:", result);
                      // @ts-ignore-line
                      data.photoId = result.data.id;
                      setUploadedPhotoId(result.data.id);
                      createAvatar({
                        variables: data,
                      })
                        .then(() => {
                          // @ts-ignore-line
                          if (typeof _paq !== "undefined")
                            // @ts-ignore-line
                            _paq.push([
                              "trackEvent",
                              "Interactions",
                              "Create avatar",
                            ]);
                        })
                        .catch(() => {
                          setSubmitting(false);
                          // @ts-ignore-line
                          if (typeof _paq !== "undefined")
                            // @ts-ignore-line
                            _paq.push([
                              "trackEvent",
                              "Interactions",
                              "Create avatar failed",
                            ]);
                        });
                    })
                    .catch((error) => {
                      console.error("Error:", error);
                    });
                } else {
                  // @ts-ignore-line
                  data.photoId = uploadedPhotoId;

                  createAvatar({
                    variables: data,
                  })
                    .then(() => {
                      // @ts-ignore-line
                      if (typeof _paq !== "undefined")
                        // @ts-ignore-line
                        _paq.push([
                          "trackEvent",
                          "Interactions",
                          "Create avatar",
                        ]);
                    })
                    .catch(() => {
                      setSubmitting(false);
                      // @ts-ignore-line
                      if (typeof _paq !== "undefined")
                        // @ts-ignore-line
                        _paq.push([
                          "trackEvent",
                          "Interactions",
                          "Create avatar failed",
                        ]);
                    });
                }
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                isSubmitting,
              }) => (
                <>
                  {isSubmitting && (
                    <Box
                      sx={{
                        ...style,
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <GenerationSvg />
                      <Typography
                        sx={{ mt: "20px", mb: "8px", fontWeight: 600 }}
                      >
                        <FormattedMessage
                          id="manage-avatars.title-create-your-avatar"
                          defaultMessage="Create your avatar"
                        />
                      </Typography>
                      <Typography
                        component="span"
                        className={styles.text}
                        sx={{ pb: "24px", fontSize: "14px" }}
                      >
                        <FormattedMessage
                          id="manage-avatars.avatar-generating"
                          defaultMessage="Avatar generating, please wait a minute"
                        />
                      </Typography>
                      <ProgressBarTimer />
                    </Box>
                  )}
                  {!isSubmitting && (
                    <Typography className={styles.title}>
                      <FormattedMessage
                        id="manage-avatars.title-create-your-avatar"
                        defaultMessage="Create your avatar"
                      />
                      <IconButton onClick={onClose}>
                        <CloseSvg />
                      </IconButton>
                    </Typography>
                  )}

                  <Box
                    component="form"
                    id="avatarForm"
                    className={styles.form}
                    onSubmit={handleSubmit}
                    sx={{
                      display: isSubmitting ? "none" : "block",
                      p: 3,
                    }}
                  >
                    <Box className={styles.field}>
                      <Typography component="label" className={styles.label}>
                        <FormattedMessage
                          id="manage-avatars.name-field-with-explanation"
                          defaultMessage="Avatar name (used in avatars listing, only you will see this name)"
                        />
                      </Typography>
                      <FormControl error={touched.name && !!errors.name}>
                        <OutlinedInput
                          sx={{
                            maxWidth: "140px",
                          }}
                          name="name"
                          id="name"
                          type="text"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.name}
                          endAdornment={
                            touched.name &&
                            !!errors.name && (
                              <InputAdornment position="end">
                                <FormHelperText>{errors.name}</FormHelperText>
                              </InputAdornment>
                            )
                          }
                        />
                      </FormControl>
                    </Box>

                    <Box className={styles.field}>
                      <Typography component="label" className={styles.label}>
                        <FormattedMessage
                          id="avatar.form.selectGender"
                          defaultMessage="Select gender"
                        />
                      </Typography>
                      <FormControl component="fieldset" error={!!errors.gender}>
                        <RadioGroup
                          className={styles.text}
                          sx={{ alignItems: "center" }}
                          row
                          name="gender"
                          id="gender"
                          value={values.gender}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          <FormControlLabel
                            value="MALE"
                            control={<Radio size="small" />}
                            label={intl.formatMessage({
                              id: "gender.male",
                              defaultMessage: "Male",
                            })}
                          />
                          <FormControlLabel
                            value="FEMALE"
                            control={<Radio size="small" />}
                            label={intl.formatMessage({
                              id: "gender.female",
                              defaultMessage: "Female",
                            })}
                          />
                          <FormControlLabel
                            value="NON_BINARY"
                            control={<Radio size="small" />}
                            label={intl.formatMessage({
                              id: "gender.non_binary",
                              defaultMessage: "Non binary",
                            })}
                          />
                        </RadioGroup>
                        {errors.gender && (
                          <FormHelperText className={styles.text}>
                            {errors.gender}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Box>

                    <Box className={styles.field}>
                      <Typography component="label" className={styles.label}>
                        <FormattedMessage
                          id="avatar.form.selectHeight"
                          defaultMessage="Select height"
                        />
                      </Typography>
                      <FormControl component="fieldset">
                        <RadioGroup
                          className={styles.text}
                          row
                          name="height"
                          id="height"
                          value={values.height}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          <FormControlLabel
                            value="SHORT"
                            control={<Radio size="small" />}
                            label={getByGender(
                              values.gender,
                              intl.formatMessage({
                                id: "height.short150160",
                                defaultMessage: "Short (150-160cm)",
                              }),
                              intl.formatMessage({
                                id: "height.short160170",
                                defaultMessage: "Short (160-170cm)",
                              }),
                              intl.formatMessage({
                                id: "height.short150170",
                                defaultMessage: "Short (150-170cm)",
                              })
                            )}
                          />
                          <FormControlLabel
                            value="MEDIUM"
                            control={<Radio size="small" />}
                            label={getByGender(
                              values.gender,
                              intl.formatMessage({
                                id: "height.medium160170",
                                defaultMessage: "Medium (160-170cm)",
                              }),
                              intl.formatMessage({
                                id: "height.medium170180",
                                defaultMessage: "Medium (170-180cm)",
                              }),
                              intl.formatMessage({
                                id: "height.medium170180",
                                defaultMessage: "Medium (170-180cm)",
                              })
                            )}
                          />
                          <FormControlLabel
                            value="TALL"
                            control={<Radio size="small" />}
                            label={getByGender(
                              values.gender,
                              intl.formatMessage({
                                id: "height.tall170180",
                                defaultMessage: "Tall (170-180cm)",
                              }),
                              intl.formatMessage({
                                id: "height.tall180190",
                                defaultMessage: "Tall (180-190cm)",
                              }),
                              intl.formatMessage({
                                id: "height.tall180190",
                                defaultMessage: "Tall (180-190cm)",
                              })
                            )}
                          />
                        </RadioGroup>
                      </FormControl>
                    </Box>

                    <Divider sx={{ mb: 4 }} />

                    <Box className={styles.field}>
                      <Typography component="label" className={styles.label}>
                        <FormattedMessage
                          id="avatar.form.selectBody"
                          defaultMessage="Select body shape"
                        />
                      </Typography>
                      <Box
                        sx={{
                          mt: 2,
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "wrap",
                          justifyContent: "space-around",
                        }}
                      >
                        <Box
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            position: "relative",
                            cursor: "pointer",
                          }}
                        >
                          <Radio
                            size="small"
                            sx={{
                              cursor: "pointer",
                              position: "absolute",
                              top: "10px",
                              right: "2px",
                              background: "#ffffff",
                              maxHeight: "20px",
                              maxWidth: "20px",
                            }}
                            name="weight"
                            value="NORMAL"
                            checked={values.weight === "NORMAL"}
                            onChange={() => {
                              setFieldValue("weight", "NORMAL");
                            }}
                          />
                          <img
                            onClick={() => {
                              setFieldValue("weight", "NORMAL");
                            }}
                            style={{
                              width: "115px",
                              height: "200px",
                            }}
                            src={getByGender(
                              values.gender,
                              femaleNormal,
                              maleNormal,
                              nonBinaryNormal
                            )}
                            alt=""
                          />
                        </Box>
                        <Box
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            position: "relative",
                            cursor: "pointer",
                          }}
                        >
                          <Radio
                            size="small"
                            sx={{
                              cursor: "pointer",
                              position: "absolute",
                              top: "10px",
                              right: "2px",
                              background: "#ffffff",
                              maxHeight: "20px",
                              maxWidth: "20px",
                            }}
                            name="weight"
                            value="OVERWEIGHT"
                            checked={values.weight === "OVERWEIGHT"}
                            onChange={() => {
                              setFieldValue("weight", "OVERWEIGHT");
                            }}
                          />
                          <img
                            onClick={() => {
                              setFieldValue("weight", "OVERWEIGHT");
                            }}
                            style={{
                              width: "115px",
                              height: "200px",
                            }}
                            src={getByGender(
                              values.gender,
                              femaleOverweight,
                              maleOverweight,
                              nonBinaryOverweight
                            )}
                            alt=""
                          />
                        </Box>
                        <Box
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            position: "relative",
                            cursor: "pointer",
                          }}
                        >
                          <Radio
                            size="small"
                            sx={{
                              cursor: "pointer",
                              position: "absolute",
                              top: "10px",
                              right: "2px",
                              background: "#ffffff",
                              maxHeight: "20px",
                              maxWidth: "20px",
                            }}
                            name="weight"
                            value="OBESE"
                            checked={values.weight === "OBESE"}
                            onChange={() => {
                              setFieldValue("weight", "OBESE");
                            }}
                          />
                          <img
                            onClick={() => {
                              setFieldValue("weight", "OBESE");
                            }}
                            style={{
                              width: "115px",
                              height: "200px",
                            }}
                            src={getByGender(
                              values.gender,
                              femaleObese,
                              maleObese,
                              nonBinaryObese
                            )}
                            alt=""
                          />
                        </Box>
                      </Box>
                    </Box>

                    <Divider sx={{ mb: 4 }} />

                    <Box className={styles.field}>
                      <Box display="flex">
                        <Typography className={styles.text}>
                          <FormattedMessage
                            id="avatar.form.loadPhoto"
                            defaultMessage="Load a photo"
                          />
                        </Typography>
                        <Typography color="primary" sx={{ pl: "4px" }}>
                          <FormattedMessage
                            id="avatar.form.required"
                            defaultMessage="(Required)"
                          />
                        </Typography>
                      </Box>

                      {
                        // @ts-expect-error Property 'statusCode' does not exist on type 'Error'. ts(2339)
                        error?.networkError?.statusCode === 502 ? (
                          <Typography color="red">
                            <FormattedMessage
                              id="errors.create-avatar-try-again-later"
                              defaultMessage="An error occurred while generating the avatar. The cause may be the image used or a temporary failure of the server. You can retry with another picture now. If the issue persist, please delete avatar and try again later."
                            />
                          </Typography>
                        ) : (
                          <Error error={error} />
                        )
                      }
                    </Box>

                    <FormGroup sx={{ pb: 3 }}>
                      <FormControl error={!!errors.photo}>
                        <Box
                          className={styles.uploadBox}
                          component="label"
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            position: "relative",
                            "&.hover": {
                              borderColor: "primary.main",
                            },
                          }}
                          htmlFor="photo"
                          ref={imageField}
                        >
                          <UploadSvg />
                          <Typography color="primary">
                            <FormattedMessage
                              id="avatar.form-upload-file"
                              defaultMessage="Upload a file"
                            />
                            <Box component="span" sx={{ pl: "4px" }}>
                              <FormattedMessage
                                id="avatar.form-drop-text"
                                defaultMessage="or drag and drop"
                              />
                            </Box>
                          </Typography>
                          <Typography className={styles.helpedText}>
                            <FormattedMessage
                              id="avatar.formHelpedText"
                              defaultMessage="jpg/png format, portrait with no smile, good lighting"
                            />
                          </Typography>
                          <input
                            name="photo"
                            id="photo"
                            style={{
                              position: "absolute",
                              inset: 0,
                              opacity: 0,
                              cursor: "pointer",
                            }}
                            accept="image/*"
                            type="file"
                            onChange={(
                              event: ChangeEvent<HTMLInputElement>
                            ) => {
                              setUploadedPhotoId(null);
                              setFieldValue(
                                "photo",
                                event.currentTarget.files
                                  ? event.currentTarget.files[0]
                                  : null
                              );
                            }}
                            onBlur={handleBlur}
                            onDragEnter={() => {
                              if (imageField.current !== null) {
                                // @ts-ignore-line
                                imageField.current.classList.add("hover");
                              }
                            }}
                            onDrop={() => {
                              if (imageField.current !== null) {
                                // @ts-ignore-line
                                imageField.current.classList.remove("hover");
                              }
                            }}
                          />
                        </Box>

                        {/* <FormHelperText error>
                          {errors.photo && touched.photo && errors.photo}
                        </FormHelperText> */}
                      </FormControl>
                    </FormGroup>

                    <FormGroup>
                      <Box
                        className={styles.inlineField}
                        sx={{
                          alignItems: "flex-start",
                          mb: 0,
                        }}
                      >
                        <Box display="flex" flexDirection="column">
                          <Typography className={styles.text} sx={{ mb: 3 }}>
                            <FormattedMessage
                              id="avatar.form-take-selfie"
                              defaultMessage="Or take a selfie"
                            />
                          </Typography>
                          <TakePicture
                            setFile={(blob) => {
                              setUploadedPhotoId(null);
                              setFieldValue("photo", blob);
                            }}
                          />
                        </Box>
                        <Thumb
                          file={values.photo}
                          defaultImage={getByGender(
                            values.gender,
                            femalePhoto,
                            malePhoto,
                            nonBinaryPhoto
                          )}
                        />
                      </Box>
                    </FormGroup>

                    {/* <FormGroup>
                  <Form.Check
                    name="removeGlasses"
                    type="checkbox"
                    id="removeGlasses"
                    checked={values.removeGlasses}
                    label={intl.formatMessage({
                      id: "manage-avatars.remove-glasses-field",
                      defaultMessage: "Try to remove glasses",
                    })}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </FormGroup> */}
                  </Box>
                  {!isSubmitting && (
                    <>
                      <Divider />
                      <Box className={styles.modalFooter}>
                        <Button
                          onClick={onClose}
                          variant="outlined"
                          color="secondary"
                        >
                          <FormattedMessage
                            id="actions.cancel"
                            defaultMessage="Cancel"
                          />
                        </Button>
                        <Button
                          type="submit"
                          form="avatarForm"
                          disabled={isSubmitting}
                          variant="contained"
                        >
                          <FormattedMessage
                            id="actions.create"
                            defaultMessage="Create"
                          />
                        </Button>
                      </Box>
                    </>
                  )}
                </>
              )}
            </Formik>
          )}
        </Mutation>
      </Container>
    </Modal>
  );
};

export default AddAvatarForm;
