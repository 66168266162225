import { Box, Button, Fade, FormControl, Typography } from "@mui/material";
import styles from "./index.module.scss";
import { FormattedMessage } from "react-intl";
import { ReactComponent as Lightning } from "../../../assets/Lightning.svg";
import { FC, useState } from "react";
import { ReactComponent as StepDone } from "../../../assets/StepDone.svg";

interface Props {
  handleCloseAllPlans: any;
  plan: string;
}

export const ChangePlan: FC<Props> = ({ handleCloseAllPlans, plan }) => {
  const [activePlan, setActivePlan] = useState<string>(plan);
  return (
    <>
      <Typography
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          mb: 5,
          textAlign: "center",
        }}
        className={styles.title}
      >
        <FormattedMessage id="payment-plan" defaultMessage="Plan details" />
      </Typography>

      <Fade in={true} timeout={800}>
        <Box>
          <Box
            onClick={() => setActivePlan("Business")}
            p={4}
            sx={{
              border: `1px solid ${
                activePlan === "Business" ? "#8005ed" : "#E5E7EB"
              }`,
              borderRadius: "16px",
              display: "flex",
              justifyContent: "space-between",
              mb: "20px",
              cursor: "pointer",
              transition: "0.5s",
            }}
          >
            <Box
              className={styles.container}
              sx={{ width: "100%", mr: "80px" }}
            >
              {activePlan === "Business" && (
                <StepDone
                  style={{ position: "absolute" }}
                  width={40}
                  height={40}
                />
              )}

              <Typography
                className={styles.title}
                sx={{ textAlign: "start", fontSize: "20px" }}
                mb="16px"
              >
                <FormattedMessage
                  id="manage.organization-subscription-business"
                  defaultMessage="Business"
                />
              </Typography>
              <Typography className={styles.price} mb={3}>
                <FormattedMessage
                  tagName="span"
                  id="manage.organization-subscription-business-price"
                  defaultMessage="{currency}19"
                  values={{
                    currency: "$",
                  }}
                />
                <FormattedMessage id="per-month" defaultMessage="/month" />
              </Typography>

              <Typography className={styles.text}>
                <FormattedMessage
                  id="business-text"
                  defaultMessage="A plan that scales with your rapidly growing business."
                />
              </Typography>
            </Box>
            <Box sx={{ width: "100%" }}>
              <Box mb="18px" display="flex">
                <Lightning style={{ marginRight: "12px" }} />
                <Box display="flex" justifyContent="space-between" width="100%">
                  <Typography className={styles.text}>
                    <FormattedMessage
                      id="numberOfLicensedUsers"
                      defaultMessage="Number of licensed users"
                    />
                  </Typography>
                  <Typography className={styles.values}>
                    <FormattedMessage
                      id="licensed-values"
                      defaultMessage="1 to 20"
                    />
                  </Typography>
                </Box>
              </Box>
              <Box mb="18px" display="flex">
                <Lightning style={{ marginRight: "12px" }} />
                <Box display="flex" justifyContent="space-between" width="100%">
                  <Typography className={styles.text}>
                    <FormattedMessage
                      id="meetingTimeLimit"
                      defaultMessage="Meeting time limit"
                    />
                  </Typography>
                  <Typography className={styles.values}>
                    <FormattedMessage
                      id="limit-values"
                      defaultMessage="unlimited"
                    />
                  </Typography>
                </Box>
              </Box>
              <Box mb="18px" display="flex">
                <Lightning style={{ marginRight: "12px" }} />
                <Box display="flex" justifyContent="space-between" width="100%">
                  <Typography className={styles.text}>
                    <FormattedMessage
                      id="participants-values"
                      defaultMessage="Number of participants per room"
                    />
                  </Typography>
                  <Typography className={styles.values}>25</Typography>
                </Box>
              </Box>
              <Box mb="18px" display="flex">
                <Lightning style={{ marginRight: "12px" }} />
                <Box display="flex" justifyContent="space-between" width="100%">
                  <Typography className={styles.text}>
                    <FormattedMessage
                      id="guests-values"
                      defaultMessage="Free external guests per room"
                    />
                  </Typography>
                  <Typography className={styles.values}>14</Typography>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box
            onClick={() => setActivePlan("Corporate")}
            p={4}
            sx={{
              border: `1px solid ${
                activePlan === "Corporate" ? "#8005ed" : "#E5E7EB"
              }`,
              borderRadius: "16px",
              display: "flex",
              justifyContent: "space-between",
              mb: "20px",
              cursor: "pointer",
              transition: "0.5s",
            }}
          >
            <Box
              className={styles.container}
              sx={{ width: "100%", mr: "80px" }}
            >
              {activePlan === "Corporate" && (
                <StepDone
                  style={{ position: "absolute" }}
                  width={40}
                  height={40}
                />
              )}
              <Typography
                className={styles.title}
                sx={{ textAlign: "start", fontSize: "20px" }}
                mb="16px"
              >
                <FormattedMessage
                  id="manage.organization-subscription-corporate"
                  defaultMessage="Corporate"
                />
              </Typography>
              <Typography className={styles.price} mb={3}>
                <FormattedMessage
                  tagName="span"
                  id="special-price"
                  defaultMessage="Special price"
                />
              </Typography>

              <Typography className={styles.text}>
                <FormattedMessage
                  id="corporate-text"
                  defaultMessage="Contact us for a detailed consultation."
                />
              </Typography>
            </Box>
            <Box sx={{ width: "100%" }}>
              <Box mb="18px" display="flex">
                <Lightning style={{ marginRight: "12px" }} />
                <Box display="flex" justifyContent="space-between" width="100%">
                  <Typography className={styles.text}>
                    <FormattedMessage
                      id="numberOfLicensedUsers"
                      defaultMessage="Number of licensed users"
                    />
                  </Typography>
                  <Typography className={styles.values}>
                    <FormattedMessage
                      id="limit-values"
                      defaultMessage="unlimited"
                    />
                  </Typography>
                </Box>
              </Box>
              <Box mb="18px" display="flex">
                <Lightning style={{ marginRight: "12px" }} />
                <Box display="flex" justifyContent="space-between" width="100%">
                  <Typography className={styles.text}>
                    <FormattedMessage
                      id="meetingTimeLimit"
                      defaultMessage="Meeting time limit"
                    />
                  </Typography>
                  <Typography className={styles.values}>
                    <FormattedMessage
                      id="limit-values"
                      defaultMessage="unlimited"
                    />
                  </Typography>
                </Box>
              </Box>
              <Box mb="18px" display="flex">
                <Lightning style={{ marginRight: "12px" }} />
                <Box display="flex" justifyContent="space-between" width="100%">
                  <Typography className={styles.text}>
                    <FormattedMessage
                      id="participants-values"
                      defaultMessage="Number of participants per room"
                    />
                  </Typography>
                  <Typography className={styles.values}>25</Typography>
                </Box>
              </Box>
              <Box mb="18px" display="flex">
                <Lightning style={{ marginRight: "12px" }} />
                <Box display="flex" justifyContent="space-between" width="100%">
                  <Typography className={styles.text}>
                    <FormattedMessage
                      id="guests-values"
                      defaultMessage="Free external guests per room"
                    />
                  </Typography>
                  <Typography className={styles.values}>14</Typography>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box
            onClick={() => setActivePlan("Starter")}
            p={4}
            sx={{
              border: `1px solid ${
                activePlan === "Starter" ? "#8005ed" : "#E5E7EB"
              }`,
              borderRadius: "16px",
              display: "flex",
              justifyContent: "space-between",
              mb: "20px",
              cursor: "pointer",
              transition: "0.5s",
            }}
          >
            <Box
              className={styles.container}
              sx={{ width: "100%", mr: "80px" }}
            >
              {activePlan === "Starter" && (
                <StepDone
                  style={{ position: "absolute" }}
                  width={40}
                  height={40}
                />
              )}
              <Typography
                className={styles.title}
                sx={{ textAlign: "start", fontSize: "20px" }}
                mb="16px"
              >
                <FormattedMessage
                  id="manage.organization-subscription-starter"
                  defaultMessage="Starter"
                />
              </Typography>
              <Typography className={styles.price} mb={3}>
                <FormattedMessage
                  tagName="span"
                  id="manage.organization-subscription-starter-price"
                  defaultMessage="{currency}0"
                  values={{
                    currency: "$",
                  }}
                />
                <FormattedMessage id="per-month" defaultMessage="/month" />
              </Typography>

              <Typography className={styles.text}>
                <FormattedMessage
                  id="starter-text"
                  defaultMessage="The essentials to provide your best work for clients."
                />
              </Typography>
            </Box>
            <Box sx={{ width: "100%" }}>
              <Box mb="18px" display="flex">
                <Lightning style={{ marginRight: "12px" }} />
                <Box display="flex" justifyContent="space-between" width="100%">
                  <Typography className={styles.text}>
                    <FormattedMessage
                      id="numberOfLicensedUsers"
                      defaultMessage="Number of licensed users"
                    />
                  </Typography>
                  <Typography className={styles.values}>
                    <FormattedMessage
                      id="licensedStarter-values"
                      defaultMessage="4 max"
                    />
                  </Typography>
                </Box>
              </Box>
              <Box mb="18px" display="flex">
                <Lightning style={{ marginRight: "12px" }} />
                <Box display="flex" justifyContent="space-between" width="100%">
                  <Typography className={styles.text}>
                    <FormattedMessage
                      id="meetingTimeLimit"
                      defaultMessage="Meeting time limit"
                    />
                  </Typography>
                  <Typography className={styles.values}>
                    <FormattedMessage
                      id="meetingTimeLimit-values"
                      defaultMessage="40 min"
                    />
                  </Typography>
                </Box>
              </Box>
              <Box mb="18px" display="flex">
                <Lightning style={{ marginRight: "12px" }} />
                <Box display="flex" justifyContent="space-between" width="100%">
                  <Typography className={styles.text}>
                    <FormattedMessage
                      id="participants-values"
                      defaultMessage="Number of participants per room"
                    />
                  </Typography>
                  <Typography className={styles.values}>4</Typography>
                </Box>
              </Box>
              <Box mb="18px" display="flex">
                <Lightning style={{ marginRight: "12px" }} />
                <Box display="flex" justifyContent="space-between" width="100%">
                  <Typography className={styles.text}>
                    <FormattedMessage
                      id="guests-values"
                      defaultMessage="Free external guests per room"
                    />
                  </Typography>
                  <Typography className={styles.values}>4</Typography>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box
            flexWrap="wrap"
            className={styles.modalFooter}
            sx={{ maxHeight: "42px" }}
          >
            <FormControl
              onClick={handleCloseAllPlans}
              fullWidth
              sx={{ mr: "20px", maxWidth: "220px", maxHeight: "42px" }}
            >
              <Button variant="contained" color="secondary">
                <FormattedMessage id="actions.back" defaultMessage="Back" />
              </Button>
            </FormControl>

            <Button type="submit" variant="contained" color="primary">
              <FormattedMessage
                id="actions.continue"
                defaultMessage="Continue"
              />
            </Button>
          </Box>
        </Box>
      </Fade>
    </>
  );
};
