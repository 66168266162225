import styles from "./index.module.scss";
import { FormattedMessage } from "react-intl";
import { Box, FormControl, Typography } from "@mui/material";
import { useEffect, useRef } from "react";

export const Calculator = () => {
  const scriptRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    // @ts-ignore
    const script: HTMLScriptElement = document.createElement("script");
    script.id = "datagir-impact-co2";
    script.src = "https://impactco2.fr/iframe.js";
    script.setAttribute("data-type", "convertisseur");
    script.setAttribute("data-search", "?theme=default");

    if (scriptRef.current) {
      const currentScriptRef = scriptRef.current;
      currentScriptRef.appendChild(script);

      return () => {
        currentScriptRef.removeChild(script);
      };
    }
  }, []);

  return (
    <Box>
      <Typography
        sx={{ display: "flex", justifyContent: "flex-start", mb: 3 }}
        className={styles.title}
      >
        <FormattedMessage
          id="carbonCreditsTabsCalculator"
          defaultMessage="Calculator"
        />
      </Typography>
      <FormControl
        component="div"
        sx={{
          overflow: "hidden",
          "& div": {
            display: "none",
          },
        }}
        ref={scriptRef}
        fullWidth
        className={styles.frame}
      ></FormControl>
    </Box>
  );
};
