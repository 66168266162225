import React, { useState } from "react";
import styles from "../../../../assets/stylesheets/dialog.module.scss";
import { loader } from "graphql.macro";
import { Mutation } from "@apollo/client/react/components";
import Error from "../../../Error";
import { Formik, FormikErrors } from "formik";
import {
  Box,
  Button,
  IconButton,
  Modal,
  Tooltip,
  Typography,
} from "@mui/material";

import { FormattedMessage, useIntl } from "react-intl";
import { ReactComponent as Trash } from "../../../../assets/Trash.svg";
import { ReactComponent as DeleteSvg } from "../../../../assets/deleteMemberSvg.svg";

const REMOVE_ORGANIZATION_MEMBER = loader(
  "../../../../schema/mutation/DeleteRoom.graphql"
);

interface Props {
  slug: string;
}

interface Data {
  deleteRoom: Room;
}

interface FormValues {}

const DeleteRoomButton: React.FC<Props> = ({ slug }) => {
  const intl = useIntl();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const initialValues: FormValues = {};

  return (
    <>
      <Tooltip
        title={intl.formatMessage({
          id: "actions.delete",
          defaultMessage: "Delete",
        })}
      >
        <IconButton type="button" onClick={handleShow}>
          <Trash />
        </IconButton>
      </Tooltip>

      <Modal open={show} onClose={handleClose}>
        <Box
          className={styles.dialog}
          sx={{
            bgcolor: "background.paper",
          }}
        >
          <Mutation<Data, FormValues>
            mutation={REMOVE_ORGANIZATION_MEMBER}
            onCompleted={handleClose}
            refetchQueries={["GetRooms"]}
          >
            {(deleteRoom, { error }) => (
              <Formik
                initialValues={initialValues}
                validate={(values: FormValues) => {
                  const errors: FormikErrors<FormValues> = {};
                  return errors;
                }}
                onSubmit={(_, { setSubmitting }) => {
                  let data = {
                    slug: slug,
                  };
                  deleteRoom({
                    variables: data,
                  })
                    .then(() => {
                      // @ts-ignore-line
                      if (typeof _paq !== "undefined")
                        // @ts-ignore-line
                        _paq.push(["trackEvent", "Rooms", "Delete room"]);
                    })
                    .catch(() => {
                      setSubmitting(false);
                    });
                }}
              >
                {({ handleSubmit, isSubmitting }) => (
                  <>
                    <DeleteSvg />
                    <Typography className={styles.title}>
                      <FormattedMessage
                        id="actions.delete-meeting"
                        defaultMessage="Delete meeting"
                      />
                    </Typography>

                    <Typography className={styles.secondaryText}>
                      <FormattedMessage
                        id="actions.delete-confirmation-paragraph"
                        defaultMessage="This will also remove the associated whiteboard. So be sure to export the whiteboard on your machine if you want to keep it."
                      />
                    </Typography>
                    <Error error={error} />
                    <Box
                      className={styles.actions}
                      component="form"
                      onSubmit={handleSubmit}
                    >
                      <Button
                        onClick={handleClose}
                        className={styles.cancelButton}
                        variant="contained"
                        color="secondary"
                      >
                        <FormattedMessage
                          id="actions.back"
                          defaultMessage="Back"
                        />
                      </Button>
                      <Button
                        className={styles.validateButton}
                        type="submit"
                        disabled={isSubmitting}
                        variant="contained"
                      >
                        <FormattedMessage
                          id="action-delete.yes"
                          defaultMessage="Yes"
                        />
                      </Button>
                    </Box>
                  </>
                )}
              </Formik>
            )}
          </Mutation>
        </Box>
      </Modal>
    </>
  );
};

export default DeleteRoomButton;
