import React from "react";
import MeetingCard from "../MeetingCard/index";

import { Fade, Grid } from "@mui/material";

import { TransitionGroup } from "react-transition-group";

interface Props {
  isFinished?: boolean;
  loading?: boolean;
  rooms: Array<Room>;
  selectedOrganizationId: string | null;
  setSelectedOrganizationId: (id: string) => void;
}

const MeetingList: React.FC<Props> = ({
  rooms,
  isFinished,
  loading,
  selectedOrganizationId,
  setSelectedOrganizationId,
}) => {
  return (
    <TransitionGroup component={null}>
      {rooms.map((room) => (
        <Fade key={room.id} in={true} timeout={500}>
          <Grid item>
            <MeetingCard
              selectedOrganizationId={selectedOrganizationId}
              setSelectedOrganizationId={setSelectedOrganizationId}
              finishedMeeting={isFinished}
              room={room}
            />
          </Grid>
        </Fade>
      ))}
    </TransitionGroup>
  );
};

export default MeetingList;
