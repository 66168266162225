import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useQuery } from "@apollo/client";
import { loader } from "graphql.macro";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Error from "../components/Error";
import CurrentUser from "../components/CurrentUser";
import PleaseLogIn from "../components/PleaseLogIn";
import ManageOrganizationTable from "../components/ManageOrganizationTable/index";
import ManageOrganizationMembersTable from "../components/ManageOrganizationMembersTable/ManageOrganizationMembersTable";
import OrganizationInfo from "../components/OrganizationInfo/OrganizationInfo";
import { faLock } from "@fortawesome/free-solid-svg-icons/faLock";
import { faUsers } from "@fortawesome/free-solid-svg-icons/faUsers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  Box,
  Button,
  Container,
  Divider,
  Fade,
  Typography,
} from "@mui/material";
import { APIKeys } from "../components/APIKeys/APIKeys";

const GET_ORGANIZATION_QUERY = loader(
  "../schema/query/GetOrganization.graphql"
);
const Organization = () => {
  const intl = useIntl();
  const [selectedOrganization, setSelectedOrganization] = useState(null);
  const [selectedTab, setSelectedTab] = useState<"members" | "apikeys">(
    "members"
  );

  const { data, loading, error } = useQuery(GET_ORGANIZATION_QUERY, {
    variables: { id: selectedOrganization || "" },
    skip: !selectedOrganization,
  });

  if (loading) return null;
  if (error) return <Error error={error} />;
  const organization = data?.organization;

  const handleTabChange = (
    event: React.SyntheticEvent,
    newValue: "members" | "apikeys"
  ) => {
    setSelectedTab(newValue);
  };

  return (
    <CurrentUser>
      {(currentUser) => {
        if (!currentUser) {
          return <PleaseLogIn />;
        }
        const organizations = currentUser.organizations;
        if (selectedOrganization === null && organizations.length > 0) {
          return (
            <Fade in={!loading} timeout={500}>
              <Container
                disableGutters
                sx={{
                  minWidth: "85%",
                  display: "flex",
                  flex: 1,
                  flexDirection: "column",
                }}
              >
                <Box
                  sx={{
                    border: "1px solid #D1D5DB",
                    borderRadius: "12px",
                    mr: 3,
                    ml: 3,
                  }}
                >
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    justifyItems="center"
                    flexWrap="wrap"
                    gap="10px 20px"
                    sx={{
                      px: 4,
                    }}
                  >
                    <Typography
                      sx={{
                        py: 2,
                        display: "flex",
                        alignItems: "center",
                        fontSize: "24px",
                        fontWeight: 600,
                        color: "#931ffa",
                      }}
                    >
                      <FormattedMessage
                        id="manage-organization.my-organizations"
                        defaultMessage="My Organizations"
                      />
                    </Typography>
                  </Box>
                  <Divider />
                  <ManageOrganizationTable
                    organizations={organizations}
                    onManageOrganization={(id: any) =>
                      setSelectedOrganization(id)
                    }
                    setSelectedOrganization={setSelectedOrganization}
                  />
                  {/* <Grid container spacing={2} padding={4}></Grid> */}
                </Box>
              </Container>
            </Fade>
          );
        } else {
          const membersCount = organization.members.filter(
            (m: any) => m.status !== "REJECTED"
          )?.length;

          return (
            <Container
              disableGutters
              sx={{
                minWidth: "85%",
                display: "flex",
                flex: 1,
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  border: "1px solid #D1D5DB",
                  borderRadius: "12px",
                  mr: 3,
                  ml: 3,
                }}
              >
                <Box
                  display="flex"
                  justifyContent="space-between"
                  justifyItems="center"
                  flexWrap="wrap"
                  gap="10px 20px"
                  sx={{
                    px: 4,
                  }}
                >
                  <Typography
                    sx={{
                      py: 2,
                      display: "flex",
                      alignItems: "center",
                      fontSize: "24px",
                      fontWeight: 600,
                      color: "#931ffa",
                    }}
                  >
                    <FormattedMessage
                      id="manage.organization-info"
                      defaultMessage="Organization info"
                    />
                  </Typography>
                  {organization.role === "OWNER" && (
                    <Tabs
                      value={selectedTab}
                      onChange={handleTabChange}
                      aria-label="organization menu"
                    >
                      <Tab
                        icon={<FontAwesomeIcon icon={faUsers} />}
                        iconPosition="start"
                        label={intl.formatMessage({
                          id: "members",
                          defaultMessage: "Members",
                        })}
                        value="members"
                      />
                      <Tab
                        icon={<FontAwesomeIcon icon={faLock} />}
                        iconPosition="start"
                        label={intl.formatMessage({
                          id: "apikeys",
                          defaultMessage: "API keys",
                        })}
                        value="apikeys"
                      />
                    </Tabs>
                  )}
                  {organizations.length > 0 && (
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Button
                        onClick={() => {
                          setSelectedOrganization(null);
                          setSelectedTab("members");
                        }}
                        variant="outlined"
                        color="secondary"
                      >
                        <FormattedMessage
                          id="actions.back-to-my-organizations"
                          defaultMessage="Back to my organizations"
                        />
                      </Button>
                    </Box>
                  )}
                </Box>
                <Divider />
                {selectedTab === "members" && (
                  <>
                    <OrganizationInfo
                      id={organization.id}
                      name={organization.name}
                      logoId={organization.logoId}
                      premiumType={organization.premiumType}
                      subscriptionDate={organization.subscriptionDate}
                      role={organization.role}
                      numberOfSeats={organization.numberOfSeats}
                      membersCount={membersCount}
                    />

                    <ManageOrganizationMembersTable
                      organizationId={organization.id}
                      role={organization.role}
                      members={organization.members}
                    />
                  </>
                )}
                {selectedTab === "apikeys" && (
                  <Box
                    sx={{
                      p: 4,
                    }}
                  >
                    <APIKeys organizationId={organization.id} />
                  </Box>
                )}
              </Box>
            </Container>
          );
        }
      }}
    </CurrentUser>
  );
};

export default Organization;
