import { Box, Container, Divider, Grid, Typography } from "@mui/material";
import AvatarList from "../components/MyRooms/components/AvatarList";
import { FormattedMessage } from "react-intl";

const Avatars = () => {
  return (
    <Container
      disableGutters
      sx={{
        minWidth: "85%",
        display: "flex",
        flex: 1,
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          border: "1px solid #D1D5DB",
          borderRadius: "12px",
          mr: 3,
          ml: 3,
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          justifyItems="center"
          flexWrap="wrap"
          gap="10px 20px"
          sx={{
            px: 4,
          }}
        >
          <Typography
            sx={{
              py: 2,
              display: "flex",
              alignItems: "center",
              fontSize: "24px",
              fontWeight: 600,
              color: "#931ffa",
            }}
          >
            <FormattedMessage id="avatars.header" defaultMessage="My Avatars" />
          </Typography>
        </Box>
        <Divider />
        <Grid container spacing={2} padding={{ xs: 1, sm: 2, md: 3 }}>
          <AvatarList />
        </Grid>
      </Box>
    </Container>
  );
};

export default Avatars;
