import { FormattedMessage, useIntl } from "react-intl";
import styles from "./index.module.scss";

import { Box, Typography, Card, Grid, Divider } from "@mui/material";
import CreateMeetingBtn from "../../../CreateMeetingButton";

interface Props {
  title: string;
  selectedOrganizationId: string | null;
  setSelectedOrganizationId: (id: string) => void;
}
export const DefaultMeetingCard: React.FC<Props> = ({
  title,
  selectedOrganizationId,
  setSelectedOrganizationId,
}) => {
  const intl = useIntl();

  return (
    <Grid item>
      <Card
        className={styles.card}
        sx={{
          boxShadow: "none",
          borderRadius: "12px",
          maxHeight: "392px",
          maxWidth: "300px",
          minWidth: "250px",
        }}
      >
        <Box className={styles.header} display="flex">
          <Typography component="h1" className={styles.title}>
            {title}
          </Typography>
        </Box>
        <Box>
          <Box className={styles.image} sx={{ minHeight: "161px" }}>
            <img src="/thumbnails/meeting_room_4.jpg" alt={""} />
          </Box>
        </Box>

        <Typography sx={{ textAlign: "center", p: "10px 20px 10px 20px" }}>
          <FormattedMessage
            id="card.invite"
            defaultMessage="You can invite anyone to your meeting room at a specific date in time."
          />
        </Typography>

        <Divider />
        <Box className={styles.btn}>
          <CreateMeetingBtn
            label={intl.formatMessage({
              id: "create.btn",
              defaultMessage: "Create it",
            })}
            selectedOrganizationId={selectedOrganizationId}
            setSelectedOrganizationId={setSelectedOrganizationId}
          />
        </Box>
      </Card>
    </Grid>
  );
};
