import React from "react";
import { createRoot } from "react-dom/client";
import { ThemeProvider } from "@mui/material/styles";
import App from "./App";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import { ApolloProvider } from "@apollo/client";

import client from "./client";

import "./assets/main.scss";
import { theme } from "./theme";
import { CssBaseline } from "@mui/material";
let environment = "dev";
if (process.env.NODE_ENV === "production") {
  if (window.location.hostname === "preprod.synantoo.app") {
    environment = "preprod";
  } else {
    environment = "production";
  }
}

if (environment !== "dev") {
  Sentry.init({
    dsn: process.env.REACT_APP_DSN,
    integrations: [new Integrations.BrowserTracing()],
    release: process.env.REACT_APP_RELEASE || "1",
    environment: environment,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

const root = createRoot(document.getElementById("root")!);
root.render(
  // <React.StrictMode>
  <ApolloProvider client={client}>
    <CssBaseline />
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </ApolloProvider>
  // </React.StrictMode>,
);
