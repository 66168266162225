import React from "react";
import { useParams } from "react-router-dom";
import CurrentUser from "../components/CurrentUser";
import { loader } from "graphql.macro";
import { MeetingInfoCard } from "../components/MeetingInfoCard";
import { useQuery } from "@apollo/client";
import Error from "../components/Error";
import PleaseLogIn from "../components/PleaseLogIn";
import { getSelectedOrganization } from "../lib/account";

const GET_ROOM_QUERY = loader("../schema/query/GetRoom.graphql");

interface Data {
  room: Room;
}

interface Variables {
  slug: string;
}

interface Props {
  selectedOrganizationId: string | null;
  setSelectedOrganizationId: (id: string) => void;
}

interface RouteParams {
  slug: string;
}
const MeetingInfo: React.FC<Props> = ({
  selectedOrganizationId,
  setSelectedOrganizationId,
}) => {
  let { slug } = useParams<RouteParams>();

  const { data, loading, error } = useQuery<Data, Variables>(GET_ROOM_QUERY, {
    variables: { slug: slug },
  });

  if (!data) {
    return <Error error={error} />;
  }

  const checkRoom = (room: Room) => {
    const currentDate = new Date();

    if (room.endDate) {
      const roomEndDate = new Date(room.endDate);
      return roomEndDate < currentDate;
    }

    return false;
  };

  return (
    <CurrentUser>
      {(currentUser: User | null) => {
        if (!currentUser) {
          return <PleaseLogIn />;
        }
        if (loading) return null;
        if (error || !data) return <Error error={error} />;
        if (!data.room) {
          return null;
        }

        const organization = getSelectedOrganization(
          currentUser,
          selectedOrganizationId
        );
        selectedOrganizationId = organization ? organization.id : null;

        return (
          <MeetingInfoCard
            isFinished={checkRoom(data.room)}
            room={data?.room}
            selectedOrganizationId={selectedOrganizationId}
            setSelectedOrganizationId={setSelectedOrganizationId}
          />
        );
      }}
    </CurrentUser>
  );
};

export default MeetingInfo;
