import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete, {
  AutocompleteChangeDetails,
} from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { FC, useState, useMemo } from "react";
import { debounce } from "@mui/material/utils";
import { Chip, CircularProgress, Typography } from "@mui/material";
import { fetchAttendees } from "../../../lib/helpers";
import { useIntl } from "react-intl";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface Props {
  id: string;
  attendees: Attendee[];
  handleSelectedUsers: (
    event: React.SyntheticEvent,
    value: (string | Attendee)[],
    reason: "createOption" | "selectOption" | "removeOption" | "blur" | "clear",
    details?: AutocompleteChangeDetails<Attendee> | undefined
  ) => void;
  placeholder: string;
}

const ParticipantsInput: FC<Props> = ({
  placeholder,
  handleSelectedUsers,
  attendees,
  id,
}) => {
  const intl = useIntl();
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<readonly Attendee[]>([]);
  const [loading, setLoading] = useState(false);
  // We use null initially to do the first fetchAttendees("") on focus
  const [currentSearchedValue, setCurrentSearchedValue] = useState<
    null | string
  >(null);

  // The useMemo is needed here for debounce to work properly, otherwise it's a new instance
  // of debounce every render so every time value change making the debounce not working.
  const fetchAndSetOptions = useMemo(() => {
    const cache = new Map();
    return debounce(async (value: string) => {
      // using setLoading(true) here doesn't work, it doesn't show the spinner
      let attendees = cache.get(value);
      if (!attendees) {
        attendees = await fetchAttendees(value);
        cache.set(value, attendees);
      }
      setOptions(attendees);
      setLoading(false);
    }, 200);
  }, []);

  const handleInputChange = (
    event: React.ChangeEvent<{}> | null,
    value: string
  ) => {
    value = value.trim();
    if (currentSearchedValue !== value && value.indexOf("@") === -1) {
      // Don't do a fetch if the user is typing an email
      setLoading(true);
      setCurrentSearchedValue(value);
      fetchAndSetOptions(value);
    }
  };

  return (
    <Autocomplete
      sx={{
        maxWidth: 448,
      }}
      fullWidth
      size="small"
      id={id}
      open={open}
      onFocus={() => {
        // Get the latest used participants on first focus
        if (currentSearchedValue === null) {
          handleInputChange(null, "");
        }
      }}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      multiple
      disableCloseOnSelect
      freeSolo
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      options={options}
      isOptionEqualToValue={(option, value) => {
        if (typeof option === "object" && typeof value === "object") {
          return option.email === value.email;
        } else {
          return option === value;
        }
      }}
      disableListWrap={true}
      renderOption={(props, option, { selected }) => {
        return (
          <Typography
            component="li"
            key={option.email}
            {...props}
            sx={{
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              checked={selected}
            />
            <span style={{ marginRight: 10, color: "#931ffa" }}>
              {option.fullname}
            </span>
            {option.email}
          </Typography>
        );
      }}
      getOptionLabel={(option: string | Attendee) => {
        if (typeof option === "string") {
          return option;
        }
        return option.fullname || option.email;
      }}
      renderTags={(value: Attendee[], getTagProps) =>
        value.map((option: Attendee, index) => {
          const isStringOption = typeof option === "string";
          const isInvalidEmail =
            isStringOption && (option as string).indexOf("@") === -1;
          let label;
          if (isStringOption) {
            if (!isInvalidEmail) {
              label = option;
            } else {
              label = intl.formatMessage({
                id: "errors.invalid-email-address",
                defaultMessage: "Invalid email address",
              });
            }
          } else {
            label = option.fullname || option.email;
          }

          return (
            <Chip
              color="primary"
              label={label}
              {...getTagProps({ index })}
              sx={{
                backgroundColor: isInvalidEmail ? "#FF6347" : "",
                color: "#fff",
              }}
            />
          );
        })
      }
      value={attendees}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={attendees.length > 0 ? "" : placeholder}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="primary" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      onInputChange={handleInputChange}
      onChange={handleSelectedUsers}
    />
  );
};

export default ParticipantsInput;
