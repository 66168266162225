import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Typography,
} from "@mui/material";
import styles from "./index.module.scss";
import { ReactComponent as Upload } from "../../assets/upload.svg";
import { FormattedMessage } from "react-intl";
import { MuiTelInput, MuiTelInputContinent } from "mui-tel-input";

import { CustomCountrySelect } from "./components/CustomCountrySelect";
import { ReactComponent as Mail } from "../../assets/Mail.svg";
import RemoveAccountButton from "../RemoveAccountButton/RemoveAccountButton";

import { useFormik } from "formik";

export const PersonaInfo = () => {
  const formik = useFormik({
    initialValues: {
      name: "Test name",
      email: "exae@mail.com",
      phone: "+380000000000",
      street: "Test street",
      street2: "Test street2",
      city: "Test city",
      zip: "00000",
      lang: "en_US",
      country: "FR",
      password: "test",
      image: null,
    },
    // validationSchema: Yup.object().shape({
    //   name: Yup.string().required('Name is required'),
    //   email: Yup.string().email('Invalid email').required('Email is required'),
    //   // Add validation for other fields here
    // }),
    onSubmit: async (values) => {
      const formData = new FormData();
      formData.append("client", JSON.stringify(values));
      if (values.image) {
        formData.append("image", values.image);
      }

      try {
        const response = await fetch("https://odoo.synantoo.app/v1/clients/", {
          method: "POST",
          body: formData,
        });
        if (response.ok) {
          const data = await response.json();
          console.log(data);
        } else {
          console.error("Error submitting the form");
        }
      } catch (error) {
        console.error(error);
      }
    },
  });

  const continents: MuiTelInputContinent[] = ["EU"];
  return (
    <>
      <Typography sx={{ mb: "30px" }} className={styles.title}>
        <FormattedMessage
          id="settings-personalInfo"
          defaultMessage="Personal information"
        />
      </Typography>

      <Box display="flex" flexDirection="row" alignItems="center">
        <input
          type="file"
          id="image"
          name="image"
          style={{ display: "none" }}
          onChange={(event) => {
            const selectedFile = event.target.files
              ? event.target.files[0]
              : null;
            formik.setFieldValue("image", selectedFile);
          }}
        />
        <label htmlFor="image">
          <IconButton
            className={styles.uploadIconButton}
            sx={{ mr: "20px", minWidth: "64px", minHeight: "64px" }}
            component="span"
          >
            <Upload />
          </IconButton>
        </label>
        <Box>
          <Typography className={styles.subTitle}>
            {" "}
            <FormattedMessage
              id="settings-personalInfo-profilePicture"
              defaultMessage="Profile picture"
            />
          </Typography>
          <Typography className={styles.text}>
            {" "}
            <FormattedMessage
              id="settings-personalInfo-profilePicture-title"
              defaultMessage="The ideal size is 500x500 px."
            />
          </Typography>
          <Typography className={styles.text}>
            {" "}
            <FormattedMessage
              id="settings-personalInfo-profilePicture-subtitle"
              defaultMessage="The maximum file size is 5 MB."
            />
          </Typography>
        </Box>
      </Box>

      <Box
        component="form"
        onClick={formik.handleSubmit}
        id="updateClientInfo"
        mt="24px"
        display="flex"
        flexDirection="column"
      >
        <Box
          display="flex"
          justifyContent="space-between"
          flexWrap="wrap"
          sx={{ width: "100%", mb: "10px" }}
        >
          <FormControl
            sx={{ mr: "24px", maxWidth: "47%", pb: 1 }}
            className={styles.inputWithLabel}
          >
            <Typography>
              <FormattedMessage id="first-name" defaultMessage="First name" />
            </Typography>
            <OutlinedInput
              autoComplete="name"
              id="name"
              name="name"
              required
              type="text"
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </FormControl>
          <FormControl
            sx={{ maxWidth: "47%" }}
            className={styles.inputWithLabel}
          >
            <Typography>
              <FormattedMessage
                id="profile-linkedin"
                defaultMessage="Linkedin profile"
              />
            </Typography>
            <OutlinedInput
              fullWidth
              id="linkedinProfile"
              name="linkedinProfile"
              // value={formik.values.linkedinProfile}
              // onChange={formik.handleChange}
              // onBlur={formik.handleBlur}
            />
          </FormControl>
        </Box>

        <Box
          justifyContent="space-between"
          display="flex"
          flexWrap="wrap"
          sx={{ width: "100%", mb: "10px" }}
        >
          <FormControl
            sx={{ mr: "24px", maxWidth: "47%", pb: 1 }}
            className={styles.inputWithLabel}
          >
            <Typography>
              <FormattedMessage id="form-country" defaultMessage="Country" />
            </Typography>
            <CustomCountrySelect />
          </FormControl>

          <FormControl
            sx={{ maxWidth: "47%" }}
            className={styles.inputWithLabel}
          >
            <Typography>
              <FormattedMessage id="profile-street" defaultMessage="Street" />
            </Typography>
            <OutlinedInput
              fullWidth
              id="street"
              name="street"
              type="text"
              value={formik.values.street}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </FormControl>
        </Box>

        <Box
          display="flex"
          justifyContent="space-between"
          flexWrap="wrap"
          sx={{ width: "100%", mb: "10px" }}
        >
          <FormControl
            sx={{ mr: "24px", maxWidth: "47%", pb: 1 }}
            className={styles.inputWithLabel}
          >
            <Typography>
              <FormattedMessage
                id="email-address"
                defaultMessage="Email address"
              />
            </Typography>
            <OutlinedInput
              id="email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              type="email"
              name="email"
              placeholder="you@example.com"
              required
              startAdornment={
                <InputAdornment position="start">
                  <Mail />
                </InputAdornment>
              }
            />
          </FormControl>
          <FormControl
            sx={{ maxWidth: "47%" }}
            className={styles.inputWithLabel}
          >
            <Typography>
              <FormattedMessage id="profile-street" defaultMessage="Street" /> 2
            </Typography>
            <OutlinedInput
              fullWidth
              id="street2"
              name="street2"
              type="text"
              value={formik.values.street2}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </FormControl>
        </Box>

        <Box
          display="flex"
          justifyContent="space-between"
          flexWrap="wrap"
          sx={{ width: "100%" }}
        >
          <FormControl
            sx={{ mr: "24px", maxWidth: "47%", pb: 1 }}
            className={styles.inputWithLabel}
          >
            <Typography>
              <FormattedMessage
                id="phone-number"
                defaultMessage="Phone number"
              />
            </Typography>
            <FormControl
              sx={{ minWidth: "100%", minHeight: "48px", maxWidth: "47%" }}
            >
              <MuiTelInput
                value={formik.values.phone}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                continents={continents}
                defaultCountry="FR"
              />
            </FormControl>
          </FormControl>
          <FormControl
            sx={{ maxWidth: "47%" }}
            className={styles.inputWithLabel}
          >
            <Typography>
              <FormattedMessage id="profile-zip" defaultMessage="ZIP" />
            </Typography>
            <OutlinedInput
              fullWidth
              id="zip"
              name="zip"
              type="text"
              value={formik.values.zip}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </FormControl>
        </Box>
        <Box flexWrap="wrap" className={styles.modalFooter}>
          <RemoveAccountButton />

          <Button
            form="updateClientInfo"
            variant="contained"
            color="primary"
            type="submit"
          >
            <FormattedMessage
              id="settings-saveData"
              defaultMessage="Save data"
            />
          </Button>
        </Box>
      </Box>
    </>
  );
};
