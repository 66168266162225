import { ReactComponent as Globe } from "../../assets/Globe.svg";
import { ReactComponent as ArrowRise } from "../../assets/ArrowRise.svg";
import { ReactComponent as Office } from "../../assets/OfficeBuilding.svg";
import { ReactComponent as UserGroup } from "../../assets/UserGroup.svg";
import { ReactComponent as Line } from "../../assets/Line.svg";
import { ReactComponent as Dot } from "../../assets/Dot2.svg";
import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import styles from "./index.module.scss";
import { FormattedMessage } from "react-intl";
import { FC, useState } from "react";
import LinearProgressWithLabel from "../LinearProgress";
import { colors } from "../../lib/constants";
import { BarChartData } from "../../lib/helpers";

import LineChart from "../Charts/LineChart";
import ChartModal from "../Charts/ChartModal/ChartModal";
import User from "../User";
const { dark, purple } = colors;

export const statisticUserData = [
  {
    id: "nbOfMeetingCreated",
    value: 113,
    comparedPercent: 22,
    css: "item-1",
  },
  {
    id: "nbOfMeetingInvited",
    value: 97,
    comparedPercent: -12,
    css: "item-2",
  },
  {
    id: "nbOfInternalUserInvited",
    value: 6,
    css: "item-3",
  },
  {
    id: "nbOfExternalUserInvited",
    value: 6,
    css: "item-4",
  },
  {
    id: "hoursInOfficeAndRemote",
    value: 36,
    remote: 6,
    css: "item-5",
  },
  {
    id: "totalCarbonCredits",
    value: 1200,
    saved: "335",
    css: "item-6",
  },
];

interface Props {
  currentUser: User;
  selectedMenuItem: string;
}

const data = {
  averageTimeOnMeeting: [
    { month: "August", hours: 50 },
    { month: "September", hours: 100 },
    { month: "October", hours: 30 },
    { month: "November", hours: 20 },
  ],
};

export const DashboardPerUser: FC<Props> = ({
  currentUser,
  selectedMenuItem,
}) => {
  const [showModal, setShowModal] = useState(null);
  const isNarrowScreen = useMediaQuery("(max-width:599px)");
  const currentDate = new Date();
  const currentMonth = new Intl.DateTimeFormat("en-US", {
    month: "long",
  }).format(currentDate);

  const handleShowModal = (index: any) => {
    setShowModal(index);
  };
  const handleClose = () => {
    setShowModal(null);
  };

  let currentData =
    selectedMenuItem === "Day"
      ? [0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 1, 1]
      : [55, 45, 6, 7, 87, 5, 13, 44, 66, 20, 0, 0];
  let lastData =
    selectedMenuItem === "Day"
      ? [5]
      : [20, 25, 30, 15, 10, 5, 18, 50, 90, 30, 0, 0];

  const filteredArray = [
    {
      label: "",
      data: currentData,
      borderColor: "rgba(28, 28, 28, 1)",
      fill: false,
    },
    {
      label: "",
      data: lastData,
      borderColor: "rgba(117, 1, 156, 1)",
      fill: false,
    },
  ];

  return (
    <Box
      p={isNarrowScreen ? 1 : 3}
      sx={{ display: isNarrowScreen ? "block" : "flex" }}
    >
      <Box pr={isNarrowScreen ? 0 : 3}>
        <Box
          sx={{
            width: "100%",
            m: 0,
            pb: 3,
            minWidth: "225px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              p: "28px 19px",
              alignItems: "center",
              backgroundColor: "rgba(153, 0, 204, 0.10)",
              borderRadius: "16px",
              maxHeight: "112px",
            }}
          >
            <Box
              sx={{
                mr: "12px",
                "& .MuiAvatar-root": {
                  height: "40px !important",
                  width: "40px !important",
                },
              }}
            >
              {currentUser && <User user={currentUser} />}
            </Box>

            <Box>
              <Typography
                color="secondary"
                sx={{ fontSize: "14px", fontWeight: 500 }}
              >
                {currentUser.fullname ? currentUser.fullname : ""}
              </Typography>
              <Typography
                sx={{
                  fontSize: "12px",
                  fontWeight: 500,
                }}
                component="span"
                color="primary"
              >
                <FormattedMessage
                  id="user-type"
                  defaultMessage="User of Kizwork"
                />
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            width: "100%",
            m: 0,
            minWidth: "225px",
            pb: 2,
          }}
        >
          <Box
            sx={{
              p: "28px 19px 0px 19px",
              alignItems: "center",
              backgroundColor: "#F7F9FB",
              borderRadius: "16px",
            }}
          >
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: 500,
              }}
              component="span"
              color="secondary"
            >
              <FormattedMessage
                id="card-averageTimeSpent"
                defaultMessage="Average time spent by meeting"
              />
            </Typography>
            <Box sx={{ mt: 3 }}>
              {data.averageTimeOnMeeting.map((item, index) => (
                <Box
                  sx={{ pb: 2 }}
                  key={index}
                  display="flex"
                  justifyContent="space-between"
                >
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: 400,
                    }}
                    color={dark}
                  >
                    <FormattedMessage
                      id={`month-${item.month}`}
                      defaultMessage={item.month}
                    />
                  </Typography>
                  <LinearProgressWithLabel
                    value={item.hours}
                    isCurrentMonth={item.month === currentMonth}
                  />
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
      <Box>
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          {statisticUserData.map((card, index) => (
            <ChartModal
              key={index}
              show={showModal === index}
              handleClose={handleClose}
              id={card.id}
              barChartData={BarChartData}
              selectedMenuItem={selectedMenuItem}
            />
          ))}
          {statisticUserData.map((card, index) => (
            <Grid
              item
              xs={12}
              sm={12}
              lg={4}
              md={4}
              key={index}
              minHeight={"125px"}
              minWidth="250px"
            >
              <Box
                className={`${styles[card.css]}`}
                onClick={() => handleShowModal(index)}
                sx={{
                  cursor: "pointer",
                  height: "100%",
                  p: 3,
                  pb: 2,
                  borderRadius: "16px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 500,
                    pb: 1,
                  }}
                  color="secondary"
                >
                  {card.id === "nbOfMeetingCreated" && (
                    <FormattedMessage
                      id="card-nbOfMeetingCreated"
                      defaultMessage="Nb of meetings created"
                    />
                  )}
                  {card.id === "nbOfMeetingInvited" && (
                    <FormattedMessage
                      id="card-nbOfMeetingInvited"
                      defaultMessage="Nb of meetings invited"
                    />
                  )}
                  {card.id === "nbOfInternalUserInvited" && (
                    <FormattedMessage
                      id="card-nbOfInternalUserInvited"
                      defaultMessage="Nb of internal users invited"
                    />
                  )}
                  {card.id === "nbOfExternalUserInvited" && (
                    <FormattedMessage
                      id="card-nbOfExternalUserInvited"
                      defaultMessage="Nb of external users invited"
                    />
                  )}

                  {card.id === "hoursInOfficeAndRemote" && (
                    <FormattedMessage
                      id="card-hoursInOfficeAndRemote"
                      defaultMessage="Nb of office h and non office h"
                    />
                  )}
                  {card.id === "totalCarbonCredits" && (
                    <FormattedMessage
                      id="card-totalCarbonCredits"
                      defaultMessage="Total Carbon credits (kg of CO2)"
                    />
                  )}
                </Typography>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography
                    sx={{
                      fontSize: "24px",
                      fontWeight: 600,
                    }}
                    color="secondary"
                  >
                    {index === 2 || index === 3 ? "12/" : ""}
                    {card.value}
                    {index === 4 && `/${card.remote}`}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: 400,
                      "& span": {
                        color: "red",
                      },
                    }}
                    display="flex"
                    color="primary"
                    alignItems="center"
                  >
                    {index === 0 || index === 1 ? (
                      <>
                        {card.comparedPercent && card.comparedPercent >= 0 ? (
                          <>
                            <ArrowRise style={{ marginRight: "4px" }} />
                            {selectedMenuItem === "Day" && (
                              <>
                                <FormattedMessage
                                  id="card.increased-day"
                                  defaultMessage="increased (day)"
                                />
                                <Typography
                                  sx={{
                                    fontSize: "12px",
                                    pl: "4px",
                                  }}
                                >
                                  {`+${card.comparedPercent}%`}
                                </Typography>
                              </>
                            )}
                            {selectedMenuItem === "Month" && (
                              <>
                                <FormattedMessage
                                  id="card.increased-month"
                                  defaultMessage="increased (month)"
                                />
                                <Typography
                                  sx={{
                                    fontSize: "12px",
                                    pl: "4px",
                                  }}
                                >
                                  {`+${card.comparedPercent}%`}
                                </Typography>
                              </>
                            )}
                            {selectedMenuItem === "Week" && (
                              <>
                                <FormattedMessage
                                  id="card.increased-week"
                                  defaultMessage="increased (week)"
                                />
                                <Typography
                                  sx={{
                                    fontSize: "12px",
                                    pl: "4px",
                                  }}
                                >
                                  {`+${card.comparedPercent}%`}
                                </Typography>
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            <ArrowRise
                              style={{
                                transform: "rotate(180deg)",
                                marginRight: "4px",
                              }}
                            />
                            {selectedMenuItem === "Day" && (
                              <>
                                <FormattedMessage
                                  tagName="span"
                                  id="card.decreased-day"
                                  defaultMessage="decreased (day)"
                                />
                                <span
                                  style={{ paddingLeft: "4px" }}
                                >{`${card.comparedPercent}%`}</span>
                              </>
                            )}
                            {selectedMenuItem === "Month" && (
                              <>
                                <FormattedMessage
                                  tagName="span"
                                  id="card.decreased-month"
                                  defaultMessage="decreased (month)"
                                />
                                <span
                                  style={{ paddingLeft: "4px" }}
                                >{`${card.comparedPercent}%`}</span>
                              </>
                            )}
                            {selectedMenuItem === "Week" && (
                              <>
                                <FormattedMessage
                                  tagName="span"
                                  id="card.decreased-week"
                                  defaultMessage="decreased (week)"
                                />
                                <span
                                  style={{ paddingLeft: "4px" }}
                                >{`${card.comparedPercent}%`}</span>
                              </>
                            )}
                          </>
                        )}
                      </>
                    ) : null}

                    {index === 2 || index === 3 ? (
                      <>
                        <UserGroup style={{ marginRight: "4px" }} />
                        <FormattedMessage
                          id="totalAverage"
                          defaultMessage="(total/average)"
                        />
                      </>
                    ) : null}
                    {index === 4 && (
                      <>
                        <Office style={{ marginRight: "4px" }} />
                        <FormattedMessage
                          id="projectRooms"
                          defaultMessage="Project rooms"
                        />
                      </>
                    )}
                    {index === 5 && (
                      <>
                        <Globe style={{ marginRight: "4px" }} />
                        <FormattedMessage
                          id="distanceSaved"
                          defaultMessage="{carbonCredits} km saved"
                          values={{
                            carbonCredits: 335,
                          }}
                        />
                      </>
                    )}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
        <Grid container className={styles.lineChartBox}>
          <Grid item xs={12} sm={12} lg={12} md={12}>
            <Box className={styles.chartBox}>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: 600,

                  mr: 3,
                }}
                color="inherit"
              >
                <FormattedMessage
                  id="lineChart-title"
                  defaultMessage="Nb of hours spent on meetings"
                />
              </Typography>

              <Box display="flex">
                {!isNarrowScreen && <Line style={{ marginRight: "10px" }} />}

                <Typography
                  sx={{
                    display: "flex",
                    fontSize: "12px",
                    fontWeight: 400,
                    whiteSpace: "nowrap",
                  }}
                  color="inherit"
                  alignItems="center"
                >
                  <Dot />
                  {selectedMenuItem === "Day" && (
                    <FormattedMessage
                      id="chart-current-day"
                      defaultMessage="Current day"
                    />
                  )}
                  {selectedMenuItem === "Month" && (
                    <FormattedMessage
                      id="chart-current-month"
                      defaultMessage="Current month"
                    />
                  )}
                  {selectedMenuItem === "Week" && (
                    <FormattedMessage
                      id="chart-current-week"
                      defaultMessage="Current week"
                    />
                  )}
                </Typography>

                <Typography
                  sx={{
                    display: "flex",
                    fontSize: "12px",
                    fontWeight: 400,
                    whiteSpace: "nowrap",
                    mr: 2,
                    "& path": {
                      fill: purple,
                    },
                  }}
                  color="inherit"
                  alignItems="center"
                >
                  <Dot style={{ marginLeft: "10px" }} />
                  {selectedMenuItem === "Day" && (
                    <FormattedMessage
                      id="chart-previous-day"
                      defaultMessage="Previous day"
                    />
                  )}
                  {selectedMenuItem === "Month" && (
                    <FormattedMessage
                      id="chart-previous-month"
                      defaultMessage="Previous month"
                    />
                  )}
                  {selectedMenuItem === "Week" && (
                    <FormattedMessage
                      id="chart-previous-week"
                      defaultMessage="Previous week"
                    />
                  )}
                </Typography>
                {!isNarrowScreen && <Line style={{ marginRight: "10px" }} />}
              </Box>
            </Box>
            {filteredArray.length > 0 && (
              <Box className={styles.chartWrraper}>
                <LineChart
                  selectedMenuItem={selectedMenuItem}
                  data={filteredArray}
                />
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
