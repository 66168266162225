import { ReactComponent as ArrowRise } from "../../assets/ArrowRise.svg";
import { ReactComponent as Office } from "../../assets/OfficeBuilding.svg";
import { ReactComponent as UserGroup } from "../../assets/UserGroup.svg";
import { ReactComponent as Line } from "../../assets/Line.svg";
import { ReactComponent as Dot } from "../../assets/Dot2.svg";
import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import styles from "./index.module.scss";
import { FormattedMessage, FormattedNumber } from "react-intl";
import { FC, useState } from "react";
import LinearProgressWithLabel from "../LinearProgress";
import { colors } from "../../lib/constants";
import { BarChartData } from "../../lib/helpers";
import LineChart from "../Charts/LineChart";
import BarChart from "../Charts/BarChart";
import DoughnutChart from "../Charts/DoughnutChart";
import CustomPaginationTable from "../Table";
import ChartModal from "../Charts/ChartModal/ChartModal";
import User from "../User";
const { dark, purple, customGrey, deepBlue } = colors;

export const statisticUserData = [
  {
    id: "nbOfMeeting",
    value: 19872,
    css: "item-1",
  },
  {
    id: "nbOfInternalUser",
    value: 1220,
    comparedPercent: 12,
    css: "item-2",
  },
  {
    id: "nbOfExternalUser",
    value: 1239,
    comparedPercent: -12,
    css: "item-3",
  },
  {
    id: "nbOfTotalUsers",
    value: 22872,

    css: "item-4",
  },

  {
    id: "averageSpentTime",
    value: 60987,
    css: "item-5",
  },
  {
    id: "nbOfRoomsUsed",
    value: 30,
    css: "item-6",
  },
];

const data = {
  averageTimeOnMeeting: [
    { id: 1, hours: 50 },
    { id: 2, hours: 21 },
    { id: 3, hours: 34 },
    { id: 4, hours: 50 },
    { id: 5, hours: 100 },
    { id: 6, hours: 77 },
    { id: 7, hours: 33 },
    { id: 8, hours: 50 },
    { id: 8, hours: 56 },
  ],
};

const sortedData = data.averageTimeOnMeeting.sort((a, b) => b.hours - a.hours);

interface Props {
  currentUser: User;
  selectedMenuItem: string;
}

export const DashboardPerAdmin: FC<Props> = ({
  currentUser,
  selectedMenuItem,
}) => {
  const isNarrowScreen = useMediaQuery("(max-width:599px)");
  const [showModal, setShowModal] = useState(null);

  const handleShowModal = (index: any) => {
    setShowModal(index);
  };

  const handleClose = () => {
    setShowModal(null);
  };

  let currentData =
    selectedMenuItem === "Day"
      ? [0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 1, 1]
      : [55, 45, 6, 7, 87, 5, 13, 44, 66, 20, 0, 0];
  let lastData =
    selectedMenuItem === "Day"
      ? [5]
      : [20, 25, 30, 15, 10, 5, 18, 50, 90, 30, 0, 0];

  const filteredArray = [
    {
      label: "",
      data: currentData,
      borderColor: "rgba(28, 28, 28, 1)",
      fill: false,
    },
    {
      label: "",
      data: lastData,
      borderColor: "rgba(117, 1, 156, 1)",
      fill: false,
    },
  ];

  return (
    <>
      <Box
        p={isNarrowScreen ? 1 : 3}
        sx={{ display: isNarrowScreen ? "block" : "flex" }}
      >
        <Box pr={isNarrowScreen ? 0 : 3}>
          <Box
            sx={{
              width: "100%",
              m: 0,
              pb: 3,
              minWidth: "225px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                p: "28px 19px",
                alignItems: "center",
                backgroundColor: "rgba(153, 0, 204, 0.10)",
                borderRadius: "16px",
                maxHeight: "112px",
              }}
            >
              <Box
                sx={{
                  mr: "12px",
                  "& .MuiAvatar-root": {
                    height: "40px !important",
                    width: "40px !important",
                  },
                }}
              >
                {currentUser && <User user={currentUser} />}
              </Box>

              <Box>
                <Typography
                  color="secondary"
                  sx={{ fontSize: "14px", fontWeight: 500 }}
                >
                  {currentUser.fullname ? currentUser.fullname : ""}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: 500,
                  }}
                  component="span"
                  color="primary"
                >
                  <FormattedMessage
                    id="user-typeAdmin"
                    defaultMessage="Administrator"
                  />
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              width: "100%",
              m: 0,
              minWidth: "225px",
              pb: 2,
            }}
          >
            <Box
              sx={{
                p: "28px 19px 0px 19px",
                alignItems: "center",
                backgroundColor: "#F7F9FB",
                borderRadius: "16px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: 500,
                }}
                component="span"
                color="secondary"
              >
                <FormattedMessage
                  id="NbOfRoomsUsed"
                  defaultMessage="Nb of rooms used by all users"
                />
              </Typography>
              <Box sx={{ mt: 3 }}>
                {sortedData.map((item, index) => (
                  <Box
                    sx={{ pb: 2 }}
                    key={index}
                    display="flex"
                    justifyContent="space-between"
                  >
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: 400,
                      }}
                      color={dark}
                    >
                      <FormattedMessage
                        id="office-rooms"
                        defaultMessage="Office"
                      />{" "}
                      <span>{item.id}</span>
                    </Typography>
                    <LinearProgressWithLabel
                      value={item.hours}
                      bestColor={index <= 4 ? purple : null}
                    />
                  </Box>
                ))}
                <Box>
                  <Typography
                    sx={{
                      fontSize: "10px",
                      fontWeight: 400,
                      "& path": {
                        fill: purple,
                      },
                      pb: "15px",
                    }}
                    color={customGrey}
                  >
                    <Dot style={{ marginRight: "8px" }} />
                    <FormattedMessage
                      id="best-indicators"
                      defaultMessage="the best indicators are highlighted"
                    />
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box>
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
            //
          >
            {statisticUserData.map((card, index) => (
              <ChartModal
                key={index}
                show={showModal === index}
                handleClose={handleClose}
                id={card.id}
                barChartData={BarChartData}
                selectedMenuItem={selectedMenuItem}
              />
            ))}

            {statisticUserData.map((card, index) => (
              <Grid
                item
                xs={12}
                sm={12}
                lg={4}
                md={4}
                key={index}
                minHeight={"125px"}
                minWidth="250px"
              >
                <Box
                  className={`${styles[card.css]}`}
                  onClick={() => handleShowModal(index)}
                  sx={{
                    cursor: "pointer",
                    height: "100%",
                    p: 3,
                    pb: 2,
                    borderRadius: "16px",
                    // "&:hover": {
                    //   border: `1px solid ${purple}`,
                    // },
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: 500,
                      pb: 1,
                    }}
                    color="secondary"
                  >
                    {card.id === "nbOfMeeting" && (
                      <FormattedMessage
                        id="card-nbOfMeeting"
                        defaultMessage="Nb of meetings"
                      />
                    )}
                    {card.id === "nbOfInternalUser" && (
                      <FormattedMessage
                        id="card-nbOfInternalUser"
                        defaultMessage="Nb of internal users"
                      />
                    )}
                    {card.id === "nbOfExternalUser" && (
                      <FormattedMessage
                        id="card-nbOfExternalUser"
                        defaultMessage="Nb of external users"
                      />
                    )}
                    {card.id === "nbOfTotalUsers" && (
                      <FormattedMessage
                        id="card-nbOfTotalUsers"
                        defaultMessage="Nb of total users"
                      />
                    )}
                    {card.id === "averageSpentTime" && (
                      <FormattedMessage
                        id="card-averageSpentTime"
                        defaultMessage="Average time spent by meeting"
                      />
                    )}
                    {card.id === "nbOfRoomsUsed" && (
                      <FormattedMessage
                        id="card-nbOfRoomsUsed"
                        defaultMessage="Nb of rooms used"
                      />
                    )}
                  </Typography>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography
                      sx={{
                        fontSize: "24px",
                        fontWeight: 600,
                      }}
                      color="secondary"
                    >
                      {card.value}
                      {index === 4 ? " h" : ""}
                    </Typography>
                    <Typography
                      component="div"
                      sx={{
                        fontSize: "12px",
                        fontWeight: 400,
                        "& span": {
                          color: "red",
                        },
                      }}
                      display="flex"
                      color="primary"
                      alignItems="center"
                    >
                      {index === 1 || index === 2 ? (
                        <>
                          {card.comparedPercent && card.comparedPercent >= 0 ? (
                            <>
                              <ArrowRise style={{ marginRight: "4px" }} />
                              {selectedMenuItem === "Day" && (
                                <>
                                  <FormattedMessage
                                    id="card.increased-day"
                                    defaultMessage="increased (day)"
                                  />
                                  <Typography
                                    sx={{
                                      fontSize: "12px",
                                      pl: "4px",
                                    }}
                                  >
                                    {`+${card.comparedPercent}%`}
                                  </Typography>
                                </>
                              )}
                              {selectedMenuItem === "Month" && (
                                <>
                                  <FormattedMessage
                                    id="card.increased-month"
                                    defaultMessage="increased (month)"
                                  />
                                  <Typography
                                    sx={{
                                      fontSize: "12px",
                                      pl: "4px",
                                    }}
                                  >
                                    {`+${card.comparedPercent}%`}
                                  </Typography>
                                </>
                              )}
                              {selectedMenuItem === "Week" && (
                                <>
                                  <FormattedMessage
                                    id="card.increased-week"
                                    defaultMessage="increased (week)"
                                  />
                                  <Typography
                                    sx={{
                                      fontSize: "12px",
                                      pl: "4px",
                                    }}
                                  >
                                    {`+${card.comparedPercent}%`}
                                  </Typography>
                                </>
                              )}
                            </>
                          ) : (
                            <>
                              <ArrowRise
                                style={{
                                  transform: "rotate(180deg)",
                                  marginRight: "4px",
                                }}
                              />
                              {selectedMenuItem === "Day" && (
                                <>
                                  <FormattedMessage
                                    tagName="span"
                                    id="card.decreased-day"
                                    defaultMessage="decreased (day)"
                                  />
                                  <span
                                    style={{ paddingLeft: "4px" }}
                                  >{`${card.comparedPercent}%`}</span>
                                </>
                              )}
                              {selectedMenuItem === "Month" && (
                                <>
                                  <FormattedMessage
                                    tagName="span"
                                    id="card.decreased-month"
                                    defaultMessage="decreased (month)"
                                  />
                                  <span
                                    style={{ paddingLeft: "4px" }}
                                  >{`${card.comparedPercent}%`}</span>
                                </>
                              )}
                              {selectedMenuItem === "Week" && (
                                <>
                                  <FormattedMessage
                                    tagName="span"
                                    id="card.decreased-week"
                                    defaultMessage="decreased (week)"
                                  />
                                  <span
                                    style={{ paddingLeft: "4px" }}
                                  >{`${card.comparedPercent}%`}</span>
                                </>
                              )}
                            </>
                          )}
                        </>
                      ) : null}

                      {index === 3 ? (
                        <>
                          <UserGroup style={{ marginRight: "4px" }} />
                          <FormattedMessage
                            id="total"
                            defaultMessage="(total)"
                          />
                        </>
                      ) : null}
                      {index === 5 && (
                        <>
                          <Office style={{ marginRight: "4px" }} />
                          <FormattedMessage
                            id="projectRooms"
                            defaultMessage="Project rooms"
                          />
                        </>
                      )}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>

          <Grid container className={styles.lineChartBox}>
            <Grid item xs={12} sm={12} lg={12} md={12}>
              <Box className={styles.chartBox}>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 600,
                    mr: 3,
                  }}
                  color="inherit"
                >
                  <FormattedMessage
                    id="lineChart-adminTitle"
                    defaultMessage="Nb of hours used by all users"
                  />
                </Typography>

                <Box display="flex">
                  {!isNarrowScreen && <Line style={{ marginRight: "10px" }} />}

                  <Typography
                    sx={{
                      display: "flex",
                      fontSize: "12px",
                      fontWeight: 400,
                      whiteSpace: "nowrap",
                    }}
                    color="inherit"
                    alignItems="center"
                  >
                    <Dot />
                    {selectedMenuItem === "Day" && (
                      <FormattedMessage
                        id="chart-current-day"
                        defaultMessage="Current day"
                      />
                    )}
                    {selectedMenuItem === "Month" && (
                      <FormattedMessage
                        id="chart-current-month"
                        defaultMessage="Current month"
                      />
                    )}
                    {selectedMenuItem === "Week" && (
                      <FormattedMessage
                        id="chart-current-week"
                        defaultMessage="Current week"
                      />
                    )}
                  </Typography>

                  <Typography
                    sx={{
                      display: "flex",
                      fontSize: "12px",
                      fontWeight: 400,
                      whiteSpace: "nowrap",
                      mr: 2,
                      "& path": {
                        fill: purple,
                      },
                    }}
                    color="inherit"
                    alignItems="center"
                  >
                    <Dot style={{ marginLeft: "10px" }} />
                    {selectedMenuItem === "Day" && (
                      <FormattedMessage
                        id="chart-previous-day"
                        defaultMessage="Previous day"
                      />
                    )}
                    {selectedMenuItem === "Month" && (
                      <FormattedMessage
                        id="chart-previous-month"
                        defaultMessage="Previous month"
                      />
                    )}
                    {selectedMenuItem === "Week" && (
                      <FormattedMessage
                        id="chart-previous-week"
                        defaultMessage="Previous week"
                      />
                    )}
                  </Typography>
                  {!isNarrowScreen && <Line style={{ marginRight: "10px" }} />}
                </Box>
              </Box>
              {filteredArray.length > 0 && (
                <Box className={styles.chartWrraper} sx={{ minHeight: "80%" }}>
                  <LineChart
                    selectedMenuItem={selectedMenuItem}
                    data={filteredArray}
                  />
                </Box>
              )}
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Grid
        container
        spacing={{ xs: 2, md: 3, lg: 3 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
        p={isNarrowScreen ? 1 : 3}
        pt={0}
      >
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Box
            className={styles.barChartItem}
            sx={{ minWidth: "200px", minHeight: "100%" }}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={2}
              flexWrap="wrap"
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: 600,
                }}
                color="secondary"
              >
                <FormattedMessage
                  id="total-carbonCredits"
                  defaultMessage="Total Carbon credits"
                />
                <Typography
                  component="span"
                  sx={{
                    fontSize: "24px",
                    fontWeight: 600,
                    ml: 2,
                  }}
                >
                  {/* eslint-disable-next-line */}
                  <FormattedNumber value={1286.789} style="decimal" />
                </Typography>
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: 400,
                }}
                color="primary"
              >
                <FormattedMessage id="table-total" defaultMessage="Total" />
              </Typography>
            </Box>

            <Box
              sx={{
                height: isNarrowScreen ? "50vh" : "30vh",
                width: "100%",
                mt: 2,
              }}
            >
              <BarChart
                selectedMenuItem={selectedMenuItem}
                data={BarChartData.data}
                options={BarChartData.options}
              />
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} md={6} lg={6}>
          <Box
            className={styles.doughnutChartItem}
            sx={{ minWidth: "200px", minHeight: "100%" }}
          >
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: 600,
              }}
              color="secondary"
            >
              <FormattedMessage
                id="trafficByLocation"
                defaultMessage="Traffic by Location"
              />
            </Typography>
            <Box
              display={isNarrowScreen ? "block" : "flex"}
              sx={{ minWidth: "150px" }}
            >
              <Box
                sx={{
                  mt: isNarrowScreen ? 2 : 8,

                  width: "100%",
                }}
              >
                <DoughnutChart selectedMenuItem={selectedMenuItem} />
              </Box>
              <Box
                sx={{
                  width: isNarrowScreen ? "200px" : "100%",
                  minWidth: "200px",
                  p: 3,
                  pt: isNarrowScreen ? 2 : 10,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  "& p": {
                    fontSize: "12px",
                    fontWeight: 400,
                    color: deepBlue,
                  },
                }}
              >
                <Box
                  display="flex"
                  justifyContent="space-between"
                  mb={2}
                  sx={{
                    "& p": {
                      whiteSpace: "nowrap",
                    },
                  }}
                >
                  <Typography
                    display="flex"
                    alignItems="center"
                    sx={{
                      "& path": {
                        fill: purple,
                      },
                    }}
                  >
                    <Dot style={{ marginRight: "8px" }} />
                    <FormattedMessage
                      id="c-us"
                      defaultMessage="United States"
                    />
                  </Typography>
                  <Typography>10%</Typography>
                </Box>
                <Box display="flex" justifyContent="space-between" mb={2}>
                  <Typography
                    display="flex"
                    alignItems="center"
                    sx={{
                      "& path": {
                        fill: "#FFCDFA",
                      },
                    }}
                  >
                    <Dot style={{ marginRight: "8px" }} />
                    <FormattedMessage id="c-fr" defaultMessage="France" />
                  </Typography>
                  <Typography>10%</Typography>
                </Box>
                <Box display="flex" justifyContent="space-between" mb={2}>
                  <Typography
                    display="flex"
                    alignItems="center"
                    sx={{
                      "& path": {
                        fill: "#BEDCFF",
                      },
                    }}
                  >
                    <Dot style={{ marginRight: "8px" }} />
                    <FormattedMessage id="c-mexico" defaultMessage="Mexico" />
                  </Typography>
                  <Typography>10%</Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography
                    display="flex"
                    alignItems="center"
                    sx={{
                      "& path": {
                        fill: "#CBFFB0",
                      },
                    }}
                  >
                    <Dot style={{ marginRight: "8px" }} />
                    <FormattedMessage id="c-other" defaultMessage="Other" />
                  </Typography>
                  <Typography>10%</Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Grid container>
        <CustomPaginationTable />
      </Grid>
    </>
  );
};
