import React from "react";
import { Container } from "@mui/material";
import { RouteComponentProps } from "react-router-dom";

const NotFound = ({ location }: RouteComponentProps) => (
  <Container>
    <h3 className="text-center">
      Sorry, no matching page for <code>{location.pathname}</code>
    </h3>
  </Container>
);

export default NotFound;
