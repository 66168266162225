import React, { useState, FC } from "react";
import {
  Box,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Typography,
} from "@mui/material";
import { ReactComponent as ExclamationCircle } from "../../assets/ExclamationCircle.svg";
import { FormattedMessage, useIntl } from "react-intl";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import styles from "./index.module.scss";

interface Props {
  setPassword: (v: string) => void;
}

export const ConfirmPassword: FC<Props> = ({ setPassword }) => {
  const [showPassword, setShowPassword] = useState(false);

  const intl = useIntl();

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };
  return (
    <>
      <ExclamationCircle />
      <Typography className={styles.title}>
        <FormattedMessage
          id="actionDeleteKizworkAccount"
          defaultMessage="Delete your Kizwork account"
        />
      </Typography>
      <Typography
        sx={{ pl: 1, pr: 1, textAlign: "center" }}
        className={styles.secondaryText}
      >
        <FormattedMessage
          id="actionDeleteKizworkAccountText"
          defaultMessage="First, please confirm that this is your account"
        />
      </Typography>
      <Box
        sx={{
          mt: "40px",
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
        display="flex"
      >
        <OutlinedInput
          sx={{ maxWidth: "400px" }}
          fullWidth
          autoComplete="password"
          id="password"
          name="password"
          required
          onChange={handleChange}
          type={showPassword ? "text" : "password"}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label={intl.formatMessage({
                  id: "toggle-password-visibility",
                  defaultMessage: "toggle password visibility",
                })}
                onClick={() => setShowPassword((show) => !show)}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
        />
      </Box>
    </>
  );
};
