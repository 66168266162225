import React, { FC, useEffect, useState } from "react";
import Chart from "chart.js/auto";
import { useIntl } from "react-intl";

interface Props {
  selectedMenuItem: string;
  data: any;
}

const LineChart: FC<Props> = ({ selectedMenuItem, data }) => {
  const chartRef = React.useRef<HTMLCanvasElement | null>(null);
  const [chart, setChart] = useState<Chart | null>(null);
  const intl = useIntl();

  useEffect(() => {
    const generateLabels = () => {
      switch (selectedMenuItem) {
        case "Month":
          return [
            intl.formatMessage({ id: "chart-labelJan", defaultMessage: "Jan" }),
            intl.formatMessage({ id: "chart-labelFeb", defaultMessage: "Feb" }),
            intl.formatMessage({ id: "chart-labelMar", defaultMessage: "Mar" }),
            intl.formatMessage({ id: "chart-labelApr", defaultMessage: "Apr" }),
            intl.formatMessage({ id: "chart-labelMay", defaultMessage: "May" }),
            intl.formatMessage({ id: "chart-labelJun", defaultMessage: "Jun" }),
            intl.formatMessage({ id: "chart-labelJul", defaultMessage: "Jul" }),
            intl.formatMessage({ id: "chart-labelAug", defaultMessage: "Aug" }),
            intl.formatMessage({ id: "chart-labelSep", defaultMessage: "Sep" }),
            intl.formatMessage({ id: "chart-labelOct", defaultMessage: "Oct" }),
            intl.formatMessage({ id: "chart-labelNov", defaultMessage: "Nov" }),
            intl.formatMessage({ id: "chart-labelDec", defaultMessage: "Dec" }),
          ];
        case "Week":
          return [
            intl.formatMessage({ id: "chart-labelMon", defaultMessage: "Mon" }),
            intl.formatMessage({ id: "chart-labelTue", defaultMessage: "Tue" }),
            intl.formatMessage({ id: "chart-labelWed", defaultMessage: "Wed" }),
            intl.formatMessage({ id: "chart-labelThu", defaultMessage: "Thu" }),
            intl.formatMessage({ id: "chart-labelFri", defaultMessage: "Fri" }),
            intl.formatMessage({ id: "chart-labelSat", defaultMessage: "Sat" }),
            intl.formatMessage({ id: "chart-labelSun", defaultMessage: "Sun" }),
          ];
        case "Day":
          return Array.from({ length: 24 }, (_, i) => i.toString());
        default:
          return [];
      }
    };
    const newData = {
      labels: generateLabels(),
      datasets: data,
    };
    if (chart) {
      chart.destroy();
    }

    if (chartRef.current) {
      const ctx = chartRef.current.getContext("2d") as CanvasRenderingContext2D;

      chartRef.current.style.backgroundColor = "#F7F9FB";
      const newChart = new Chart(ctx, {
        type: "line",
        data: newData,
        options: {
          elements: {
            line: {
              cubicInterpolationMode: "monotone",
            },
          },
          responsive: true,
          maintainAspectRatio: false,

          scales: {
            y: {
              beginAtZero: true,
              max: selectedMenuItem === "Day" ? 24 : 100,
            },
          },

          plugins: {
            title: {
              display: false,
              text: "Nb of hours spent on meetings",
              padding: 0,
              color: "#1C1C1C",
              font: {
                size: 14,
                weight: "600",
              },
              align: "start",
            },
            legend: {
              display: false,
              labels: {
                boxWidth: 15,
                boxHeight: 5,
                borderRadius: 12,
                font: {
                  size: 12,
                },

                pointStyle: "circle",
                usePointStyle: true,
                textAlign: "center",
                color: "rgba(28, 28, 28, 1)",
                useBorderRadius: true,
                padding: 10,
              },
            },
          },
        },
      });

      setChart(newChart);
    }
    // eslint-disable-next-line
  }, [chartRef, selectedMenuItem]);

  return <canvas width="100%" ref={chartRef} />;
};

export default LineChart;
