import React from "react";
import styles from "./index.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-solid-svg-icons/faClock";
import UpdateMemberButton from "../UpdateMemberButton/UpdateMemberButton";
import RemoveMemberButton from "../RemoveMemberButton/RemoveMemberButton";
import { Avatar, Box, Container, Divider, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { rolesThatCanManage } from "../../lib/constants";

interface Props {
  organizationId: string;
  members: Array<OrganizationMember>;
  role: string;
}

const ManageOrganizationMembersTable: React.FC<Props> = ({
  organizationId,
  members,
  role,
}) => {
  return (
    <Container
      disableGutters
      sx={{
        minWidth: "100%",
        display: "flex",
        flex: 1,
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          border: "1px solid #D1D5DB",
          borderRadius: "12px",
          m: 4,
          mt: 0,
          alignItems: "center",
        }}
      >
        {members?.map((member) => (
          <Box key={member.id}>
            <Box
              alignItems="center"
              display="flex"
              flexWrap="wrap"
              p="16px 24px"
            >
              <Avatar
                sx={{
                  mr: "12px",
                }}
              />
              <Typography
                component="div"
                sx={{ width: "25%" }}
                display="flex"
                flexDirection="column"
                className={styles.title}
              >
                {member.fullname ? (
                  <Box sx={{ whiteSpace: "nowrap" }}>{member.fullname}</Box>
                ) : (
                  <Box display="flex" flexDirection="row" alignItems="center">
                    <FontAwesomeIcon
                      icon={faClock}
                      fixedWidth
                      className="alert-color"
                    />{" "}
                    <FormattedMessage
                      id="pending-acceptation"
                      defaultMessage="Pending acceptation"
                    />
                  </Box>
                )}

                <span>{member.email}</span>
              </Typography>

              <Box
                sx={{
                  my: "4px",
                  mr: "20px",
                  borderRadius: "10px",
                  px: "10px",
                  display: "inline-block",
                }}
                className={
                  member.membershipType === "INTERNAL"
                    ? styles.owner
                    : styles.external
                }
              >
                <Typography className={styles.text}>
                  <FormattedMessage
                    id="membership-type"
                    defaultMessage="Membership type"
                  />
                </Typography>
                <Typography pr="5px" pl="5px" className={styles.text}>
                  -
                </Typography>
                <Typography className={styles.text}>
                  {member.membershipType === "INTERNAL" && (
                    <FormattedMessage id="Internal" defaultMessage="Internal" />
                  )}
                  {member.membershipType === "EXTERNAL" && (
                    <FormattedMessage id="External" defaultMessage="External" />
                  )}
                </Typography>
              </Box>

              <Box
                sx={{
                  my: "4px",
                  px: "10px",
                  borderRadius: "10px",
                  display: "inline-block",
                }}
                className={
                  member.role === "MEMBER" ? styles.member : styles.owner
                }
              >
                <Typography className={styles.text}>
                  <FormattedMessage id="role" defaultMessage="Role" />
                </Typography>
                <Typography pr="5px" pl="5px" className={styles.text}>
                  -
                </Typography>
                <Typography className={styles.text}>
                  {member.role === "MEMBER" && (
                    <FormattedMessage id="Member" defaultMessage="Member" />
                  )}
                  {member.role === "ADMIN" && (
                    <FormattedMessage id="Admin" defaultMessage="Admin" />
                  )}
                  {member.role === "OWNER" && (
                    <FormattedMessage id="Owner" defaultMessage="Owner" />
                  )}
                </Typography>
              </Box>

              {rolesThatCanManage.indexOf(role) > -1 &&
                member.role !== "OWNER" && (
                  <Box sx={{ textAlign: "right", flexGrow: 1 }}>
                    <UpdateMemberButton
                      organizationId={organizationId}
                      memberId={member.id}
                      member={member}
                    />
                    <RemoveMemberButton
                      fullname={member.fullname}
                      organizationId={organizationId}
                      memberId={member.id}
                    />
                  </Box>
                )}
            </Box>
            <Divider />
          </Box>
        ))}
      </Box>
    </Container>
  );
};

export default ManageOrganizationMembersTable;
