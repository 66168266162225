import { ApolloClient, InMemoryCache, HttpLink } from "@apollo/client";
import { onError } from "@apollo/client/link/error";

let HTTP_ENDPOINT = "https://localhost:3000/api"; // this is proxied to http://localhost:4000/api see src/setupProxy.js
if (process.env.NODE_ENV === "production") {
  if (
    window.location.hostname !== "localhost" &&
    window.location.hostname !== "hubs.local"
  ) {
    HTTP_ENDPOINT = "https://" + window.location.hostname + "/api";
  }
}

// Create an HTTP link to the Phoenix app's HTTP endpoint URL.
const httpLink = new HttpLink({
  uri: HTTP_ENDPOINT,
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
          locations
        )}, Path: ${path}`
      )
    );
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

// Create the Apollo Client instance.
const client = new ApolloClient({
  link: errorLink.concat(httpLink),
  cache: new InMemoryCache(),
});

export default client;
