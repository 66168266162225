import React, { useEffect, useState } from "react";
import styles from "../assets/stylesheets/pages.module.scss";
import {
  Box,
  Divider,
  Fade,
  Typography,
  Container,
  Button,
} from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { calculateDateRange } from "../lib/helpers";
import DateComponent from "../components/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import CurrentUser from "../components/CurrentUser";
import { NavLink } from "react-router-dom";
import { DashboardPerUser } from "../components/DashboardPerUser";
import { DashboardPerAdmin } from "../components/DashboardPerAdmin";

let type = "admin";

const Dashboard = () => {
  const [week, setWeek] = React.useState<Dayjs>(() => {
    const savedWeek = localStorage.getItem("dashboardWeek");
    return savedWeek ? dayjs(savedWeek) : dayjs();
  });
  const [selectedDate, setSelectedDate] = useState<Dayjs>(() => {
    const savedDate = localStorage.getItem("selectedDashboardDate");
    return savedDate ? dayjs(savedDate) : dayjs();
  });
  const [selectedMenuItem, setSelectedMenuItem] = useState(
    localStorage.getItem("selectedDashboardMenuItem") ?? "Month"
  );

  useEffect(() => {
    const saveDateToLocalStorage = () => {
      localStorage.setItem("selectedDashboardMenuItem", selectedMenuItem);
      localStorage.setItem("selectedDashboardDate", selectedDate.toISOString());
      localStorage.setItem("dashboardWeek", week.toISOString());
    };

    saveDateToLocalStorage();
  }, [selectedMenuItem, selectedDate, week]);

  const DataRange = calculateDateRange(
    dayjs(selectedMenuItem === "Week" ? week : selectedDate),
    selectedMenuItem
  );
  const intl = useIntl();

  return (
    <CurrentUser>
      {(currentUser: User | null) => {
        if (currentUser) {
          return (
            <Fade in={true} timeout={500}>
              <Container
                className={styles.dashboardContainer}
                disableGutters
                sx={{
                  minWidth: "85%",
                  display: "flex",
                  flex: 1,
                  flexDirection: "column",
                }}
              >
                <Box
                  sx={{
                    border: "1px solid #D1D5DB",
                    borderRadius: "12px",
                    mr: 3,
                    ml: 3,
                    minHeight: "75vh",
                  }}
                >
                  <Box
                    className={styles.dashboardHeaderBox}
                    display="flex"
                    width="100%"
                    alignItems="center"
                    padding={3}
                    flexWrap="wrap"
                    justifyContent="space-between"
                  >
                    <Box display="flex" alignItems="center" flexWrap="wrap">
                      <Typography
                        sx={{
                          fontSize: "24px",
                          fontWeight: 600,
                          color: "#931ffa",
                          mr: 3,
                        }}
                      >
                        <FormattedMessage
                          id="nav.organization"
                          defaultMessage="Organization"
                        />
                      </Typography>
                      <Typography
                        color="secondary"
                        sx={{
                          pt: "5px",
                          fontSize: "14px",
                          fontWeight: 500,
                        }}
                      >
                        {intl.formatDate(selectedDate.toDate())}
                      </Typography>
                    </Box>
                    <DateComponent
                      setSelectedDate={setSelectedDate}
                      selectedDate={selectedDate}
                      week={week}
                      setWeek={setWeek}
                      weekRange={selectedMenuItem === "Week" ? DataRange : null}
                      selectedMenuItem={selectedMenuItem}
                      setSelectedMenuItem={setSelectedMenuItem}
                    />
                    <Box className={styles.dashboardBackButton}>
                      <NavLink to="/">
                        <Button
                          variant="contained"
                          color="secondary"
                          sx={{ whiteSpace: "nowrap" }}
                        >
                          <FormattedMessage
                            id="backAndStart"
                            defaultMessage="Back and Start your meeting"
                          />
                        </Button>
                      </NavLink>
                    </Box>
                  </Box>
                  <Divider />
                  {type === "admin" ? (
                    <DashboardPerAdmin
                      currentUser={currentUser}
                      selectedMenuItem={selectedMenuItem}
                    />
                  ) : (
                    <DashboardPerUser
                      currentUser={currentUser}
                      selectedMenuItem={selectedMenuItem}
                    />
                  )}
                </Box>
              </Container>
            </Fade>
          );
        }
      }}
    </CurrentUser>
  );
};

export default Dashboard;
