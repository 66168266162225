import React, { useState } from "react";
import CreateMeetingForm from "../../components/CreateMeetingForm/index";
import CurrentUser from "../../components/CurrentUser";
import { Button } from "@mui/material";

interface Props {
  selectedOrganizationId: string | null;
  setSelectedOrganizationId: (id: string) => void;
  label: string;
  roomFromHistory?: Room;
}

const CreateMeetingBtn: React.FC<Props> = ({
  roomFromHistory,
  selectedOrganizationId,
  setSelectedOrganizationId,
  label,
}) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <CurrentUser>
      {(currentUser: User | null) => {
        return (
          <>
            <Button variant="contained" onClick={handleShow}>
              {label}
            </Button>
            {/* Because the CreateMeetingForm component create an initialFormValues state outside Modal,
            add a show condition here to properly unmount and clean the form state. */}
            {show && (
              <CreateMeetingForm
                open={show}
                onClose={handleClose}
                authenticated={!!currentUser}
                organizations={currentUser ? currentUser.organizations : []}
                selectedOrganizationId={selectedOrganizationId}
                setSelectedOrganizationId={setSelectedOrganizationId}
                persistent={!currentUser}
                roomFromHistory={roomFromHistory}
              />
            )}
          </>
        );
      }}
    </CurrentUser>
  );
};

export default CreateMeetingBtn;
