import styles from "./index.module.scss";
import { ReactComponent as Dots } from "../../../assets/Dots.svg";
import { ReactComponent as Visa } from "../../../assets/visa.svg";
import { ReactComponent as MasterCard } from "../../../assets/Mastercard.svg";
import { FormattedMessage, useIntl } from "react-intl";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";

import {
  Box,
  Button,
  Checkbox,
  FormControl,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";

import { useState } from "react";
import { NavLink } from "react-router-dom";

export const paymentCards = [
  {
    cardNumber: "**** **** **** 1234",
    id: 1,
    type: "MASTERCARD",
    nextBillingDate: "2023-11-01",
  },
  {
    cardNumber: "**** **** **** 5678",
    id: 2,
    type: "VISA",
    nextBillingDate: "2023-11-15",
  },
  {
    cardNumber: "**** **** **** 5678",
    id: 3,
    type: "MASTERCARD",
    nextBillingDate: "2023-11-15",
  },
];

export const PaymentMethods = () => {
  const [activeCard, setActiveCard] = useState<number | null>(1);
  const intl = useIntl();

  const handleCheckboxChange = (cardId: number) => {
    if (cardId === activeCard) {
      setActiveCard(null);
    } else {
      setActiveCard(cardId);
    }
  };

  return (
    <>
      <Box>
        <Typography
          sx={{ display: "flex", justifyContent: "flex-start", mb: 3 }}
          className={styles.title}
        >
          <FormattedMessage
            id="payment-methods"
            defaultMessage="Payment methods"
          />
        </Typography>
      </Box>

      <Box className={styles.container}>
        {paymentCards.map((card, index) => (
          <Box
            display="flex"
            key={card.id}
            sx={{
              alignItems: "center",
              border:
                card.id === activeCard
                  ? "1px solid #75019C"
                  : "1px solid #D1D5DB",

              borderRadius:
                index === 0
                  ? "12px 12px 0 0"
                  : index === paymentCards.length - 1
                  ? "0 0 12px 12px"
                  : "0",
              p: "13px",
              minHeight: "90px",
              transition: "1s",
            }}
          >
            <Checkbox
              size="small"
              icon={<RadioButtonUncheckedIcon fontSize="small" />}
              checkedIcon={<RadioButtonCheckedIcon fontSize="small" />}
              checked={card.id === activeCard}
              onChange={() => handleCheckboxChange(card.id)}
            />

            <FormControl fullWidth>
              <Box display="flex">
                <Box className={styles.iconWrapper}>
                  {card.type === "MASTERCARD" ? (
                    <MasterCard />
                  ) : (
                    <Visa width={38} height={38} />
                  )}
                </Box>

                <Typography className={styles.numberCard} sx={{ ml: 3 }}>
                  {card.cardNumber}
                </Typography>
              </Box>

              {card.id === activeCard ? (
                <Typography className={styles.text}>
                  <FormattedMessage
                    id="nextBillingDate"
                    defaultMessage="Your next billing date is {date}"
                    values={{
                      date: intl.formatDate(new Date(card.nextBillingDate), {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      }),
                    }}
                  />
                </Typography>
              ) : null}
            </FormControl>
            <Tooltip
              title={intl.formatMessage({
                id: "info",
                defaultMessage: "info",
              })}
            >
              <IconButton sx={{ maxHeight: "38px" }}>
                <Dots />
              </IconButton>
            </Tooltip>
          </Box>
        ))}
      </Box>

      <Box flexWrap="wrap" className={styles.modalFooter}>
        <NavLink to="/manage-payments">
          <Button
            sx={{ whiteSpace: "nowrap" }}
            variant="contained"
            color="primary"
          >
            <FormattedMessage
              id="manage-paymentButton"
              defaultMessage="Manage payment info"
            />
          </Button>
        </NavLink>
      </Box>
    </>
  );
};
