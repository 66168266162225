import React, { useState } from "react";
import styles from "../../assets/stylesheets/modal.module.scss";
import {
  Button,
  Modal,
  Container,
  Box,
  Select,
  Typography,
  MenuItem,
  IconButton,
  Divider,
  Tooltip,
  FormControl,
} from "@mui/material";
import { ReactComponent as CloseSvg } from "../../assets/CloseSvg.svg";
import { ReactComponent as Pencil } from "../../assets/editSvg.svg";
import { loader } from "graphql.macro";
import { Mutation } from "@apollo/client/react/components";
import Error from "../Error";
import { Formik, FormikErrors } from "formik";
import { FormattedMessage, useIntl } from "react-intl";

const UPDATE_ORGANIZATION_MEMBER = loader(
  "../../schema/mutation/UpdateOrganizationMember.graphql"
);

interface Props {
  organizationId: string;
  memberId: string;
  member: OrganizationMember;
}

interface Data {
  updateOrganizationMember: Organization;
}

interface FormValues {
  role: string;
  membershipType: string;
}

const UpdateMemberButton: React.FC<Props> = ({
  organizationId,
  memberId,
  member,
}) => {
  const intl = useIntl();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const initialValues: FormValues = {
    role: member.role,
    membershipType: member.membershipType,
  };

  return (
    <>
      <Tooltip
        title={intl.formatMessage({
          id: "manage-organization.edit-member",
          defaultMessage: "Edit member",
        })}
      >
        <IconButton onClick={handleShow}>
          <Pencil />
        </IconButton>
      </Tooltip>

      <Modal open={show} onClose={handleClose}>
        <Container
          disableGutters
          maxWidth={false}
          className={styles.container}
          sx={{
            bgcolor: "background.paper",
          }}
        >
          <Typography className={styles.title}>
            <FormattedMessage
              id="manage-organization.modification-member"
              defaultMessage="Modification of the member"
            />
            <IconButton onClick={handleClose}>
              <CloseSvg />
            </IconButton>
          </Typography>

          <Mutation<Data, FormValues>
            mutation={UPDATE_ORGANIZATION_MEMBER}
            onCompleted={handleClose}
          >
            {(updateMemberFromOrganization, { error }) => (
              <Formik
                initialValues={initialValues}
                validate={(values: FormValues) => {
                  const errors: FormikErrors<FormValues> = {};
                  return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                  let data = {
                    ...values,
                    organizationId: organizationId,
                    memberId: memberId,
                  };
                  updateMemberFromOrganization({
                    variables: data,
                  }).catch(() => {
                    setSubmitting(false);
                  });
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                }) => (
                  <Box
                    component="form"
                    id="updateMember"
                    onSubmit={handleSubmit}
                  >
                    <Box sx={{ p: 3 }}>
                      <Error error={error} />
                      <Box className={styles.inlineField}>
                        <Typography className={styles.text}>
                          <FormattedMessage
                            id="manage-organization.select-role"
                            defaultMessage="Select user role"
                          />
                        </Typography>
                        <FormControl fullWidth sx={{ maxWidth: "250px" }}>
                          <Select
                            name="role"
                            id="role"
                            value={values.role}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            aria-describedby="roleHelpBlock"
                          >
                            <MenuItem value="MEMBER">
                              {intl.formatMessage({
                                id: "Member",
                                defaultMessage: "Member",
                              })}
                            </MenuItem>
                            <MenuItem value="ADMIN">
                              {intl.formatMessage({
                                id: "Admin",
                                defaultMessage: "Admin",
                              })}
                            </MenuItem>
                          </Select>
                        </FormControl>
                        <Typography className={styles.explanation}>
                          <FormattedMessage
                            id="manage-organization.select-role-message"
                            defaultMessage="Admin role can add new members."
                          />
                        </Typography>
                      </Box>

                      <Box className={styles.inlineField} sx={{ mb: 0 }}>
                        <Typography className={styles.text}>
                          <FormattedMessage
                            id="membership-type"
                            defaultMessage="Membership type"
                          />
                        </Typography>
                        <FormControl fullWidth sx={{ maxWidth: "250px" }}>
                          <Select
                            name="membershipType"
                            id="membershipType"
                            value={values.membershipType}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            // aria-describedby="membershipTypeHelpBlock"
                          >
                            <MenuItem value="INTERNAL">
                              {intl.formatMessage({
                                id: "Internal",
                                defaultMessage: "Internal",
                              })}
                            </MenuItem>
                            <MenuItem value="EXTERNAL">
                              {intl.formatMessage({
                                id: "External",
                                defaultMessage: "External",
                              })}
                            </MenuItem>
                          </Select>

                          {/* <Typography
                            id="membershipTypeHelpBlock"
                            sx={{ maxWidth: "250px" }}
                            className={styles.text}
                          >
                            <FormattedMessage
                              id="manage-organization.select-membership-type-message"
                              defaultMessage="Some rooms may only be visible to internal users. External users won't see those rooms."
                            />
                          </Typography> */}
                        </FormControl>
                      </Box>
                    </Box>
                  </Box>
                )}
              </Formik>
            )}
          </Mutation>
          <Divider />
          <Box className={styles.modalFooter}>
            <Button onClick={handleClose} variant="outlined" color="secondary">
              <FormattedMessage id="actions.cancel" defaultMessage="Cancel" />
            </Button>

            <Button
              type="submit"
              variant="contained"
              form="updateMember"
              // disabled={isSubmitting}
            >
              <FormattedMessage id="actions.update" defaultMessage="Update" />
            </Button>
          </Box>
        </Container>
      </Modal>
    </>
  );
};

export default UpdateMemberButton;
