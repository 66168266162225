import React from "react";
import styles from "./index.module.scss";

import {
  FormattedMessage,
  FormattedDate,
  FormattedTime,
  useIntl,
} from "react-intl";
import { faUserShield } from "@fortawesome/free-solid-svg-icons/faUserShield";
import { faIdCardAlt } from "@fortawesome/free-solid-svg-icons/faIdCardAlt";
import { faGlobe } from "@fortawesome/free-solid-svg-icons/faGlobe";

import { IconDefinition } from "@fortawesome/fontawesome-common-types";

import { getRoomURL } from "../../../../lib/room";

import DeleteRoomButton from "./DeleteRoomButton";
import AddMeetingToMyCalendar from "./AddMeetingToMyCalendar";
import {
  Card,
  Button,
  CardHeader,
  Typography,
  Box,
  Divider,
  Tooltip,
} from "@mui/material";
import { NavLink } from "react-router-dom";
import UpdateRoomModal from "../../../UpdateRoomButton/UpdateRoomButton";
import CreateMeetingBtn from "../../../CreateMeetingButton/index";
import { HorizontalAttendeeList } from "../HorizontalAttendeeList";

interface Props {
  finishedMeeting?: boolean;
  room: Room;
  selectedOrganizationId: string | null;
  setSelectedOrganizationId: (id: string) => void;
}

const getThumbnail = (identifier: string): string => {
  return `/thumbnails/${identifier}.jpg`;
};

export const getEntryModeLabel = (entryMode: EntryMode): string => {
  switch (entryMode) {
    case "ALLOW":
      return "Allow everyone knowing the url";
    case "INTERNAL_EXTERNAL":
      return "Internal and external members of the organization";
    case "INTERNAL_ONLY":
      return "Internal members of the organization only";
    case "INVITE":
      return "Users listed as attendees only";
    case "DENY":
      return "Only the creator of this room";
    default:
      return entryMode;
  }
};

export const getEntryModeIcon = (entryMode: string): IconDefinition => {
  switch (entryMode) {
    case "ALLOW":
      return faGlobe;
    case "INTERNAL_EXTERNAL":
      return faUserShield;
    case "INTERNAL_ONLY":
      return faIdCardAlt;
    case "INVITE":
      return faUserShield;
    case "DENY":
      return faUserShield;
    default:
      return faGlobe;
  }
};

export const getMembershipTypeIcon = (
  membershipType: string
): IconDefinition => {
  switch (membershipType) {
    case "EXTERNAL":
      return faUserShield;
    case "INTERNAL":
      return faIdCardAlt;
    default:
      return faGlobe;
  }
};

const MeetingCard: React.FC<Props> = ({
  room,
  finishedMeeting = false,
  selectedOrganizationId,
  setSelectedOrganizationId,
}) => {
  const url = getRoomURL(room);
  const Intl = useIntl();

  function getGMTOffset(timeZone: any) {
    const now = new Date();
    const utcTime = now.getTime() + now.getTimezoneOffset() * 60000;

    const timeZoneOffset = new Date(utcTime).toLocaleTimeString("en-US", {
      timeZoneName: "short",
      timeZone: timeZone,
    });
    return timeZoneOffset.split(" ")[2];
  }

  return (
    <Card
      component="div"
      className={styles.card}
      sx={{
        boxShadow: "none",
        borderRadius: "12px",
        minHeight: "419px",
        maxWidth: "326px",
        width: "35vw",
      }}
    >
      <CardHeader
        sx={{ display: "flex" }}
        className={styles.header}
        title={
          <Box display="flex" sx={{ alignItems: "center" }}>
            <Typography
              sx={{ mr: "auto" }}
              component="h1"
              className={styles.title}
            >
              {room.name || "Meeting"}
            </Typography>

            {!finishedMeeting && (
              <>
                {room.authenticatedUserIsModerator && (
                  <UpdateRoomModal room={room} />
                )}
                {room.authenticatedUserIsModerator && (
                  <DeleteRoomButton slug={room.slug} />
                )}

                {room.startDate && <AddMeetingToMyCalendar room={room} />}
              </>
            )}
          </Box>
        }
      ></CardHeader>
      {/* {currentUser && currentUser.id === room.user.id && ( // we don't have currentUser.id
        <UpdateRoomModal room={room} />
      )} */}

      <img
        className={styles.roomImg}
        src={getThumbnail(room.scene.identifier)}
        alt={room.name || ""}
      />

      <Box>
        <Box
          className={styles.roomDate}
          sx={{
            p: "24px 25px",
            pb: "10px",
            display: "flex",
          }}
        >
          <Typography sx={{ mr: "5px" }}>
            {room.startDate !== null ? (
              <>
                <FormattedDate value={new Date(room.startDate)} />{" "}
                <FormattedTime value={new Date(room.startDate)} />
                {" - "}
              </>
            ) : null}
          </Typography>
          <Typography>
            {room.endDate !== null ? (
              <FormattedTime value={new Date(room.endDate)} />
            ) : null}
          </Typography>
          <Tooltip title={room.timeZone} placement="bottom-start">
            <Typography sx={{ ml: "5px" }}>
              {" "}
              {getGMTOffset(room.timeZone)}
            </Typography>
          </Tooltip>
        </Box>

        <Box
          sx={{
            p: "0px 20px 20px 25px",
          }}
        >
          <Typography sx={{ pb: "10px", fontSize: "14px" }}>
            <FormattedMessage
              id="meetingCard.invitedUsers"
              defaultMessage="Users invited to this meeting"
            />
          </Typography>
          <HorizontalAttendeeList attendees={room.attendees} />
        </Box>
        <Divider />
        <Box
          className={styles.footerButtons}
          display="flex"
          sx={{ p: "16px 20px 16px 20px" }}
        >
          <NavLink style={{ marginLeft: "auto" }} to={`/meeting/${room.slug}`}>
            <Button
              sx={{
                minWidth: "96px",
                mr: "8px",
              }}
              type="button"
              variant="outlined"
              color="secondary"
            >
              <FormattedMessage
                id="meetingCard.more"
                defaultMessage="View more"
              />
            </Button>
          </NavLink>
          {!finishedMeeting ? (
            <>
              <Button
                sx={{ width: "100%" }}
                variant="contained"
                type="button"
                onClick={() => {
                  window.open(url);
                }}
              >
                <FormattedMessage
                  id="meetingCard.join"
                  defaultMessage="Join the meeting"
                />
              </Button>
            </>
          ) : (
            <>
              <Box sx={{ "& button": { maxHeight: "38px" } }}>
                <CreateMeetingBtn
                  label={Intl.formatMessage({
                    id: "meetingCard.scheduleNewMeeting",
                    defaultMessage: "Schedule new meeting",
                  })}
                  selectedOrganizationId={selectedOrganizationId}
                  setSelectedOrganizationId={setSelectedOrganizationId}
                  roomFromHistory={room}
                />
              </Box>
            </>
          )}
        </Box>
      </Box>

      {/* {room.organization && (
          <div className="card-footer">
            <div>
              {room.user && (
                <>
                  <span
                    title={intl.formatMessage({
                      id: "Creator",
                      defaultMessage: "Creator",
                    })}
                  >
                    {room.user.fullname}
                  </span>
                  {", "}
                </>
              )}
              <span
                className="primary-color"
                title={intl.formatMessage({
                  id: "nav.organization",
                  defaultMessage: "Organization",
                })}
              >
                {room.organization.name}
              </span>
            </div>
            <OverlayTrigger trigger="click" placement="left" overlay={popover}>
              <Button type="button">
                <FontAwesomeIcon
                  icon={getEntryModeIcon(room.entryMode)}
                  fixedWidth
                  className="primary-color"
                />
              </Button>
            </OverlayTrigger>
          </div>
        )} */}
    </Card>
  );
};

export default MeetingCard;
