import React from "react";
import { Button } from "@mui/material";
import { FormattedMessage } from "react-intl";

interface Props {}

const SignUpButton: React.FC<Props> = () => {
  return (
    <div>
      <div>
        <Button
          size="large"
          onClick={() => {
            window.location.href = "/users/register";
          }}
          variant="contained"
        >
          <FormattedMessage id="nav.signup" defaultMessage="Sign Up" />
        </Button>
      </div>
      <div
        style={{
          paddingTop: "1em",
        }}
      >
        <a href="/users/log_in">
          <FormattedMessage
            id="have-an-account"
            defaultMessage="Have an account?"
          />
        </a>
      </div>
    </div>
  );
};

export default SignUpButton;
