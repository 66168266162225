import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { Box, Stack, Typography } from "@mui/material";

const Footer: FC = () => {
  return (
    <Box component="footer" py={1} sx={{ bgcolor: "bg.footer" }}>
      <Stack direction="column">
        <Stack
          direction="row"
          rowGap={0.5}
          columnGap={2}
          justifyContent="center"
          flexWrap="wrap"
        >
          <a href="/privacy-policy/" target="_blank" rel="noreferrer">
            <FormattedMessage
              id="nav.privacy-policy"
              defaultMessage="Privacy"
            />
          </a>
          <a href="/terms/" target="_blank" rel="noreferrer">
            <FormattedMessage id="nav.terms" defaultMessage="Terms" />
          </a>
          <a href="https://www.kizwork.com" target="_blank" rel="noreferrer">
            <FormattedMessage id="nav.contact" defaultMessage="Contact" />
          </a>
        </Stack>
        <Stack
          direction="row"
          flexWrap="wrap"
          columnGap={0.5}
          justifyContent="center"
        >
          <Typography component="span" textAlign="center">
            <FormattedMessage
              id="footer.copyright"
              defaultMessage="Kizwork by Kizwork S.A.S © copyright 2022-{year}."
              values={{ year: new Date().getFullYear() }}
            />
          </Typography>

          <Typography component="span" textAlign="center">
            <FormattedMessage
              id="footer.all-rights-reserved"
              defaultMessage="All rights reserved."
            />
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
};

export default Footer;
