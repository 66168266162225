export const getSelectedOrganization = (
  currentUser: User | null,
  selectedOrganizationId: string | null
): Organization | null => {
  if (!currentUser) return null;
  let organization = null;
  organization = currentUser.organizations.find(
    (o) => o.id === selectedOrganizationId
  );
  if (organization) {
    return organization;
  }
  if (currentUser.organizations.length > 1) {
    return currentUser.organizations[1];
  }
  return currentUser.organization;
};
