import React from "react";
import styles from "./index.module.scss";
import { FormattedMessage } from "react-intl";
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";

interface Props {
  organizations: Array<Organization>;
  onManageOrganization: (orga: string) => void;
  setSelectedOrganization: any;
}

const ManageOrganizationTable: React.FC<Props> = ({
  organizations,
  onManageOrganization,
  setSelectedOrganization,
}) => {
  return (
    <Box
      className={styles.container}
      sx={{
        m: 4,
        border: "1px solid #D1D5DB",
        borderRadius: "8px",
      }}
    >
      <Table aria-label="simple table">
        <TableBody>
          {organizations?.map((orga) => (
            <TableRow
              key={orga.id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
              }}
            >
              <TableCell component="th" scope="row">
                <Box display="flex" alignItems="center" flexWrap="wrap">
                  <Box flexGrow={1}>
                    <Typography className={styles.title}>
                      {orga.name}
                    </Typography>
                    <Box>
                      <Box
                        sx={{
                          my: "4px",
                          px: "10px",
                          borderRadius: "10px",
                          display: "inline-block",
                        }}
                        className={
                          orga.role === "MEMBER" ? styles.member : styles.owner
                        }
                      >
                        <Typography className={styles.text}>
                          <FormattedMessage id="role" defaultMessage="Role" />
                        </Typography>
                        <Typography pr="5px" pl="5px" className={styles.text}>
                          -
                        </Typography>
                        <Typography className={styles.text}>
                          {orga.role === "MEMBER" && (
                            <FormattedMessage
                              id="Member"
                              defaultMessage="Member"
                            />
                          )}
                          {orga.role === "ADMIN" && (
                            <FormattedMessage
                              id="Admin"
                              defaultMessage="Admin"
                            />
                          )}
                          {orga.role === "OWNER" && (
                            <FormattedMessage
                              id="Owner"
                              defaultMessage="Owner"
                            />
                          )}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>

                  {orga.role === "OWNER" ? (
                    <Button
                      onClick={() => onManageOrganization(orga.id)}
                      variant="contained"
                    >
                      <FormattedMessage
                        id="actions.manage"
                        defaultMessage="Manage"
                      />
                    </Button>
                  ) : (
                    <Button
                      onClick={() => setSelectedOrganization(orga.id)}
                      variant="contained"
                      color="secondary"
                    >
                      <FormattedMessage
                        id="meetingCard.more"
                        defaultMessage="View more"
                      />
                    </Button>
                  )}
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};

export default ManageOrganizationTable;
