import React, { useState } from "react";
import styles from "../../assets/stylesheets/modal.module.scss";
import dayjs, { Dayjs } from "dayjs";
import { loader } from "graphql.macro";
import { useMutation } from "@apollo/client";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import { TimezoneSelect, clientTz } from "timezone-select-js";
import { FormattedMessage, useIntl } from "react-intl";
import { AutocompleteChangeDetails } from "@mui/material/Autocomplete";
import Error from "../Error";
import SceneSelect from "./components/SceneSelect";
import { formatToNaiveDateTime } from "../../lib/helpers";
import { ReactComponent as CloseSvg } from "../../assets/CloseSvg.svg";
import {
  Box,
  Button,
  Divider,
  FormControl,
  Modal,
  Typography,
  OutlinedInput,
  Container,
  IconButton,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import { useFormik } from "formik";
import { colors } from "../../lib/constants";
import ParticipantsInput from "./components/ParticipantsInput";

const { backgroundCustomPink, greyBlue, purple } = colors;

const CREATE_ROOM_MUTATION = loader("../../schema/mutation/CreateRoom.graphql");

type ChangeHandler = (
  event: React.SyntheticEvent,
  value: (string | Attendee)[],
  reason: "createOption" | "selectOption" | "removeOption" | "blur" | "clear",
  details?: AutocompleteChangeDetails<Attendee> | undefined
) => void;

interface Data {
  createRoom: Room;
}

interface Variables {
  name: string;
  description: string;
  organizationId: string | null;
  sceneIdentifier: string;
  public: boolean;
  persistent: boolean;
  promoted: boolean;
  entryMode: string;
  startDate: string | null;
  endDate: string | null;
  timeZone: string | null;
  attendees: Attendee[];
}

interface Props {
  authenticated: boolean;
  organizations: Array<Organization>;
  persistent: boolean;
  selectedOrganizationId: string | null;
  setSelectedOrganizationId: (id: string) => void;
  open: boolean;
  roomFromHistory?: Room;
  onClose?: () => void;
}

const CreateMeetingForm: React.FC<Props & RouteComponentProps> = ({
  authenticated,
  organizations,
  persistent,
  selectedOrganizationId,
  setSelectedOrganizationId,
  history,
  open,
  onClose,
  roomFromHistory,
}) => {
  const intl = useIntl();

  let nowPlusOneHour = new Date(new Date().getTime() + 60 * 60 * 1000);
  nowPlusOneHour.setMinutes(0);
  const startDate = dayjs(nowPlusOneHour);
  let initialFormValues = {
    name: "",
    description: "",
    organizationId: selectedOrganizationId,
    sceneIdentifier: "meeting_room_1",
    promoted: false,
    entryMode: "ALLOW",
    startDate: startDate,
    endDate: startDate.add(1, "hour"),
    timeZone: clientTz(),
    attendees: [] as Attendee[],
  };
  if (roomFromHistory) {
    initialFormValues = {
      ...initialFormValues,
      name: roomFromHistory.name || "",
      description: roomFromHistory.description || "",
      organizationId: roomFromHistory.organization.id,
      sceneIdentifier: roomFromHistory.scene.identifier,
      entryMode: roomFromHistory.entryMode,
      startDate: dayjs(roomFromHistory.startDate).add(1, "week"),
      endDate: dayjs(roomFromHistory.endDate).add(1, "week"),
      timeZone: roomFromHistory.timeZone || clientTz(),
      // remove __typename in each attendee
      attendees: roomFromHistory.attendees.map((attendee) => ({
        email: attendee.email,
        fullname: attendee.fullname,
      })),
    };
  }

  const formik = useFormik({
    initialValues: initialFormValues,
    onSubmit: (values) => {
      createRoom({
        variables: {
          name: values.name || NAME_PLACEHOLDER,
          description: values.description,
          organizationId: values.organizationId,
          sceneIdentifier: values.sceneIdentifier,
          public: false,
          persistent,
          promoted: values.promoted,
          entryMode: !authenticated ? "ALLOW" : values.entryMode,
          startDate: formatToNaiveDateTime(values.startDate),
          endDate: formatToNaiveDateTime(values.endDate),
          timeZone: persistent ? null : values.timeZone,
          attendees: values.attendees,
        },
      })
        .then(() => {
          // @ts-ignore-line
          if (typeof _paq !== "undefined")
            // @ts-ignore-line
            _paq.push([
              "trackEvent",
              "Rooms",
              "Create " + persistent ? "project room" : "meeting",
              formik.values.sceneIdentifier,
            ]);

          setSelectedOrganizationId(values.organizationId || "");
          if (onClose) {
            onClose();
          }
        })
        .catch(() => {});
    },
  });

  //   const handleChange = (
  //     event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  //   ) => {
  //     const { name, value } = event.target;
  //     switch (name) {
  //       case "name":
  //         setName(value);
  //         break;
  //       case "description":
  //         setDescription(value);
  //         break;
  //       case "promoted":
  //         setPromoted(!promoted);
  //         break;
  //       case "entryMode":
  //         setEntryMode(value);
  //         break;
  //       default:
  //         break;
  //     }
  //   };

  const [createRoom, { loading, error }] = useMutation<Data, Variables>(
    CREATE_ROOM_MUTATION,
    {
      onCompleted: (data) => {
        if (authenticated) {
          history.push("/");
        } else {
          window.location.href = `/${data.createRoom.scene.identifier}/?room=${data.createRoom.slug}`;
        }
      },
      refetchQueries: ["GetRooms"],
    }
  );

  const hasPickedDate = () => {
    const currentDate = dayjs();
    return (
      persistent ||
      (formik.values.startDate && formik.values.startDate >= currentDate)
    );
  };

  const NAME_PLACEHOLDER = persistent
    ? intl.formatMessage({
        id: "meetingForm.roomNameForProject",
        defaultMessage: "Room name for the project",
      })
    : intl.formatMessage({
        id: "meetingForm.meeting",
        defaultMessage: "Meeting",
      });

  // const organizationOptions = organizations.map((orga) => ({
  //   value: orga.id,
  //   label: orga.name,
  // }));

  // const organizationDefaultValue = organizationOptions.find(
  //   (orga) => orga.value === formik.values.organizationId
  // );

  const handleStartDateChange = (startDate: Dayjs | null) => {
    let newEndDate = formik.values.endDate;
    if (startDate) {
      newEndDate = startDate.add(1, "hour");
    }
    formik.setFieldValue("startDate", startDate);
    formik.setFieldValue("endDate", newEndDate);
  };

  const handleEndDateChange = (newEndDate: Dayjs | null) => {
    formik.setFieldValue("endDate", newEndDate);
  };

  const handleTimeZoneChange = (option: any) => {
    formik.setFieldValue("timeZone", option ? option.value : null);
  };

  // const handleOrganizationChange: OnOrganizationChange = (option) => {
  //   formik.setFieldValue("organizationId", option ? option.value : null);
  // };

  //
  const handleSelectedUsers: ChangeHandler = (
    event,
    value,
    reason,
    details
  ) => {
    if (reason === "selectOption") {
      formik.setFieldValue("attendees", value as Attendee[]);
    }
    if (reason === "clear") {
      formik.setFieldValue("attendees", []);
    }
    if (reason === "removeOption") {
      formik.setFieldValue("attendees", value as Attendee[]);
    }
    if (reason === "createOption") {
      const createdOption = details?.option as string | undefined;
      if (createdOption && createdOption?.indexOf("@") > -1) {
        // timeout to avoid instant validation of the dialog's form.
        setTimeout(() => {
          setOpenCreateDialog(true);
          setDialogValue({
            email: createdOption,
            fullname: "",
          });
        });
      }
    }
  };

  const [openCreateDialog, setOpenCreateDialog] = useState(false);

  const handleClose = () => {
    setDialogValue({
      fullname: "",
      email: "",
    });
    setOpenCreateDialog(false);
  };
  const [dialogValue, setDialogValue] = useState({
    fullname: "",
    email: "",
  });

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const newUser: Attendee = {
      fullname: dialogValue.fullname,
      email: dialogValue.email,
    };

    formik.setFieldValue("attendees", [...formik.values.attendees, newUser]);
    handleClose();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <>
        <Dialog open={openCreateDialog} onClose={handleClose}>
          <form onSubmit={handleSubmit}>
            <DialogTitle>
              <FormattedMessage
                id="meetingForm.add-a-participant"
                defaultMessage="Add a participant"
              />
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                <FormattedMessage
                  id="meetingForm.please-enter-the-name-of-the-participant"
                  defaultMessage="Please enter the name of the participant"
                />
              </DialogContentText>

              <TextField
                autoFocus
                margin="dense"
                id="fullname"
                value={dialogValue.fullname}
                onChange={(event) =>
                  setDialogValue({
                    ...dialogValue,
                    fullname: event.target.value,
                  })
                }
                label={intl.formatMessage({
                  id: "fullname",
                  defaultMessage: "Fullname",
                })}
                type="text"
                variant="standard"
              />
              <TextField
                margin="dense"
                id="email"
                value={dialogValue.email}
                onChange={(event) =>
                  setDialogValue({
                    ...dialogValue,
                    email: event.target.value,
                  })
                }
                label={intl.formatMessage({
                  id: "email",
                  defaultMessage: "Email",
                })}
                type="text"
                variant="standard"
              />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleClose}
                variant="outlined"
                color="secondary"
              >
                <FormattedMessage id="actions.cancel" defaultMessage="Cancel" />
              </Button>
              <Button type="submit" variant="contained" color="primary">
                <FormattedMessage id="actions.add" defaultMessage="Add" />
              </Button>
            </DialogActions>
          </form>
        </Dialog>
        <Container
          disableGutters
          maxWidth={false}
          className={styles.container}
          sx={{
            bgcolor: "background.paper",
          }}
        >
          <Typography className={styles.title}>
            <FormattedMessage
              id="header.createMeeting"
              defaultMessage="Create meeting"
            />
            <IconButton onClick={onClose}>
              <CloseSvg />
            </IconButton>
          </Typography>

          <Box component="form" id="createroom" onSubmit={formik.handleSubmit}>
            <Error error={error} />
            {authenticated && (
              <>
                <Box sx={{ p: 3 }}>
                  {organizations.length > 1 && (
                    <Box className={styles.inlineField}>
                      <Typography
                        component="label"
                        htmlFor="organizationId"
                        className={styles.label}
                      >
                        <FormattedMessage
                          id="meetingForm.organization"
                          defaultMessage="Create under the following organization"
                        />
                      </Typography>
                      <RadioGroup
                        name="organizationId"
                        id="organizationId"
                        row
                        value={formik.values.organizationId}
                        onChange={formik.handleChange}
                      >
                        {organizations.map((orga) => (
                          <FormControlLabel
                            key={orga.id}
                            value={orga.id}
                            control={<Radio size="small" />}
                            label={orga.name}
                            className={styles.label}
                            sx={{ marginBottom: 0 }}
                          />
                        ))}
                      </RadioGroup>
                    </Box>
                  )}
                  <Box className={styles.inlineField}>
                    <Typography
                      component="label"
                      htmlFor="name"
                      className={styles.label}
                    >
                      <FormattedMessage
                        id="meetingForm.title"
                        defaultMessage="Title"
                      />
                    </Typography>
                    <OutlinedInput
                      sx={{ maxWidth: "448px" }}
                      fullWidth
                      type="text"
                      name="name"
                      id="name"
                      autoFocus
                      placeholder={NAME_PLACEHOLDER}
                      value={formik.values.name}
                      onChange={formik.handleChange}
                    />
                  </Box>

                  <Box className={styles.inlineField}>
                    <Typography
                      component="label"
                      htmlFor="description"
                      className={styles.label}
                    >
                      <FormattedMessage
                        id="meetingForm.description"
                        defaultMessage="Description"
                      />
                    </Typography>
                    <OutlinedInput
                      sx={{ maxWidth: "448px" }}
                      fullWidth
                      type="text"
                      name="description"
                      id="description"
                      placeholder={intl.formatMessage({
                        id: "meetingForm.descriptionPlaceholder",
                        defaultMessage: "What the meeting is about",
                      })}
                      value={formik.values.description}
                      onChange={formik.handleChange}
                    />
                  </Box>

                  <Box className={styles.inlineField}>
                    <Typography
                      component="label"
                      htmlFor="attendees"
                      className={styles.label}
                    >
                      <FormattedMessage
                        id="meetingForm.participants"
                        defaultMessage="Participants"
                      />
                    </Typography>
                    <ParticipantsInput
                      id="attendees"
                      attendees={formik.values.attendees}
                      handleSelectedUsers={handleSelectedUsers}
                      placeholder={intl.formatMessage({
                        id: "meetingForm.participantsPlaceholder",
                        defaultMessage: "Choose participants",
                      })}
                    />
                  </Box>

                  {!persistent && (
                    <>
                      <Box className={styles.inlineField}>
                        <Typography
                          component="label"
                          htmlFor="timeZone"
                          className={styles.label}
                        >
                          <FormattedMessage
                            id="meetingForm.timezone"
                            defaultMessage="Timezone"
                          />
                        </Typography>
                        <FormControl fullWidth sx={{ maxWidth: "448px" }}>
                          <TimezoneSelect
                            name="timeZone"
                            id="timeZone"
                            value={formik.values.timeZone || ""}
                            onChange={handleTimeZoneChange}
                            // @ts-ignore
                            theme={(theme) => ({
                              ...theme,
                              colors: {
                                ...theme.colors,
                                primary25: backgroundCustomPink,
                                primary50: backgroundCustomPink,
                                primary: purple,
                              },
                            })}
                            styles={{
                              // @ts-ignore
                              control: (baseStyles, state) => {
                                return {
                                  ...baseStyles,
                                  "&:hover": {
                                    borderColor: state.isFocused
                                      ? purple
                                      : greyBlue,
                                  },
                                };
                              },
                            }}
                          />
                        </FormControl>
                      </Box>

                      <Box className={styles.inlineField}>
                        <Typography component="label" className={styles.label}>
                          <FormattedMessage
                            id="meetingForm.startDate"
                            defaultMessage="Start Date"
                          />
                        </Typography>
                        <FormControl>
                          <DateTimePicker
                            minDate={dayjs()}
                            value={formik.values.startDate}
                            onChange={handleStartDateChange}
                            viewRenderers={{
                              hours: renderTimeViewClock,
                              minutes: renderTimeViewClock,
                            }}
                            sx={{
                              "& .MuiInputBase-root": {
                                border: !hasPickedDate()
                                  ? "1px solid red"
                                  : null,
                              },
                            }}
                          />
                          {!hasPickedDate() && (
                            <Typography
                              variant="caption"
                              sx={{
                                color: "red",
                              }}
                            >
                              <FormattedMessage
                                id="meetingForm.startDateError"
                                defaultMessage="Enter the correct date. (Must be in the future)"
                              />
                            </Typography>
                          )}
                        </FormControl>
                      </Box>

                      <Box className={styles.inlineField}>
                        <Typography component="label" className={styles.label}>
                          <FormattedMessage
                            id="meetingForm.endDate"
                            defaultMessage="End Date"
                          />
                        </Typography>
                        <FormControl>
                          <DateTimePicker
                            minDate={dayjs()}
                            value={formik.values.endDate}
                            onChange={handleEndDateChange}
                            viewRenderers={{
                              hours: renderTimeViewClock,
                              minutes: renderTimeViewClock,
                            }}
                          />
                        </FormControl>
                      </Box>
                    </>
                  )}

                  <Divider sx={{ mb: 4 }} />
                  <Typography component="label" className={styles.label}>
                    <FormattedMessage
                      id="meetingForm.chooseRoom"
                      defaultMessage="Choose your room"
                    />
                  </Typography>
                </Box>
                <SceneSelect
                  authenticated={authenticated}
                  onUpdate={(value) =>
                    formik.setFieldValue("sceneIdentifier", value)
                  }
                  value={formik.values.sceneIdentifier}
                />
              </>
            )}

            {/* {authenticated && (
          <FormControl fullWidth>
            <Typography component="label" htmlFor="entryMode">
              <FormattedMessage
                id="room.entry-mode"
                defaultMessage="Entry mode"
              />{" "}
              <FontAwesomeIcon
                icon={getEntryModeIcon(entryMode)}
                fixedWidth
                className="primary-color"
              />
            </Typography>
            <Select
              name="entryMode"
              id="entryMode"
              value={entryMode}
              onChange={(e) => setEntryMode(e.target.value as string)}
            >
              <MenuItem value="ALLOW">
                {intl.formatMessage({
                  id: "entry-mode.allow",
                  defaultMessage: "Allow everyone knowing the url",
                })}
              </MenuItem>
              <MenuItem value="INTERNAL_EXTERNAL">
                {intl.formatMessage({
                  id: "entry-mode.internal-external",
                  defaultMessage:
                    "Internal and external members of the organization",
                })}
              </MenuItem>
              <MenuItem value="INTERNAL_ONLY">
                {intl.formatMessage({
                  id: "entry-mode.internal-only",
                  defaultMessage: "Internal members of the organization only",
                })}
              </MenuItem>
            </Select>
            {entryMode === "ALLOW" && (
              <Typography variant="body2" id="entryModeHelpBlock">
                <FormattedMessage
                  id="room.entry-mode-explanation"
                  defaultMessage="Anyone on the internet with the link can access the room."
                />
              </Typography>
            )}
          </FormControl>
        )} */}
          </Box>
          <Divider />
          <Box className={styles.modalFooter}>
            <Button onClick={onClose} variant="outlined" color="secondary">
              <FormattedMessage id="actions.cancel" defaultMessage="Cancel" />
            </Button>

            <Button
              variant="contained"
              color="primary"
              type="submit"
              form="createroom"
              disabled={loading || !hasPickedDate()}
            >
              <FormattedMessage id="actions.create" defaultMessage="Create" />
            </Button>
          </Box>
        </Container>
      </>
    </Modal>
  );
};

export default withRouter(CreateMeetingForm);
