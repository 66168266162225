import React, { useState } from "react";
import { Button, Box } from "@mui/material";
import { FormattedMessage } from "react-intl";
import AddAvatarForm from "../AddAvatarForm/AddAvatarForm";

interface Props {
  disabled: boolean;
  name: string;
}

const AddAvatarButton: React.FC<Props> = ({ disabled, name }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <Box sx={{ p: "16px 20px 11px 20px" }}>
      <Button
        sx={{ minWidth: "100%" }}
        onClick={handleShow}
        disabled={disabled}
        variant="contained"
      >
        <FormattedMessage
          id="manage-avatars.create-new-avatar"
          defaultMessage="Get started"
        />
      </Button>
      <AddAvatarForm
        name={name}
        open={show}
        onClose={handleClose}
        onCompleted={handleClose}
      />
    </Box>
  );
};

export default AddAvatarButton;
