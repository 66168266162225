import { Suspense, useState } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Header from "./components/Layout/Header";
import Footer from "./components/Layout/Footer";
import Home from "./pages/Home";
import History from "./pages/History";
import Pricing from "./pages/Pricing";
import NotFound from "./pages/NotFound";
import Avatars from "./pages/Avatars";
import MeetingInfo from "./pages/MeetingInfo";
import Organization from "./pages/Organization";
import Settings from "./pages/Settings";
import { DeleteAccount } from "./pages/DeleteAccount";
import { ManagePayment } from "./pages/ManagePayment";
import Dashboard from "./pages/Dashboard";
import { Box } from "@mui/material";

import { UserSettings } from "./pages/UserSettings";
import { I18nProvider } from "./I18nProvider";

const App = () => {
  const [selectedOrganizationId, setSelectedOrganizationId] = useState<
    string | null
  >(() => {
    return localStorage.getItem("selectedOrganizationId");
  });
  const setSelectedOrganizationIdInLocalStorage = (orgaId: string) => {
    setSelectedOrganizationId(orgaId);
    localStorage.setItem("selectedOrganizationId", orgaId);
  };

  return (
    <BrowserRouter>
      <I18nProvider>
        <div
          id="app"
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100vh",
            overflowY: "auto",
          }}
        >
          <Header />
          <Box sx={{ display: "flex", flex: 1 }}>
            <Suspense fallback={<div></div>}>
              <Switch>
                <Route
                  render={() => (
                    <Box sx={{ pt: "100px", display: "flex", flex: 1 }}>
                      <Switch>
                        <Route path="/" exact>
                          <Home
                            selectedOrganizationId={selectedOrganizationId}
                            setSelectedOrganizationId={
                              setSelectedOrganizationIdInLocalStorage
                            }
                          />
                        </Route>
                        <Route path="/account-settings">
                          <Settings />
                        </Route>
                        <Route path="/dashboard" component={Dashboard} />
                        <Route
                          path="/delete-account"
                          component={DeleteAccount}
                        />
                        <Route
                          path="/manage-payments"
                          component={ManagePayment}
                        />
                        <Route path="/meetings-history">
                          <History
                            selectedOrganizationId={selectedOrganizationId}
                            setSelectedOrganizationId={
                              setSelectedOrganizationIdInLocalStorage
                            }
                          />
                        </Route>
                        <Route path="/meeting/:slug" component={MeetingInfo}>
                          <MeetingInfo
                            selectedOrganizationId={selectedOrganizationId}
                            setSelectedOrganizationId={
                              setSelectedOrganizationIdInLocalStorage
                            }
                          />
                        </Route>
                        <Route path="/pricing" component={Pricing} />
                        <Route
                          path="/manage-organization"
                          component={Organization}
                        />
                        <Route path="/manage-avatars" component={Avatars} />
                        <Route path="/user-settings" component={UserSettings} />
                        <Route component={NotFound} />
                      </Switch>
                    </Box>
                  )}
                />
              </Switch>
            </Suspense>
          </Box>
          <Footer />
        </div>
      </I18nProvider>
    </BrowserRouter>
  );
};

export default App;
