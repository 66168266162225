import React, { FC } from "react";
import styles from "../assets/stylesheets/pages.module.scss";
import {
  Box,
  Container,
  Tabs,
  Typography,
  Tab,
  Fade,
  useMediaQuery,
} from "@mui/material";
import { useIntl } from "react-intl";
import { ReactComponent as HomeSvg } from "../assets/Home.svg";
import { ReactComponent as CarbonCreditsSvg } from "../assets/CarbonCredits.svg";
import { ReactComponent as DollarSvg } from "../assets/Dollar.svg";
import { ReactComponent as UserSvg } from "../assets/user.svg";
import CurrentUser from "../components/CurrentUser";
import { PersonaInfo } from "../components/PersonaInfo";
import CarbonCredits from "../components/CarbonCredits";
import Payment from "../components/Payment";
import { FEATURES } from "../lib/constants";
import User from "../components/User";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel: FC<TabPanelProps> = ({ children, value, index, ...other }) => {
  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Fade in={true} timeout={800}>
          <Box sx={{ pt: "24px" }}>{children}</Box>
        </Fade>
      )}
    </Box>
  );
};

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const tabs = [
  {
    label: "My Account",
    icon: (
      <span className={styles.svgWithStroke}>
        <HomeSvg />
      </span>
    ),
    id: "settings-myAccount",
  },
  {
    label: "Carbon credits",
    icon: (
      <span className={styles.svgWithStroke}>
        <CarbonCreditsSvg />
      </span>
    ),
    id: "settings-carbonCredits",
  },
  {
    label: "Plan details and payment",
    icon: (
      <span className={styles.svgWithFill}>
        <DollarSvg />
      </span>
    ),
    id: "settings-payment",
  },
];

if (FEATURES.SUPPORT_CHAT) {
  tabs.push({
    label: "Support chat",
    icon: (
      <span className={styles.svgWithFill}>
        <UserSvg className={styles.svgWithFill} width="24px" height="24px" />
      </span>
    ),
    id: "settings-support",
  });
}

const Settings: React.FC = () => {
  const intl = useIntl();
  const isNarrowScreen = useMediaQuery("(max-width:550px)");
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Container
      className={styles.settingPageContainer}
      maxWidth={false}
      disableGutters
      sx={{
        maxWidth: "95vw",
      }}
    >
      <CurrentUser>
        {(currentUser: User | null) => {
          return (
            <>
              <Box
                className={styles.container}
                sx={{
                  width: "100%",
                  m: 0,
                  minWidth: "300px",
                  maxWidth: isNarrowScreen ? " " : "320px",
                  height: "75vh",
                  backgroundColor: "#FDFBFD",
                  borderRadius: "12px",
                  pb: 2,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    p: 2,
                    pt: 4,
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      mr: "12px",
                      "& .MuiAvatar-root": {
                        height: "40px !important",
                        width: "40px !important",
                      },
                    }}
                  >
                    {currentUser && <User user={currentUser} />}
                  </Box>
                  <Box>
                    <Typography className={styles.title} sx={{ pr: 4 }}>
                      {currentUser?.fullname}
                    </Typography>
                    <Typography className={styles.text} component="span">
                      {currentUser?.email}
                    </Typography>
                  </Box>
                </Box>

                <Box
                  sx={{
                    flexGrow: 1,
                    bgcolor: "background.paper",
                    display: "flex",
                  }}
                >
                  <Tabs
                    className={styles.tabs}
                    orientation="vertical"
                    variant="scrollable"
                    value={value}
                    onChange={handleChange}
                    aria-label="Vertical tabs example"
                    sx={{
                      width: "100%",
                      minHeight: "100%",
                      backgroundColor: "#FDFBFD",
                      overflow: "hidden",
                      pl: 1,
                      pr: 1,
                    }}
                    TabIndicatorProps={{
                      hidden: true,
                    }}
                  >
                    {tabs.map((tab, index) => (
                      <Tab
                        disableRipple={true}
                        key={index}
                        className={index === value ? styles.activeTab : ""}
                        sx={{
                          justifyContent: "flex-start",
                        }}
                        icon={tab.icon}
                        iconPosition="start"
                        label={intl.formatMessage({
                          id: tab.id,
                          defaultMessage: tab.label,
                        })}
                        {...a11yProps(index)}
                      />
                    ))}
                  </Tabs>
                </Box>
                {/* <Signout /> */}
              </Box>

              <Container
                className={styles.mainContentContainer}
                maxWidth={false}
                disableGutters
                sx={{
                  pr: 10,
                  pl: 10,
                  mb: 2,
                }}
              >
                {/* <header>
                  <OutlinedInput
                    placeholder={intl.formatMessage({
                      id: "settings-search-input",
                      defaultMessage: "Search",
                    })}
                    fullWidth
                    sx={{
                      display: "flex",
                      flexDirection: "row-reverse",
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <SearchIcon />
                      </InputAdornment>
                    }
                  />
                  <Divider />
                </header> */}
                <Box component="main">
                  <TabPanel value={value} index={0}>
                    <PersonaInfo />
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    <CarbonCredits />
                  </TabPanel>
                  <TabPanel value={value} index={2}>
                    <Payment />
                  </TabPanel>
                  <TabPanel value={value} index={3}>
                    Item Four
                  </TabPanel>
                  <TabPanel value={value} index={4}>
                    Item Five
                  </TabPanel>
                </Box>
              </Container>
            </>
          );
        }}
      </CurrentUser>
    </Container>
  );
};

export default Settings;
