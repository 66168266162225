import React, { useState } from "react";
import styles from "../../assets/stylesheets/dialog.module.scss";
import {
  Box,
  Button,
  IconButton,
  Modal,
  Tooltip,
  Typography,
} from "@mui/material";
import { loader } from "graphql.macro";
import { Mutation } from "@apollo/client/react/components";
import Error from "../Error";
import { Formik, FormikErrors } from "formik";
import { ReactComponent as DeleteSvg } from "../../assets/deleteMemberSvg.svg";
import { FormattedMessage, useIntl } from "react-intl";
import { ReactComponent as Trash } from "../../assets/Trash.svg";

const REMOVE_ORGANIZATION_MEMBER = loader(
  "../../schema/mutation/RemoveOrganizationMember.graphql"
);

interface Props {
  organizationId: string;
  memberId: string;
  fullname: string;
}

interface Data {
  removeOrganizationMember: Organization;
}

interface FormValues {}

const RemoveMemberButton: React.FC<Props> = ({
  organizationId,
  memberId,
  fullname,
}) => {
  const intl = useIntl();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const initialValues: FormValues = {};

  return (
    <>
      <Tooltip
        title={intl.formatMessage({
          id: "manage-organization.remove-member-from-the-organization",
          defaultMessage: "Remove member from the organization",
        })}
      >
        <IconButton onClick={handleShow}>
          <Trash />
        </IconButton>
      </Tooltip>

      <Modal open={show} onClose={handleClose}>
        <Box
          className={styles.dialog}
          sx={{
            bgcolor: "background.paper",
          }}
        >
          <Mutation<Data, FormValues>
            mutation={REMOVE_ORGANIZATION_MEMBER}
            onCompleted={handleClose}
          >
            {(removeMemberFromOrganization, { error }) => (
              <Formik
                initialValues={initialValues}
                validate={(values: FormValues) => {
                  const errors: FormikErrors<FormValues> = {};
                  return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                  let data = {
                    organizationId: organizationId,
                    memberId: memberId,
                  };
                  removeMemberFromOrganization({
                    variables: data,
                  }).catch(() => {
                    setSubmitting(false);
                  });
                }}
              >
                {({ handleSubmit, isSubmitting }) => (
                  <>
                    <DeleteSvg />
                    <Typography className={styles.title}>
                      <FormattedMessage
                        id="actionDeleteMember"
                        defaultMessage="Delete Member"
                      />
                    </Typography>

                    <Typography className={styles.secondaryText}>
                      <FormattedMessage
                        id="actionDeleteMemberText"
                        defaultMessage="Are you sure you want to remove {fullname} from your organization?"
                        values={{
                          fullname: fullname,
                        }}
                      />
                    </Typography>
                    <Error error={error} />
                    <Box
                      className={styles.actions}
                      component="form"
                      onSubmit={handleSubmit}
                    >
                      <Button
                        onClick={handleClose}
                        className={styles.cancelButton}
                        variant="contained"
                        color="secondary"
                      >
                        <FormattedMessage
                          id="deleteModalBackButton"
                          defaultMessage="Back to Organization"
                        />
                      </Button>
                      <Button
                        className={styles.validateButton}
                        type="submit"
                        disabled={isSubmitting}
                        variant="contained"
                      >
                        <FormattedMessage
                          id="action-delete.yes"
                          defaultMessage="Yes"
                        />
                      </Button>
                    </Box>
                  </>
                )}
              </Formik>
            )}
          </Mutation>
        </Box>
      </Modal>
    </>
  );
};

export default RemoveMemberButton;
