import React, { FC } from "react";
import { Box, Fade, Tab, Tabs } from "@mui/material";
import { useIntl } from "react-intl";

import { Calculator } from "./Calculator/Calculator";
import { Credits } from "./Credits/Credits";
import { Focus } from "./Focus/Focus";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const CustomTabPanel: FC<TabPanelProps> = ({
  children,
  value,
  index,
  ...other
}) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Fade in={true} timeout={800}>
          <Box sx={{ p: 3, pl: 0, pr: 0 }}>{children}</Box>
        </Fade>
      )}
    </div>
  );
};

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const CarbonCredits: FC = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const intl = useIntl();

  return (
    <Box sx={{}}>
      <Tabs
        value={value}
        onChange={handleChange}
        variant="fullWidth"
        aria-label="full width tabs example"
        sx={{
          border: 1,
          borderColor: "divider",
          borderRadius: "6px",
        }}
      >
        <Tab
          sx={{
            borderRight: 1,
          }}
          label={intl.formatMessage({
            id: "carbonCreditsTabsCalculator",
            defaultMessage: "Calculator",
          })}
          {...a11yProps(0)}
        />
        <Tab
          sx={{ borderRight: 1 }}
          label={intl.formatMessage({
            id: "settings-carbonCredits",
            defaultMessage: "Carbon Credits",
          })}
          {...a11yProps(1)}
        />
        <Tab
          // sx={{ width: "100%" }}
          label={intl.formatMessage({
            id: "carbonCreditsTabsEsg",
            defaultMessage: "ESG Focus",
          })}
          {...a11yProps(2)}
        />
      </Tabs>

      <CustomTabPanel value={value} index={0}>
        <Calculator />
      </CustomTabPanel>

      <CustomTabPanel value={value} index={1}>
        <Credits />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <Focus />
      </CustomTabPanel>
    </Box>
  );
};

export default CarbonCredits;
