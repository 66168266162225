import { FC } from "react";
import styles from "./index.module.scss";
import {
  Box,
  Button,
  Fade,
  FormControl,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";
import { ReactComponent as ExclamationCircle } from "../../../assets/CurrencyDollar.svg";
import { ReactComponent as Visa } from "../../../assets/visa.svg";
import { ReactComponent as MasterCard } from "../../../assets/Mastercard.svg";
import { FormattedMessage } from "react-intl";
import { useFormik } from "formik";
import * as Yup from "yup";
import { DateField } from "@mui/x-date-pickers";
import { CustomCountrySelect } from "../../PersonaInfo/components/CustomCountrySelect";

interface Props {
  formTitle: string;
  handleClose: any;
}

export const AddCardForm: FC<Props> = ({ formTitle, handleClose }) => {
  const formik = useFormik({
    initialValues: {
      cardNumber: "",
      cardName: "",
      expiry: null,
      cardCvv: "",
    },
    validationSchema: Yup.object({
      cardNumber: Yup.string()
        .matches(/^\d{16}$/, "Invalid card number")
        .required("Required"),
      cardName: Yup.string()
        .matches(/^[a-zA-Z\s]*$/, "Invalid name")
        .min(5, "Must be at least 5 characters")
        .required("Required"),
      expiry: Yup.string()
        .matches(/\d\d\/\d\d/, "Enter a valid date (MM/YY)")
        .required("Required"),
      cardCvv: Yup.string()
        .matches(/^\d{3}$/, "Invalid CVV")
        .required("Required"),
    }),
    onSubmit: (values) => {
      console.log(values);
    },
  });

  function getCardType(cardNumber: string) {
    // MasterCard
    if (/^5[1-5]/.test(cardNumber)) {
      return "mastercard";
    }
    // Visa
    if (/^4/.test(cardNumber)) {
      return "visa";
    }

    return "";
  }
  const handleChangeDate = (date: any) => {
    formik.setFieldValue("expiry", date);
  };

  const { values, handleChange, handleBlur, handleSubmit } = formik;

  return (
    <Box
      sx={{ maxWidth: "50vw", display: "flex", justifyContent: "center" }}
      className={styles.form}
      component="form"
      onSubmit={handleSubmit}
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Box display="flex" justifyContent="center">
          <ExclamationCircle />
        </Box>

        <Typography className={styles.title}>{formTitle}</Typography>
        <Typography
          sx={{
            pl: 1,
            pr: 1,
            textAlign: "center",
            mb: "16px",
          }}
          className={styles.subtitle}
        >
          <FormattedMessage
            id="manage-paymentInfoText"
            defaultMessage="Edit your payment details, add a backup, or switch your preferred payment method."
          />
        </Typography>

        <Box sx={{ minWidth: "250px", width: "30vw" }}>
          <Box sx={{ mb: "20px" }} className={styles.inputWithLabel}>
            <Typography>
              <FormattedMessage
                id="form-cardDetails"
                defaultMessage="Card Details"
              />
            </Typography>

            <OutlinedInput
              sx={{
                maxHeight: "48px",
                minHeight: "32px",
                borderRadius: "4px 4px 0 0",
              }}
              fullWidth
              id="cardNumber"
              name="cardNumber"
              type="text"
              value={
                values.cardNumber.slice(0, 2) +
                values.cardNumber.slice(2, 12).replace(/\d/g, "*") +
                values.cardNumber.slice(12)
              }
              onChange={handleChange}
              onBlur={handleBlur}
              inputProps={{ maxLength: 16 }}
              startAdornment={
                getCardType(values.cardNumber) === "mastercard" ? (
                  <Fade in={true} timeout={800}>
                    <MasterCard width={32} height={32} />
                  </Fade>
                ) : getCardType(values.cardNumber) === "visa" ? (
                  <Fade in={true} timeout={800}>
                    <Visa width={32} height={32} />
                  </Fade>
                ) : null
              }
            />

            <Box sx={{ display: "flex" }}>
              <DateField
                sx={{
                  "& .MuiInputBase-root": {
                    borderRadius: "0 0 0 4px",
                    borderRight: "0",
                  },
                }}
                name="expiry"
                onChange={handleChangeDate}
                value={values.expiry}
                format="MM/dd"
                fullWidth
              />

              <OutlinedInput
                fullWidth
                sx={{
                  maxHeight: "48px",
                  minHeight: "32px",
                  borderRadius: "0 0 4px 0",
                }}
                id="cardCvv"
                name="cardCvv"
                type="tel"
                placeholder="CVV"
                value={values.cardCvv}
                onChange={handleChange}
                onBlur={handleBlur}
                inputProps={{ maxLength: 3, inputMode: "numeric" }}
              />
            </Box>
          </Box>
          <FormControl
            sx={{ mb: "20px" }}
            fullWidth
            className={styles.inputWithLabel}
          >
            <Typography>
              <FormattedMessage
                id="holder-name"
                defaultMessage="Account holder name"
              />
            </Typography>
            <OutlinedInput
              sx={{ maxHeight: "48px", minHeight: "32px" }}
              fullWidth
              id="cardName"
              name="cardName"
              type="text"
              value={values.cardName}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </FormControl>

          <FormControl
            sx={{ mb: "20px" }}
            fullWidth
            className={styles.inputWithLabel}
          >
            <Typography>
              <FormattedMessage
                id="holder-type"
                defaultMessage="Account holder type"
              />
            </Typography>
            <OutlinedInput
              sx={{ maxHeight: "48px", minHeight: "32px" }}
              fullWidth
              id="holderType"
              name="holderType"
              type="text"
              //   value={formik.values.holderType}
              //   onChange={formik.handleChange}
              //   onBlur={formik.handleBlur}
            />
          </FormControl>
          <FormControl
            sx={{ mb: "20px" }}
            fullWidth
            className={styles.inputWithLabel}
          >
            <Typography>
              <FormattedMessage id="form-currency" defaultMessage="Currency" />
            </Typography>
            <Select
              sx={{ maxHeight: "48px", minHeight: "32px" }}
              fullWidth
              id="currency"
              name="currency"
              type="text"
              //   value={formik.values.currency}
              //   onChange={formik.handleChange}
              //   onBlur={formik.handleBlur}
            />
          </FormControl>
          <FormControl
            sx={{ mb: "20px" }}
            fullWidth
            className={styles.inputWithLabel}
          >
            <Typography>
              <FormattedMessage id="form-country" defaultMessage="Country" />
            </Typography>
            <CustomCountrySelect

            //   value={formik.values.country}
            //   onChange={formik.handleChange}
            //   onBlur={formik.handleBlur}
            />
          </FormControl>
          <FormControl
            sx={{ mb: "20px" }}
            fullWidth
            className={styles.inputWithLabel}
          >
            <Typography>
              <FormattedMessage
                id="account-number"
                defaultMessage="Account number"
              />
            </Typography>
            <OutlinedInput
              sx={{ maxHeight: "48px", minHeight: "32px" }}
              fullWidth
              id="accountNumber"
              name="accountNumber"
              type="number"
              //   value={formik.values.accountNumber}
              //   onChange={formik.handleChange}
              //   onBlur={formik.handleBlur}
            />
          </FormControl>
          <FormControl
            sx={{ mb: "20px" }}
            fullWidth
            className={styles.inputWithLabel}
          >
            <Typography>
              <FormattedMessage
                id="routing-number"
                defaultMessage="Routing number"
              />
            </Typography>
            <OutlinedInput
              sx={{ maxHeight: "48px", minHeight: "32px" }}
              fullWidth
              id="routingNumber"
              name="routingNumber"
              type="number"
              //   value={formik.values.routingNumber}
              //   onChange={formik.handleChange}
              //   onBlur={formik.handleBlur}
            />
          </FormControl>
          <Box className={styles.modalFooter}>
            <FormControl
              onClick={handleClose}
              fullWidth
              sx={{ mr: "20px", maxWidth: "220px", minWidth: "150px" }}
            >
              <Button variant="contained" color="secondary">
                <FormattedMessage
                  id="deleteModalBackToAccount"
                  defaultMessage="Back to Account"
                />
              </Button>
            </FormControl>

            <Button type="submit" variant="contained" color="primary">
              <FormattedMessage id="actions.add" defaultMessage="Add" />
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AddCardForm;
