import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";
import separator from "../../assets/Separator.svg";
import StepDone from "../../assets/StepDone.svg";
import dayjs from "dayjs";
import {
  Box,
  Button,
  Container,
  Typography,
  Avatar,
  Grid,
  Divider,
  Fade,
} from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { loader } from "graphql.macro";
import { useQuery } from "@apollo/client";
import Error from "../Error";
import { DefaultMeetingCard } from "./components/DefaultMeetingCard/index";
import AvatarList from "./components/AvatarList";
import MeetingList from "./components/MeetingList";
import { NavLink } from "react-router-dom";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import CreateMeetingBtn from "../CreateMeetingButton";
dayjs.extend(utc);
dayjs.extend(timezone);

const GET_ROOMS_QUERY = loader("../../schema/query/GetRooms.graphql");

interface Data {
  me: {
    id: string;
    meetings: Array<Room>;
  };
}

interface Variables {
  organizationId: string | null;
  startDate: string; // datetime in utc
  endDate: string | null;
}

interface Props {
  currentUser: User;
  selectedOrganizationId: string | null;
  setSelectedOrganizationId: (id: string) => void;
}

const MyRooms: React.FC<Props> = ({
  currentUser,
  selectedOrganizationId,
  setSelectedOrganizationId,
}) => {
  const [activeStep, setActiveStep] = useState("one");
  const [hasCreatedAnAvatar, setHasCreatedAnAvatar] = useState(false);

  useEffect(() => {
    if (hasCreatedAnAvatar) {
      setActiveStep("two");
    }
  }, [hasCreatedAnAvatar]);

  const desiredTimezone = "Europe/Paris";

  const startDate = dayjs()
    .tz(desiredTimezone)
    .utc()
    .format("YYYY-MM-DDTHH:mm:ss[Z]");
  const intl = useIntl();

  const { data, loading, error } = useQuery<Data, Variables>(GET_ROOMS_QUERY, {
    variables: {
      organizationId: selectedOrganizationId,
      startDate: startDate,
      endDate: null,
    },
  });

  if (loading) return null;
  if (error || !data) return <Error error={error} />;
  if (!data.me) {
    return null;
  }

  let filteredMeetings = data.me.meetings;

  return (
    <Fade in={!loading} timeout={500}>
      <Container
        maxWidth={false}
        sx={{
          placeItems: "center",
          mb: 1,
          minHeight: "60vh",
        }}
        disableGutters
        className={styles.container}
      >
        <Box className={styles.header}>
          <Typography className={styles.text} component="div">
            {filteredMeetings.length === 0 ? (
              <FormattedMessage
                id="header.startMeeting"
                defaultMessage="Start your meeting"
              />
            ) : (
              <FormattedMessage
                id="header.myMeetings"
                defaultMessage="My meetings"
              />
            )}
          </Typography>

          <Box
            sx={{
              display: "flex",
              gap: "20px",
            }}
          >
            {filteredMeetings.length > 0 ? (
              <CreateMeetingBtn
                label={intl.formatMessage({
                  id: "header.createNewMeeting",
                  defaultMessage: "Create new meeting",
                })}
                selectedOrganizationId={selectedOrganizationId}
                setSelectedOrganizationId={setSelectedOrganizationId}
              />
            ) : null}

            <NavLink to="/meetings-history">
              <Button variant="contained" color="secondary">
                <FormattedMessage
                  id="header.btn"
                  defaultMessage="View the history of meetings"
                />
              </Button>
            </NavLink>
          </Box>
        </Box>

        {filteredMeetings.length === 0 ? (
          <Box className={styles.stepsSections}>
            {/* Step 1 */}
            <Box
              sx={{ cursor: "pointer" }}
              onClick={() => setActiveStep("one")}
              className={
                activeStep === "one"
                  ? styles.activeStep
                  : activeStep === "two"
                  ? styles.stepBox
                  : styles.stepDone
              }
            >
              {hasCreatedAnAvatar ? (
                <Avatar sx={{ m: "24px 16px" }} src={StepDone} />
              ) : (
                <Box className={styles.circle}>
                  <Box className={styles.circleText}>01</Box>
                </Box>
              )}
              <Box
                className={styles.stepsHeader}
                sx={{ width: "100%", paddingY: "5px" }}
              >
                <Typography>
                  <FormattedMessage
                    id="steps.create.message"
                    defaultMessage="Create your avatar"
                  />
                </Typography>
                <Typography component="span">
                  <FormattedMessage
                    id="steps.choose.message"
                    defaultMessage="Choose the one you need now"
                  />
                </Typography>
              </Box>
            </Box>

            {/* Step 2 */}

            <Box
              sx={{ cursor: "pointer" }}
              onClick={() => setActiveStep("two")}
              className={
                activeStep === "two" ? styles.activeStep : styles.stepBox
              }
            >
              <img src={separator} alt="Separator" />

              <Box className={styles.circle}>
                <Box className={styles.circleText}>02</Box>
              </Box>

              <Box className={styles.stepsHeader} sx={{ width: "100%" }}>
                <Typography>
                  <FormattedMessage
                    id="nav.meetings"
                    defaultMessage="Meetings"
                  />
                </Typography>
                <Typography component="span">
                  <FormattedMessage
                    id="steps.start.meeting.message"
                    defaultMessage="Start your meeting right now"
                  />
                </Typography>
              </Box>
            </Box>
          </Box>
        ) : (
          <Divider sx={{ mb: "40px" }} />
        )}

        <Box>
          {activeStep === "one" && filteredMeetings.length === 0 ? (
            <Grid container spacing={2} padding={3}>
              <AvatarList setHasCreatedAnAvatar={setHasCreatedAnAvatar} />
            </Grid>
          ) : null}

          {activeStep === "two" && filteredMeetings.length === 0 ? (
            <Grid container spacing={2} padding={3}>
              <DefaultMeetingCard
                selectedOrganizationId={selectedOrganizationId}
                setSelectedOrganizationId={setSelectedOrganizationId}
                title={intl.formatMessage({
                  id: "nav.meetings",
                  defaultMessage: "Meetings",
                })}
              />
            </Grid>
          ) : null}

          {filteredMeetings.length > 0 ? (
            <Grid minHeight="60vh" container spacing={2} padding={3} pt={0}>
              <MeetingList
                selectedOrganizationId={selectedOrganizationId}
                setSelectedOrganizationId={setSelectedOrganizationId}
                loading={loading}
                rooms={filteredMeetings}
              />
            </Grid>
          ) : null}
        </Box>
      </Container>
    </Fade>
  );
};

export default MyRooms;
export { GET_ROOMS_QUERY };
