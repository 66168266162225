export default class Webcam {
  constructor(
    webcamElement,
    facingMode = "user",
    canvasElement = null,
    snapSoundElement = null
  ) {
    this._webcamElement = webcamElement;
    this._webcamElement.width = this._webcamElement.width || 640;
    this._webcamElement.height =
      this._webcamElement.height || this._webcamElement.width * (3 / 4);
    this._facingMode = facingMode;
    this._webcamList = [];
    this._streamList = [];
    this._selectedDeviceId = "";
    this._canvasElement = canvasElement;
    this._snapSoundElement = snapSoundElement;
  }

  get facingMode() {
    return this._facingMode;
  }

  set facingMode(value) {
    this._facingMode = value;
  }

  get webcamList() {
    return this._webcamList;
  }

  get webcamCount() {
    return this._webcamList.length;
  }

  get selectedDeviceId() {
    return this._selectedDeviceId;
  }

  /* Get all video input devices info */
  getVideoInputs(mediaDevices) {
    this._webcamList = [];
    mediaDevices.forEach((mediaDevice) => {
      if (mediaDevice.kind === "videoinput") {
        this._webcamList.push(mediaDevice);
      }
    });
    if (this._webcamList.length === 1) {
      this._facingMode = "user";
    }
    return this._webcamList;
  }

  /* Get media constraints */
  getMediaConstraints() {
    const videoConstraints = {};
    if (this._selectedDeviceId === "") {
      videoConstraints.facingMode = this._facingMode;
    } else {
      videoConstraints.deviceId = { exact: this._selectedDeviceId };
    }
    const constraints = {
      video: videoConstraints,
      audio: false,
    };
    return constraints;
  }

  /* Select camera based on facingMode */
  selectCamera() {
    for (let webcam of this._webcamList) {
      if (
        (this._facingMode === "user" &&
          webcam.label.toLowerCase().includes("front")) ||
        (this._facingMode === "environment" &&
          webcam.label.toLowerCase().includes("back"))
      ) {
        this._selectedDeviceId = webcam.deviceId;
        break;
      }
    }
  }

  /* Change Facing mode and selected camera */
  flip() {
    this._facingMode = this._facingMode === "user" ? "environment" : "user";
    this._webcamElement.style.transform = "";
    this.selectCamera();
  }

  /*
      1. Get permission from user
      2. Get all video input devices info
      3. Select camera based on facingMode 
      4. Start stream
    */
  async start(startStream = true) {
    return new Promise((resolve, reject) => {
      this.stop();
      navigator.mediaDevices
        .getUserMedia(this.getMediaConstraints()) // get permission from user
        .then((stream) => {
          this._streamList.push(stream);
          this.info() // get all video input devices info
            .then((webcams) => {
              this.selectCamera(); // select camera based on facingMode
              if (startStream) {
                this.stream()
                  .then((facingMode) => {
                    resolve(this._facingMode);
                  })
                  .catch((error) => {
                    reject(error);
                  });
              } else {
                resolve(this._selectedDeviceId);
              }
            })
            .catch((error) => {
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /* Get all video input devices info */
  async info() {
    return new Promise((resolve, reject) => {
      navigator.mediaDevices
        .enumerateDevices()
        .then((devices) => {
          this.getVideoInputs(devices);
          resolve(this._webcamList);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /* Start streaming webcam to video element */
  async stream() {
    return new Promise((resolve, reject) => {
      navigator.mediaDevices
        .getUserMedia(this.getMediaConstraints())
        .then((stream) => {
          this._streamList.push(stream);
          this._webcamElement.srcObject = stream;
          if (this._facingMode === "user") {
            this._webcamElement.style.transform = "scale(-1,1)";
          }
          this._webcamElement.play();
          resolve(this._facingMode);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  }

  /* Stop streaming webcam */
  stop() {
    this._streamList.forEach((stream) => {
      stream.getTracks().forEach((track) => {
        track.stop();
      });
    });
  }

  snap() {
    if (this._canvasElement != null) {
      if (this._snapSoundElement != null) {
        this._snapSoundElement.play();
      }

      // set the original height and width of the video track on canvas to not lose quality
      const stream = this._webcamElement.srcObject;
      if (!stream) {
        throw new Error("webcam stream not found");
      }
      const videoTracks = stream.getVideoTracks();
      const videoSettings =
        videoTracks.length > 0 ? videoTracks[0].getSettings() : {};

      // window.confirm(
      //   JSON.stringify(
      //     Object.assign({}, videoSettings, {
      //       screenOrientationType: screen.orientation.type,
      //       scrollHeight: this._webcamElement.scrollHeight,
      //       scrollWidth: this._webcamElement.scrollWidth,
      //     }),
      //     2
      //   )
      // );
      if (videoSettings.height && videoSettings.width) {
        // on iOS 16.4 videoTracks[0].getSettings() returns always height: 480, width 640 in portrait or landscape
        // on Chrome 111 Android in portrait, height: 640, width: 480, aspectRatio: 0.75, portrait to landscape doesn't change the values but the video dimensions change
        // on Chrome 111 Android in landscape, height: 480, width: 640, aspectRatio: 1.33333, landscape to portrait correctly changes the values to portrait values
        let height = videoSettings.height;
        let width = videoSettings.width;
        // screen.orientation is undefined on Safari < 16.4
        // eslint-disable-next-line
        switch (screen.orientation?.type) {
          case "landscape-primary":
          case "landscape-secondary":
            if (height > width) {
              // switch the two values
              height = videoSettings.width;
              width = videoSettings.height;
            }
            break;
          case "portrait-secondary":
          case "portrait-primary":
            if (width > height) {
              // switch the two values
              height = videoSettings.width;
              width = videoSettings.height;
            }
            break;
          default:
            break;
          // The orientation API isn't supported in this browser
        }
        this._canvasElement.height = height;
        this._canvasElement.width = width;
      } else {
        // when I last tested, we never go here on desktop, iOS, Android
        this._canvasElement.height = this._webcamElement.scrollHeight;
        this._canvasElement.width = this._webcamElement.scrollWidth;
      }

      let context = this._canvasElement.getContext("2d");
      // if (this._facingMode === "user") {
      //   context.translate(this._canvasElement.width, 0);
      //   context.scale(-1, 1);
      // }
      context.clearRect(
        0,
        0,
        this._canvasElement.width,
        this._canvasElement.height
      );
      context.drawImage(
        this._webcamElement,
        0,
        0,
        this._canvasElement.width,
        this._canvasElement.height
      );
      let data = this._canvasElement.toDataURL("image/jpeg");
      return data;
    } else {
      throw new Error("canvas element is missing");
    }
  }
}
