import React from "react";
import SignUpButton from "../components/SignUpButton";
import CurrentUser from "../components/CurrentUser";
import MyRooms from "../components/MyRooms/MyRooms";
import { Container } from "@mui/material";
import { getSelectedOrganization } from "../lib/account";

interface Props {
  setSelectedOrganizationId: (id: string) => void;
  selectedOrganizationId: string | null;
}

const Home: React.FC<Props> = ({
  selectedOrganizationId,
  setSelectedOrganizationId,
}) => (
  <CurrentUser>
    {(currentUser: User | null) => {
      if (currentUser) {
        const organization = getSelectedOrganization(
          currentUser,
          selectedOrganizationId
        );
        selectedOrganizationId = organization ? organization.id : null;
        return (
          <Container sx={{ minWidth: "85%" }}>
            <MyRooms
              setSelectedOrganizationId={setSelectedOrganizationId}
              selectedOrganizationId={selectedOrganizationId}
              currentUser={currentUser}
            />
          </Container>
        );
      } else {
        return (
          <Container
            disableGutters
            maxWidth={false}
            className="home-anonymous"
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              flex: 1,
              mt: "-41px",
            }}
          >
            <div
              style={{
                textAlign: "center",
                backgroundColor: "rgba(255, 255, 255, 0.9)",
                padding: "2em 0",
              }}
            >
              <SignUpButton />
            </div>
          </Container>
        );
      }
    }}
  </CurrentUser>
);

export default Home;
