import React from "react";

import { Box, Grid, Radio } from "@mui/material";
import { scenes } from "../../../lib/helpers";

interface Props {
  authenticated: boolean;
  onUpdate: (sceneIdentifier: string) => void;
  value: string;
}

const SceneSelect: React.FC<Props> = ({ authenticated, onUpdate, value }) => {
  return (
    <Grid
      columnGap={4}
      columnSpacing={{ xs: 1, sm: 3, md: 3 }}
      container
      sx={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        maxWidth: "100%",
        pl: "30px",
      }}
    >
      {scenes.map((scene) => {
        const allowSelect = authenticated || scene.premiumType === "NONE";
        return (
          <Grid
            item
            key={scene.identifier}
            sx={
              allowSelect
                ? {
                    cursor: "pointer",
                    display: "flex",
                    position: "relative",
                  }
                : {
                    position: "relative",
                  }
            }
          >
            {allowSelect && (
              <Radio
                name="sceneIdentifier"
                size="small"
                sx={{
                  cursor: "pointer",
                  position: "absolute",
                  top: "10px",
                  right: "2px",
                  background: "#ffffff",
                  maxHeight: "20px",
                  maxWidth: "20px",
                }}
                checked={value === scene.identifier}
                onChange={() => onUpdate(scene.identifier)}
              />
            )}
            <img
              style={{
                width: "168px",
                height: "81px",
                margin: "0.4em 0 1em 0",
              }}
              src={scene.image}
              title={scene.name}
              alt={scene.name}
              onClick={
                allowSelect ? () => onUpdate(scene.identifier) : undefined
              }
            />
          </Grid>
        );
      })}
      {!authenticated ? (
        <Box>
          <Box style={{ margin: "2em" }}>
            <a href="/users/register">Create an account</a> to select the other
            rooms
          </Box>
        </Box>
      ) : null}
    </Grid>
  );
};

export default SceneSelect;
