import LinearProgress, {
  LinearProgressProps,
} from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { colors } from "../../lib/constants";

const { dark, purple } = colors;

const LinearProgressWithLabel = (
  props: LinearProgressProps & {
    value: number;
    isCurrentMonth?: boolean;
    bestColor?: string | null;
  }
) => {
  return (
    <Box sx={{ minWidth: "50%", alignItems: "center" }} display="flex">
      <Typography
        sx={{ mr: "5px", fontSize: "14px", fontWeight: 500 }}
        variant="body2"
        color="secondary"
      >{`${Math.round(props.value)}`}</Typography>

      <LinearProgress
        sx={{
          width: "100%",
          minHeight: "6px",
          borderRadius: "6px",

          bgcolor: "#F7F9FB",

          "& span": props.bestColor
            ? {
                bgcolor: purple,
                borderRadius: "6px",
              }
            : {
                bgcolor: props.isCurrentMonth ? dark : "rgba(0, 0, 0, 0.1)",
                borderRadius: "6px",
              },
        }}
        variant="determinate"
        value={props.value}
        valueBuffer={100}
      />
    </Box>
  );
};

export default LinearProgressWithLabel;
