import React, { useState } from "react";
import styles from "../../../../assets/stylesheets/modal.module.scss";
import {
  Box,
  Button,
  Container,
  IconButton,
  OutlinedInput,
  Tooltip,
  Typography,
  Modal,
  Divider,
  FormHelperText,
} from "@mui/material";
import { loader } from "graphql.macro";
import { Mutation } from "@apollo/client/react/components";
import Error from "../../../Error";
import { Formik, FormikErrors } from "formik";
import { FormattedMessage, useIntl } from "react-intl";
import { ReactComponent as Pencil } from "../../../../assets/editSvg.svg";
import { ReactComponent as CloseSvg } from "../../../../assets/CloseSvg.svg";

const UPDATE_AVATAR = loader(
  "../../../../schema/mutation/UpdateAvatar.graphql"
);

interface Props {
  id: string;
  name: string;
}

interface Data {
  updateAvatar: Avatar;
}

interface FormValues {
  name: string;
}

const UpdateAvatarButton: React.FC<Props> = ({ id, name }) => {
  const intl = useIntl();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const initialValues: FormValues = {
    name: name,
  };

  return (
    <>
      <Tooltip
        title={intl.formatMessage({
          id: "manage-avatars.edit-avatar-name",
          defaultMessage: "Edit the name",
        })}
      >
        <IconButton type="button" onClick={handleShow}>
          <Pencil />
        </IconButton>
      </Tooltip>

      <Modal open={show} onClose={handleClose}>
        <Container
          disableGutters
          maxWidth={false}
          className={styles.container}
          sx={{
            bgcolor: "background.paper",
            maxWidth: "600px",
          }}
        >
          <Typography className={styles.title}>
            <FormattedMessage
              id="manage-avatars.modification-avatar"
              defaultMessage="Modification of the avatar"
            />
            <IconButton onClick={handleClose}>
              <CloseSvg />
            </IconButton>
          </Typography>
          <Mutation<Data, FormValues>
            mutation={UPDATE_AVATAR}
            onCompleted={handleClose}
          >
            {(updateAvatar, { error }) => (
              <Formik
                initialValues={initialValues}
                validate={(values: FormValues) => {
                  const errors: FormikErrors<FormValues> = {};
                  if (!values.name) {
                    errors.name = intl.formatMessage({
                      id: "errors.required",
                      defaultMessage: "Required",
                    });
                  }
                  return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                  const data = { ...values, id: id };
                  updateAvatar({
                    variables: data,
                  }).catch(() => {
                    setSubmitting(false);
                  });
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                }) => (
                  <Box component="form" id="editName" onSubmit={handleSubmit}>
                    <Box sx={{ p: 3 }}>
                      <Error error={error} />
                      <Box className={styles.inlineField} sx={{ mb: 0 }}>
                        <Typography
                          className={styles.label}
                          component="label"
                          htmlFor="name"
                        >
                          <FormattedMessage
                            id="manage-avatars.name-field"
                            defaultMessage="Avatar name"
                          />
                        </Typography>

                        <OutlinedInput
                          name="name"
                          id="name"
                          type="text"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.name}
                          error={!!errors.name}
                        />
                        {errors.name && touched.name && (
                          <FormHelperText error>{errors.name}</FormHelperText>
                        )}
                      </Box>
                    </Box>
                    <Divider />
                    <Box className={styles.modalFooter}>
                      <Button
                        onClick={handleClose}
                        form=""
                        variant="outlined"
                        color="secondary"
                      >
                        <FormattedMessage
                          id="actions.cancel"
                          defaultMessage="Cancel"
                        />
                      </Button>

                      <Button
                        type="submit"
                        variant="contained"
                        form="editName"
                        disabled={isSubmitting}
                      >
                        <FormattedMessage
                          id="actions.update"
                          defaultMessage="Update"
                        />
                      </Button>
                    </Box>
                  </Box>
                )}
              </Formik>
            )}
          </Mutation>
        </Container>
      </Modal>
    </>
  );
};

export default UpdateAvatarButton;
