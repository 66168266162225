import { FC } from "react";
import { Box } from "@mui/material";
import User from "../../User";

interface Props {
  attendees: Attendee[];
}

export const HorizontalAttendeeList: FC<Props> = ({ attendees }) => {
  return (
    <Box sx={{ display: "flex", flexWrap: "wrap" }}>
      {attendees.map((attendee) => (
        <User key={attendee.email} attendee={attendee} />
      ))}
    </Box>
  );
};
