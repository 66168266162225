import { Avatar, Tooltip } from "@mui/material";
import { FC } from "react";
import styles from "./index.module.scss";

interface Props {
  user?: User;
  attendee?: Attendee;
}

const User: FC<Props> = ({ user, attendee }) => {
  const generateAvatarText = (fullname?: string | null): string => {
    return (fullname?.match(/\b\w/g) || []).join("").toUpperCase();
  };

  const renderAvatar = (user: Attendee, index: number) => (
    <Tooltip
      key={index}
      title={user.fullname ? `${user.fullname} <${user.email}>` : user.email}
    >
      {user.picture ? (
        <Avatar
          src={user.picture}
          sx={{ width: "32px", height: "32px", mr: "5px", mb: "5px" }}
        />
      ) : user.fullname ? (
        <Avatar
          sx={{ mr: "5px", mb: "5px" }}
          className={styles.defaultUserLogo}
        >
          {generateAvatarText(user.fullname)}
        </Avatar>
      ) : (
        <Avatar
          sx={{ mr: "5px", mb: "5px" }}
          className={styles.defaultUserLogo}
        />
      )}
    </Tooltip>
  );

  return (
    <>
      {user ? (
        <>
          {user.picture ? (
            <Avatar src={user.picture} sx={{ width: "32px", height: "32px" }} />
          ) : (
            <Avatar className={styles.defaultUserLogo}>
              {generateAvatarText(user.fullname)}
            </Avatar>
          )}
        </>
      ) : attendee ? (
        renderAvatar(attendee, 0)
      ) : null}
    </>
  );
};

export default User;
