import React, { useState } from "react";
import styles from "../../assets/stylesheets/dialog.module.scss";
import { Box, Button, Modal, Typography } from "@mui/material";

import { ReactComponent as DeleteSvg } from "../../assets/deleteMemberSvg.svg";
import { FormattedMessage } from "react-intl";

import { NavLink } from "react-router-dom";

interface Props {}

const RemoveAccountButton: React.FC<Props> = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button onClick={handleShow} variant="contained" color="secondary">
        <FormattedMessage
          id="settingsDeleteButton"
          defaultMessage="Delete my Account"
        />
      </Button>

      <Modal open={show} onClose={handleClose}>
        <Box
          className={styles.dialog}
          sx={{
            bgcolor: "background.paper",
          }}
        >
          <>
            <DeleteSvg />
            <Typography className={styles.title}>
              <FormattedMessage
                id="actionDeleteAccount"
                defaultMessage="Delete my Account"
              />
            </Typography>

            <Typography className={styles.secondaryText}>
              <FormattedMessage
                id="actionDeleteAccountText"
                defaultMessage="Are you sure you want to delete your Kizwork account?"
              />
            </Typography>
            <Box className={styles.actions}>
              <Button
                onClick={handleClose}
                className={styles.cancelButton}
                variant="contained"
                color="secondary"
              >
                <FormattedMessage
                  id="deleteModalBackToAccount"
                  defaultMessage="Back to Account"
                />
              </Button>
              <NavLink to="/delete-account">
                <Button
                  className={styles.validateButton}
                  type="submit"
                  variant="contained"
                >
                  <FormattedMessage
                    id="action-delete.yes"
                    defaultMessage="Yes"
                  />
                </Button>
              </NavLink>
            </Box>
          </>
        </Box>
      </Modal>
    </>
  );
};

export default RemoveAccountButton;
