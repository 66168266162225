import React from "react";
import { Button, Container } from "@mui/material";
import { RouteComponentProps } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons/faCheck";

const Pricing = ({ location }: RouteComponentProps) => (
  <Container>
    <h1>
      <FormattedMessage id="pricing.pricing-title" defaultMessage="Pricing" />
    </h1>
    <table role="table" className="table table-bordered" style={{ border: 0 }}>
      <colgroup>
        <col style={{ width: "25%" }} />
        <col style={{ width: "25%" }} />
        <col style={{ width: "25%" }} />
        <col style={{ width: "25%" }} />
      </colgroup>
      <thead className="thead-dark">
        <tr>
          <td style={{ border: 0 }}></td>
          <th style={{ textAlign: "center" }}>Starter</th>
          <th style={{ textAlign: "center" }}>Business</th>
          <th style={{ textAlign: "center" }}>Corporate</th>
        </tr>
        <tr>
          <td style={{ border: 0 }}></td>
          <td style={{ textAlign: "center", verticalAlign: "middle" }}>
            <strong>Free</strong>
          </td>
          <td style={{ textAlign: "center", verticalAlign: "middle" }}>
            <strong>19.99 €</strong>
            <br />
            <span className="discreet">
              excl. VAT per user and per month with annual billing
            </span>
            <br />
            <span className="discreet">
              24.99 € excl. VAT per user and per month with monthly billing
            </span>
            <br />
            <Button
              variant="contained"
              href="mailto:contact@kizwork.com?subject=I%20am%20interested%20in%20your%20Business%20Pricing!"
              onClick={() => {
                // @ts-ignore-line
                if (typeof _paq !== "undefined")
                  // @ts-ignore-line
                  _paq.push([
                    "trackEvent",
                    "Accounts",
                    "Contact Us Pricing Business",
                  ]);
              }}
            >
              Contact us
            </Button>
          </td>
          <td style={{ textAlign: "center", verticalAlign: "middle" }}>
            <Button
              variant="contained"
              href="mailto:contact@kizwork.com?subject=I%20am%20interested%20in%20your%20Corporate%20Pricing!"
              onClick={() => {
                // @ts-ignore-line
                if (typeof _paq !== "undefined")
                  // @ts-ignore-line
                  _paq.push([
                    "trackEvent",
                    "Accounts",
                    "Contact Us Pricing Corporate",
                  ]);
              }}
            >
              Contact us
            </Button>
          </td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Number of licensed users*</td>
          <td style={{ textAlign: "center" }}>4 max</td>
          <td style={{ textAlign: "center" }}>1 to 20</td>
          <td style={{ textAlign: "center" }}>over 20</td>
        </tr>
        <tr>
          <td>Meeting time limit</td>
          <td style={{ textAlign: "center" }}>40 min</td>
          <td style={{ textAlign: "center" }}>unlimited</td>
          <td style={{ textAlign: "center" }}>unlimited</td>
        </tr>
        <tr>
          <td>Number of participants per room</td>
          <td style={{ textAlign: "center" }}>4</td>
          <td style={{ textAlign: "center" }}>25</td>
          <td style={{ textAlign: "center" }}>contact us</td>
        </tr>
        <tr>
          <td>Free external guests per room</td>
          <td style={{ textAlign: "center" }}>4</td>
          <td style={{ textAlign: "center" }}>14</td>
          <td style={{ textAlign: "center" }}>contact us</td>
        </tr>
        <tr>
          <td>Company logo in virtualized rooms</td>
          <td style={{ textAlign: "center" }}>&mdash;</td>
          <td style={{ textAlign: "center", color: "#228B22" }}>
            <FontAwesomeIcon icon={faCheck} />
          </td>
          <td style={{ textAlign: "center", color: "#228B22" }}>
            <FontAwesomeIcon icon={faCheck} />
          </td>
        </tr>
        <tr>
          <td>Chat</td>
          <td style={{ textAlign: "center", color: "#228B22" }}>
            <FontAwesomeIcon icon={faCheck} />
          </td>
          <td style={{ textAlign: "center", color: "#228B22" }}>
            <FontAwesomeIcon icon={faCheck} />
          </td>
          <td style={{ textAlign: "center", color: "#228B22" }}>
            <FontAwesomeIcon icon={faCheck} />
          </td>
        </tr>
        <tr>
          <td>Real time whiteboard / post-it</td>
          <td style={{ textAlign: "center", color: "#228B22" }}>
            <FontAwesomeIcon icon={faCheck} />
          </td>
          <td style={{ textAlign: "center", color: "#228B22" }}>
            <FontAwesomeIcon icon={faCheck} />
          </td>
          <td style={{ textAlign: "center", color: "#228B22" }}>
            <FontAwesomeIcon icon={faCheck} />
          </td>
        </tr>
        <tr>
          <td>Webcam sharing</td>
          <td style={{ textAlign: "center", color: "#228B22" }}>
            <FontAwesomeIcon icon={faCheck} />
          </td>
          <td style={{ textAlign: "center", color: "#228B22" }}>
            <FontAwesomeIcon icon={faCheck} />
          </td>
          <td style={{ textAlign: "center", color: "#228B22" }}>
            <FontAwesomeIcon icon={faCheck} />
          </td>
        </tr>
        <tr>
          <td>Simultaneous screen share</td>
          <td style={{ textAlign: "center", color: "#228B22" }}>
            <FontAwesomeIcon icon={faCheck} />
          </td>
          <td style={{ textAlign: "center", color: "#228B22" }}>
            <FontAwesomeIcon icon={faCheck} />
          </td>
          <td style={{ textAlign: "center", color: "#228B22" }}>
            <FontAwesomeIcon icon={faCheck} />
          </td>
        </tr>
        <tr>
          <td>Realistic avatar from selfie</td>
          <td style={{ textAlign: "center", color: "#228B22" }}>
            <FontAwesomeIcon icon={faCheck} />
          </td>
          <td style={{ textAlign: "center", color: "#228B22" }}>
            <FontAwesomeIcon icon={faCheck} />
          </td>
          <td style={{ textAlign: "center", color: "#228B22" }}>
            <FontAwesomeIcon icon={faCheck} />
          </td>
        </tr>
        <tr>
          <td>Avatar outfits</td>
          <td style={{ textAlign: "center", color: "#228B22" }}>
            <FontAwesomeIcon icon={faCheck} />
          </td>
          <td style={{ textAlign: "center", color: "#228B22" }}>
            <FontAwesomeIcon icon={faCheck} />
          </td>
          <td style={{ textAlign: "center", color: "#228B22" }}>
            <FontAwesomeIcon icon={faCheck} />
          </td>
        </tr>
        <tr>
          <td>Breakout room</td>
          <td style={{ textAlign: "center", color: "#228B22" }}>
            <FontAwesomeIcon icon={faCheck} />
          </td>
          <td style={{ textAlign: "center", color: "#228B22" }}>
            <FontAwesomeIcon icon={faCheck} />
          </td>
          <td style={{ textAlign: "center", color: "#228B22" }}>
            <FontAwesomeIcon icon={faCheck} />
          </td>
        </tr>
        <tr>
          <td>Permanent room</td>
          <td style={{ textAlign: "center", color: "#228B22" }}>
            <FontAwesomeIcon icon={faCheck} />
          </td>
          <td style={{ textAlign: "center", color: "#228B22" }}>
            <FontAwesomeIcon icon={faCheck} />
          </td>
          <td style={{ textAlign: "center", color: "#228B22" }}>
            <FontAwesomeIcon icon={faCheck} />
          </td>
        </tr>
        <tr>
          <td>Moderated meeting / raise hand</td>
          <td style={{ textAlign: "center", color: "#228B22" }}>
            <FontAwesomeIcon icon={faCheck} />
          </td>
          <td style={{ textAlign: "center", color: "#228B22" }}>
            <FontAwesomeIcon icon={faCheck} />
          </td>
          <td style={{ textAlign: "center", color: "#228B22" }}>
            <FontAwesomeIcon icon={faCheck} />
          </td>
        </tr>
        {/* <tr>
              <td>Upload documents</td>
              <td style={{ textAlign: "center" }}>—</td>
              <td style={{ textAlign: "center", color: "#228B22" }}>
                <FontAwesomeIcon icon={faCheck} />
              </td>
              <td style={{ textAlign: "center", color: "#228B22" }}>
                <FontAwesomeIcon icon={faCheck} />
              </td>
            </tr> */}
        <tr>
          <td className="discreet">
            *A licensed user can create meetings and project rooms. They can
            invite users and free guests in any room.
          </td>
          <td style={{ border: "none" }}></td>
          <td style={{ border: "none" }}></td>
          <td style={{ border: "none" }}></td>
        </tr>
      </tbody>
    </table>
  </Container>
);

export default Pricing;
