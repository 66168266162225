import React from "react";

interface Props {
  name: string;
  avatarSrc: string;
  outfit: string | null;
  avatarHeight?: AvatarHeight;
}

const AvatarModelViewer: React.FC<Props> = ({
  name,
  avatarSrc,
  avatarHeight = "MEDIUM",
  outfit,
}) => {
  let url = `/avatars/preview.html?v=9&show=head&avatarSrc=${avatarSrc}&avatarHeight=${avatarHeight}`;
  if (outfit) url += `&outfit=${outfit}`;
  return (
    <iframe
      key={url}
      className="avatarPreview"
      title={name}
      style={{
        borderRadius: "99999px",
        display: "block",
      }}
      src={url}
    ></iframe>
  );
};

export default AvatarModelViewer;
