import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";
import { ReactComponent as CloseSvg } from "../../assets/CloseSvg.svg";
import { ReactComponent as Plus } from "../../assets/PlusCircle.svg";
import {
  Button,
  FormControl,
  MenuItem,
  Modal,
  Select,
  Container,
  Box,
  OutlinedInput,
  Typography,
  FormHelperText,
  IconButton,
  Divider,
} from "@mui/material";

import { FormattedMessage, useIntl } from "react-intl";
import { useFormik } from "formik";
import { loader } from "graphql.macro";
import { useMutation } from "@apollo/client";
import Error from "../Error";
import { ReactComponent as CheckSvg } from "../../assets/modalCheckSvg.svg";
import { NavLink } from "react-router-dom";

const ADD_USER_TO_ORGANIZATION = loader(
  "../../schema/mutation/AddUserToOrganization.graphql"
);

interface Props {
  organizationId: string;
  seatsQuotaReachedModal?: boolean;
  freeToBusinessModal?: boolean;
}

interface Data {
  addUserToOrganization: Organization;
}

interface FormValues {
  email: string;
  role: string;
  membershipType: string;
}

const AddMemberModal: React.FC<Props> = ({
  organizationId,
  seatsQuotaReachedModal = false,
  freeToBusinessModal = false,
}) => {
  const intl = useIntl();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const initialValues: FormValues = {
    email: "",
    role: "MEMBER",
    membershipType: "INTERNAL",
  };

  const [addUserToOrganization, { error, reset }] = useMutation<
    Data,
    FormValues
  >(ADD_USER_TO_ORGANIZATION, {
    onCompleted: handleClose,
  });

  const formik = useFormik({
    initialValues,
    validate: (values: FormValues) => {
      const errors: Partial<FormValues> = {};

      if (!values.email) {
        errors.email = intl.formatMessage({
          id: "errors.required",
          defaultMessage: "Required",
        });
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
      ) {
        errors.email = intl.formatMessage({
          id: "errors.invalid-email-address",
          defaultMessage: "Invalid email address",
        });
      }

      return errors;
    },
    onSubmit: (values, { setSubmitting }) => {
      const data = {
        ...values,
        fullname: "",
        organizationId: organizationId,
      };
      addUserToOrganization({
        variables: data,
      }).catch(() => {
        setSubmitting(false);
      });
    },
  });

  useEffect(() => {
    if (!show) {
      formik.resetForm();
      reset();
    }
    // eslint-disable-next-line
  }, [show]);

  return (
    <>
      <Button onClick={handleShow} startIcon={<Plus />} variant="contained">
        <FormattedMessage
          id="manage-organization.add-a-user"
          defaultMessage="Add a user"
        />
      </Button>

      <Modal open={show} onClose={handleClose}>
        <>
          {freeToBusinessModal && (
            <Box
              className={styles.dialog}
              sx={{
                bgcolor: "background.paper",
              }}
            >
              <CheckSvg />
              <Typography className={styles.title}>
                <FormattedMessage
                  id="actionMovingToBusiness"
                  defaultMessage="Move to Business plan"
                />
              </Typography>

              <Typography className={styles.secondaryText}>
                <FormattedMessage
                  id="actionMovingToBusinessText"
                  defaultMessage="Your plan allows you to have only 4 members in your organization. To add more members and unlock new features, move to the Business plan!"
                />
              </Typography>
              <Box className={styles.actions} component="form">
                <Button
                  onClick={handleClose}
                  className={styles.cancelButton}
                  variant="contained"
                  color="secondary"
                >
                  <FormattedMessage
                    id="deleteModalBackButton"
                    defaultMessage="Back to Organization"
                  />
                </Button>
                <NavLink to="/pricing">
                  <Button
                    className={styles.validateButton}
                    type="button"
                    variant="contained"
                  >
                    <FormattedMessage
                      id="actions.letsgo"
                      defaultMessage="Let's go"
                    />
                  </Button>
                </NavLink>
              </Box>
            </Box>
          )}

          {seatsQuotaReachedModal && (
            <Box
              className={styles.dialog}
              sx={{
                bgcolor: "background.paper",
              }}
            >
              <CheckSvg />
              <Typography className={styles.title}>
                <FormattedMessage
                  id="seatsQuotaReachedTitle"
                  defaultMessage="Maximum number of members reached"
                />
              </Typography>

              <Typography className={styles.secondaryText}>
                <FormattedMessage
                  id="seatsQuotaReachedText"
                  defaultMessage="You reached the maximum number of members in your organization with your current plan. Do you want to add more members?"
                />
              </Typography>
              <Box className={styles.actions} component="form">
                <Button
                  onClick={handleClose}
                  className={styles.cancelButton}
                  variant="contained"
                  color="secondary"
                >
                  <FormattedMessage
                    id="deleteModalBackButton"
                    defaultMessage="Back to Organization"
                  />
                </Button>
                <NavLink to="/pricing">
                  <Button
                    className={styles.validateButton}
                    type="button"
                    variant="contained"
                  >
                    <FormattedMessage
                      id="action-delete.yes"
                      defaultMessage="Yes"
                    />
                  </Button>
                </NavLink>
              </Box>
            </Box>
          )}

          {!freeToBusinessModal && !seatsQuotaReachedModal ? (
            <Container
              disableGutters
              maxWidth={false}
              className={styles.container}
              sx={{
                bgcolor: "background.paper",
              }}
            >
              <Typography className={styles.title}>
                <FormattedMessage
                  id="manage-organization.add-a-user"
                  defaultMessage="Add a user"
                />
                <IconButton onClick={handleClose}>
                  <CloseSvg />
                </IconButton>
              </Typography>

              <Box
                component="form"
                id="createMember"
                onSubmit={formik.handleSubmit}
              >
                <Box sx={{ p: 3 }}>
                  <Error error={error} />
                  <Box className={styles.inlineField}>
                    <Typography className={styles.text}>
                      <FormattedMessage
                        id="manage-organization.add-email-address"
                        defaultMessage="Add your invitee email address"
                      />
                    </Typography>
                    <FormControl fullWidth sx={{ maxWidth: "250px" }}>
                      <OutlinedInput
                        placeholder="Email"
                        name="email"
                        type="email"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.email}
                        error={
                          formik.touched.email && Boolean(formik.errors.email)
                        }
                        required
                      />
                      <FormHelperText
                        sx={{ position: "absolute", top: "35px" }}
                      >
                        {formik.touched.email && formik.errors.email}
                      </FormHelperText>
                    </FormControl>
                  </Box>
                  <Box className={styles.inlineField}>
                    <Typography className={styles.text}>
                      <FormattedMessage
                        id="manage-organization.select-role"
                        defaultMessage="Select user role"
                      />
                    </Typography>
                    <FormControl fullWidth sx={{ maxWidth: "250px" }}>
                      <Select
                        name="role"
                        id="role"
                        value={formik.values.role}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        aria-describedby="roleHelpBlock"
                      >
                        <MenuItem value="MEMBER">
                          {intl.formatMessage({
                            id: "Member",
                            defaultMessage: "Member",
                          })}
                        </MenuItem>
                        <MenuItem value="ADMIN">
                          {intl.formatMessage({
                            id: "Admin",
                            defaultMessage: "Admin",
                          })}
                        </MenuItem>
                      </Select>
                    </FormControl>
                    <Typography className={styles.explanation}>
                      <FormattedMessage
                        id="manage-organization.select-role-message"
                        defaultMessage="Admin role can add new members."
                      />
                    </Typography>
                  </Box>
                  <Box className={styles.inlineField} sx={{ mb: 0 }}>
                    <Typography className={styles.text}>
                      <FormattedMessage
                        id="membership-type"
                        defaultMessage="Membership type"
                      />
                    </Typography>
                    <FormControl fullWidth sx={{ maxWidth: "250px" }}>
                      <Select
                        name="membershipType"
                        id="membershipType"
                        value={formik.values.membershipType}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        // aria-describedby="membershipTypeHelpBlock"
                      >
                        <MenuItem value="INTERNAL">
                          {intl.formatMessage({
                            id: "Internal",
                            defaultMessage: "Internal",
                          })}
                        </MenuItem>
                        <MenuItem value="EXTERNAL">
                          {intl.formatMessage({
                            id: "External",
                            defaultMessage: "External",
                          })}
                        </MenuItem>
                      </Select>

                      {/* <Typography
                        id="membershipTypeHelpBlock"
                        sx={{ maxWidth: "250px" }}
                        className={styles.text}
                      >
                        <FormattedMessage
                          id="manage-organization.select-membership-type-message"
                          defaultMessage="Some rooms may only be visible to internal users. External users won't see those rooms."
                        />
                      </Typography> */}
                    </FormControl>
                  </Box>
                </Box>
              </Box>
              <Divider />
              <Box className={styles.modalFooter}>
                <Button
                  onClick={handleClose}
                  variant="outlined"
                  color="secondary"
                >
                  <FormattedMessage
                    id="actions.cancel"
                    defaultMessage="Cancel"
                  />
                </Button>

                <Button
                  type="submit"
                  variant="contained"
                  form="createMember"
                  disabled={formik.isSubmitting}
                >
                  <FormattedMessage id="actions.add" defaultMessage="Add" />
                </Button>
              </Box>
            </Container>
          ) : null}
        </>
      </Modal>
    </>
  );
};

export default AddMemberModal;
