export const MAX_AVATARS = 11;
export const MAX_USERS_PER_ROOM_FOR_FREE = 4;
export const NUMBER_OF_SEATS_FOR_FREE = 4;
export const colors = {
  deepBlue: "#203b54",
  darkBlue: "#364f65",
  greyBlue: "#4d6276",
  purple: "#931ffa",
  purpleDark: "#8005ed",
  dark: "#1C1C1C",
  pink: "#eee8f0", // $pink
  backgroundCustomPink: "rgba(207, 160, 223, 0.4)", // $background-custom-pink
  lavender: "#EEE3FF",
  lightBlue: "#E3E9FF",
  lightAqua: "#E3F5FF",
  lightCyan: "#E3FFFC",
  lightGreen: "#ECFFE3",
  customGrey: "#6b7280",
  grey: "#F9FAFB", // $form-gray
  border: "#D1D5DB",
  lightGreyAlt: "#d1d5db", // $light-grey-alt
  red: "#dc3545", // used for LeaveRoomButton
};
export const FEATURES = {
  CARBON_CREDITS: false,
  NOTIFICATIONS: false,
  ROOMS_PAGE: false,
  SUPPORT_CHAT: false,
};
export const rolesThatCanManage = ["ADMIN", "OWNER"];

export let OPENAI_API_ENDPOINT = `/openai`;
if (process.env.NODE_ENV === "production") {
  OPENAI_API_ENDPOINT = `https://record.synantoo.app/openai`;
}
