import React from "react";
import { FormattedMessage } from "react-intl";
import LogInButton from "./LogInButton";
import { Stack, Typography } from "@mui/material";

const PleaseLogIn: React.FC = () => (
  <Stack
    direction="column"
    justifyContent="center"
    alignItems="center"
    flexGrow={1}
    spacing={2}
  >
    <Typography sx={{ textAlign: "center", fontSize: "1.25rem" }}>
      <FormattedMessage
        id="errors.please-login"
        defaultMessage="Please log in first..."
      />
    </Typography>
    <LogInButton />
  </Stack>
);

export default PleaseLogIn;
