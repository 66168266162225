import { useState } from "react";
import styles from "../../assets/stylesheets/dialog.module.scss";
import {
  Box,
  Button,
  IconButton,
  Modal,
  OutlinedInput,
  Tooltip,
  Typography,
} from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { ReactComponent as DeleteSvg } from "../../assets/deleteMemberSvg.svg";
import { ReactComponent as Trash } from "../../assets/Trash.svg";
import type { ApiKey } from "./APIKeys";

interface Props {
  apikey: ApiKey;
  onCompleted: () => void;
}

export const DeleteKeyButton: React.FC<Props> = ({ apikey, onCompleted }) => {
  const intl = useIntl();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const onValidate = () => {
    fetch(`/rapi/apikeys/${apikey.id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          onCompleted();
          handleClose();
        } else {
          console.error("Error removing the api key", response);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <>
      <Tooltip
        title={intl.formatMessage({
          id: "apikeys.revoke-key",
          defaultMessage: "Revoke key",
        })}
      >
        <IconButton onClick={handleShow} size="small">
          <Trash />
        </IconButton>
      </Tooltip>
      <Modal open={show}>
        <Box
          className={styles.dialog}
          sx={{
            bgcolor: "background.paper",
          }}
        >
          <>
            <DeleteSvg />
            <Typography className={styles.title}>
              <FormattedMessage
                id="actions.revoke-secret-key"
                defaultMessage="Revoke secret key"
              />
            </Typography>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
              }}
            >
              <Typography className={styles.secondaryText}>
                <FormattedMessage
                  id="actions.revoke-secret-key-confirmation-paragraph"
                  defaultMessage="This API key will immediately be disabled. API requests made using this key will be rejected, which could cause any systems still depending on it to break. Once revoked, you'll no longer be able to view or modify this API key."
                />
              </Typography>
              <OutlinedInput
                sx={{ flexGrow: 1 }}
                type="text"
                readOnly
                value={"************" + apikey.key_suffix}
              />
            </Box>
            <Box className={styles.actions}>
              <Button
                onClick={handleClose}
                className={styles.cancelButton}
                variant="contained"
                color="secondary"
              >
                <FormattedMessage id="actions.cancel" defaultMessage="Cancel" />
              </Button>
              <Button
                onClick={onValidate}
                className={styles.validateButton}
                type="submit"
                variant="contained"
              >
                <FormattedMessage id="action-delete.yes" defaultMessage="Yes" />
              </Button>
            </Box>
          </>
        </Box>
      </Modal>
    </>
  );
};
