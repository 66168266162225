import React, { useState } from "react";
import styles from "../../../../assets/stylesheets/dialog.module.scss";

import {
  Box,
  Button,
  IconButton,
  Modal,
  Tooltip,
  Typography,
} from "@mui/material";
import { loader } from "graphql.macro";
import { Mutation } from "@apollo/client/react/components";
import Error from "../../../Error";
import { Formik, FormikErrors } from "formik";
import { FormattedMessage, useIntl } from "react-intl";
import { ReactComponent as Trash } from "../../../../assets/Trash.svg";
import { ReactComponent as DeleteSvg } from "../../../../assets/deleteMemberSvg.svg";

const GET_AVATARS_QUERY = loader("../../../../schema/query/GetAvatars.graphql");
const DELETE_AVATAR = loader(
  "../../../../schema/mutation/DeleteAvatar.graphql"
);

interface Props {
  id: string;
}

interface Data {
  deleteAvatar: Room;
}

interface FormValues {}

const DeleteAvatarButton: React.FC<Props> = ({ id }) => {
  const intl = useIntl();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const initialValues: FormValues = {};

  return (
    <>
      <Tooltip
        title={intl.formatMessage({
          id: "actions.delete",
          defaultMessage: "Delete",
        })}
      >
        <IconButton onClick={handleShow}>
          <Trash />
        </IconButton>
      </Tooltip>

      <Modal open={show} onClose={handleClose}>
        <Box
          className={styles.dialog}
          sx={{
            minWidth: "350px",
            bgcolor: "background.paper",
          }}
        >
          <Mutation<Data, FormValues>
            mutation={DELETE_AVATAR}
            onCompleted={handleClose}
            refetchQueries={[
              { query: GET_AVATARS_QUERY, variables: { status: "COMPLETED" } },
              { query: GET_AVATARS_QUERY, variables: {} },
            ]}
          >
            {(deleteAvatar, { error }) => (
              <Formik
                initialValues={initialValues}
                validate={(values: FormValues) => {
                  const errors: FormikErrors<FormValues> = {};
                  return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                  let data = {
                    id: id,
                  };

                  deleteAvatar({
                    variables: data,
                  })
                    .then(() => {
                      // @ts-ignore-line
                      if (typeof _paq !== "undefined")
                        // @ts-ignore-line
                        _paq.push([
                          "trackEvent",
                          "Interactions",
                          "Delete avatar",
                        ]);
                    })
                    .catch(() => {
                      setSubmitting(false);
                    });
                }}
              >
                {({ handleSubmit, isSubmitting }) => (
                  <>
                    <DeleteSvg />
                    <Typography className={styles.title}>
                      <FormattedMessage
                        id="actions.delete-avatar"
                        defaultMessage="Delete avatar"
                      />
                    </Typography>

                    <Typography className={styles.secondaryText}>
                      <FormattedMessage
                        id="actions.delete-confirmation"
                        defaultMessage="Are you sure you want to delete?"
                      />
                    </Typography>
                    <Error error={error} />
                    <Box
                      className={styles.actions}
                      component="form"
                      onSubmit={handleSubmit}
                    >
                      <Button
                        onClick={handleClose}
                        className={styles.cancelButton}
                        variant="contained"
                        color="secondary"
                      >
                        <FormattedMessage
                          id="actions.back"
                          defaultMessage="Back"
                        />
                      </Button>
                      <Button
                        className={styles.validateButton}
                        type="submit"
                        disabled={isSubmitting}
                        variant="contained"
                      >
                        <FormattedMessage
                          id="action-delete.yes"
                          defaultMessage="Yes"
                        />
                      </Button>
                    </Box>
                  </>
                )}
              </Formik>
            )}
          </Mutation>
        </Box>
      </Modal>
    </>
  );
};

export default DeleteAvatarButton;
