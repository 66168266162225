import React, { useCallback, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { getRoomURL } from "../../../../lib/room";
import { loadGoogleAPI } from "../../../../lib/load-google-api";
import GoogleButton from "../../../GoogleButton";
import {
  Alert,
  Box,
  Button,
  Container,
  IconButton,
  Modal,
  Tooltip,
  Typography,
} from "@mui/material";
import { ReactComponent as Calendar } from "../../../../assets/Calendar.svg";
import { ReactComponent as CloseSvg } from "../../../../assets/CloseSvg.svg";
import styles from "../../../../assets/stylesheets/modal.module.scss";

interface Props {
  room: Room;
}

// Client ID and API key from the Developer Console
const CLIENT_ID = process.env.REACT_APP_GAPI_CLIENT_ID;
const API_KEY = process.env.REACT_APP_GAPI_API_KEY;

// Array of API discovery doc URLs for APIs used by the quickstart
const DISCOVERY_DOCS = [
  "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest",
];

// Authorization scopes required by the API; multiple scopes can be
// included, separated by spaces.
// const SCOPES = "https://www.googleapis.com/auth/calendar";
const SCOPES = "https://www.googleapis.com/auth/calendar.events";

const AddMeetingToMyCalendar: React.FC<Props> = ({ room }) => {
  const intl = useIntl();
  const [show, setShow] = useState(false);
  const [gapiLoaded, setGapiLoaded] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [eventCreated, setEventCreated] = useState(false);
  const [eventHtmlLink, setEventHtmlLink] = useState("");
  const [showError, setShowError] = useState(false);
  const [errorReason, setErrorReason] = useState("");

  /**
   *  Sign in the user upon button click.
   */
  const handleAuthClick = useCallback(() => {
    // @ts-ignore
    gapi.auth2.getAuthInstance().signIn();
    // For debugging, comment the line above and just use setIsSignedIn(true)
  }, []);

  /**
   *  Sign out the user upon button click.
   */
  const handleSignoutClick = useCallback(() => {
    // @ts-ignore
    gapi.auth2.getAuthInstance().signOut();
    // For debugging, comment the line above and just use setIsSignedIn(false)
    setIsSubmitting(false);
    setEventCreated(false);
    setEventHtmlLink("");
    setShowError(false);
    setErrorReason("");
  }, []);

  /**
   *  Called when the signed in status changes, to update the UI
   *  appropriately. After a sign-in, the API is called.
   */
  const updateSigninStatus = useCallback((isSignedIn: boolean) => {
    setIsSignedIn(isSignedIn);
  }, []);

  /**
   *  Initializes the API client library and sets up sign-in state
   *  listeners.
   */
  const initClient = useCallback(() => {
    // @ts-ignore
    gapi.client
      .init({
        apiKey: API_KEY,
        clientId: CLIENT_ID,
        discoveryDocs: DISCOVERY_DOCS,
        scope: SCOPES,
      })
      .then(
        function () {
          // Listen for sign-in state changes.
          // @ts-ignore
          gapi.auth2.getAuthInstance().isSignedIn.listen(updateSigninStatus);

          // Handle the initial sign-in state.
          // @ts-ignore
          updateSigninStatus(gapi.auth2.getAuthInstance().isSignedIn.get());
        },
        // @ts-ignore
        function (error) {
          console.error(JSON.stringify(error, null, 2));
        }
      );
  }, [updateSigninStatus]);

  /**
   *  On load, called to load the auth2 library and API client library.
   */
  const handleClientLoad = useCallback(() => {
    // @ts-ignore
    gapi.load("client:auth2", initClient);
  }, [initClient]);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
    // @ts-ignore-line
    if (typeof _paq !== "undefined")
      // @ts-ignore-line
      _paq.push(["trackEvent", "Interactions", "Add meeting to my calendar"]);
  };

  useEffect(() => {
    if (show && !gapiLoaded) {
      // if (process.env.NODE_ENV === "production") {
      loadGoogleAPI().then(() => {
        handleClientLoad();
        setGapiLoaded(true);
      });
      // }
    }
  }, [show, gapiLoaded, handleClientLoad]);

  const onAddToGoogleCalendar = useCallback(() => {
    setIsSubmitting(true);
    const roomURL = getRoomURL(room);
    const event = {
      summary: room.name || "",
      location: roomURL,
      description: room.description || "",
      start: {
        dateTime: room.startDate,
        timeZone: room.timeZone,
      },
      end: {
        dateTime: room.endDate,
        timeZone: room.timeZone,
      },
      attendees: [],
      // conferenceData: {
      //   conferenceId: room.slug,
      //   conferenceSolution: {
      //     key: { type: "addOn" },
      //     name: "Kizwork",
      //   },
      //   entryPoints: [{ entryPointType: "video", uri: roomURL }],
      // },
    };
    if (
      room.user.email &&
      room.user.email !== "Can't access email of another user"
    ) {
      // @ts-ignore
      event.attendees.push({
        email: room.user.email,
        responseStatus: "accepted",
      });
    }
    // if (process.env.NODE_ENV === "production") {
    // @ts-ignore
    const request = gapi.client.calendar.events.insert({
      calendarId: "primary",
      resource: event,
    });
    request.execute(
      (event: {
        htmlLink: string;
        code?: number;
        data?: [{ reason: string }];
        message?: string;
      }) => {
        if (event.htmlLink) {
          setEventCreated(true);
          setEventHtmlLink(event.htmlLink);
          // @ts-ignore-line
          if (typeof _paq !== "undefined")
            // @ts-ignore-line
            _paq.push([
              "trackEvent",
              "Interactions",
              "Add meeting to my calendar success",
            ]);
        } else {
          // https://developers.google.com/calendar/api/guides/errors
          // event can be an error:
          // {
          //   "code": 403,
          //   "data": [
          //     {
          //       "message": "Insufficient Permission",
          //       "domain": "global",
          //       "reason": "insufficientPermissions"
          //     }
          //   ],
          //   "message": "Request had insufficient authentication scopes.",
          //   "error": {
          //     "code": 403,
          //     "data": [
          //       {
          //         "message": "Insufficient Permission",
          //         "domain": "global",
          //         "reason": "insufficientPermissions"
          //       }
          //     ],
          //     "message": "Request had insufficient authentication scopes."
          //   }
          // }

          // or if the user revoked the access from https://myaccount.google.com/security-checkup/3
          // {
          //   "code": 401,
          //   "data": [
          //     {
          //       "message": "Invalid Credentials",
          //       "domain": "global",
          //       "reason": "authError",
          //       "location": "Authorization",
          //       "locationType": "header"
          //     }
          //   ],
          //   "message": "Request had invalid authentication credentials. Expected OAuth 2 access token, login cookie or other valid authentication credential. See https://developers.google.com/identity/sign-in/web/devconsole-project.",
          //   "error": {
          //     "code": 401,
          //     "data": [
          //       {
          //         "message": "Invalid Credentials",
          //         "domain": "global",
          //         "reason": "authError",
          //         "location": "Authorization",
          //         "locationType": "header"
          //       }
          //     ],
          //     "message": "Request had invalid authentication credentials. Expected OAuth 2 access token, login cookie or other valid authentication credential. See https://developers.google.com/identity/sign-in/web/devconsole-project."
          //   }
          // }
          setShowError(true);
          if (
            (event.code &&
              event.code === 403 &&
              event.data &&
              event.data.length > 0 &&
              event.data[0].reason === "insufficientPermissions") ||
            (event.code && event.code === 401)
          ) {
            setErrorReason("insufficientPermissions");
          } else if (event.message) {
            setErrorReason(event.message);
          }
          console.error(JSON.stringify(event, null, 2));
          // @ts-ignore-line
          if (typeof _paq !== "undefined")
            // @ts-ignore-line
            _paq.push([
              "trackEvent",
              "Interactions",
              "Add meeting to my calendar failed",
            ]);
        }
      }
    );
    // }
    // if (process.env.NODE_ENV !== "production") {
    //   window.alert(JSON.stringify(event, null, 2));
    //   setEventCreated(true);
    // }
  }, [room]);

  return (
    <>
      <Tooltip
        title={intl.formatMessage({
          id: "actions.add-meeting-to-my-calendar",
          defaultMessage: "Add meeting to my calendar",
        })}
      >
        <IconButton size="small" type="button" onClick={handleShow}>
          <Calendar />
        </IconButton>
      </Tooltip>

      <Modal open={show} onClose={handleClose}>
        <Container
          disableGutters
          maxWidth={false}
          className={styles.container}
          sx={{
            bgcolor: "background.paper",
          }}
        >
          <Typography className={styles.title}>
            <FormattedMessage
              id="actions.add-meeting-to-my-calendar"
              defaultMessage="Add meeting to my calendar"
            />
            <IconButton onClick={handleClose}>
              <CloseSvg />
            </IconButton>
          </Typography>

          <Box sx={{ p: 3, display: "flex", flexDirection: "column", gap: 2 }}>
            {isSignedIn ? (
              <>
                <Box sx={{ textAlign: "center" }}>
                  <GoogleButton onClick={handleSignoutClick}>
                    <FormattedMessage
                      id="actions.sign-out-from-google"
                      defaultMessage="Sign out from Google"
                    />
                  </GoogleButton>
                </Box>
                {eventCreated && (
                  <>
                    <Alert severity="success">
                      <FormattedMessage
                        id="actions.event-created"
                        defaultMessage="The meeting has been added to your calendar."
                      />
                    </Alert>
                    <Box sx={{ textAlign: "center" }}>
                      <a href={eventHtmlLink} target="_blank" rel="noreferrer">
                        <FormattedMessage
                          id="actions.see-event"
                          defaultMessage="See meeting in Google Calendar"
                        />
                      </a>
                    </Box>
                  </>
                )}
                {showError && (
                  <Alert severity="warning">
                    {errorReason === "insufficientPermissions" ? (
                      <FormattedMessage
                        id="actions.event-failed-to-create-insufficient-permissions"
                        defaultMessage="The meeting creation failed. Did you check the permission to add events in the Google consent screen? Try to Sign out and Sign in again."
                      />
                    ) : (
                      <FormattedMessage
                        id="actions.event-failed-to-create"
                        defaultMessage="The meeting creation failed. You may try again later. Google replied with this error: {reason}"
                        values={{ reason: errorReason }}
                      />
                    )}
                  </Alert>
                )}
                {!eventCreated && !showError && (
                  <Button
                    type="button"
                    sx={{ width: "100%" }}
                    disabled={isSubmitting}
                    onClick={onAddToGoogleCalendar}
                    variant="contained"
                  >
                    <FormattedMessage
                      id="actions.add-meeting-to-google-calendar"
                      defaultMessage="Add meeting to Google Calendar"
                    />
                  </Button>
                )}
              </>
            ) : (
              <>
                <Box>
                  <FormattedMessage
                    id="actions.first-connect-with-your-google-account"
                    defaultMessage="To add the meeting to your Google Calendar, please authorize Kizwork to access your calendar. Kizwork will only use this authorization to add new events to your calendar."
                  />
                </Box>
                <Box sx={{ textAlign: "center" }}>
                  {gapiLoaded && (
                    <GoogleButton onClick={handleAuthClick}>
                      <FormattedMessage
                        id="actions.sign-in-with-google-and-authorize"
                        defaultMessage="Sign in with Google and authorize access to my calendar"
                      />
                    </GoogleButton>
                  )}
                </Box>
              </>
            )}
          </Box>
        </Container>
      </Modal>
    </>
  );
};

export default AddMeetingToMyCalendar;
