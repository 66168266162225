import { FC } from "react";
import { loader } from "graphql.macro";
import { Mutation } from "@apollo/client/react/components";
import { useQuery } from "@apollo/client";
import MenuItem from "@mui/material/MenuItem";
import ListSubheader from "@mui/material/ListSubheader";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import { Formik } from "formik";
import { FormattedMessage, useIntl } from "react-intl";
import Error from "./Error";
import AvatarOutfitSelect, {
  getDefaultOutfit,
} from "./MyRooms/components/AvatarCard/AvatarOutfitSelect";
import { defaultAvatars } from "./MyRooms/components/DefaultAvatarSelect/DefaultAvatarSelect";
import AvatarModelViewer from "./AvatarModelViewer";

const GET_AVATARS_QUERY = loader("../schema/query/GetAvatars.graphql");
const SAVE_AVATAR_CHOICE = loader(
  "../schema/mutation/SaveAvatarChoice.graphql"
);
interface Data {
  avatars: Array<Avatar>;
  me: {
    selectedAvatar: string | null;
  };
}

interface Variables {
  status: string;
}

interface UpdateChoiceData {
  saveAvatarChoice: Avatar;
}

interface UpdateChoiceFormValues {
  selectedAvatar: string;
}

interface Props {
  localStorageSelectedAvatar?: string | null;
  createNewAvatar?: boolean;
}

const setAvatarAndOutfit = (avatarSrc: string, avatarOutfit: string | null) => {
  const cameraRig = document.getElementById("cameraRig");
  if (cameraRig)
    cameraRig.setAttribute(
      "player-info",
      `avatarSrc:${avatarSrc};avatarOutfit:${avatarOutfit ?? ""}`
    );
};

export const getAvatarObj = (
  selectedAvatar: string | null,
  avatars: Array<Avatar>
): Avatar | null => {
  const selectedAvatarObj =
    selectedAvatar &&
    avatars.find(
      (avatar) => avatar.modelId && selectedAvatar.endsWith(avatar.modelId)
    );
  return selectedAvatarObj || null;
};

const getAvatarOutfit = (avatar: Avatar): string => {
  let outfit = avatar.selectedOutfit;
  if (!outfit) {
    outfit = getDefaultOutfit(avatar.gender === "FEMALE");
  }
  return outfit;
};

export const AvatarSelect: FC<Props> = ({
  localStorageSelectedAvatar = null,
  createNewAvatar = true,
}) => {
  const intl = useIntl();
  const { data, loading, error } = useQuery<Data, Variables>(
    GET_AVATARS_QUERY,
    { variables: { status: "COMPLETED" } }
  );
  if (loading) return null;
  if (error || !data) return <Error error={error} />;
  if (!data.avatars) {
    return null;
  }
  const defaultSelectedAvatar = "/avatars/pack3/man1.glb";
  let selectedAvatar = data.me
    ? data.me.selectedAvatar
    : localStorageSelectedAvatar;
  const selectedAvatarObj = getAvatarObj(selectedAvatar, data.avatars);
  const found =
    selectedAvatarObj ||
    (selectedAvatar && defaultAvatars.indexOf(selectedAvatar) > -1);
  if (!found) {
    selectedAvatar = defaultSelectedAvatar;
  }
  if (!selectedAvatar) {
    selectedAvatar = defaultSelectedAvatar;
  }
  const initialValues: UpdateChoiceFormValues = {
    selectedAvatar: selectedAvatar,
  };

  setAvatarAndOutfit(
    selectedAvatar,
    selectedAvatarObj ? getAvatarOutfit(selectedAvatarObj) : null
  );

  return (
    <Mutation<UpdateChoiceData, UpdateChoiceFormValues>
      mutation={SAVE_AVATAR_CHOICE}
    >
      {(saveAvatarChoice) => (
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          onSubmit={(values, { setSubmitting }) => {
            localStorage.setItem("avatarValue", values.selectedAvatar);
            const selectedAvatarObj = getAvatarObj(
              values.selectedAvatar,
              data.avatars
            );
            setAvatarAndOutfit(
              values.selectedAvatar,
              selectedAvatarObj ? getAvatarOutfit(selectedAvatarObj) : null
            );
            if (data.me) {
              saveAvatarChoice({
                variables: values,
              }).catch(() => {
                setSubmitting(false);
              });
            }
          }}
        >
          {({ values, handleChange, handleBlur, handleSubmit }) => {
            const handleChangeAndSubmit = (e: SelectChangeEvent) => {
              handleChange(e);
              // handleChange updates the values.selectedAvatar state asynchronously.
              // Calling handleSubmit right after worked everywhere excepted Safari macOS, just wait 200ms to be sure...
              setTimeout(() => handleSubmit(), 200);
            };
            const selectedAvatarObj = getAvatarObj(
              values.selectedAvatar,
              data.avatars
            );
            return (
              <>
                {selectedAvatarObj ? (
                  <AvatarOutfitSelect
                    key={selectedAvatarObj.id}
                    avatar={selectedAvatarObj}
                    showUpdateDeleteButtons={false}
                  />
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <AvatarModelViewer
                      name="Selected avatar"
                      avatarSrc={values.selectedAvatar}
                      outfit={null}
                    />
                  </Box>
                )}
                <Grid container rowSpacing={1}>
                  <Grid
                    item
                    xs={5}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <label id="selectedAvatar">
                      <FormattedMessage
                        id="avatars.change-avatar"
                        defaultMessage="Change avatar"
                      />
                    </label>
                  </Grid>
                  <Grid
                    item
                    xs={7}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <FormControl fullWidth>
                      <Select
                        id="selectedAvatar"
                        name="selectedAvatar"
                        onChange={handleChangeAndSubmit}
                        onBlur={handleBlur}
                        value={values.selectedAvatar}
                      >
                        {data.avatars.length > 0 && (
                          <ListSubheader>
                            {intl.formatMessage({
                              id: "avatar.my-avatars",
                              defaultMessage: "My avatars",
                            })}
                          </ListSubheader>
                        )}
                        {data.avatars.map((avatar) => (
                          <MenuItem
                            key={avatar.id}
                            value={`/files/${avatar.modelId}`}
                          >
                            {avatar.name}
                          </MenuItem>
                        ))}

                        <ListSubheader>
                          {intl.formatMessage({
                            id: "avatar.default-avatars",
                            defaultMessage: "Default avatars",
                          })}
                        </ListSubheader>
                        <MenuItem value="/avatars/pack3/man1.glb">
                          <FormattedMessage
                            id="select.man1"
                            defaultMessage="Man 1 (business)"
                          />
                        </MenuItem>
                        <MenuItem value="/avatars/pack3/woman1.glb">
                          <FormattedMessage
                            id="select.woman1"
                            defaultMessage="Woman 1 (business)"
                          />
                        </MenuItem>
                        <MenuItem value="/avatars/pack3/man_019.glb">
                          <FormattedMessage
                            id="select.man2"
                            defaultMessage="Man 2 (business casual)"
                          />
                        </MenuItem>
                        <MenuItem value="/avatars/pack3/woman_025.glb">
                          <FormattedMessage
                            id="select.woman2"
                            defaultMessage="Woman 2 (business casual)"
                          />
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  {createNewAvatar && (
                    <>
                      {data.me ? (
                        <>
                          <Grid
                            item
                            xs={5}
                            sx={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          ></Grid>

                          <Grid
                            item
                            xs={7}
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Button
                              variant="contained"
                              color="secondary"
                              href="/manage-avatars"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <FormattedMessage
                                id="avatars.create-new-avatar"
                                defaultMessage="Create new avatar"
                              />
                            </Button>
                          </Grid>
                        </>
                      ) : (
                        <Stack direction="row" justifyContent="center">
                          <Typography
                            sx={{
                              textAlign: "center",
                            }}
                          >
                            <FormattedMessage
                              id="avatars.login-to-create-avatar"
                              defaultMessage="Please log in to select your own avatar or create one."
                            />
                          </Typography>
                        </Stack>
                      )}
                    </>
                  )}
                </Grid>
              </>
            );
          }}
        </Formik>
      )}
    </Mutation>
  );
};
