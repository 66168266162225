import { useContext } from "react";
import {
  LangContext,
  supportedLanguages,
  supportedLanguagesWithLabel,
} from "../../I18nProvider";
import { Box, IconButton, Tooltip } from "@mui/material";
import { ReactComponent as EnSvg } from "../../assets/flags/gb.svg";
import { ReactComponent as FrSvg } from "../../assets/flags/fr.svg";
import styles from "../LanguageSwitcher/LanguageSwitcher.module.scss";

export const LanguageSwitcher = () => {
  const { setLanguage, language } = useContext(LangContext);
  return (
    <Box className={styles.langBtnGroup}>
      {supportedLanguages.map((lang) => (
        <Tooltip key={lang} title={supportedLanguagesWithLabel.get(lang)}>
          <IconButton
            onClick={() => setLanguage(lang)}
            key={lang}
            className={
              lang === language ? styles.langActiveBtn : styles.langBtn
            }
          >
            {lang === "en" ? <EnSvg /> : <FrSvg />}
          </IconButton>
        </Tooltip>
      ))}
    </Box>
  );
};
