import React from "react";
import { ApolloError } from "@apollo/client";
import { FormattedMessage } from "react-intl";

// Render runtime errors, including GraphQL errors and network errors.
//
// The error passed as a prop to this component is an Apollo Client
// 'QueryResult' object that has 'graphQLErrors' and 'networkError' properties.

interface Props {
  error: ApolloError | undefined;
}
const Error: React.FC<Props> = ({ error }) => {
  if (!error?.message) return null;

  const isNetworkError =
    error?.networkError?.message &&
    // @ts-expect-error Property 'statusCode' does not exist on type 'Error'. ts(2339)
    error?.networkError?.statusCode;

  const hasGraphQLErrors = error?.graphQLErrors?.length;

  let errorMessage;

  if (isNetworkError) {
    // @ts-expect-error Property 'statusCode' does not exist on type 'Error'. ts(2339)
    if (error.networkError.statusCode === 404) {
      errorMessage = <h3>404: Not Found</h3>;
    } else {
      errorMessage = (
        <>
          {/* @ts-expect-error Property 'statusCode' does not exist on type 'Error'. ts(2339) */}
          {error.networkError.statusCode === 502 ? (
            <div className="message">
              <FormattedMessage
                id="errors.network-error-try-again-later"
                defaultMessage="There was a network error, please try again in a few minutes."
              />
            </div>
          ) : (
            <code>
              {/* @ts-expect-error Property 'statusCode' does not exist on type 'Error'. ts(2339) */}
              {error.networkError.statusCode}: {error.networkError.message}
            </code>
          )}
        </>
      );
    }
  } else if (hasGraphQLErrors) {
    errorMessage = (
      <>
        <ul style={{ margin: 0 }}>
          {
            // @ts-expect-error Property 'details' does not exist on type 'GraphQLError'. ts(2339)
            error.graphQLErrors.map(({ message, details }, i) => (
              <li key={i}>
                <span className="message">
                  {message === "A member with this email already exists." ? (
                    <FormattedMessage
                      id="errors.a-member-with-this-email-already-exists"
                      defaultMessage="A member with this email already exists."
                    />
                  ) : (
                    message
                  )}
                </span>
                {details && (
                  <ul>
                    {Object.keys(details).map((key) => (
                      <li key={key}>
                        {key} {details[key]}
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))
          }
        </ul>
      </>
    );
  } else {
    errorMessage = (
      <>
        <h3>Whoops!</h3>
        <p>{error.message}</p>
      </>
    );
  }

  return <div className="errors">{errorMessage}</div>;
};

export default Error;
