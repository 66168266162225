import { Box, Button, FormControl, Typography } from "@mui/material";
import styles from "./index.module.scss";
import { FormattedMessage } from "react-intl";
import { ReactComponent as Lightning } from "../../../assets/Lightning.svg";
import { FC } from "react";

interface Props {
  handleShowAllPlans: any;
  plan: string;
}

export const PlanDetails: FC<Props> = ({ handleShowAllPlans, plan }) => {
  return (
    <>
      <Typography
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          mb: 5,
          textAlign: "center",
        }}
        className={styles.title}
      >
        <FormattedMessage id="payment-plan" defaultMessage="Plan details" />
      </Typography>

      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        minHeight="45vh"
      >
        {plan === "Business" && (
          <Box
            className={styles.typeContainer}
            p={4}
            sx={{
              border: "1px solid #E5E7EB",
              borderRadius: "16px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ width: "100%", mr: "80px" }}>
              <Typography
                className={styles.title}
                sx={{ textAlign: "start", fontSize: "20px" }}
                mb="16px"
              >
                <FormattedMessage
                  id="manage.organization-subscription-business"
                  defaultMessage="Business"
                />
              </Typography>
              <Typography className={styles.price} mb={3}>
                <FormattedMessage
                  tagName="span"
                  id="manage.organization-subscription-business-price"
                  defaultMessage="{currency}19"
                  values={{
                    currency: "$",
                  }}
                />
                <FormattedMessage id="per-month" defaultMessage="/month" />
              </Typography>

              <Typography className={styles.text}>
                <FormattedMessage
                  id="business-text"
                  defaultMessage="A plan that scales with your rapidly growing business."
                />
              </Typography>
            </Box>
            <Box sx={{ width: "100%" }}>
              <Box mb="18px" display="flex">
                <Lightning style={{ marginRight: "12px" }} />
                <Box display="flex" justifyContent="space-between" width="100%">
                  <Typography className={styles.text}>
                    <FormattedMessage
                      id="numberOfLicensedUsers"
                      defaultMessage="Number of licensed users"
                    />
                  </Typography>
                  <Typography className={styles.values}>
                    <FormattedMessage
                      id="licensed-values"
                      defaultMessage="1 to 20"
                    />
                  </Typography>
                </Box>
              </Box>
              <Box mb="18px" display="flex">
                <Lightning style={{ marginRight: "12px" }} />
                <Box display="flex" justifyContent="space-between" width="100%">
                  <Typography className={styles.text}>
                    <FormattedMessage
                      id="meetingTimeLimit"
                      defaultMessage="Meeting time limit"
                    />
                  </Typography>
                  <Typography className={styles.values}>
                    <FormattedMessage
                      id="limit-values"
                      defaultMessage="unlimited"
                    />
                  </Typography>
                </Box>
              </Box>
              <Box mb="18px" display="flex">
                <Lightning style={{ marginRight: "12px" }} />
                <Box display="flex" justifyContent="space-between" width="100%">
                  <Typography className={styles.text}>
                    <FormattedMessage
                      id="participants-values"
                      defaultMessage="Number of participants per room"
                    />
                  </Typography>

                  <Typography className={styles.values}>25</Typography>
                </Box>
              </Box>
              <Box mb="18px" display="flex">
                <Lightning style={{ marginRight: "12px" }} />
                <Box display="flex" justifyContent="space-between" width="100%">
                  <Typography className={styles.text}>
                    <FormattedMessage
                      id="guests-values"
                      defaultMessage="Free external guests per room"
                    />
                  </Typography>
                  <Typography className={styles.values}>14</Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        )}
        {plan === "Corporate" && (
          <Box
            className={styles.typeContainer}
            p={4}
            sx={{
              border: "1px solid #E5E7EB",
              borderRadius: "16px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ width: "100%", mr: "80px" }}>
              <Typography
                className={styles.title}
                sx={{ textAlign: "start", fontSize: "20px" }}
                mb="16px"
              >
                <FormattedMessage
                  id="manage.organization-subscription-corporate"
                  defaultMessage="Corporate"
                />
              </Typography>
              <Typography className={styles.price} mb={3}>
                <FormattedMessage
                  tagName="span"
                  id="special-price"
                  defaultMessage="Special price"
                />
              </Typography>

              <Typography className={styles.text}>
                <FormattedMessage
                  id="corporate-text"
                  defaultMessage="Contact us for a detailed consultation."
                />
              </Typography>
            </Box>
            <Box sx={{ width: "100%", maxWidth: "400px" }}>
              <Box mb="18px" display="flex">
                <Lightning style={{ marginRight: "12px" }} />
                <Box display="flex" justifyContent="space-between" width="100%">
                  <Typography className={styles.text}>
                    <FormattedMessage
                      id="numberOfLicensedUsers"
                      defaultMessage="Number of licensed users"
                    />
                  </Typography>
                  <Typography className={styles.values}>
                    <FormattedMessage
                      id="limit-values"
                      defaultMessage="unlimited"
                    />
                  </Typography>
                </Box>
              </Box>
              <Box mb="18px" display="flex">
                <Lightning style={{ marginRight: "12px" }} />
                <Box display="flex" justifyContent="space-between" width="100%">
                  <Typography className={styles.text}>
                    <FormattedMessage
                      id="meetingTimeLimit"
                      defaultMessage="Meeting time limit"
                    />
                  </Typography>
                  <Typography className={styles.values}>
                    <FormattedMessage
                      id="limit-values"
                      defaultMessage="unlimited"
                    />
                  </Typography>
                </Box>
              </Box>
              <Box mb="18px" display="flex">
                <Lightning style={{ marginRight: "12px" }} />
                <Box display="flex" justifyContent="space-between" width="100%">
                  <Typography className={styles.text}>
                    <FormattedMessage
                      id="participants-values"
                      defaultMessage="Number of participants per room"
                    />
                  </Typography>
                  <Typography className={styles.values}>25</Typography>
                </Box>
              </Box>
              <Box mb="18px" display="flex">
                <Lightning style={{ marginRight: "12px" }} />
                <Box display="flex" justifyContent="space-between" width="100%">
                  <Typography className={styles.text}>
                    <FormattedMessage
                      id="guests-values"
                      defaultMessage="Free external guests per room"
                    />
                  </Typography>
                  <Typography className={styles.values}>14</Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        )}
        {plan === "Starter" && (
          <Box
            className={styles.typeContainer}
            p={4}
            sx={{
              border: "1px solid #E5E7EB",
              borderRadius: "16px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ width: "100%", mr: "80px" }}>
              <Typography
                className={styles.title}
                sx={{ textAlign: "start", fontSize: "20px" }}
                mb="16px"
              >
                <FormattedMessage
                  id="manage.organization-subscription-starter"
                  defaultMessage="Starter"
                />
              </Typography>
              <Typography className={styles.price} mb={3}>
                <FormattedMessage
                  tagName="span"
                  id="manage.organization-subscription-starter-price"
                  defaultMessage="{currency}0"
                  values={{
                    currency: "$",
                  }}
                />
                <FormattedMessage id="per-month" defaultMessage="/month" />
              </Typography>

              <Typography className={styles.text}>
                <FormattedMessage
                  id="starter-text"
                  defaultMessage="The essentials to provide your best work for clients."
                />
              </Typography>
            </Box>
            <Box sx={{ width: "100%", maxWidth: "400px" }}>
              <Box mb="18px" display="flex">
                <Lightning style={{ marginRight: "12px" }} />
                <Box display="flex" justifyContent="space-between" width="100%">
                  <Typography className={styles.text}>
                    <FormattedMessage
                      id="numberOfLicensedUsers"
                      defaultMessage="Number of licensed users"
                    />
                  </Typography>
                  <Typography className={styles.values}>
                    <FormattedMessage
                      id="licensedStarter-values"
                      defaultMessage="4 max"
                    />
                  </Typography>
                </Box>
              </Box>
              <Box mb="18px" display="flex">
                <Lightning style={{ marginRight: "12px" }} />
                <Box display="flex" justifyContent="space-between" width="100%">
                  <Typography className={styles.text}>
                    <FormattedMessage
                      id="meetingTimeLimit"
                      defaultMessage="Meeting time limit"
                    />
                  </Typography>
                  <Typography className={styles.values}>
                    <FormattedMessage
                      id="meetingTimeLimit-values"
                      defaultMessage="40 min"
                    />
                  </Typography>
                </Box>
              </Box>
              <Box mb="18px" display="flex">
                <Lightning style={{ marginRight: "12px" }} />
                <Box display="flex" justifyContent="space-between" width="100%">
                  <Typography className={styles.text}>
                    <FormattedMessage
                      id="participants-values"
                      defaultMessage="Number of participants per room"
                    />
                  </Typography>
                  <Typography className={styles.values}>4</Typography>
                </Box>
              </Box>
              <Box mb="18px" display="flex">
                <Lightning style={{ marginRight: "12px" }} />
                <Box display="flex" justifyContent="space-between" width="100%">
                  <Typography className={styles.text}>
                    <FormattedMessage
                      id="guests-values"
                      defaultMessage="Free external guests per room"
                    />
                  </Typography>
                  <Typography className={styles.values}>4</Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        )}

        <Box
          flexWrap="wrap"
          className={styles.modalFooter}
          sx={{ display: "flex", mt: 1 }}
        >
          <FormControl
            // onClick={handleClose}
            fullWidth
            sx={{ mr: "20px", maxWidth: "220px" }}
          >
            <Button variant="contained" color="secondary">
              <FormattedMessage
                id="action-cancelYourMembership"
                defaultMessage="Cancel your membership"
              />
            </Button>
          </FormControl>

          <Button
            onClick={handleShowAllPlans}
            variant="contained"
            color="primary"
          >
            <FormattedMessage
              id="action-changePlan"
              defaultMessage="Change plan"
            />
          </Button>
        </Box>
      </Box>
    </>
  );
};
