import React, { useEffect, useRef } from "react";
import clsx from "clsx";
import { Box } from "@mui/material";

interface Props {
  selected: boolean;
  tempSelected: boolean;
  value: string;
  label: string;
  onClick: (value: string) => void;
}

const OutfitCard: React.FC<Props> = ({
  selected,
  tempSelected,
  value,
  label,
  onClick,
}) => {
  const cardRef = useRef(null);
  useEffect(() => {
    if (selected && cardRef.current) {
      // @ts-ignore
      cardRef.current.scrollIntoView();
    }
  }, [selected, cardRef]);

  let selectedOutfitBase = value;
  let selectedOutfitVariant = null;
  const selectedOutfitParts = selectedOutfitBase.split("|");
  if (selectedOutfitParts.length === 2) {
    selectedOutfitBase = selectedOutfitParts[0];
    selectedOutfitVariant = selectedOutfitParts[1];
  }
  const previewFilename =
    selectedOutfitVariant === null
      ? selectedOutfitBase + ".jpg"
      : selectedOutfitBase.replace(
          "_lowpoly",
          `_v${selectedOutfitVariant}.jpg`
        );
  const previewURL = `/avatars/outfits/preview/${previewFilename}`;
  return (
    <Box
      ref={cardRef}
      className={clsx("card-outfit", {
        "card-selected": selected,
        "card-temp-selected": tempSelected,
      })}
      onClick={() => onClick(value)}
    >
      <Box className="card-body">
        <img src={previewURL} width="200" height="200" alt={label} />
      </Box>
      <Box className="card-footer">{label}</Box>
    </Box>
  );
};

export default OutfitCard;
