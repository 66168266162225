import { createTheme } from "@mui/material/styles";
import { colors } from "./lib/constants";

const {
  purple,
  deepBlue,
  pink,
  purpleDark,
  grey,
  greyBlue,
  lightGreyAlt,
  backgroundCustomPink,
} = colors;

declare module "@mui/material/styles" {
  interface PaletteOptions {
    bg: {
      header: string;
      footer: string;
    };
  }
  interface Palette {
    bg: {
      header: string;
      footer: string;
    };
  }
}

export const theme = createTheme({
  palette: {
    primary: {
      main: purple,
    },
    secondary: {
      main: deepBlue,
    },
    text: {
      primary: deepBlue,
    },
    bg: {
      header: "#ffffff",
      footer: "#fcfcfd",
    },
  },
  typography: {
    fontFamily: "Euclid Circular B, sans-serif",
    button: {
      textTransform: "none",
      fontFamily: "Euclid Circular B, sans-serif",
    },
  },

  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          borderRadius: "10px",
          boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.05)",
          lineHeight: "1.1em",
          ...(ownerState.size === "large" && {
            padding: "12px 22px",
          }),
          ...(ownerState.size === "medium" && {
            padding: "10px 10px",
          }),
          ...(ownerState.size === "small" && {
            padding: "6px 10px",
          }),
          transitionProperty:
            "background-color, box-shadow, border-color, color, outline-color",
          outlineColor: "transparent",
          // primary button
          ...(ownerState.variant === "contained" &&
            ownerState.color === "primary" && {
              border: `1px solid ${purple}`,
              background: purple,
              color: "#ffffff",
              "&:hover": {
                border: `1px solid ${purpleDark}`,
                background: purpleDark,
                outlineStyle: "solid",
                outlineOffset: "2px",
                outlineWidth: "2px",
                outlineColor: purpleDark,
                boxShadow: "none",
              },
            }),
          // secondary button like back buttons
          ...(ownerState.variant === "contained" &&
            ownerState.color === "secondary" && {
              border: `1px solid ${pink}`,
              background: pink,
              color: purpleDark,
              "&:hover": {
                border: `1px solid ${pink}`,
                background: backgroundCustomPink,
                outlineStyle: "solid",
                outlineOffset: "2px",
                outlineWidth: "2px",
                outlineColor: purpleDark,
                boxShadow: "none",
              },
            }),
          // cancel button and view more
          ...(ownerState.variant === "outlined" &&
            ownerState.color === "secondary" && {
              border: `1px solid ${lightGreyAlt}`,
              background: grey,
              color: greyBlue,
              "&:hover": {
                border: `1px solid ${lightGreyAlt}`,
                background: "rgb(240, 240, 240)",
                outlineStyle: "solid",
                outlineOffset: "2px",
                outlineWidth: "2px",
                outlineColor: lightGreyAlt,
                boxShadow: "none",
              },
            }),
        }),
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          "&.Mui-focused": {
            color: purpleDark,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          maxHeight: "38px",
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: purpleDark,
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          ".MuiOutlinedInput-root": {
            minHeight: "48px",
            maxHeight: "48px",
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {},
      },
    },
    MuiStack: {
      defaultProps: {
        useFlexGap: true,
      },
    },
  },
});
