import React, { FC, ReactElement, useState } from "react";
import { NavLink } from "react-router-dom";
import styles from "./index.module.scss";
import { FormattedMessage } from "react-intl";
import {
  Button,
  Container,
  Box,
  Typography,
  Avatar,
  Stack,
  IconButton,
  List,
  Divider,
  ListItem,
  SwipeableDrawer,
  Menu,
  MenuItem,
  Tooltip,
  ListItemButton,
} from "@mui/material";
import Signout from "../../Signout/index";
import MenuIcon from "@mui/icons-material/Menu";
import { ReactComponent as LogoSvg } from "../../../assets/logo.svg";
import notification from "../../../assets/notification.svg";
import user from "../../../assets/user.svg";
import CurrentUser from "../../CurrentUser";
// import { RoomsLink } from "./RoomsList";
import { FEATURES } from "../../../lib/constants";
import User from "../../User";
import { LanguageSwitcher } from "../../LanguageSwitcher/LanguageSwitcher";
import LogInButton from "../../LogInButton";

interface NavItem {
  href: string;
  exact?: boolean;
  text: ReactElement;
}

const defaultNavItems: NavItem[] = [
  {
    href: "/",
    exact: true,
    text: <FormattedMessage id="nav.meetings" defaultMessage="Meetings" />,
  },
  {
    href: "/manage-avatars",
    text: <FormattedMessage id="nav.avatars" defaultMessage="Avatars" />,
  },
  {
    href: "/manage-organization",
    text: (
      <FormattedMessage id="nav.organization" defaultMessage="Organization" />
    ),
  },
  // {
  //   href: "/rooms",
  //   text: <FormattedMessage id="nav.rooms" defaultMessage="Rooms" />,
  // },
];

const defaultNavUserItems: NavItem[] = [
  {
    href: "/user-settings",
    text: <FormattedMessage id="nav.settings" defaultMessage="Settings" />,
  },
];

const NotificationsIcon = () => {
  if (!FEATURES.NOTIFICATIONS) {
    return null;
  }
  return (
    <Box
      sx={{
        justifyContent: "center",
        display: "flex",
        flexDirection: "row",
      }}
    >
      <Tooltip
        title={
          <FormattedMessage
            id="nav.notifications"
            defaultMessage="Notifications"
          />
        }
      >
        <IconButton>
          <img src={notification} alt="notification" />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

interface Props {
  navItems?: NavItem[];
  navUserItems?: NavItem[];
}

const Header: FC<Props> = ({
  navItems = defaultNavItems,
  navUserItems = defaultNavUserItems,
}) => {
  const [open, setOpen] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const openDropDown = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const toggleDrawer =
    (isOpen: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setOpen(isOpen);
    };

  return (
    <CurrentUser>
      {(currentUser: User | null) => {
        const headerClass = currentUser
          ? `${styles.header} ${styles.menuOpen}`
          : styles.header;
        return (
          <Box
            className={headerClass}
            sx={{ bgcolor: "bg.header" }}
            component="header"
          >
            <Container
              disableGutters
              maxWidth={false}
              sx={{
                placeItems: "center",
                margin: "0 auto",
              }}
              className={styles.content}
            >
              {currentUser && (
                <>
                  <a
                    href="https://www.kizwork.com"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      paddingRight: "16px",
                    }}
                  >
                    <LogoSvg width="46" height="42" />
                  </a>
                  <Box className={styles.group}>
                    {navItems.map((item, idx) => {
                      return (
                        <NavLink
                          key={idx}
                          activeClassName={styles.activeBtn}
                          to={item.href}
                          exact={item.exact}
                        >
                          <Button sx={{ mr: 3 }}>{item.text}</Button>
                        </NavLink>
                      );
                    })}
                    {/* <RoomsLink /> */}
                  </Box>
                </>
              )}

              <Box
                component="div"
                className={styles.login}
                sx={{ display: "flex", justifyContent: "flex-end", flex: 1 }}
              >
                {currentUser ? (
                  <Box>
                    <Box className={styles.menuIcon}>
                      <IconButton
                        color="inherit"
                        edge="end"
                        onClick={handleDrawerOpen}
                      >
                        <MenuIcon />
                      </IconButton>
                      <SwipeableDrawer
                        anchor="right"
                        open={open}
                        onClose={toggleDrawer(false)}
                        onOpen={toggleDrawer(true)}
                      >
                        <Box
                          sx={{ width: 200 }}
                          role="presentation"
                          onClick={toggleDrawer(false)}
                          onKeyDown={toggleDrawer(false)}
                        >
                          <Box
                            sx={{
                              p: 2,
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Avatar sx={{ mr: 1 }} />
                            <Typography
                              sx={{ fontSize: "16px", fontWeight: 600 }}
                            >
                              {currentUser.fullname}
                            </Typography>
                          </Box>
                          <Divider />

                          <List disablePadding>
                            {navItems.map((item, idx) => {
                              return (
                                <ListItem disablePadding key={idx}>
                                  <NavLink
                                    to={item.href}
                                    exact={!!item.exact}
                                    className={styles.menuBtn}
                                    activeClassName={styles.activeMenuBtn}
                                  >
                                    <ListItemButton color="primary">
                                      {item.text}
                                    </ListItemButton>
                                  </NavLink>
                                </ListItem>
                              );
                            })}
                          </List>
                          <LanguageSwitcher />
                          <NotificationsIcon />
                          <List disablePadding>
                            {navUserItems.map((item, idx) => {
                              return (
                                <ListItem disablePadding key={idx}>
                                  <NavLink
                                    to={item.href}
                                    className={styles.menuBtn}
                                    activeClassName={styles.activeMenuBtn}
                                  >
                                    <ListItemButton color="primary">
                                      {item.text}
                                    </ListItemButton>
                                  </NavLink>
                                </ListItem>
                              );
                            })}
                            <ListItem disablePadding>
                              <Signout />
                            </ListItem>
                          </List>
                          <Divider />
                        </Box>
                      </SwipeableDrawer>
                    </Box>
                    <Stack
                      direction="row"
                      spacing={1}
                      className={styles.loginAvatars}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        overflowY: "auto",
                      }}
                    >
                      <LanguageSwitcher />
                      <NotificationsIcon />
                      <Tooltip
                        title={
                          <FormattedMessage
                            id="cardProfileTooltip"
                            defaultMessage="Profile"
                          />
                        }
                      >
                        <IconButton onClick={handleClick}>
                          <User user={currentUser} />
                        </IconButton>
                      </Tooltip>
                      <Menu
                        anchorEl={anchorEl}
                        open={openDropDown}
                        onClose={handleClose}
                      >
                        <Box
                          sx={{
                            p: 1.5,
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <Typography onClick={handleClose}>
                            {currentUser.fullname}
                          </Typography>
                        </Box>
                        <Divider />
                        {navUserItems.map((item, idx) => {
                          return (
                            <MenuItem
                              key={idx}
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                p: 0,
                              }}
                            >
                              <NavLink
                                to={item.href}
                                onClick={() => setAnchorEl(null)}
                                className={styles.menuBtn}
                                activeClassName={styles.activeMenuBtn}
                              >
                                <ListItemButton>{item.text}</ListItemButton>
                              </NavLink>
                            </MenuItem>
                          );
                        })}

                        <MenuItem
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            p: 0,
                          }}
                        >
                          <Signout />
                        </MenuItem>
                      </Menu>
                    </Stack>
                  </Box>
                ) : (
                  // anonymous
                  <Stack
                    direction="row"
                    spacing={1}
                    className={styles.loginAvatars}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      pt: "5px",
                      pb: "5px",
                    }}
                  >
                    <LanguageSwitcher />
                    <Stack
                      direction="row"
                      gap={2}
                      alignItems="center"
                      className={styles.loginBtn}
                    >
                      <Stack direction="row" gap={1} alignItems="center">
                        <img src={user} alt="" />
                        <Typography component="a" href="/users/register">
                          <FormattedMessage
                            id="nav.signup"
                            defaultMessage="Sign Up"
                          />
                        </Typography>
                      </Stack>
                      <LogInButton />
                    </Stack>
                  </Stack>
                )}
              </Box>
            </Container>
          </Box>
        );
      }}
    </CurrentUser>
  );
};

export default Header;
