import styles from "./index.module.scss";
import { FormattedDate, FormattedMessage } from "react-intl";
import {
  Box,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Paper,
  useMediaQuery,
} from "@mui/material";

const data = [
  {
    Date: "2023-10-13",
    Description: "Service 1",
    "Service period": "2023-07-06—2023-08-05",
    "Payment method": "Credit Card",
    Total: "$20",
    Invoice: "url",
  },
  {
    Date: "2023-10-14",
    Description: "Service 2",
    "Service period": "2023/07/06—2023/08/05",
    "Payment method": "PayPal",
    Total: "$20",
    Invoice: "url",
  },
];

export const BillingDetails = () => {
  const isNarrowScreen = useMediaQuery("(max-width:500px)");

  if (isNarrowScreen) {
    return (
      <>
        <Typography
          sx={{ display: "flex", justifyContent: "flex-start" }}
          className={styles.title}
        >
          <FormattedMessage
            id="payment-details"
            defaultMessage="Billing details"
          />
        </Typography>
        {data.map((row, index) => (
          <Paper
            key={index}
            sx={{
              boxShadow: "none",
              mt: 3,
              p: 2,
              border: "1px solid #f3f6f7",
              borderRadius: "12px",
            }}
          >
            <Box display="flex" justifyContent="space-between" pb={1}>
              <Typography
                sx={{ fontSize: "14px", fontWeight: 600, color: "secondary" }}
              >
                <FormattedMessage id="table-date" defaultMessage="Date" />:{" "}
              </Typography>

              {row.Date}
            </Box>
            <Box display="flex" justifyContent="space-between" pb={1}>
              <Typography
                sx={{ fontSize: "14px", fontWeight: 600, color: "secondary" }}
              >
                <FormattedMessage
                  id="meetingForm.description"
                  defaultMessage="Description"
                />
                :
              </Typography>
              {row.Description}
            </Box>

            <Box display="flex" justifyContent="space-between" pb={1}>
              <Typography
                sx={{ fontSize: "14px", fontWeight: 600, color: "secondary" }}
              >
                <FormattedMessage
                  id="table-servicePeriod"
                  defaultMessage="Service period"
                />
                :
              </Typography>
              <FormattedDate value={row["Service period"]} />
            </Box>

            <Box display="flex" justifyContent="space-between" pb={1}>
              <Typography
                sx={{ fontSize: "14px", fontWeight: 600, color: "secondary" }}
              >
                <FormattedMessage
                  id="table-paymentMethod"
                  defaultMessage="Payment method"
                />
                :
              </Typography>
              {row["Payment method"]}
            </Box>

            <Box display="flex" justifyContent="space-between" pb={1}>
              <Typography
                sx={{ fontSize: "14px", fontWeight: 600, color: "secondary" }}
              >
                <FormattedMessage id="table-total" defaultMessage="Total" />:{" "}
              </Typography>
              <Typography
                sx={{ fontSize: "14px", fontWeight: 600, color: "secondary" }}
              >
                {" "}
                {row.Total}
              </Typography>
            </Box>

            <a href={row.Invoice}>
              <Typography color="primary" sx={{ fontSize: "14px" }}>
                <FormattedMessage
                  id="invoice-download"
                  defaultMessage="Download"
                />
              </Typography>
            </a>
          </Paper>
        ))}
      </>
    );
  } else {
    return (
      <>
        <Typography
          sx={{ display: "flex", justifyContent: "flex-start" }}
          className={styles.title}
        >
          <FormattedMessage
            id="payment-details"
            defaultMessage="Billing details"
          />
        </Typography>

        <Paper
          sx={{
            boxShadow: "none",
            mt: 3,
          }}
        >
          <Table>
            <TableHead
              sx={{
                maxHeight: "40px",
                p: 0,
                border: "1px solid #f3f6f7",
              }}
            >
              <TableRow
                sx={{
                  "& .MuiTableCell-root": {
                    p: "12px 16px",
                  },
                }}
              >
                <TableCell>
                  <Typography
                    color="secondary"
                    sx={{ fontSize: "14px", fontWeight: 600 }}
                  >
                    <FormattedMessage id="table-date" defaultMessage="Date" />
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    color="secondary"
                    sx={{ fontSize: "14px", fontWeight: 600 }}
                  >
                    <FormattedMessage
                      id="meetingForm.description"
                      defaultMessage="Description"
                    />
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    color="secondary"
                    sx={{ fontSize: "14px", fontWeight: 600 }}
                  >
                    <FormattedMessage
                      id="table-servicePeriod"
                      defaultMessage="Service period"
                    />
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    color="secondary"
                    sx={{ fontSize: "14px", fontWeight: 600 }}
                  >
                    <FormattedMessage
                      id="table-paymentMethod"
                      defaultMessage="Payment method"
                    />
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    color="secondary"
                    sx={{ fontSize: "14px", fontWeight: 600 }}
                  >
                    <FormattedMessage id="table-total" defaultMessage="Total" />
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    color="secondary"
                    sx={{ fontSize: "14px", fontWeight: 600 }}
                  >
                    <FormattedMessage
                      id="table-invoice"
                      defaultMessage="Invoice"
                    />
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody
              sx={{
                border: "1px solid #f3f6f7",
                borderRadius: "0 0 12px 12px",
              }}
            >
              {data.map((row, index) => (
                <TableRow
                  key={index}
                  sx={
                    index === data.length - 1
                      ? {
                          borderBottomLeftRadius: "12px",
                          borderBottomRightRadius: "12px",
                        }
                      : { p: 1 }
                  }
                >
                  <TableCell sx={{ p: "24px 16px" }}>{row.Date}</TableCell>
                  <TableCell>
                    <Typography sx={{ fontSize: "14px" }}>
                      <FormattedMessage
                        id="table-subscription"
                        defaultMessage="Subscription payment"
                      />
                    </Typography>
                  </TableCell>
                  <TableCell>{row["Service period"]}</TableCell>
                  <TableCell>{row["Payment method"]}</TableCell>
                  <TableCell>
                    <Typography sx={{ fontSize: "14px", fontWeight: 700 }}>
                      {row.Total}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <a href={row.Invoice}>
                      <Typography color="primary" sx={{ fontSize: "14px" }}>
                        <FormattedMessage
                          id="invoice-download"
                          defaultMessage="Download"
                        />
                      </Typography>
                    </a>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </>
    );
  }
};
