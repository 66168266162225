import React from "react";
import styles from "./index.module.scss";
import {
  Box,
  Container,
  Divider,
  Typography,
  Button,
  Fade,
} from "@mui/material";
import {
  FormattedDate,
  FormattedMessage,
  FormattedNumber,
  FormattedTime,
  useIntl,
} from "react-intl";
import { NavLink } from "react-router-dom";
import CreateMeetingBtn from "../CreateMeetingButton/index";
import { FEATURES } from "../../lib/constants";
import User from "../User";

interface Props {
  isFinished: boolean;
  room: Room;
  selectedOrganizationId: string | null;
  setSelectedOrganizationId: (id: string) => void;
}

export const MeetingInfoCard: React.FC<Props> = ({
  room,
  selectedOrganizationId,
  setSelectedOrganizationId,
  isFinished = false,
}) => {
  const intl = useIntl();
  const formatDateAndTime = (dateString: string | undefined | null) => {
    if (dateString) {
      const date = new Date(dateString);
      return (
        <>
          <FormattedDate value={date} /> <FormattedTime value={date} />
        </>
      );
    } else {
      return " ";
    }
  };

  return (
    <Fade in={true} timeout={500}>
      <Container
        disableGutters
        sx={{
          minWidth: "85%",
          display: "flex",
          flex: 1,
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            minHeight: "65vh",
            border: "1px solid #D1D5DB",
            borderRadius: "12px",
            mr: 3,
            ml: 3,
          }}
        >
          <Box className={styles.header}>
            <Typography className={styles.text} component="div">
              <FormattedMessage id="nav.meeting" defaultMessage="Meeting" />
            </Typography>

            <Box
              className={styles.btnGroup}
              sx={{
                display: "flex",
                width: "70%",
                justifyContent: "end",
              }}
            >
              {isFinished ? (
                <NavLink
                  style={{ textDecoration: "none" }}
                  to="/meetings-history"
                >
                  <Button variant="outlined" color="secondary">
                    <FormattedMessage
                      id="header.btn-back-history"
                      defaultMessage="Back to History of meetings"
                    />
                  </Button>
                </NavLink>
              ) : (
                <NavLink style={{ textDecoration: "none" }} to="/">
                  <Button variant="outlined" color="secondary">
                    <FormattedMessage id="actions.back" defaultMessage="Back" />
                  </Button>
                </NavLink>
              )}
            </Box>
          </Box>
          <Divider />

          <Box
            sx={{
              minHeight: "65vh",
              border: "1px solid #D1D5DB",
              borderRadius: "8px",
              m: 2,
              mb: 2,
            }}
          >
            <Box className={styles.info} sx={{ p: "40px", pb: "22px" }}>
              <Box
                sx={{ pb: "20px", flexWrap: "wrap" }}
                display="flex"
                className={styles.infoHeader}
              >
                <Box sx={{ width: "34%" }} className={styles.text}>
                  {room?.name}

                  <Box display="flex" alignItems="center">
                    <Typography>
                      {formatDateAndTime(room?.startDate)}
                    </Typography>
                    <Typography sx={{ mr: "5px", ml: "5px" }}>-</Typography>
                    <Typography>{formatDateAndTime(room?.endDate)}</Typography>
                  </Box>
                </Box>

                {FEATURES.CARBON_CREDITS && (
                  <Box display="flex" alignItems="end">
                    <Typography sx={{ pl: "25px" }}>
                      <FormattedMessage
                        id="infoCard.saved-kg"
                        defaultMessage="{number} kg of CO2 saved"
                        values={{
                          number: (
                            <FormattedNumber
                              value={123456.789}
                              // eslint-disable-next-line
                              style="decimal"
                            />
                          ),
                        }}
                      />
                    </Typography>
                  </Box>
                )}

                <Box sx={{ ml: "auto" }}>
                  <CreateMeetingBtn
                    label={intl.formatMessage({
                      id: "meetingCard.scheduleNewMeeting",
                      defaultMessage: "Schedule new meeting",
                    })}
                    selectedOrganizationId={selectedOrganizationId}
                    setSelectedOrganizationId={setSelectedOrganizationId}
                    roomFromHistory={room}
                  />
                </Box>
              </Box>
              <Divider />
              <Box
                sx={{ p: "20px 24px", pl: 0, minWidth: "100%" }}
                display="flex"
                justifyContent="space-between"
              >
                <Typography sx={{ minWidth: "34%" }}>
                  <FormattedMessage
                    id="meetingForm.description"
                    defaultMessage="Description"
                  />
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "flex-start",
                    width: "100%",
                  }}
                >
                  <Typography sx={{ width: "100%" }}>
                    {room.description}
                  </Typography>
                </Box>
              </Box>
              <Divider />
              <Box
                sx={{ p: "20px 24px", pl: 0, minWidth: "100%" }}
                display="flex"
                justifyContent="space-between"
              >
                <Typography sx={{ minWidth: "34%" }}>
                  <FormattedMessage
                    id="meetingCard.invitedUsers"
                    defaultMessage="Users invited to this meeting"
                  />
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    flexGrow: 1,
                  }}
                >
                  {room.attendees?.map((attendee) => (
                    <Box
                      key={attendee.email}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                        mb: 1,
                      }}
                    >
                      <User attendee={attendee} />
                      <div>
                        <Typography>{attendee.fullname}</Typography>
                        {attendee.email === room.user.email && (
                          <Typography className={styles.discreet}>
                            <FormattedMessage
                              id="organizer"
                              defaultMessage="Organizer"
                            />
                          </Typography>
                        )}
                      </div>
                    </Box>
                  ))}
                </Box>
              </Box>
              <Divider />
              {/* <Box
              sx={{ p: "20px 24px", pl: 0, minWidth: "100%" }}
              display="flex"
              justifyContent="space-between"
            >
              <Typography sx={{ minWidth: "34%" }}>
                <FormattedMessage
                  id="infoCard.commentsTitle"
                  defaultMessage="Comments after the meeting"
                />
              </Typography>
              <Typography sx={{ width: "100%" }}>
                <FormattedMessage
                  id="infoCard.comments"
                  defaultMessage="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum."
                  // defaultMessage="{name}"
                  // values={{ name: card?.comments }}
                />
              </Typography>
            </Box> */}
              {/* <Divider /> */}
              <Box
                sx={{ p: "20px 24px", pl: 0, minWidth: "100%" }}
                display="flex"
                justifyContent="space-between"
              >
                <Typography sx={{ minWidth: "34%" }}>
                  <FormattedMessage
                    id="infoCard.attachments"
                    defaultMessage="Attachments"
                  />
                </Typography>
                <Typography sx={{ width: "100%" }}></Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>
    </Fade>
  );
};
