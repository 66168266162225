const API_URL = "https://apis.google.com/js/api.js";

let promise = null;

const loadGoogleAPI = () => {
  promise =
    promise ||
    new Promise(function (resolve, reject) {
      // Reject the promise after a timeout
      const timeoutId = setTimeout(() => {
        reject(new Error("Could not load the Google API"));
      }, 10000);

      // Hook up the on load callback
      const onload = () => {
        if (timeoutId) {
          clearTimeout(timeoutId);
        }
        resolve(window.gapi);
      };

      // Prepare the `script` tag to be inserted into the page
      const scriptElement = document.createElement("script");
      scriptElement.onload = onload;
      scriptElement.src = API_URL;

      // Insert the `script` tag
      document.body.appendChild(scriptElement);
    });
  return promise;
};

export { loadGoogleAPI };
