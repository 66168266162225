import React from "react";
import styles from "./index.module.scss";
import { loader } from "graphql.macro";
import { Mutation } from "@apollo/client/react/components";
import { Formik } from "formik";
import { FormattedMessage, useIntl } from "react-intl";

import {
  Select,
  MenuItem,
  Box,
  Typography,
  Switch,
  Card,
  FormControlLabel,
  Grid,
  Tooltip,
} from "@mui/material";

import { SelectChangeEvent } from "@mui/material/Select";
import AvatarModelViewer from "../../../AvatarModelViewer";

const SAVE_AVATAR_CHOICE = loader(
  "../../../../schema/mutation/SaveAvatarChoice.graphql"
);

export const defaultAvatars = [
  "/avatars/pack3/man1.glb",
  "/avatars/pack3/woman1.glb",
  "/avatars/pack3/man_019.glb",
  "/avatars/pack3/woman_025.glb",
];

interface UpdateChoiceData {
  saveAvatarChoice: Avatar;
}
interface Props {
  localStorageSelectedAvatar: string | null;
  activeDefaultAvatar: boolean;
}
const DefaultAvatarSelect: React.FC<Props> = ({
  localStorageSelectedAvatar,
  activeDefaultAvatar,
}) => {
  const intl = useIntl();

  const defaultSelectedAvatar = "/avatars/pack3/man1.glb";
  let selectedAvatar = defaultSelectedAvatar;
  if (
    localStorageSelectedAvatar &&
    defaultAvatars.indexOf(localStorageSelectedAvatar) > -1
  ) {
    selectedAvatar = localStorageSelectedAvatar;
  }
  const initialValues = {
    selectedAvatar: selectedAvatar,
  };

  return (
    <Mutation<UpdateChoiceData> mutation={SAVE_AVATAR_CHOICE}>
      {(saveAvatarChoice) => (
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          onSubmit={(values, { setSubmitting }) => {
            localStorage.setItem("avatarValue", values.selectedAvatar);

            saveAvatarChoice({
              variables: values,
            }).catch(() => {
              setSubmitting(false);
            });
          }}
        >
          {({ values, handleChange, handleBlur, handleSubmit }) => {
            const handleClick = () => {
              const variables = {
                selectedAvatar: values.selectedAvatar,
              };

              saveAvatarChoice({ variables });
            };

            const handleChangeAndSubmit = (
              e: SelectChangeEvent<string | null>
            ) => {
              handleChange(e);

              // handleChange updates the values.selectedAvatar state asynchronously.
              // Calling handleSubmit right after worked everywhere excepted Safari macOS, just wait 200ms to be sure...

              setTimeout(() => handleSubmit(), 200);
            };

            return (
              <Grid item>
                <Card
                  className={styles.card}
                  sx={{
                    boxShadow: "none",
                    borderRadius: "12px",
                    minHeight: "100%",
                    maxWidth: "300px",
                    width: "35vw",
                    minWidth: "250px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Box className={styles.header}>
                    <Typography component="h1" className={styles.title}>
                      <FormattedMessage
                        id="avatar.default-avatars"
                        defaultMessage="Default avatars"
                      />
                    </Typography>
                    <Tooltip
                      title={intl.formatMessage({
                        id: "SwitchTooltip",
                        defaultMessage: "Switch Avatar",
                      })}
                    >
                      <Switch
                        onClick={handleClick}
                        checked={activeDefaultAvatar}
                        inputProps={{
                          "aria-label": "controlled",
                        }}
                        size="small"
                      />
                    </Tooltip>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      flexGrow: 1,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        flexGrow: 1,
                      }}
                    >
                      <AvatarModelViewer
                        name="Selected avatar"
                        avatarSrc={values.selectedAvatar}
                        outfit={null}
                      />
                    </Box>
                    <Box
                      sx={{
                        height: "100px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        borderTop: "1px solid rgba(0, 0, 0, 0.12)",
                      }}
                    >
                      <FormControlLabel
                        sx={{
                          display: "flex",
                          flexDirection: "column-reverse",
                          justifyContent: "center",
                          alignItems: "start",
                          m: 0,
                        }}
                        label={intl.formatMessage({
                          id: "select.avatar",
                          defaultMessage: "Select avatar",
                        })}
                        control={
                          <Select
                            disabled={!activeDefaultAvatar}
                            sx={{ width: "240px", maxHeight: "42px" }}
                            id="selectedAvatar"
                            name="selectedAvatar"
                            onChange={handleChangeAndSubmit}
                            onBlur={handleBlur}
                            value={values.selectedAvatar}
                          >
                            <MenuItem value="/avatars/pack3/man1.glb">
                              <FormattedMessage
                                id="select.man1"
                                defaultMessage="Man 1 (business)"
                              />
                            </MenuItem>
                            <MenuItem value="/avatars/pack3/woman1.glb">
                              <FormattedMessage
                                id="select.woman1"
                                defaultMessage="Woman 1 (business)"
                              />
                            </MenuItem>
                            <MenuItem value="/avatars/pack3/man_019.glb">
                              <FormattedMessage
                                id="select.man2"
                                defaultMessage="Man 2 (business casual)"
                              />
                            </MenuItem>
                            <MenuItem value="/avatars/pack3/woman_025.glb">
                              <FormattedMessage
                                id="select.woman2"
                                defaultMessage="Woman 2 (business casual)"
                              />
                            </MenuItem>
                          </Select>
                        }
                      />
                    </Box>
                  </Box>
                </Card>
              </Grid>
            );
          }}
        </Formik>
      )}
    </Mutation>
  );
};

export default DefaultAvatarSelect;
