import React from "react";
import { Typography, Box } from "@mui/material";
import styles from "./index.module.scss";
import { FormattedDate, FormattedMessage, useIntl } from "react-intl";
import Logo from "../../assets/DefaultOrgLogo.svg";
import UpdateOrganizationModal from "../UpdateOrganizationModal/index";
import AddMemberModal from "../AddMemberModal/AddMemberModal";
import {
  NUMBER_OF_SEATS_FOR_FREE,
  rolesThatCanManage,
} from "../../lib/constants";

interface Props {
  id: string;
  name: string;
  logoId: string | null;
  premiumType: string;
  subscriptionDate: Date;
  role: string;
  numberOfSeats: number;
  membersCount: number;
}

const OrganizationInfo: React.FC<Props> = ({
  id,
  name,
  logoId,
  premiumType,
  subscriptionDate,
  role,
  numberOfSeats,
  membersCount,
}) => {
  const intl = useIntl();

  const freeToBusinessModal =
    premiumType === "NONE" && membersCount === NUMBER_OF_SEATS_FOR_FREE;
  const seatsQuotaReachedModal =
    premiumType !== "NONE" && membersCount === numberOfSeats;

  return (
    <Box sx={{ m: "40px 32px", flexWrap: "wrap" }}>
      <Box className={styles.container} display="flex">
        {logoId ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              border: "1px solid #D1D5DB",
              borderRadius: "6px",
              minWidth: "116px",
            }}
          >
            <img
              src={`/files/${logoId}`}
              alt={intl.formatMessage({
                id: "organization.organization-logo",
                defaultMessage: "Organization's logo",
              })}
              style={{ width: 100, height: 100 }}
            />
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              border: "1px solid #D1D5DB",
              borderRadius: "6px",
              minWidth: "116px",
            }}
          >
            <img
              src={Logo}
              alt={intl.formatMessage({
                id: "organization.organization-logo",
                defaultMessage: "Organization's logo",
              })}
              style={{ width: 80, height: 80 }}
            />
          </Box>
        )}

        <Box
          className={styles.wrapper}
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            ml: "20px",
            border: "1px solid #D1D5DB",
            borderRadius: "6px",
            p: 4,
            gap: 2,
          }}
        >
          <Box
            className={styles.wrapper}
            display="flex"
            sx={{
              width: "50%",
              flexDirection: "row",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            <Box>
              <Typography className={styles.title}>{name}</Typography>
              <Box
                sx={{
                  px: "10px",
                  borderRadius: "10px",
                  display: "inline-block",
                }}
                className={role === "MEMBER" ? styles.member : styles.owner}
              >
                <Typography className={styles.text}>
                  <FormattedMessage id="role" defaultMessage="Role" />
                </Typography>
                <Typography pr="5px" pl="5px" className={styles.text}>
                  -
                </Typography>
                <Typography className={styles.text}>
                  {role === "MEMBER" && (
                    <FormattedMessage id="Member" defaultMessage="Member" />
                  )}
                  {role === "ADMIN" && (
                    <FormattedMessage id="Admin" defaultMessage="Admin" />
                  )}
                  {role === "OWNER" && (
                    <FormattedMessage id="Owner" defaultMessage="Owner" />
                  )}
                </Typography>
              </Box>
            </Box>
            {rolesThatCanManage.indexOf(role) > -1 && (
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography sx={{ mb: "8px" }} className={styles.title}>
                  <FormattedMessage
                    id="manage.organization-subscription"
                    defaultMessage="Subscription"
                  />
                </Typography>

                <Box
                  className={styles.secondaryText}
                  display="flex"
                  whiteSpace="nowrap"
                >
                  <Typography sx={{ mr: "5px" }}>
                    {premiumType === "NONE" && (
                      <FormattedMessage
                        id="manage.organization-subscription-free"
                        defaultMessage="Free"
                      />
                    )}
                    {premiumType === "TIER1" && (
                      <FormattedMessage
                        id="manage.organization-subscription-business"
                        defaultMessage="Business"
                      />
                    )}
                    {premiumType === "TIER2" && (
                      <FormattedMessage
                        id="manage.organization-subscription-corporate"
                        defaultMessage="Corporate"
                      />
                    )}
                  </Typography>
                  {premiumType !== "NONE" && (
                    <Typography>
                      <FormattedMessage
                        id="manage.organization-subscription-start-date"
                        defaultMessage="(started {date})"
                        values={{
                          date: <FormattedDate value={subscriptionDate} />,
                        }}
                      />
                    </Typography>
                  )}
                </Box>
              </Box>
            )}
          </Box>

          {rolesThatCanManage.indexOf(role) > -1 && (
            <Box
              className={styles.wrapper}
              display="flex"
              sx={{
                width: "25%",
                flexDirection: "row",
                justifyContent: "space-between",
                flexWrap: "wrap",
              }}
            >
              <Box>
                <Typography sx={{ mb: "8px" }} className={styles.title}>
                  <FormattedMessage
                    id="numberOfLicensedUsers"
                    defaultMessage="Number of licensed users"
                  />
                </Typography>
                <Box className={styles.secondaryText} display="flex">
                  <Typography>
                    <FormattedMessage
                      id="manage.organization-number-of-seatsValue"
                      defaultMessage="{current}/{total}"
                      values={{
                        current: membersCount,
                        total:
                          premiumType !== "NONE"
                            ? numberOfSeats
                            : NUMBER_OF_SEATS_FOR_FREE,
                      }}
                    />
                  </Typography>
                </Box>
              </Box>
            </Box>
          )}
          {rolesThatCanManage.indexOf(role) > -1 && (
            <Box className={styles.modalBtnBox} display="flex">
              <UpdateOrganizationModal
                organizationId={id}
                name={name}
                logoId={logoId}
                premiumType={premiumType}
              />
              <AddMemberModal
                organizationId={id}
                freeToBusinessModal={freeToBusinessModal}
                seatsQuotaReachedModal={seatsQuotaReachedModal}
              />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default OrganizationInfo;
