import { FC } from "react";
import { FormattedMessage } from "react-intl";
import styles from "./index.module.scss";
import AddAvatarButton from "../../../AddAvatarButton";
import {
  Typography,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Grid,
} from "@mui/material";
import avatarSvg from "../../../../assets/AvatarCardSvg.svg";

interface CardProps {
  disabled: boolean;
  name: string;
}
export const DefaultAvatarCard: FC<CardProps> = ({ disabled, name }) => {
  return (
    <Grid item>
      <Card
        className={styles.card}
        sx={{
          boxShadow: "none",
          borderRadius: "12px",
          minHeight: "100%",
          maxWidth: "300px",
          width: "35vw",
          minWidth: "250px",
        }}
      >
        <CardHeader
          className={styles.header}
          title={
            <Typography component="h1" className={styles.title}>
              <FormattedMessage id="room" defaultMessage="Create your avatar" />
            </Typography>
          }
        ></CardHeader>
        <CardContent
          sx={{
            p: 0,
            alignItems: "center",
            mt: "24px",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            minHeight: "128px",
          }}
          className={styles.cardContent}
        >
          <img src={avatarSvg} alt="avatarSvg" />
        </CardContent>
        <Divider sx={{ mt: "50px" }} />
        <Typography
          sx={{
            textAlign: "center",
            p: "16px 20px 0px 20px",
          }}
        >
          <FormattedMessage
            id="avatar.description"
            defaultMessage="To create a personalized avatar, we will need some of your personal information. Such as gender, body shape, height, photo."
          />
        </Typography>
        <AddAvatarButton disabled={disabled} name={name} />
        {disabled && (
          <Typography sx={{ px: 2, pb: 1, textAlign: "center" }}>
            <FormattedMessage
              id="avatars.quota-exceded"
              defaultMessage="Please remove one of your avatar if you want to create a new one."
            />
          </Typography>
        )}
      </Card>
    </Grid>
  );
};
