import { useMutation } from "@apollo/client";
import { loader } from "graphql.macro";
import { FormattedMessage } from "react-intl";
import { ListItemButton } from "@mui/material";
import { ReactComponent as LogoutSvg } from "../../assets/Logout.svg";
import styles from "../Layout/Header/index.module.scss";

const SIGNOUT_MUTATION = loader("../../schema/mutation/SignOut.graphql");

function Signout() {
  const [signoutMutation] = useMutation(SIGNOUT_MUTATION, {
    onCompleted: () => {
      window.location.href = "/";
    },
  });

  const handleSignout = () => {
    signoutMutation().catch(() => {});
  };

  return (
    <ListItemButton onClick={handleSignout} className={styles.signoutBtn}>
      <LogoutSvg style={{ marginRight: "5px" }} />
      <FormattedMessage id="nav.logout" defaultMessage="Log Out" />
    </ListItemButton>
  );
}

export default Signout;
