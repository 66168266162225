import { Container, Fade } from "@mui/material";
import { ManagePaymentInfo } from "../components/ManagePaymentInfo";

export const ManagePayment = () => {
  return (
    <Fade in={true} timeout={800}>
      <Container
        maxWidth={false}
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "75vh",
          p: 4,
          pt: 0,
          maxWidth: "45vw",
          minWidth: "320px",
        }}
      >
        <ManagePaymentInfo />
      </Container>
    </Fade>
  );
};
