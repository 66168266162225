import React, { FC } from "react";
import { loader } from "graphql.macro";
import { useQuery } from "@apollo/client";
import Error from "../components/Error";

const GET_CURRENT_USER_QUERY = loader("../schema/query/GetCurrentUser.graphql");

interface Data {
  me: User | null;
}

interface Props {
  children(props: User | null): React.ReactNode;
}

const CurrentUser: FC<Props> = (props) => {
  const { data, loading, error } = useQuery<Data>(GET_CURRENT_USER_QUERY);
  if (loading) return null;
  if (error || !data) return <Error error={error} />;
  if (typeof data.me === "undefined") return null;
  if (data.me?.userToken) {
    // @ts-ignore
    window.userToken = data.me.userToken;
  }
  return props.children(data.me) as JSX.Element;
};

export default CurrentUser;
