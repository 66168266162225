import { Dayjs } from "dayjs";

export function formatToNaiveDateTime(date: Dayjs | null): null | string {
  if (!date) {
    return null;
  }
  // date.toISOString() is converting the current date timezone to UTC
  // Here we want the iso format without seconds without hours changes
  return date.format("YYYY-MM-DDTHH:mm:00");
}

export function calculateDateRange(
  input: Dayjs,
  type: string
): { start: Dayjs; end: Dayjs } {
  let start: Dayjs;
  let end: Dayjs;

  if (type === "Month") {
    start = input.startOf("month");

    end = input.endOf("month");
  } else if (type === "Day") {
    start = input.startOf("day");
    end = input.endOf("day");
  } else {
    start = input.startOf("week");
    end = input.endOf("week");
  }

  return { start, end };
}

export const transport = [
  {
    label: "Bike or walk",
    value: 0,
  },

  {
    label: "Metro",
    value: 0.003,
  },

  {
    label: "Electric scooter",
    value: 0.01,
  },

  {
    label: "Scooter or light motorbike",
    value: 0.08,
  },

  {
    label: "Car (electric engine)",
    value: 0.1,
  },
  {
    label: "Bus (thermal engine)",
    value: 0.11,
  },
  {
    label: "Car (thermal engine)",
    value: 0.22,
  },
];

// TODO do a GetScenes query, premiumType to be removed, image is now thumbnail
export const scenes = [
  {
    identifier: "meeting_room_1",
    name: "Meeting room 1",
    description: "",
    image: "/thumbnails/meeting_room_1.jpg",
    premiumType: "NONE",
  },
  {
    identifier: "meeting_room_2",
    name: "Meeting room 2",
    description: "",
    image: "/thumbnails/meeting_room_2.jpg",
    premiumType: "TIER1",
  },
  {
    identifier: "meeting_room_3",
    name: "Meeting room 3",
    description: "",
    image: "/thumbnails/meeting_room_3.jpg",
    premiumType: "TIER1",
  },
  {
    identifier: "meeting_room_4",
    name: "Meeting room 4",
    description: "",
    image: "/thumbnails/meeting_room_4.jpg",
    premiumType: "TIER1",
  },
  {
    identifier: "meeting_room_5",
    name: "White room",
    description: "",
    image: "/thumbnails/meeting_room_5.jpg",
    premiumType: "TIER1",
  },
  {
    identifier: "meeting_room_6",
    name: "Mall",
    description: "",
    image: "/thumbnails/meeting_room_6.jpg",
    premiumType: "TIER1",
  },
  {
    identifier: "meeting_room_7",
    name: "Auditorium",
    description: "",
    image: "/thumbnails/meeting_room_7.jpg",
    premiumType: "TIER1",
  },
  {
    identifier: "meeting_room_8",
    name: "Forest",
    description: "",
    image: "/thumbnails/meeting_room_8.jpg",
    premiumType: "TIER1",
  },
  {
    identifier: "meeting_room_9",
    name: "Office",
    description: "",
    image: "/thumbnails/meeting_room_9.jpg",
    premiumType: "TIER1",
  },
  {
    identifier: "meeting_room_10",
    name: "IEC",
    description: "",
    image: "/thumbnails/meeting_room_10.jpg",
    premiumType: "TIER1",
  },
];

export const getDistance = async (origin: any, destination: any) => {
  const service = new window.google.maps.DistanceMatrixService();
  return new Promise((resolve, reject) => {
    service.getDistanceMatrix(
      {
        origins: [origin],
        destinations: [destination],
        travelMode: window.google.maps.TravelMode.DRIVING,
      },
      (response: any, status: string) => {
        if (status === "OK" && response.rows[0].elements[0].status === "OK") {
          resolve(response.rows[0].elements[0].distance.text);
        } else {
          reject(new Error("Could not calculate distance"));
        }
      }
    );
  });
};

export const calculateCo2ForDays = (
  distance: string,
  days: number,
  selectedMode: string,
  transport: Transport[]
) => {
  const selectedTransport = transport.find(
    (item) => item.label === selectedMode
  );

  if (!selectedTransport) {
    return 0;
  }

  const totalDistance = parseFloat(distance);
  const co2Emissions = totalDistance * days * selectedTransport.value;

  return co2Emissions;
};

export const BarChartData = {
  data: {
    labels: ["2", "2", "3", "4"],
    datasets: [
      {
        label: "",
        data: [65, 59, 80, 81, 56, 55, 70],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(255, 159, 64, 0.2)",
          "rgba(255, 205, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(201, 203, 207, 0.2)",
        ],
        borderColor: [
          "rgb(255, 99, 132)",
          "rgb(255, 159, 64)",
          "rgb(255, 205, 86)",
          "rgb(75, 192, 192)",
          "rgb(54, 162, 235)",
          "rgb(153, 102, 255)",
          "rgb(201, 203, 207)",
        ],

        borderWidth: 0,
        borderRadius: 20,
        barPercentage: 0.25,
      },
    ],
  },
  options: {
    elements: {
      line: {
        cubicInterpolationMode: "monotone",
      },
    },
    responsive: true,
    maintainAspectRatio: false,

    scales: {
      y: {
        beginAtZero: true,
        max: 100,
      },
    },

    plugins: {
      title: {
        display: false,
        padding: 0,
        color: "#1C1C1C",
        font: {
          size: 14,
          weight: "600",
        },
        align: "start",
      },
      legend: {
        display: false,
        labels: {
          boxWidth: 15,
          boxHeight: 5,
          borderRadius: 12,
          font: {
            size: 12,
          },

          pointStyle: "circle",
          usePointStyle: true,
          textAlign: "center",
          color: "rgba(28, 28, 28, 1)",
          useBorderRadius: true,
          padding: 10,
        },
      },
    },
  },
};

export const fetchAttendees = async (
  query: string
): Promise<readonly Attendee[]> => {
  try {
    const response = await fetch(`/attendees?q=${query}`);
    if (response.ok) {
      const data = await response.json();
      return data.data;
    } else {
      console.error("Failed to fetch attendees");
      return [];
    }
  } catch (error) {
    console.error("Error fetching attendees:", error);
    return [];
  }
};
