import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import {
  Avatar,
  Button,
  Grid,
  TableHead,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { FormattedMessage, FormattedNumber, useIntl } from "react-intl";
import { colors } from "../../lib/constants";

const { grey, customGrey, border, purple } = colors;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: grey,
    color: theme.palette.secondary,
    padding: "12px 24px",
    fontSize: 14,
    fontWeight: 600,
    lineHeight: "16px",
    "&:first-of-type": {
      borderTopLeftRadius: "8px",
    },
    "&:last-of-type": {
      borderTopRightRadius: "8px",
    },
  },
}));

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number
  ) => void;
}

function TablePaginationActions(props: Readonly<TablePaginationActionsProps>) {
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleBackButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page + 1);
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "12px",
        mb: 1,
        mr: 1,
      }}
    >
      <Button
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
        variant="outlined"
        color="secondary"
      >
        <FormattedMessage id="actions.previous" defaultMessage="Previous" />
      </Button>
      <Button
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
        variant="outlined"
        color="secondary"
      >
        <FormattedMessage id="actions.next" defaultMessage="Next" />
      </Button>
    </Box>
  );
}

function createData(
  name: string,
  credits: number,
  dist: number,
  totalHours: number,
  participated: number
) {
  return { name, credits, dist, totalHours, participated };
}

const rows = [
  createData("Jane Cooper", 305, 3.7, 2, 3),
  createData("Leo Bool", 452, 25.0, 8, 5),
  createData("Karl Viol", 262, 16.0, 0, 9),
  createData("Frozen yoghurt", 159, 6.0, 8, 5),
  createData("Ginger Bread", 356, 16.0, 8, 5),
  createData("Honey Comb", 408, 3.2, 8, 5),
  createData("Ice Sandwich", 237, 9.0, 8, 5),
  createData("Jelly Bean", 375, 0.0, 8, 5),
  createData("Kit Kat", 518, 26.0, 8, 5),
  createData("Lolli Pop", 392, 0.2, 8, 5),
  createData("Marsh Mallow", 318, 0, 8, 5),
  createData("Nou Gat", 360, 19.0, 8, 5),
  createData("Oreo Twwix", 437, 18.0, 8, 5),
].sort((a, b) => (a.credits < b.credits ? -1 : 1));

export default function CustomPaginationTable() {
  const intl = useIntl();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const Tablet = useMediaQuery("(max-width:899px)");
  const Mobile = useMediaQuery("(max-width:599px)");

  // Avoid a layout jump when reaching the last page with empty rows.
  // const emptyRows =
  //   page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (Tablet) {
    return (
      <>
        {rows.map((row, index) => (
          <Grid
            key={index}
            item
            xs={Mobile ? 12 : 4}
            md={4}
            sx={{ p: 1, pt: 0 }}
          >
            <Paper
              sx={{
                boxShadow: "none",

                p: 2,
                border: "1px solid #f3f6f7",
                borderRadius: "12px",
              }}
            >
              <Box display="flex" justifyContent="space-between" pb={1}>
                <Typography
                  sx={{ fontSize: "12px", fontWeight: 600, color: "secondary" }}
                >
                  <FormattedMessage id="name" defaultMessage="Name" />
                </Typography>
                <Typography
                  sx={{ fontSize: "12px", fontWeight: 600, color: purple }}
                >
                  {" "}
                  {row.name}
                </Typography>
              </Box>
              <Box display="flex" justifyContent="space-between" pb={1}>
                <Typography
                  sx={{ fontSize: "12px", fontWeight: 600, color: "secondary" }}
                >
                  <FormattedMessage
                    id="total-totalCredits"
                    defaultMessage="Total Credits"
                  />
                </Typography>
                <Typography
                  sx={{ fontSize: "12px", fontWeight: 500, color: purple }}
                >
                  {" "}
                  {row.credits}
                </Typography>
              </Box>
              <Box display="flex" justifyContent="space-between" pb={1}>
                <Typography
                  sx={{ fontSize: "12px", fontWeight: 600, color: "secondary" }}
                >
                  <FormattedMessage
                    id="table-savedDistance"
                    defaultMessage="Distance saved"
                  />
                  :
                </Typography>
                <Typography
                  sx={{ fontSize: "12px", fontWeight: 500, color: purple }}
                >
                  {row.dist}
                </Typography>
              </Box>

              <Box display="flex" justifyContent="space-between" pb={1}>
                <Typography
                  sx={{ fontSize: "12px", fontWeight: 600, color: "secondary" }}
                >
                  <FormattedMessage
                    id="table-meetingHours"
                    defaultMessage="Meeting Hours"
                  />
                  :
                </Typography>
                <Typography
                  sx={{ fontSize: "12px", fontWeight: 500, color: purple }}
                >
                  {row.totalHours}
                </Typography>
              </Box>

              <Box display="flex" justifyContent="space-between" pb={1}>
                <Typography
                  sx={{ fontSize: "12px", fontWeight: 600, color: "secondary" }}
                >
                  <FormattedMessage
                    id="table-mettingsParticipated"
                    defaultMessage="Meetings participated"
                  />
                  :
                </Typography>
                <Typography
                  sx={{ fontSize: "12px", fontWeight: 500, color: purple }}
                >
                  {row.participated}
                </Typography>
              </Box>
            </Paper>
          </Grid>
        ))}
      </>
    );
  } else {
    return (
      <Grid item md={12} sx={{ p: 3, pt: 0 }}>
        <TableContainer
          sx={{
            border: `1px solid ${border}`,
            borderRadius: "8px",
            boxShadow: "none",
          }}
          component={Paper}
        >
          <Table
            sx={{ minWidth: "320px" }}
            aria-label="custom pagination table"
          >
            <TableHead>
              <TableRow>
                <StyledTableCell>
                  <FormattedMessage id="name" defaultMessage="Name" />
                </StyledTableCell>
                <StyledTableCell align="center">
                  <FormattedMessage
                    id="total-carbonCredits"
                    defaultMessage="Total Carbon credits"
                  />
                </StyledTableCell>
                <StyledTableCell align="center">
                  <FormattedMessage
                    id="table-savedDistance"
                    defaultMessage="Distance saved"
                  />
                </StyledTableCell>
                <StyledTableCell width="10%" align="center">
                  <FormattedMessage
                    id="table-spentOnMeeting"
                    defaultMessage="Total h spent on meeting"
                  />
                </StyledTableCell>
                <StyledTableCell>
                  <FormattedMessage
                    id="table-nbMettingsParticipated"
                    defaultMessage="Nb of meetings participated"
                  />
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0
                ? rows.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : rows
              ).map((row, index) => (
                <TableRow
                  key={row.name}
                  sx={{
                    "& .MuiTableCell-root": {
                      borderBottom: index === 4 ? "none" : "",
                    },
                  }}
                >
                  <TableCell>
                    <Box display="flex" flexWrap="wrap">
                      <Avatar sx={{ mr: 2 }} />
                      <Typography
                        display="flex"
                        flexDirection="column"
                        whiteSpace="nowrap"
                        sx={{
                          fontSize: "14px",
                          fontWeight: 500,
                          "& span": {
                            fontWeight: 400,
                            color: customGrey,
                          },
                        }}
                        color="secondary"
                      >
                        {row.name}
                        <span>jane.cooper@example.com</span>
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell sx={{ width: "20%" }} align="center">
                    <Typography
                      whiteSpace="nowrap"
                      sx={{
                        fontSize: "14px",
                        fontWeight: 400,
                        color: customGrey,
                      }}
                    >
                      <FormattedMessage
                        id="infoCard.saved-kg"
                        defaultMessage="{number} kg of CO2 saved"
                        values={{
                          number: (
                            <FormattedNumber
                              value={row.credits}
                              // eslint-disable-next-line
                              style="decimal"
                            />
                          ),
                        }}
                      />
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ width: "20%" }} align="center">
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: 400,
                        color: customGrey,
                      }}
                    >
                      {Math.round(row.dist)}
                      &nbsp;km
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ width: "20%" }} align="center">
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: 400,
                        color: customGrey,
                      }}
                    >
                      {row.totalHours}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ width: "20%" }} align="center">
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: 400,
                        color: customGrey,
                      }}
                    >
                      {row.participated}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          component="div"
          sx={{
            "& .MuiToolbar-root": {
              pt: 3,
              pl: 0,
              display: "flex",
              justifyContent: "space-between",
              "& .MuiTablePagination-spacer": {
                display: "none",
              },
              "& p": {
                m: 0,
                fontSize: "14px",
                fontWeight: 500,
              },
            },
          }}
          rowsPerPageOptions={[5]}
          colSpan={3}
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          SelectProps={{
            inputProps: {
              "aria-label": "rows per page",
            },
            native: true,
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
          labelDisplayedRows={({ from, to, count }) =>
            intl.formatMessage(
              {
                id: "pagination-from-to-of",
                defaultMessage: "{from}–{to} of {count}",
              },
              { from, to, count }
            )
          }
        />
      </Grid>
    );
  }
}
