import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";

interface Props {
  file: File | null;
  defaultImage?: string;
  style?: any;
}

const Thumb: React.FC<Props> = ({ file, defaultImage = null, style }) => {
  const [state, setState] = useState<{
    loading: boolean;
    thumb: any;
  }>({
    loading: false,
    thumb: null,
  });

  useEffect(() => {
    if (!file) return;
    setState({ loading: true, thumb: null });
    const reader = new FileReader();

    reader.onloadend = () => {
      setState({ loading: false, thumb: reader.result });
    };
    reader.readAsDataURL(file);
  }, [file]);

  if (!file && defaultImage) {
    return (
      <Box
        sx={{
          height: "184px",
          width: "184px",
          position: "relative",
        }}
      >
        <img
          style={{ position: "absolute" }}
          src={defaultImage}
          alt=""
          className="img-thumbnail"
          height={174}
          width={174}
        />
        <Box
          sx={{
            width: "184px",
            height: "184px",
            position: "absolute",
            backgroundColor: "rgba(255,255,255,0.5)",
          }}
        ></Box>
      </Box>
    );
  }
  if (!file) return null;

  const { loading, thumb } = state;

  if (loading) {
    return <p>loading...</p>;
  }

  return (
    <img
      src={thumb}
      alt={file.name}
      className="img-thumbnail"
      // height={300}
      width={174}
      style={style}
    />
  );
};

export default Thumb;
