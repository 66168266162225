import React, { ChangeEvent, useEffect, useState } from "react";
import { loader } from "graphql.macro";
import styles from "../assets/stylesheets/pages.module.scss";
import { Query } from "@apollo/client/react/components";
import Error from "../components/Error";
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  InputAdornment,
  OutlinedInput,
  Typography,
} from "@mui/material";
import CurrentUser from "../components/CurrentUser";
import MeetingList from "../components/MyRooms/components/MeetingList";
import SearchIcon from "@mui/icons-material/Search";
import { FormattedMessage, useIntl } from "react-intl";
import DateComponent from "../components/DatePicker";
import { NavLink } from "react-router-dom";
import { calculateDateRange } from "../lib/helpers";
import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { getSelectedOrganization } from "../lib/account";

dayjs.extend(utc);
dayjs.extend(timezone);

const GET_ROOMS_QUERY = loader("../schema/query/GetRooms.graphql");

interface Data {
  me: {
    id: string;
    meetings: Array<Room>;
  };
}

interface Variables {
  organizationId: string | null;
  startDate: string; // datetime in utc
  endDate: string | null;
}

interface Props {
  selectedOrganizationId: string | null;
  setSelectedOrganizationId: (id: string) => void;
}

const History: React.FC<Props> = ({
  selectedOrganizationId,
  setSelectedOrganizationId,
}) => {
  const intl = useIntl();
  const [filterName, setFilterName] = useState(
    localStorage.getItem("filterName") ?? ""
  );

  const [week, setWeek] = useState<Dayjs>(() => {
    const savedWeek = localStorage.getItem("week");
    return savedWeek ? dayjs(savedWeek) : dayjs();
  });

  const [selectedDate, setSelectedDate] = useState<Dayjs>(() => {
    const savedDate = localStorage.getItem("selectedDate");
    return savedDate ? dayjs(savedDate) : dayjs();
  });
  const [selectedMenuItem, setSelectedMenuItem] = useState(
    localStorage.getItem("selectedMenuItem") ?? "Month"
  );

  useEffect(() => {
    const saveDateToLocalStorage = () => {
      localStorage.setItem("filterName", filterName);
      localStorage.setItem("selectedMenuItem", selectedMenuItem);
      localStorage.setItem("selectedDate", selectedDate.toISOString());
      localStorage.setItem("week", week.toISOString());
    };
    saveDateToLocalStorage();
  }, [filterName, selectedMenuItem, selectedDate, week]);

  const DateRange = calculateDateRange(
    dayjs(selectedMenuItem === "Week" ? week : selectedDate),
    selectedMenuItem
  );

  const desiredTimezone = "Europe/Paris";
  const formattedStart = DateRange.start
    .tz(desiredTimezone)
    .utc()
    .format("YYYY-MM-DDTHH:mm:ss[Z]");
  const formattedEnd = DateRange.end
    .tz(desiredTimezone)
    .utc()
    .format("YYYY-MM-DDTHH:mm:ss[Z]");

  return (
    <CurrentUser>
      {(currentUser: User | null) => {
        if (currentUser) {
          const organization = getSelectedOrganization(
            currentUser,
            selectedOrganizationId
          );
          selectedOrganizationId = organization ? organization.id : null;
          return (
            <Query<Data, Variables>
              query={GET_ROOMS_QUERY}
              variables={{
                organizationId: selectedOrganizationId,
                startDate: formattedStart,
                endDate: formattedEnd,
              }}
            >
              {({ data, loading, error }) => {
                if (loading) return null;
                if (error || !data) return <Error error={error} />;
                if (!data.me) {
                  return null;
                }

                let filteredMeetings = data.me.meetings;

                const filterRoomByName = (room: Room) =>
                  room.name?.toLowerCase().indexOf(filterName.toLowerCase()) !==
                  -1;

                if (filterName.length > 0) {
                  filteredMeetings = filteredMeetings.filter(filterRoomByName);
                }

                const rooms = filteredMeetings;

                const handleFilterNameChange = (
                  event: ChangeEvent<HTMLInputElement>
                ) => {
                  const { value } = event.target;
                  setFilterName(value);
                };

                return (
                  <Container
                    disableGutters
                    sx={{
                      minWidth: "85%",
                      display: "flex",
                      flex: 1,
                      flexDirection: "column",
                    }}
                  >
                    <Box
                      sx={{
                        border: "1px solid #D1D5DB",
                        borderRadius: "12px",
                        mr: 3,
                        ml: 3,
                      }}
                    >
                      <Box
                        className={styles.historyHeaderBox}
                        display="flex"
                        sx={{
                          p: "14px 16px",
                          alignItems: "center",
                          flexWrap: "wrap",
                          gap: "10px 20px",
                        }}
                      >
                        <Typography
                          sx={{
                            display: "flex",
                            flexGrow: 1,
                            fontSize: "24px",
                            fontWeight: 600,
                            color: "#931ffa",
                            pt: "8px",
                            pb: "8px",
                          }}
                        >
                          <FormattedMessage
                            id="header.history"
                            defaultMessage="History of meetings"
                          />
                        </Typography>

                        <OutlinedInput
                          sx={{
                            flexDirection: "row-reverse",
                            borderRadius: "6px",
                          }}
                          placeholder={intl.formatMessage({
                            id: "search-by-keywords",
                            defaultMessage: "Search by keywords",
                          })}
                          name="filterName"
                          type="search"
                          onChange={handleFilterNameChange}
                          value={filterName}
                          endAdornment={
                            <InputAdornment position="end">
                              <SearchIcon />
                            </InputAdornment>
                          }
                        />

                        <DateComponent
                          setSelectedDate={setSelectedDate}
                          selectedDate={selectedDate}
                          week={week}
                          setWeek={setWeek}
                          weekRange={
                            selectedMenuItem === "Week" ? DateRange : null
                          }
                          selectedMenuItem={selectedMenuItem}
                          setSelectedMenuItem={setSelectedMenuItem}
                        />
                        <Box display="flex" flexGrow="1" justifyContent="end">
                          <NavLink to="/">
                            <Button variant="outlined" color="secondary">
                              <FormattedMessage
                                id="actions.back"
                                defaultMessage="Back"
                              />
                            </Button>
                          </NavLink>
                        </Box>
                      </Box>

                      <Divider className={styles.divider} />
                      <Grid minHeight="60vh" container spacing={2} padding={3}>
                        <MeetingList
                          selectedOrganizationId={selectedOrganizationId}
                          setSelectedOrganizationId={setSelectedOrganizationId}
                          loading={loading}
                          rooms={rooms}
                          isFinished={true}
                        />
                      </Grid>
                    </Box>
                  </Container>
                );
              }}
            </Query>
          );
        }
        return null;
      }}
    </CurrentUser>
  );
};

export default History;
