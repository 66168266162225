import React, { useEffect, useState } from "react";
import { LinearProgress } from "@mui/material";
interface Props {}

const ProgressBarTimer: React.FC<Props> = () => {
  const [percent, setPercent] = useState<number>(1);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (percent < 95) {
        setPercent(percent + 1);
      }
    }, 1000);
    return () => clearTimeout(timeout);
  }, [percent]);

  return (
    <LinearProgress
      sx={{
        width: "100%",
        minHeight: "8px",
        borderRadius: "100px",
        bgcolor: "#E5E7EB",
      }}
      variant="indeterminate"
      value={percent}
    />
  );
};

export default ProgressBarTimer;
